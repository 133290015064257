
import {
  Component,
  ChangeDetectorRef, ChangeDetectionStrategy,
  ViewChild,
  OnInit, ElementRef, TemplateRef
} from '@angular/core';

import { Observable, Subject } from 'rxjs';

import {
  CalendarEvent, CalendarView, CalendarDayViewBeforeRenderEvent, CalendarMonthViewBeforeRenderEvent, CalendarWeekViewBeforeRenderEvent,
} from 'angular-calendar';
import { Router, ActivatedRoute } from '@angular/router';
import { ClinicianService } from 'src/app/staff/clinician/clinician.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { CommonService } from 'src/app/common/common.service';
import { MatDialog } from '@angular/material/dialog';
import { constantVariables } from 'src/app/constant/constant';
import { Global } from 'src/app/global/global.model';
import * as moment from 'moment';
import { TreatmentService } from 'src/app/staff/treatment/treatment.service';
import { SnackBarService } from 'src/app/services/snackBar.service';
import { SettingsService } from 'src/app/common/pages/settings/settings.service';
import { MomentDateModule } from '@angular/material-moment-adapter';
import { colors } from 'src/app/_models/_Patient/_ScheduleAppoinment';
import RRule from 'rrule';
import { ViewPeriod } from 'calendar-utils';
import { DatePipe, TitleCasePipe } from '@angular/common';
import { CdkDragDrop, moveItemInArray, transferArrayItem }
  from '@angular/cdk/drag-drop';
import { AdminTherapyPopUpObj } from 'src/app/_models/_Treatment/_TherapyPopUpObj';
import { FormControl, NgForm } from '@angular/forms';
import { dtoReScheduleModality } from 'src/app/_models/_Treatment/scheduleModality';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { PageAccessService } from 'src/app/rolemanagement/PageRoleAccessService';
import { RoelAccess } from 'src/app/_models/_Common/roleAccessMaster';
import { TherapyDetailsPopUpComponent } from '../../therapy-details-pop-up/therapy-details-pop-up.component';
import { C } from '@angular/cdk/keycodes';
import { map, startWith } from 'rxjs/operators';
import { MatTableDataSource } from '@angular/material/table';
declare var $: any;
PickedDate: Date;
interface RecurringEvent {
  title: string;
  color: any;
  end?: any;
  id?: any;
  tags?: any;
  scheduledDate?: any;
  therapy?: any;
  rrule?: {
    freq: any;
    bymonth?: number;
    bymonthday?: number;
    byweekday?: any;
    dtstart: any;
    until: any;
    interval?: any;
    therapy?: any;
  };
}


@Component({
  selector: 'app-scheduler-calender',
  templateUrl: './scheduler-calender.component.html',
  styleUrls: ['./scheduler-calender.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SchedulerCalenderComponent implements OnInit {
  @ViewChild('clientModal') clientModal = {} as TemplateRef<any>
  dataSourceClient: MatTableDataSource<any>;

  @ViewChild('dayviewDiv') dayviewDiv: ElementRef<HTMLElement>;
  @ViewChild('btn_dayview') btn_dayview: ElementRef<HTMLElement>;
  @ViewChild('tbl_reArrange') tbl_reArrange: ElementRef<HTMLElement>;
  @ViewChild('downloadLink') downloadLink: ElementRef;

  view: CalendarView = CalendarView.Week;
  CalendarView = CalendarView;
  viewDate = moment().toDate();
  refresh: Subject<any> = new Subject();
  events: CalendarEvent[] = [];
  activeDayIsOpen: boolean = false;
  dtoAppointmentType: any;
  dtoMeeting; any;
  isShowMoreEvent = false;
  EVENT_LIMIT = 4;
  moreTaskEvents: any = [];
  recurringEvents: RecurringEvent[] = [];
  viewPeriod: ViewPeriod;
  appointments: any = [];
  drpClinicians: any = [];
  selectedClinician: any;
  allAppointments: any = [];
  isAllLocation: boolean = false;
  isShowDayView = false;
  isReArrangeClinician: boolean = false;
  ExistingReArrangeDatas = [];
  reArrangeClinicianDatas = [];
  rearrangeSelectedIndex: number;
  //
  filterdate: any;
  filterweekStartDate: any;
  filterweekEndDate: any;
  filterMonth: any;
  filterMonthStartDate: any;
  filterMonthEndDate: any;
  filterMonthdisplay: any;
  grouptherapyAppointments = [];
  filterType: any;
  //Scheduler Pop up variables:
  ObjTherapyPopUp: AdminTherapyPopUpObj;
  isShowTherapydetails: boolean = true;
  isnodata: boolean = false;
  roleAccess: RoelAccess;
  viewRightsMsg: any;
  isShowScheduleModality: boolean;
  isNoClinician: boolean = false;
  ddlpatientList: any = [];
  filteredOptions: any = [];
  searchTerm: string = '';
  isSchedulerview: boolean = false
  patientNameDetails: any;
  filterControl = new FormControl();
  currentDate: any;
  dateview : boolean = false;
  isAutocompleteEnabled: boolean = true;
  dayView : boolean = false;
  appoitmenttypefirst : any;
  colornew="#00FF00";
  secondaryId : any;
  constructor(public global: Global, public settingsService: SettingsService, private cdr: ChangeDetectorRef,
    private snackbar: SnackBarService, private route: ActivatedRoute, private router: Router, private dialog: MatDialog, public commonService: CommonService, public clinicianservice: ClinicianService, public spinnerservice: NgxSpinnerService, public treatmentservice: TreatmentService,
    public datepipe: DatePipe, public roleaccessservice: PageAccessService
  ) {
    
  }

  clientdialogRef: any;
  clientTherapydetails: any = [];
  clientTherapydetailsDelete: any = [];

  appointmentType: any = 'past';
  date_view:any;
  patientNameview : any;
  ddlclientNameList: any = [];
  ngOnInit(): void {
    this.isShowTherapydetails = false;
    const pageid = constantVariables.pagelists.ScheduleAppointmentsTherapies;
    this.roleAccess = this.roleaccessservice.GetRoleAccessByPageId(pageid);
    this.viewRightsMsg = constantVariables.PagePermissionMsg.IsView;
    this.ObjTherapyPopUp = new AdminTherapyPopUpObj();
    this.dtoAppointmentType = 1;
    this.selectedClinician = -1;
    this.currentDate = moment(new Date()).format("MM/DD/YYYY");
    this.getPatientlist();
    this.getClientName();

    if (localStorage.getItem("adminDayViewClinician") == null || localStorage.getItem("adminDayViewClinician") == "0") {
      localStorage.setItem("adminDayViewClinician", "-1");
    }


    // if(this.roleAccess.isView )
    // {
    //   // if(Number(localStorage.getItem('sfcclocation'))>0){
    //     if(Number(localStorage.getItem('sfcclocation'))>0){
    //     this.isAllLocation=false;
    //     this.filterbyWeek();
    //      this.getExistingReArrangeClinician();
    //     // this.loadAllAppointments();
    //     this.loadAllAppointmentsNew();
    //       }
    //      else
    //      {
    //       let stringToSplit =localStorage.getItem('location');
    //       // let location = stringToSplit.split('"');
    //       let location = stringToSplit.replace(/['"]+/g, '');
    //       if(Number(location) > 0){
    //         this.isAllLocation=false;
    //       }
    //       else{
    //         this.isAllLocation=true;
    //       }
    //      }
    // }
    if (this.roleAccess.isView) {
      // if(Number(localStorage.getItem('sfcclocation'))>0){
      if (Number(localStorage.getItem('sfcclocation')) > 0) {
        this.isAllLocation = false;
        this.filterbyWeek();
        this.getExistingReArrangeClinician();
        // this.loadAllAppointments();

      }
      else {
        let stringToSplit = localStorage.getItem('location');
        // let location = stringToSplit.split('"');
        let location = stringToSplit.replace(/['"]+/g, '');
        if (Number(location) > 0) {
          this.isAllLocation = false;
          this.filterbyWeek();
          this.getExistingReArrangeClinician();
        }
        else {
          this.isAllLocation = true;
        }
      }
    }
    this.commonService.TherapyPopup.subscribe((res) => {
      this.OncloseTherapyDetailspopup();

    });
    this.commonService.ScheduleModality.subscribe((res) => {
      this.OnCloseScheduleModality();

    });
    // this.filterControl.valueChanges.subscribe(
    //   (value: any) => {
    //     this.filteredOptions = this.filterOptions(value)
    //   }
    // );

  }


  setView(view: CalendarView) {

    this.isShowDayView = false;
    this.view = view;
    //  this.loadAllAppointments();

    if (view == "week") { this.filterbyWeek(); }
    if (view == "month") { this.filterbyMonth(); }
    if (this.selectedClinician == -1 && this.dtoAppointmentType == 1) {
      this.selectedClinician = this.drpClinicians[0].clinicianId;
      localStorage.setItem("adminDayViewClinician", this.selectedClinician);
      //this.loadAllAppointments();
      this.loadAllAppointmentsNew();

    }
    else if (this.selectedClinician != -1 && this.dtoAppointmentType == 1) {
      //this.loadAllAppointments();
      this.loadAllAppointmentsNew();

    }
    else if (this.dtoAppointmentType != 1) {
      this.onAppointmentClinicianchange();
    }

  }
  dayViewClick() {
    if(this.dayView = true){
      this.date_view = this.date_view
      this.selectedClinician = (localStorage.getItem("adminDayViewClinician") == "-1") ? -1 : localStorage.getItem("adminDayViewClinician");
      localStorage.setItem("adminDayViewClinician", this.selectedClinician);
      localStorage.setItem("adminDayViewAppointment", this.dtoAppointmentType);
      this.isShowDayView = true;
      return;
    }
    else{
      this.date_view = null;
      this.selectedClinician = (localStorage.getItem("adminDayViewClinician") == "-1") ? -1 : localStorage.getItem("adminDayViewClinician");
      localStorage.setItem("adminDayViewClinician", this.selectedClinician);
      localStorage.setItem("adminDayViewAppointment", this.dtoAppointmentType);
      this.isShowDayView = true;
    }
  
    //  this.router.navigate(['/day-view-scheduler'], { queryParams: { appointmentId: this.commonService.FrontEndEncryption(this.dtoAppointmentType),selectedClinician: this.commonService.FrontEndEncryption(this.selectedClinician)
    //                                                } });
  }

  closeOpenMonthViewDay() {
    this.activeDayIsOpen = false;


    if (this.view == "week") { this.filterbyWeek(); }
    if (this.view == "month") { this.filterbyMonth(); }
    this.events = [];
    this.recurringEvents = [];
    this.loadAllAppointmentsNew();
    // this.getGrpTherapy();
    // this.refresh.next();
  }
  onchangeMeetingtype(event) {
    this.dtoAppointmentType = undefined;

    //this.loadGroupTherapymeeting();
    this.loadAllAppointmentsNew();
    this.getGrpTherapy();
    this.refresh.next();
  }
  onchangeAppointmentOld(event) {
    this.recurringEvents = [];
    this.events = [];
    this.filterType = event;
    if (event == 1) {
      // this.loadAllAppointments();
      this.loadAllAppointmentsNew();

    } else if (event == 2) {
      this.loadIntake();
    } else if (event == 3) {
      this.loadGroupTherapy();
    } else if (event == 4) {
      this.loadIndividualTherapy();
    } else if (event == 5) {
      this.loadFamilyTherapy();
    }
    else if (event == 6) {
      this.loadTour();
    }
    else if (event == 7) {
      this.loadPsycatricTherapy()
    }
    else if (event == 8) {
      this.loadPsycatricEvaluationTherapy()
    }
    else if (event == 9) {
      this.loadPsycatricMedication()
    }
    this.refresh.next();
  }
  loadAllAppointments() {
    this.spinnerservice.show();
    this.drpClinicians = [];
    this.commonService.getIntakeAppointments(this.dtoAppointmentType).subscribe(
      res => {
        this.spinnerservice.hide();
        if (res != null) {
          //  let tmplist=res;

          //  this.drpClinicians= tmplist.filter(
          //   (t2, i, arr) => arr.findIndex(t => t.clinicianId === t2.clinicianId && t.clinicianId !=0) === i );

          if (localStorage.getItem('roleId') == "4") {
            this.appointments = res;
            this.allAppointments = res;
            this.appointments = this.appointments.filter(x => x.patientAccountId == localStorage.getItem('patientAccountId'))
            res = this.appointments;
          }
          else {
            this.allAppointments = res;
            this.allAppointments = this.allAppointments.filter(x => x.locationId == localStorage.getItem('sfcclocation'));
            this.appointments = this.allAppointments;
            res = this.appointments;
            let tmplist = res;
            //  this.drpClinicians= tmplist.filter(
            //    (t2, i, arr) => arr.findIndex(t => t.clinicianId === t2.clinicianId && t.clinicianId !=0) === i );

            //}

            //clinician filter
            //if(this.ExistingReArrangeDatas.length>0 && this.drpClinicians.length>0)
            //{
            //let tmprearnge= this.ExistingReArrangeDatas;
            //let arr1=this.drpClinicians;
            //if(tmprearnge.length==arr1.length)
            //{
            this.drpClinicians = [];
            this.drpClinicians = this.ExistingReArrangeDatas;
            //}
            //}
            if (this.drpClinicians.length > 0) {
              this.selectedClinician = (localStorage.getItem("adminDayViewClinician") == "-1") ? this.drpClinicians[0].clinicianId :
                localStorage.getItem("adminDayViewClinician");
                localStorage.setItem("adminDayViewClinician",this.selectedClinician);
            }

          }
          if (this.appointments.length > 0) {
            this.events = [];
            this.recurringEvents = [];
            res = [];
            res = (this.selectedClinician != -1) ? this.appointments.filter(x => x.clinicianId == this.selectedClinician) : this.appointments;
            for (var i = 0; i < res.length; i++) {
              let thrpyid = 0;
              if (res[i].typeId == 2) {
                thrpyid = constantVariables.therapy.IndividualTheraphy;
              }
              else if (res[i].typeId == 3) {
                thrpyid = constantVariables.therapy.FamilyTheraphy;
              }
              else if (res[i].typeId == 5) {
                thrpyid = constantVariables.therapy.PsycatricTheraphy;
              }
              else if (res[i].typeId == 8) {
                thrpyid = constantVariables.therapy.PsycatricEvaluation;
              }
              else if (res[i].typeId == 9) {
                thrpyid = constantVariables.therapy.PsycatricMedication;
              }
              else if (res[i].typeId == 0) {
                thrpyid = constantVariables.therapy.Tour;
              }
              else if (res[i].typeId == 1) {
                thrpyid = constantVariables.therapy.Intake;
              }
              console.log(res[i].appointmentName);
              //  else  if(res[i].typeId!=1 && res[i].typeId!=2 && res[i].typeId!=3 )
              // else  if(res[i].typeId!=0 && res[i].typeId!=1 && res[i].typeId!=2 && res[i].typeId!=3 && res[i].typeId!=5 && res[i].typeId!=8 && res[i].typeId!=9 )
              //     {
              //       thrpyid=constantVariables.therapy.GroupTheraphy;
              //     }
              let _obj = {
                
                start: new Date(moment(res[i].scheduleDate).format("MM/DD/YYYY") + " " + moment(res[i].fromTime).format("HH:mm")),
                // end: res[i].scheduleEndDate == null ? (moment(res[i].scheduleDate).format("MM/DD/YYYY") + " " + moment(res[i].toTime).format("HH:mm a")) : new Date(moment(res[i].scheduleEndDate).format("MM/DD/YYYY") + " " + moment(res[i].toTime).format("HH:mm")),
                end: moment(res[i].toTime).format("HH:mm"),
                title: res[i].appointmentName,
                 color: {
                  primary: res[i].colorCode,
                  secondary: res[i].colorCode
                },
                cssClass: 'event-anchor',
                id: res[i].appointmentId,
                rrule: {
                  freq: RRule.DAILY,
                  dtstart: new Date(moment(res[i].scheduleDate).format("MM/DD/YYYY") + " " + moment(res[i].fromTime).format("HH:mm")),
                  until: res[i].scheduleEndDate == null ? (moment(res[i].scheduleDate).format("MM/DD/YYYY") + " " + moment(res[i].toTime).format("HH:mm")) : new Date(moment(res[i].scheduleEndDate).format("MM/DD/YYYY") + " " + moment(res[i].toTime).format("HH:mm")),
                  interval: 1
                },
                // tags :  res[i].typeId
                tags: thrpyid
              }

              this.recurringEvents = [...this.recurringEvents, _obj];

            }
            this.updateCalendarEventsonLoad();
            this.cdr.detectChanges();
            this.refresh.next();
            this.getGrpTherapy();
          }
          else {
            this.isnodata = true;
            this.cdr.detectChanges();

          }
        }

        //this.loadGroupTherapymeeting();
        // this.getGrpTherapy();
      },
      err => {
        this.spinnerservice.hide();
      },
    );


  }

  loadIntake() {
    if (this.appointments != null) {
      //let res = this.appointments.filter(x => x.templateId == 1 && x.typeId == 1);
      let res = (this.selectedClinician != -1) ? this.appointments.filter(x => x.templateId == 1 && x.typeId == 1 && x.clinicianId == this.selectedClinician) : this.appointments.filter(x => x.templateId == 1 && x.typeId == 1);
      this.events = [];
      this.recurringEvents = [];
      for (var i = 0; i < res.length; i++) {

        let _obj = {
          start: new Date(moment(res[i].scheduleDate).format("MM/DD/YYYY") + " " + moment(res[i].fromTime).format("HH:mm")),
          // end: res[i].scheduleEndDate == null ? (moment(res[i].scheduleDate).format("MM/DD/YYYY") + " " + moment(res[i].toTime).format("HH:mm a")) : new Date(moment(res[i].scheduleEndDate).format("MM/DD/YYYY") + " " + moment(res[i].toTime).format("HH:mm")),
          end: moment(res[i].toTime).format("HH:mm"),
          title: res[i].appointmentName,
           color: {
                  primary: res[i].colorCode,
                  secondary: res[i].colorCode
                },
          cssClass: 'event-anchor',
          id: res[i].appointmentId,
          rrule: {
            freq: RRule.DAILY,
            dtstart: new Date(moment(res[i].scheduleDate).format("MM/DD/YYYY") + " " + moment(res[i].fromTime).format("HH:mm")),
            until: res[i].scheduleEndDate == null ? (moment(res[i].scheduleDate).format("MM/DD/YYYY") + " " + moment(res[i].toTime).format("HH:mm")) : new Date(moment(res[i].scheduleEndDate).format("MM/DD/YYYY") + " " + moment(res[i].toTime).format("HH:mm")),

          },
          tags: constantVariables.therapy.Intake
        }

        this.recurringEvents = [...this.recurringEvents, _obj];
        this.updateCalendarEventsonLoad();
        this.cdr.detectChanges();
        this.refresh.next();
      }
    }
  }


  loadFamilyTherapy() {
    if (this.appointments != null) {
      // let res = this.appointments.filter(x => x.templateId == 1 && x.typeId == 3);
      let res = (this.selectedClinician != -1) ? this.appointments.filter(x => x.templateId == 1 && x.typeId == 3 && x.clinicianId == this.selectedClinician) :
        this.appointments.filter(x => x.templateId == 1 && x.typeId == 3);
      this.events = [];
      this.recurringEvents = [];
      for (var i = 0; i < res.length; i++) {
        let _obj = {
          start: new Date(moment(res[i].scheduleDate).format("MM/DD/YYYY") + " " + moment(res[i].fromTime).format("HH:mm")),
          // end: res[i].scheduleEndDate == null ? (moment(res[i].scheduleDate).format("MM/DD/YYYY") + " " + moment(res[i].toTime).format("HH:mm a")) : new Date(moment(res[i].scheduleEndDate).format("MM/DD/YYYY") + " " + moment(res[i].toTime).format("HH:mm")),
          end: moment(res[i].toTime).format("HH:mm"),
          title: res[i].appointmentName,
           color: {
                  primary: res[i].colorCode,
                  secondary: res[i].colorCode
                },
          cssClass: 'event-anchor',
          id: res[i].appointmentId,
          rrule: {
            freq: RRule.DAILY,
            dtstart: new Date(moment(res[i].scheduleDate).format("MM/DD/YYYY") + " " + moment(res[i].fromTime).format("HH:mm")),
            until: res[i].scheduleEndDate == null ? (moment(res[i].scheduleDate).format("MM/DD/YYYY") + " " + moment(res[i].toTime).format("HH:mm")) : new Date(moment(res[i].scheduleEndDate).format("MM/DD/YYYY") + " " + moment(res[i].toTime).format("HH:mm")),

          },
          tags: constantVariables.therapy.FamilyTheraphy
        }

        this.recurringEvents = [...this.recurringEvents, _obj];
        this.updateCalendarEventsonLoad();
        this.cdr.detectChanges();
        this.refresh.next();
      }
    }
  }
  loadIndividualTherapy() {
    if (this.appointments != null) {
      //let res = this.appointments.filter(x => x.templateId == 1 && x.typeId == 2);
      let res = (this.selectedClinician != -1) ? this.appointments.filter(x => x.templateId == 1 && x.typeId == 2 && x.clinicianId == this.selectedClinician) :
        this.appointments.filter(x => x.templateId == 1 && x.typeId == 2);
      this.events = [];
      this.recurringEvents = [];
      for (var i = 0; i < res.length; i++) {

        let _obj = {
          start: new Date(moment(res[i].scheduleDate).format("MM/DD/YYYY") + " " + moment(res[i].fromTime).format("HH:mm")),
          //end: res[i].scheduleEndDate == null ? (moment(res[i].scheduleDate).format("MM/DD/YYYY") + " " + moment(res[i].toTime).format("HH:mm a")) : new Date(moment(res[i].scheduleEndDate).format("MM/DD/YYYY") + " " + moment(res[i].toTime).format("HH:mm")),
          end: moment(res[i].toTime).format("HH:mm"),
          title: res[i].appointmentName,
           color: {
                  primary: res[i].colorCode,
                  secondary: res[i].colorCode
                },
          cssClass: 'event-anchor',
          id: res[i].appointmentId,
          rrule: {
            freq: RRule.DAILY,
            dtstart: new Date(moment(res[i].scheduleDate).format("MM/DD/YYYY") + " " + moment(res[i].fromTime).format("HH:mm")),
            until: res[i].scheduleEndDate == null ? (moment(res[i].scheduleDate).format("MM/DD/YYYY") + " " + moment(res[i].toTime).format("HH:mm")) : new Date(moment(res[i].scheduleEndDate).format("MM/DD/YYYY") + " " + moment(res[i].toTime).format("HH:mm")),

          },
          tags: constantVariables.therapy.IndividualTheraphy
          //tags : 1
        }
        this.recurringEvents = [...this.recurringEvents, _obj];
        this.updateCalendarEventsonLoad();
        this.cdr.detectChanges();
        this.refresh.next();
      }
    }
  }
  loadTour() {
    if (this.appointments != null) {
      // let res = this.appointments.filter(x => x.templateId == 3);
      let res = (this.selectedClinician != -1) ? this.appointments.filter(x => x.templateId == 3 && x.clinicianId == this.selectedClinician) :
        this.appointments.filter(x => x.templateId == 3);
      this.events = [];
      this.recurringEvents = [];
      for (var i = 0; i < res.length; i++) {

        let _obj = {
          start: new Date(moment(res[i].scheduleDate).format("MM/DD/YYYY") + " " + moment(res[i].fromTime).format("HH:mm")),
          // end: res[i].scheduleEndDate == null ? (moment(res[i].scheduleDate).format("MM/DD/YYYY") + " " + moment(res[i].toTime).format("HH:mm a")) : new Date(moment(res[i].scheduleEndDate).format("MM/DD/YYYY") + " " + moment(res[i].toTime).format("HH:mm")),
          end: moment(res[i].toTime).format("HH:mm"),
          title: res[i].appointmentName,
           color: {
                  primary: res[i].colorCode,
                  secondary: res[i].colorCode
                },
          cssClass: 'event-anchor',
          id: res[i].appointmentId,
          rrule: {
            freq: RRule.DAILY,
            dtstart: new Date(moment(res[i].scheduleDate).format("MM/DD/YYYY") + " " + moment(res[i].fromTime).format("HH:mm")),
            until: res[i].scheduleEndDate == null ? (moment(res[i].scheduleDate).format("MM/DD/YYYY") + " " + moment(res[i].toTime).format("HH:mm")) : new Date(moment(res[i].scheduleEndDate).format("MM/DD/YYYY") + " " + moment(res[i].toTime).format("HH:mm")),
          },
          tags: constantVariables.therapy.Tour
        }

        this.recurringEvents = [...this.recurringEvents, _obj];
        this.updateCalendarEventsonLoad();
        this.cdr.detectChanges();
        this.refresh.next();
      }
    }
  }
  loadGroupTherapy() {
    //this.loadGroupTherapymeeting();
    this.getGrpTherapy();
  }

  loadGroupTherapymeeting() {
    this.spinnerservice.show();
    this.commonService.getGroupTherapy(0).subscribe(
      res => {

        this.spinnerservice.hide();
        if (res != null) {
          // res=res.filter(x=>x.locationId.includes(localStorage.getItem('sfcclocation')))

          for (var i = 0; i < res.length; i++) {
            let byweek = res[i].isMonday == true ? RRule.MO : null; +',' + res[i].isTuesday == 1 ? RRule.TU : null; +',' + res[i].isWednesday == 1 ? RRule.WE : null; +',' + res[i].isThursday == 1 ? RRule.TH : null; +',' + res[i].isFriday == 1 ? RRule.FR : null; +',' + res[i].isSaturday == 1 ? RRule.SA : null; +',' + res[i].isSunday == 1 ? RRule.SU : null;
            let _obj = {
              start: new Date(moment(res[i].startDate).format("MM/DD/YYYY") + " " + moment(res[i].startTime).format("HH:mm")),
              end: moment(res[i].endTime).format('HH:mm'),
              title: "<div class='grp-thrphy-clr cal-container'><div class='cal-therapyname'>" + res[i].therapyName + "</div><div class='cal-assignedname text-truncate'>" + res[i].groupTherapyName + "</div><div class='cal-availablity'> <span class='slot-img mr-2 text-success'>Available: <span class='badge badge-dark'>" + (res[i].capacity - res[i].booked) + "</span></span> <span class='slot-img mr-2 text-danger'>Booked: <span class='badge badge-dark'>" + res[i].booked + "</span></span></div><a class='link-in-calendar' (click)='onCreateDaySch()'> Create  Day Schedule </a> </div>",
               color: {
                  primary: res[i].colorCode,
                  secondary: res[i].colorCode
                },
              cssClass: 'event-anchor',
              id: res[i].groupTherapyId,
              scheduledDate: res[i].scheduledDate,
              rrule: {
                freq: RRule.DAILY,
                dtstart: new Date(moment(res[i].startDate).format("MM/DD/YYYY") + " " + moment(res[i].startTime).format("HH:mm")),
                //until: new Date(moment(res[i].startDate).format("MM/DD/YYYY") + " " + moment(res[i].startTime, 'HHmm').add(res[i].duration, 'minutes').format('HH:mm')),
                until: (res[i].isRecurrance == true || res[i].isEndDate == true) ? (res[i].endDate == null || res[i].isEndDate == false) ? new Date(moment(res[i].startDate).add(180, 'days').format("MM/DD/YYYY") + " " + moment(res[i].endTime).format('HH:mm')) : new Date(moment(res[i].endDate).format("MM/DD/YYYY") + " " + moment(res[i].endTime).format('HH:mm')) : new Date(moment(res[i].startDate).format("MM/DD/YYYY") + " " + moment(res[i].endTime).format("HH:mm")),
                //byweekday:[res[i].isMonday == true ? RRule.MO: null; +','+res[i].isTuesday == 1 ? RRule.TU: null ; +','+res[i].isWednesday == 1 ? RRule.WE: null;+','+res[i].isThursday == 1 ? RRule.TH: null;+','+res[i].isFriday == 1 ? RRule.FR: null;+','+res[i].isSaturday == 1 ? RRule.SA: null;+','+res[i].isSunday == true ? RRule.SU: null]
              },
              //  tags: 1,
              tags: constantVariables.therapy.GroupTheraphy
            }


            this.recurringEvents = [...this.recurringEvents, _obj];
          }

          this.updateCalendarEventsonLoad();
          this.cdr.detectChanges();
          this.refresh.next();
        }
      },
      err => {
        this.spinnerservice.hide();
      },
    );
  }
  updateCalendarEventsonLoad() {
    var offset = new Date().getTimezoneOffset();
    if (this.recurringEvents && this.recurringEvents.length > 0) {
      this.events = [];
      this.recurringEvents.forEach((event) => {
        if (event.end != 'Invalid date') {
          //let item = event;
          const rule: RRule = new RRule({
            ...event.rrule,
            // dtstart: moment(viewRender.period.start).startOf('day').toDate(),
            // until: moment(viewRender.period.end).endOf('day').toDate(),
          });
          let endTime = event?.end;
          let therapy = event?.therapy;
          let eventTitle = event?.title;
          let currentDate = new Date();
          const { title, color, id, tags } = event;
          var arraydate = event.scheduledDate != null ? event.scheduledDate.split(',') : null;
          const END = event.end
          rule.all().forEach((date) => {
            this.events.push({
              //title: arraydate != null ? arraydate.indexOf(moment(date).format("MM/DD/YYYY")) !== -1 ? title.replace('Create  Day Schedule', 'View  Day Schedule') : title : title,
              title: title.includes("DS") ? (therapy == 3 && ((moment(date).format("MM/DD/YYYY") == moment(currentDate).format("MM/DD/YYYY") && event.end >= moment(new Date()).format("HH:mm")) || (date > currentDate))) ? title.replace("{{DS}}", "ADD DS") : title.replace("{{DS}}", "") : title,
              color,
              end: moment(moment(date).format("MM/DD/YYYY") + " " + END).utcOffset(-offset).toDate(),
              start: moment(date).utcOffset(-offset).toDate(),
              id: id,
              tags: tags

            });
          });
        }
      });
      //this.cdr.detectChanges();
      this.refresh.next();
    }
  }

  handleMoreEvent(e: any, events: CalendarEvent[]) {
    this.moreTaskEvents = events;
    this.isShowMoreEvent = !this.isShowMoreEvent;
  }
  handleEvent(action: string, event: CalendarEvent): void {

    if (event.tags == constantVariables.therapy.GroupTheraphy) {

      this.ObjTherapyPopUp = new AdminTherapyPopUpObj();
      let GrpTherapylstForFilter = this.grouptherapyAppointments
      if (GrpTherapylstForFilter.filter(m => m.groupTherapyId == event.id).length > 0) {
        let currentEvent = GrpTherapylstForFilter.filter(m => m.groupTherapyId == event.id)[0];
        this.ObjTherapyPopUp.id = currentEvent.groupTherapyId;
        this.ObjTherapyPopUp.start = event.start;
        this.ObjTherapyPopUp.scheduleDate = event.start;
        this.ObjTherapyPopUp.fromtime = currentEvent.startTime;
        this.ObjTherapyPopUp.tags = event.tags;
        this.ObjTherapyPopUp.ScheduleName = currentEvent.groupTherapyName;
        this.ObjTherapyPopUp.clinicianContactNo = currentEvent.clinicianContact;
        this.ObjTherapyPopUp.clinicianName = currentEvent.clinicianName;
        this.ObjTherapyPopUp.clinicianemail = currentEvent.clinicianEmail;
        this.ObjTherapyPopUp.ClinicianId = this.selectedClinician;
        this.ObjTherapyPopUp.DayScheduleSubGroupId = currentEvent.dayScheduleSubGroupId;
        this.ObjTherapyPopUp.GroupTherapyId = currentEvent.groupTherapyId;
        this.ObjTherapyPopUp.DayScheduleAssignId = currentEvent.dayScheduleAssignId;
        this.ObjTherapyPopUp.SfccMasterId = currentEvent.sfccMasterId;
        this.ObjTherapyPopUp.Duration = currentEvent.duration;
        this.ObjTherapyPopUp.endTime = currentEvent.endTime;
        this.ObjTherapyPopUp.ImmediateActionId = currentEvent.immediateActionId;
        //this.ObjTherapyPopUp.Location = +localStorage.getItem('location');
        let duration = (currentEvent.duration >= 60 ? Math.floor(currentEvent.duration / 60).toString() + " hr" : "") + (currentEvent.duration % 60 > 0 ? " " + (currentEvent.duration % 60).toString() + " min" : "")
        // let duration = (currentEvent.duration > 60 ? ((currentEvent.duration / 60).toString() + " hr") : "") + (currentEvent.duration % 60 > 0 ? ((currentEvent.duration % 60).toString() + " min") : "")
        this.ObjTherapyPopUp.ScheduleDuration = duration
        //this.isShowMoreEvent = false;
        this.spinnerservice.show();
        this.treatmentservice.GetGroupCount(currentEvent.groupTherapyId, moment(event.start).format("MM/DD/YYYY")).subscribe((res: any) => {
          this.spinnerservice.hide();
          this.ObjTherapyPopUp.GroupTherapyLength = res;
          this.isShowTherapydetails = true;
        });
      }
    }
    else if (event.tags == constantVariables.therapy.IndividualTheraphy || event.tags == constantVariables.therapy.FamilyTheraphy
      || event.tags == constantVariables.therapy.PsycatricTheraphy || event.tags == constantVariables.therapy.PsycatricEvaluation || event.tags == constantVariables.therapy.PsycatricMedication
      || event.tags == constantVariables.therapy.Tour || event.tags == constantVariables.therapy.Intake || event.tags == constantVariables.therapy.Tour || event.tags == constantVariables.therapy.Phonecall 
      || event.tags == constantVariables.therapy.School || event.tags == constantVariables.therapy.Speech || event.tags == constantVariables.therapy.Nutrition || event.tags == constantVariables.therapy.BlockTimeScheduler|| event.tags == constantVariables.therapy.PhonecallSceduler ) {
      this.ObjTherapyPopUp = new AdminTherapyPopUpObj();
      let MeetingDatafilter = this.appointments;
      var start = moment(event.start).format('HH:mm') + ":00" ;
      var end = moment(event.end).format('HH:mm') + ":00" ;
      if (MeetingDatafilter.filter(m => m.appointmentId == event.id && (this.datepipe.transform(m.scheduleDate, "yyyy-MM-dd")) == (this.datepipe.transform(event.start, "yyyy-MM-dd"))).length > 0) {
        // let currentEvent = MeetingDatafilter.filter(m => m.appointmentId == event.id && (this.datepipe.transform(m.scheduleDate, "yyyy-MM-dd")) == (this.datepipe.transform(event.start, "yyyy-MM-dd")));

        let currentEvent = MeetingDatafilter.filter(m => m.appointmentId == event.id && (moment(m.fromTime).format('HH:mm') + ":00" ) == start && (moment(m.toTime).format('HH:mm') + ":00" )== end
        && (this.datepipe.transform(m.scheduleDate, "yyyy-MM-dd")) == (this.datepipe.transform(event.start, "yyyy-MM-dd")));

        this.ObjTherapyPopUp.id = currentEvent[0].appointmentId;
        this.ObjTherapyPopUp.start = event.start;
        this.ObjTherapyPopUp.scheduleDate = event.start;
        this.ObjTherapyPopUp.fromtime = currentEvent[0].fromTime;
        this.ObjTherapyPopUp.tags = event.tags;
        this.ObjTherapyPopUp.ScheduleName = currentEvent[0].scheduleName;
        this.ObjTherapyPopUp.clinicianContactNo = currentEvent[0].clinicianContact;
        this.ObjTherapyPopUp.clinicianName = currentEvent[0].clinicianName;
        this.ObjTherapyPopUp.clinicianemail = currentEvent[0].clinicianEmail;
        this.ObjTherapyPopUp.patientName = currentEvent[0].userName;
        this.ObjTherapyPopUp.AdminNotes = currentEvent[0].adminNotes;
        this.ObjTherapyPopUp.email = currentEvent[0].patientEmail;
        this.ObjTherapyPopUp.contactNo = currentEvent[0].patientContact;
        this.ObjTherapyPopUp.ScheduleTimeId = currentEvent[0].meetingScheduleTimeId;
        this.ObjTherapyPopUp.MeetingRoom = currentEvent[0].meetingRoom;
        this.ObjTherapyPopUp.Location = currentEvent[0].locationId;
        this.ObjTherapyPopUp.Duration = currentEvent[0].duration;
        this.ObjTherapyPopUp.patientAccountId = currentEvent[0].patientAccountId;
        this.ObjTherapyPopUp.isPatientVisit = currentEvent[0].isPatientVisist
        this.ObjTherapyPopUp.ClinicianId = this.selectedClinician;
        this.ObjTherapyPopUp.OffSiteAddress = currentEvent[0].offSiteAddress;
        this.ObjTherapyPopUp.AppointmentId = currentEvent[0].appointmentId;
        this.ObjTherapyPopUp.ImmediateActionId = currentEvent[0].immediateActionId;
        this.ObjTherapyPopUp.Status = currentEvent[0].status;
        this.ObjTherapyPopUp.ScheduleDuration = currentEvent[0].meetingScheduleDuration;
        this.ObjTherapyPopUp.SfccMasterId = Number(currentEvent[0].locationId);
        if ((this.datepipe.transform(new Date(), "yyyy-MM-dd")) <= (this.datepipe.transform(event.start, "yyyy-MM-dd"))) {
          this.ObjTherapyPopUp.MeetingLink = currentEvent[0].meetingLink;
        }
        else {
          this.ObjTherapyPopUp.MeetingLink = null;
        }
        this.ObjTherapyPopUp.MeetingType = currentEvent[0].meetingTypeId;
        this.isShowTherapydetails = true;
      }
    }



  }
  therapyPopupShow(){
    this.isShowTherapydetails = true;
  }
  async eventClicked(event: CalendarEvent): Promise<void> {
    //New method for pop up details loading:
    if (event.tags == constantVariables.therapy.GroupTheraphy) {
      this.ObjTherapyPopUp = new AdminTherapyPopUpObj();
      let GrpTherapylstForFilter = this.grouptherapyAppointments
      if (GrpTherapylstForFilter.filter(m => m.groupTherapyId == event.id).length > 0) {

        let currentEvent = GrpTherapylstForFilter.filter(m => m.groupTherapyId == event.id)[0];
        this.ObjTherapyPopUp.id = currentEvent.groupTherapyId;
        this.ObjTherapyPopUp.start = event.start;
        this.ObjTherapyPopUp.scheduleDate = event.start;
        this.ObjTherapyPopUp.fromtime = currentEvent.startTime;
        this.ObjTherapyPopUp.tags = event.tags;
        this.ObjTherapyPopUp.ScheduleName = currentEvent.groupTherapyName;
        this.ObjTherapyPopUp.clinicianContactNo = currentEvent.clinicianContact;
        this.ObjTherapyPopUp.clinicianName = currentEvent.clinicianName;
        this.ObjTherapyPopUp.clinicianemail = currentEvent.clinicianEmail;
        this.ObjTherapyPopUp.DayScheduleSubGroupId = currentEvent.dayScheduleSubGroupId;
        this.ObjTherapyPopUp.GroupTherapyId = currentEvent.groupTherapyId;
        this.ObjTherapyPopUp.DayScheduleAssignId = currentEvent.dayScheduleAssignId;
        this.ObjTherapyPopUp.ClinicianId = this.selectedClinician;
        this.ObjTherapyPopUp.SfccMasterId = currentEvent.sfccMasterId;
        this.ObjTherapyPopUp.ImmediateActionId = currentEvent.immediateActionId;
        // console.log(currentEvent.duration)
        let duration = (currentEvent.duration > 60 ? ((currentEvent.duration / 60).toString() + " hr") : "") + (currentEvent.duration % 60 > 0 ? ((currentEvent.duration % 60).toString() + " min") : "")
        this.ObjTherapyPopUp.ScheduleDuration = duration
        this.spinnerservice.show();
        let res = await this.treatmentservice.GetGroupCountAsync(currentEvent.groupTherapyId, moment(event.start).format("MM/DD/YYYY"));
          this.spinnerservice.hide();
          this.ObjTherapyPopUp.GroupTherapyLength = res;
          this.isShowTherapydetails = false;
       this.therapyPopupShow();
      //  this.commonService.GroupPopShow.next(false);
   
      }
    }
    else if (event.tags == constantVariables.therapy.IndividualTheraphy || event.tags == constantVariables.therapy.FamilyTheraphy
      || event.tags == constantVariables.therapy.PsycatricTheraphy || event.tags == constantVariables.therapy.PsycatricEvaluation || event.tags == constantVariables.therapy.PsycatricMedication
      || event.tags == constantVariables.therapy.Tour || event.tags == constantVariables.therapy.Intake || event.tags == constantVariables.therapy.Tour || event.tags == constantVariables.therapy.Phonecall
      || event.tags == constantVariables.therapy.School || event.tags == constantVariables.therapy.Speech || event.tags == constantVariables.therapy.Nutrition ||event.tags==constantVariables.therapy.BlockTimeScheduler || event.tags==constantVariables.theraphy.PhonecallSceduler) {

      this.ObjTherapyPopUp = new AdminTherapyPopUpObj();
      let MeetingDatafilter = this.appointments
      if (MeetingDatafilter.filter(m => m.appointmentId == event.id && (this.datepipe.transform(m.scheduleDate, "yyyy-MM-dd")) == (this.datepipe.transform(event.start, "yyyy-MM-dd"))).length > 0) {
        let currentEvent = MeetingDatafilter.filter(m => m.appointmentId == event.id && (this.datepipe.transform(m.scheduleDate, "yyyy-MM-dd")) == (this.datepipe.transform(event.start, "yyyy-MM-dd")));
        this.ObjTherapyPopUp.id = currentEvent[0].appointmentId;
        this.ObjTherapyPopUp.start = event.start;
        this.ObjTherapyPopUp.scheduleDate = event.start;
        this.ObjTherapyPopUp.fromtime = currentEvent[0].fromTime;
        this.ObjTherapyPopUp.tags = event.tags;
        this.ObjTherapyPopUp.ScheduleName = currentEvent[0].scheduleName;
        this.ObjTherapyPopUp.clinicianContactNo = currentEvent[0].clinicianContact;
        this.ObjTherapyPopUp.clinicianName = currentEvent[0].clinicianName;
        this.ObjTherapyPopUp.clinicianemail = currentEvent[0].clinicianEmail;
        this.ObjTherapyPopUp.patientName = currentEvent[0].userName;
        this.ObjTherapyPopUp.AdminNotes = currentEvent[0].adminNotes;
        this.ObjTherapyPopUp.email = currentEvent[0].patientEmail;
        this.ObjTherapyPopUp.contactNo = currentEvent[0].patientContact;
        this.ObjTherapyPopUp.ScheduleTimeId = currentEvent[0].meetingScheduleTimeId;
        this.ObjTherapyPopUp.MeetingRoom = currentEvent[0].meetingRoom;
        this.ObjTherapyPopUp.Location = currentEvent[0].locationId;
        this.ObjTherapyPopUp.Duration = currentEvent[0].duration;
        this.ObjTherapyPopUp.patientAccountId = currentEvent[0].patientAccountId;
        this.ObjTherapyPopUp.isPatientVisit = currentEvent[0].isPatientVisist
        this.ObjTherapyPopUp.ClinicianId = this.selectedClinician;
        this.ObjTherapyPopUp.OffSiteAddress = currentEvent[0].offSiteAddress;
        this.ObjTherapyPopUp.ScheduleDuration = currentEvent[0].meetingScheduleDuration;
        this.ObjTherapyPopUp.SfccMasterId = Number(currentEvent[0].locationId);
        this.ObjTherapyPopUp.ImmediateActionId = currentEvent[0].immediateActionId;
        if ((this.datepipe.transform(new Date(), "yyyy-MM-dd")) <= (this.datepipe.transform(event.start, "yyyy-MM-dd"))) {
          this.ObjTherapyPopUp.MeetingLink = currentEvent[0].meetingLink;
        }
        else {
          this.ObjTherapyPopUp.MeetingLink = null;
        }
        this.ObjTherapyPopUp.MeetingType = currentEvent[0].meetingTypeId;
     
          this.isShowTherapydetails = true;
          this.roleAccess.isView = true;
    
      }
    }
  }
  onCreateDaySch(id, event) {
    this.router.navigate(['/day-schedule'], { queryParams: { id: this.commonService.FrontEndEncryption(id), date: this.commonService.FrontEndEncryption(moment(event.start).format('MM/DD/YYYY')) } });
  }

  loadPsycatricTherapy() {
    if (this.appointments != null) {
      //  let res = this.appointments.filter(x => x.templateId == 1 && x.typeId == 5);
      let res = (this.selectedClinician != -1) ? this.appointments.filter(x => x.templateId == 1 && x.typeId == 5 && x.clinicianId == this.selectedClinician) :
        this.appointments.filter(x => x.templateId == 1 && x.typeId == 5);
      this.events = [];
      this.recurringEvents = [];
      for (var i = 0; i < res.length; i++) {

        let _obj = {
          start: new Date(moment(res[i].scheduleDate).format("MM/DD/YYYY") + " " + moment(res[i].fromTime).format("HH:mm")),
          //  end: res[i].scheduleEndDate == null ? (moment(res[i].scheduleDate).format("MM/DD/YYYY") + " " + moment(res[i].toTime).format("HH:mm a")) : new Date(moment(res[i].scheduleEndDate).format("MM/DD/YYYY") + " " + moment(res[i].toTime).format("HH:mm")),
          end: moment(res[i].toTime).format("HH:mm"),
          title: res[i].appointmentName,
           color: {
                  primary: res[i].colorCode,
                  secondary: res[i].colorCode
                },
          cssClass: 'event-anchor',
          id: res[i].appointmentId,
          rrule: {
            freq: RRule.DAILY,
            dtstart: new Date(moment(res[i].scheduleDate).format("MM/DD/YYYY") + " " + moment(res[i].fromTime).format("HH:mm")),
            until: res[i].scheduleEndDate == null ? (moment(res[i].scheduleDate).format("MM/DD/YYYY") + " " + moment(res[i].toTime).format("HH:mm")) : new Date(moment(res[i].scheduleEndDate).format("MM/DD/YYYY") + " " + moment(res[i].toTime).format("HH:mm")),
          },
          //tags: constantVariables.therapy.IndividualTheraphy
          tags: 6
        }
        this.recurringEvents = [...this.recurringEvents, _obj];
        this.updateCalendarEventsonLoad();
        this.cdr.detectChanges();
        this.refresh.next();
      }
    }
  }

  loadPsycatricEvaluationTherapy() {
    if (this.appointments != null) {
      //let res = this.appointments.filter(x => x.templateId == 1 && x.typeId == 8);
      let res = (this.selectedClinician != -1) ? this.appointments.filter(x => x.templateId == 1 && x.typeId == 8 && x.clinicianId == this.selectedClinician) :
        this.appointments.filter(x => x.templateId == 1 && x.typeId == 8);
      this.events = [];
      this.recurringEvents = [];
      for (var i = 0; i < res.length; i++) {

        let _obj = {
          start: new Date(moment(res[i].scheduleDate).format("MM/DD/YYYY") + " " + moment(res[i].fromTime).format("HH:mm")),
          //  end: res[i].scheduleEndDate == null ? (moment(res[i].scheduleDate).format("MM/DD/YYYY") + " " + moment(res[i].toTime).format("HH:mm a")) : new Date(moment(res[i].scheduleEndDate).format("MM/DD/YYYY") + " " + moment(res[i].toTime).format("HH:mm")),
          end: moment(res[i].toTime).format("HH:mm"),
          title: res[i].appointmentName,
           color: {
                  primary: res[i].colorCode,
                  secondary: res[i].colorCode
                },
          cssClass: 'event-anchor',
          id: res[i].appointmentId,
          rrule: {
            freq: RRule.DAILY,
            dtstart: new Date(moment(res[i].scheduleDate).format("MM/DD/YYYY") + " " + moment(res[i].fromTime).format("HH:mm")),
            until: res[i].scheduleEndDate == null ? (moment(res[i].scheduleDate).format("MM/DD/YYYY") + " " + moment(res[i].toTime).format("HH:mm")) : new Date(moment(res[i].scheduleEndDate).format("MM/DD/YYYY") + " " + moment(res[i].toTime).format("HH:mm")),
          },
          //tags: constantVariables.therapy.IndividualTheraphy
          tags: 8
        }
        this.recurringEvents = [...this.recurringEvents, _obj];
        this.updateCalendarEventsonLoad();
        this.cdr.detectChanges();
        this.refresh.next();
      }
    }
  }

  loadPsycatricMedication() {
    if (this.appointments != null) {
      //let res = this.appointments.filter(x => x.templateId == 1 && x.typeId == 9);
      let res = (this.selectedClinician != -1) ? this.appointments.filter(x => x.templateId == 1 && x.typeId == 9 && x.clinicianId == this.selectedClinician) :
        this.appointments.filter(x => x.templateId == 1 && x.typeId == 9);
      this.events = [];
      this.recurringEvents = [];
      for (var i = 0; i < res.length; i++) {

        let _obj = {
          start: new Date(moment(res[i].scheduleDate).format("MM/DD/YYYY") + " " + moment(res[i].fromTime).format("HH:mm")),
          //  end: res[i].scheduleEndDate == null ? (moment(res[i].scheduleDate).format("MM/DD/YYYY") + " " + moment(res[i].toTime).format("HH:mm a")) : new Date(moment(res[i].scheduleEndDate).format("MM/DD/YYYY") + " " + moment(res[i].toTime).format("HH:mm")),
          end: moment(res[i].toTime).format("HH:mm"),
          title: res[i].appointmentName,
           color: {
                  primary: res[i].colorCode,
                  secondary: res[i].colorCode
                },
          cssClass: 'event-anchor',
          id: res[i].appointmentId,
          rrule: {
            freq: RRule.DAILY,
            dtstart: new Date(moment(res[i].scheduleDate).format("MM/DD/YYYY") + " " + moment(res[i].fromTime).format("HH:mm")),
            until: res[i].scheduleEndDate == null ? (moment(res[i].scheduleDate).format("MM/DD/YYYY") + " " + moment(res[i].toTime).format("HH:mm")) : new Date(moment(res[i].scheduleEndDate).format("MM/DD/YYYY") + " " + moment(res[i].toTime).format("HH:mm")),
          },
          //tags: constantVariables.therapy.IndividualTheraphy
          tags: 9
        }
        this.recurringEvents = [...this.recurringEvents, _obj];
        this.updateCalendarEventsonLoad();
        this.cdr.detectChanges();
        this.refresh.next();
      }
    }
  }

  onAppointmentClinicianchange() {
    if (this.isShowDayView == true) {
      localStorage.setItem("adminDayViewClinician", this.selectedClinician);
      localStorage.setItem("adminDayViewAppointment", this.dtoAppointmentType);
      this.commonService.adminDayViewDrpChange.next(false);

    }
    else {
      if (this.selectedClinician == -1) {
        this.selectedClinician = this.drpClinicians[0].clinicianId;
        this.snackbar.error("Must select one clinician");
      }
      localStorage.setItem("adminDayViewClinician", this.selectedClinician);
      localStorage.setItem("adminDayViewAppointment", this.dtoAppointmentType);
      this.spinnerservice.hide();
      this.recurringEvents = [];
      this.events = [];
      if (this.view == "week") { this.filterbyWeek(); }
      if (this.view == "month") { this.filterbyMonth(); }
      //if(this.dtoAppointmentType!=3 )
      // {
      if (this.dtoAppointmentType == 1) {
        this.loadAllAppointmentsNew();
      } else if (this.dtoAppointmentType == 2) {
        this.loadIntake();
      }
      else if (this.dtoAppointmentType == 4) {
        this.loadIndividualTherapy();
      }
      else if (this.dtoAppointmentType == 5) {
        this.loadFamilyTherapy();
      }
      else if (this.dtoAppointmentType == 6) {
        this.loadTour();
      }
      else if (this.dtoAppointmentType == 7) {
        this.loadPsycatricTherapy();
      }
      else if (this.dtoAppointmentType == 8) {
        this.loadPsycatricEvaluationTherapy()
      }
      else if (this.dtoAppointmentType == 9) {
        this.loadPsycatricMedication()
      }
      else if (this.dtoAppointmentType == 3) {
        this.getGrpTherapy();
      }
      else if (this.dtoAppointmentType == 11) {
        this.loadPhonecallMeeting();
      }
      else if (this.dtoAppointmentType == 10) {
        this.loadSchoolMeeting();
      }
      else if (this.dtoAppointmentType == 12) {
        this.loadSpeechTherapy();
      }
      else if (this.dtoAppointmentType == 13) {
        this.loadNutritionTherapy();
      }
      else if (this.dtoAppointmentType == 14) {
        this.loadBlockTime();
      }
      else if (this.dtoAppointmentType == 15) {
        this.loadPhonecallMeeting();
      }
      this.refresh.next();
      // }
      //  else if (this.dtoAppointmentType == 3) {
      //     //this.loadGroupTherapymeeting();
      //     this.getGrpTherapy();
      //   }
    }

  }
  loadallAppointmentdata() {
    this.spinnerservice.show();
    this.appointments = this.allAppointments;
    let res = (this.selectedClinician != -1) ? this.appointments.filter(x => x.clinicianId == this.selectedClinician) :
      this.appointments;
    this.events = [];
    this.recurringEvents = [];
    for (var i = 0; i < res.length; i++) {
      let thrpyid = 0;
      if (res[i].typeId == 2) {
        thrpyid = constantVariables.therapy.IndividualTheraphy;
      }
      else if (res[i].typeId == 3 && res[i].templateId == 1) {
        thrpyid = constantVariables.therapy.FamilyTheraphy;
      }
      else if (res[i].typeId == 5) {
        thrpyid = constantVariables.therapy.PsycatricTheraphy;
      }
      else if (res[i].typeId == 8) {
        thrpyid = constantVariables.therapy.PsycatricEvaluation;
      }
      else if (res[i].typeId == 9) {
        thrpyid = constantVariables.therapy.PsycatricMedication;
      }
      else if (res[i].typeId == 0 && res[i].templateId == 3) {
        thrpyid = constantVariables.therapy.Tour;
      }
      else if (res[i].typeId == 1) {
        thrpyid = constantVariables.therapy.Intake;
      }
      else if (res[i].typeId == 15) {
        thrpyid = constantVariables.therapy.PhonecallSceduler;
      }
      else if (res[i].typeId == 16) {
        thrpyid = constantVariables.therapy.SchoolmeetingScheduler;
      }
      else if (res[i].typeId == 17) {
        thrpyid = constantVariables.therapy.BlockTimeScheduler;
      }
     
      // else  if(res[i].typeId!=1 && res[i].typeId!=2 && res[i].typeId!=3 && res[i].typeId!=5 && res[i].typeId!=8 && res[i].typeId!=9 )
      // {
      //   thrpyid=constantVariables.therapy.GroupTheraphy;
      // }
      let _obj = {
        start: new Date(moment(res[i].scheduleDate).format("MM/DD/YYYY") + " " + moment(res[i].fromTime).format("HH:mm")),
        end: moment(res[i].toTime).format("HH:mm"),
        title: res[i].appointmentName,
         color: {
                  primary: res[i].colorCode,
                  secondary: res[i].colorCode
                },
        cssClass: 'event-anchor',
        id: res[i].appointmentId,
        rrule: {
          freq: RRule.DAILY,
          dtstart: new Date(moment(res[i].scheduleDate).format("MM/DD/YYYY") + " " + moment(res[i].fromTime).format("HH:mm")),
          until: res[i].scheduleEndDate == null ? (moment(res[i].scheduleDate).format("MM/DD/YYYY") + " " + moment(res[i].toTime).format("HH:mm")) : new Date(moment(res[i].scheduleEndDate).format("MM/DD/YYYY") + " " + moment(res[i].toTime).format("HH:mm")),
          interval: 1
        },
        tags: thrpyid
      }

      this.recurringEvents = [...this.recurringEvents, _obj];

    }
    this.updateCalendarEventsonLoad();
    this.cdr.detectChanges();
    this.refresh.next();
    this.spinnerservice.hide();
    //this.loadGroupTherapymeeting();
    this.getGrpTherapy();
  }
  OnreArrangeClinician() {
    this.isReArrangeClinician = true;
  }

  getTdindex(indx) { this.rearrangeSelectedIndex = indx; }

  moveCDown() {
    let indx = this.rearrangeSelectedIndex;
    if (indx < this.drpClinicians.length) {
      const tmp = this.drpClinicians[indx + 1];
      this.drpClinicians[indx + 1] = this.drpClinicians[indx];
      this.drpClinicians[indx] = tmp;
      //this.rearrangeSelectedIndex=-1;
      if (this.rearrangeSelectedIndex == 0) {
        this.rearrangeSelectedIndex = 1;
      }
      else if (this.rearrangeSelectedIndex > 0) {
        this.rearrangeSelectedIndex = (this.rearrangeSelectedIndex) + 1;
      }
    }
  }
  moveCUp() {
    let indx = this.rearrangeSelectedIndex;
    if (indx > 0) {
      const tmp = this.drpClinicians[indx - 1];
      this.drpClinicians[indx - 1] = this.drpClinicians[indx];
      this.drpClinicians[indx] = tmp;
      // this.rearrangeSelectedIndex=-1;
      if (this.rearrangeSelectedIndex == 0) {
        this.rearrangeSelectedIndex = 0;
      }
      else if (this.rearrangeSelectedIndex > 0) {
        this.rearrangeSelectedIndex = (this.rearrangeSelectedIndex) - 1;
      }
    }
  }

  reArrangePopUpClose() { this.isReArrangeClinician = false; }
  OnRearrancgeClinician(evnt) {
    this.rearrangeSelectedIndex = evnt.target.selectedIndex;
  }
  reArrangeClinicianSave() {
    this.reArrangeClinicianDatas = [];
    const table = <HTMLTableElement>document.getElementById('tbl_reArrange');

    for (var i = 0; i < table.rows.length; i++) {
      this.reArrangeClinicianDatas.push({
        clinicianName: table.rows[i].cells[0].innerText,
        ClinicianId: table.rows[i].cells[1].innerText,
        OrderSeq: i,

        ReArrangedBy: localStorage.getItem('id')
      });
    }

    const dataToEncrypt = this.commonService.DataToEncrypt(this.reArrangeClinicianDatas, false);
    this.spinnerservice.show();
    this.isReArrangeClinician = false;
    this.settingsService.PostReArrangeClinician(dataToEncrypt).subscribe(
      (res: any) => {
        // this.selectedClinician=(this.isShowDayView)?0:this.drpClinicians[0].clinicianId;
        this.selectedClinician = (localStorage.getItem("adminDayViewClinician") == "-1") ? this.drpClinicians[0].clinicianId :
          localStorage.getItem("adminDayViewClinician");
          localStorage.setItem("adminDayViewClinician", this.selectedClinician);
        this.spinnerservice.hide();
        this.snackbar.success(res);

      },
      err => {
        this.spinnerservice.hide();
        this.snackbar.error(err.error);
      },
    );
  }

  getExistingReArrangeClinician() {
    var location = localStorage.getItem('sfcclocation')
    if (location == null) {
      let stringToSplit = localStorage.getItem('location');
      location = stringToSplit.replace(/['"]+/g, '');

    }
    this.commonService.getReArrangeClinician(localStorage.getItem('id'), location).subscribe(
      res => {
        this.spinnerservice.hide();
        if (res != null) {
          
          this.ExistingReArrangeDatas = res;
          if (this.ExistingReArrangeDatas.length == this.drpClinicians.length) {
            this.drpClinicians = [];
            this.drpClinicians = this.ExistingReArrangeDatas;

            // this.selectedClinician=(this.isShowDayView)?0:this.drpClinicians[0].clinicianId;
            this.selectedClinician = (localStorage.getItem("adminDayViewClinician") == "-1") ? this.drpClinicians[0].clinicianId :
              localStorage.getItem("adminDayViewClinician");
              localStorage.setItem("adminDayViewClinician" , this.selectedClinician);

            this.isReArrangeClinician = false;
          }
        }

        this.loadAllAppointmentsNew();
      },
      err => {
        this.isReArrangeClinician = false;
        this.spinnerservice.hide();
        this.snackbar.error(err.error);
      },
    );
  }

  drop(event: CdkDragDrop<string[]>): void {
    moveItemInArray(this.drpClinicians, event.previousIndex, event.currentIndex);
    this.rearrangeSelectedIndex = event.currentIndex;
  }

  filterbyWeek() {
    this.filterType = 2;
    this.onTabChange(this.filterType);
    this.view = CalendarView.Week;
    if(this.dateview == true){
      this.setdefaultValue();
      this.ngOnInit();
     }
    this.filterweekStartDate = moment(this.viewDate).startOf('week').toString();
    this.filterweekEndDate = moment(this.viewDate).endOf('week').toString();
  }
  filterbyMonth() {
    this.filterType = 3;
    this.view = CalendarView.Month;
    if(this.dateview == true){
      this.setdefaultValue();
      this.ngOnInit();
     }
    this.filterMonthdisplay = moment().format('MMM YYYY');
    this.filterMonth = Number((moment(this.viewDate)).format('M'));

  }
  onTabChange(tab) {
    this.router.navigate([], {
      relativeTo: this.route, queryParams: {
        tab: tab,
      }
    });
    this.filterType = tab;

  }

  getGrpTherapy() {
    if (this.filterType == 3) {
      var grpDate = new Date(this.filterweekStartDate);
      this.filterMonthStartDate = new Date(grpDate.getFullYear(), grpDate.getMonth(), 1);
      this.filterMonthEndDate = new Date(grpDate.getFullYear(), grpDate.getMonth() + 1, 0);
    }
    let _obj = {
      userid: this.selectedClinician,
      CurrentDate: this.filterType == 1 ? moment(this.filterdate).format("MM/DD/YYYY") : null,
      StartDate: this.filterType == 2 ? moment(this.filterweekStartDate).format("MM/DD/YYYY") : this.filterType == 3 ? moment(this.filterMonthStartDate).format("MM/DD/YYYY") : null,
      EndDate: this.filterType == 2 ? moment(this.filterweekEndDate).format("MM/DD/YYYY") : this.filterType == 3 ? moment(this.filterMonthEndDate).format("MM/DD/YYYY") : null,
      CurrentMonth: this.filterType == 3 ? this.filterMonth : '0',
      LocationId: localStorage.getItem('sfcclocation')
    }
    this.spinnerservice.show();
    this.grouptherapyAppointments = [];
    const dataToEncrypt = this.commonService.DataToEncrypt(_obj, false);
    this.clinicianservice.GetGrpTherapyAppointmentsForAdmin(dataToEncrypt).subscribe(
      (response: any) => {
        this.spinnerservice.show();
        var res = JSON.parse(response);
        this.spinnerservice.hide();
        if (res != null) {
          this.grouptherapyAppointments = res;
          for (var i = 0; i < res.length; i++) {
            if(res[i].isRecurrance == 1 && res[i].frequency == 6){
              let _obj = {
                start: new Date(moment(res[i].assignDate).format("MM/DD/YYYY") + " " + moment(res[i].startTime).format("HH:mm")),
                end: moment(res[i].endTime).format("HH:mm"),
                title: "<div class='grp-thrphy-clr cal-container'><div class='cal-therapyname'>" + res[i].groupTherapyName + '</div>{{DS}}</div>',
                 color: {
                  primary: res[i].colorCode,
                  secondary: res[i].colorCode
                },
                cssClass: 'event-anchor',
                // id: res[i].groupTherapyId,
                id: res[i].groupTherapyId,
                therapy: constantVariables.therapy.GroupTheraphy,
                flag: constantVariables.therapy.GroupTheraphy,
                rrule: {
                  freq:  RRule.MONTHLY,
                  dtstart: new Date(moment(res[i].assignDate).format("MM/DD/YYYY") + " " + moment(res[i].startTime).format("HH:mm")),
                  until: new Date(moment(res[i].releaseDate).format("MM/DD/YYYY") + " " + moment(res[i].endTime).format("HH:mm")),
                  //byweekday: ((res[i].isRecurrance == 1 && res[i].frequency == 5) ? [(res[i].isMonday == 1 ? RRule.MO : ''),(res[i].isTuesday == 1 ? RRule.TU : ''),(res[i].isWednesday == 1 ? RRule.WE : ''),(res[i].isThursday == 1 ? RRule.TH : ''),(res[i].isFriday == 1 ? RRule.FR : ''),(res[i].isSunday == 1 ? RRule.SU : ''),(res[i].isSaturday == 1 ? RRule.SA : '')] :''),
                  bymonthday: this.getSpecificWeekday(new Date(res[i].assignDate), new Date(res[i].releaseDate), res[i].weekDaysOnMonth,res[i].weekOfMonth),
                },
                tags: constantVariables.therapy.GroupTheraphy
              }
              this.recurringEvents = [...this.recurringEvents, _obj];
            }else if(res[i].isRecurrance == 1 && res[i].frequency == 5){
              let _obj = {
                start: new Date(moment(res[i].assignDate).format("MM/DD/YYYY") + " " + moment(res[i].startTime).format("HH:mm")),
                end: moment(res[i].endTime).format("HH:mm"),
                title: "<div class='grp-thrphy-clr cal-container'><div class='cal-therapyname'>" + res[i].groupTherapyName + '</div>{{DS}}</div>',
                 color: {
                  primary: res[i].colorCode,
                  secondary: res[i].colorCode
                },
                cssClass: 'event-anchor',
                // id: res[i].groupTherapyId,
                id: res[i].groupTherapyId,
                therapy: constantVariables.therapy.GroupTheraphy,
                flag: constantVariables.therapy.GroupTheraphy,
                rrule: {
                  freq:  RRule.DAILY,
                  dtstart: new Date(moment(res[i].assignDate).format("MM/DD/YYYY") + " " + moment(res[i].startTime).format("HH:mm")),
                  until: new Date(moment(res[i].releaseDate).format("MM/DD/YYYY") + " " + moment(res[i].endTime).format("HH:mm")),
                  byweekday: ((res[i].isRecurrance == 1 && res[i].frequency == 5) ? [(res[i].isMonday == 1 ? RRule.MO : ''),(res[i].isTuesday == 1 ? RRule.TU : ''),(res[i].isWednesday == 1 ? RRule.WE : ''),(res[i].isThursday == 1 ? RRule.TH : ''),(res[i].isFriday == 1 ? RRule.FR : ''),(res[i].isSunday == 1 ? RRule.SU : ''),(res[i].isSaturday == 1 ? RRule.SA : '')] :''),
                  //bymonthday: 5,
                },
                tags: constantVariables.therapy.GroupTheraphy
              }
              this.recurringEvents = [...this.recurringEvents, _obj];
            }else if(res[i].isRecurrance == 1 && res[i].frequency == 4)
            {
              let _obj = {
                start: new Date(moment(res[i].assignDate).format("MM/DD/YYYY") + " " + moment(res[i].startTime).format("HH:mm")),
                end: moment(res[i].endTime).format("HH:mm"),
                title: "<div class='grp-thrphy-clr cal-container'><div class='cal-therapyname'>" + res[i].groupTherapyName + '</div>{{DS}}</div>',
                 color: {
                  primary: res[i].colorCode,
                  secondary: res[i].colorCode
                },
                cssClass: 'event-anchor',
                // id: res[i].groupTherapyId,
                id: res[i].groupTherapyId,
                therapy: constantVariables.therapy.GroupTheraphy,
                flag: constantVariables.therapy.GroupTheraphy,
                rrule: {
                  freq:  RRule.DAILY,
                  dtstart: new Date(moment(res[i].assignDate).format("MM/DD/YYYY") + " " + moment(res[i].startTime).format("HH:mm")),
                  until: new Date(moment(res[i].releaseDate).format("MM/DD/YYYY") + " " + moment(res[i].endTime).format("HH:mm")),
                  //byweekday: ((res[i].isRecurrance == 1 && res[i].frequency == 5) ? [(res[i].isMonday == 1 ? RRule.MO : ''),(res[i].isTuesday == 1 ? RRule.TU : ''),(res[i].isWednesday == 1 ? RRule.WE : ''),(res[i].isThursday == 1 ? RRule.TH : ''),(res[i].isFriday == 1 ? RRule.FR : ''),(res[i].isSunday == 1 ? RRule.SU : ''),(res[i].isSaturday == 1 ? RRule.SA : '')] :''),
                  //bymonthday: 5,
                },
                tags: constantVariables.therapy.GroupTheraphy
              }
              this.recurringEvents = [...this.recurringEvents, _obj];
            }else if(res[i].isRecurrance == 0){
              {
                let _obj = {
                  start: new Date(moment(res[i].assignDate).format("MM/DD/YYYY") + " " + moment(res[i].startTime).format("HH:mm")),
                  end: moment(res[i].endTime).format("HH:mm"),
                  title: "<div class='grp-thrphy-clr cal-container'><div class='cal-therapyname'>" + res[i].groupTherapyName + '</div>{{DS}}</div>',
                   color: {
                  primary: res[i].colorCode,
                  secondary: res[i].colorCode
                },
                  cssClass: 'event-anchor',
                  // id: res[i].groupTherapyId,
                  id: res[i].groupTherapyId,
                  therapy: constantVariables.therapy.GroupTheraphy,
                  flag: constantVariables.therapy.GroupTheraphy,
                  rrule: {
                    freq:  RRule.DAILY,
                    dtstart: new Date(moment(res[i].assignDate).format("MM/DD/YYYY") + " " + moment(res[i].startTime).format("HH:mm")),
                    until: new Date(moment(res[i].releaseDate).format("MM/DD/YYYY") + " " + moment(res[i].endTime).format("HH:mm")),
                    //byweekday: ((res[i].isRecurrance == 1 && res[i].frequency == 5) ? [(res[i].isMonday == 1 ? RRule.MO : ''),(res[i].isTuesday == 1 ? RRule.TU : ''),(res[i].isWednesday == 1 ? RRule.WE : ''),(res[i].isThursday == 1 ? RRule.TH : ''),(res[i].isFriday == 1 ? RRule.FR : ''),(res[i].isSunday == 1 ? RRule.SU : ''),(res[i].isSaturday == 1 ? RRule.SA : '')] :''),
                    //bymonthday: 5,
                  },
                  tags: constantVariables.therapy.GroupTheraphy
                }
                this.recurringEvents = [...this.recurringEvents, _obj];
              }
            }
        
            
          }

          this.updateCalendarEventsonLoad();
          this.cdr.detectChanges();
          this.refresh.next();
        }
        else {
          this.isnodata = true;
          this.cdr.detectChanges();
        }
      },
      err => {
        this.spinnerservice.hide();
      });

    this.spinnerservice.hide();
  }


  private getSpecificWeekday(startDate: Date, endDate: Date, weekday: number, occurrence: number): number {
    const start = new Date(this.filterType == 2 ? moment(this.filterweekStartDate).format("MM/DD/YYYY") : this.filterType == 3 ? moment(new Date(this.viewDate.getFullYear(), this.viewDate.getMonth(), 1)).format("MM/DD/YYYY") :  moment(this.filterdate).format("MM/DD/YYYY"))
    const year = start.getFullYear();
  const month = (this.filterType == 3 ? this.filterMonth :  start.getMonth() + 1);
  
  weekday = weekday == 7 ? 0 : weekday;

  let resultDay;
   
  if(occurrence != 5){
    let currentDay = 1;
    let count = 0;
    
    while (count < occurrence) {
      const currentDate = new Date(year, month-1, currentDay);
  
      if (currentDate.getDay() === weekday) { 
        count++;
        if (count === occurrence) {
          resultDay = currentDate;
        }
      }
  
      currentDay++;
    }


  }else{
   // Calculate the first day of next month
   const firstDayOfNextMonth = new Date(start.getFullYear(), start.getMonth() , 1);

   // Calculate the last day of next month
   const lastDayOfNextMonth = new Date(start.getFullYear(), start.getMonth()+1 , 0);

   // Find the day of the week for the last day of next month
   let lastDayOfWeek = lastDayOfNextMonth.getDay();

   // Calculate the difference between the last day's index and Friday (assuming Sunday is 0)
   let difference = (lastDayOfWeek + 6 - (weekday-1)) % 7;

   // Subtract the difference from the last day of next month to get the last Friday
   resultDay = new Date(
     lastDayOfNextMonth.getFullYear(),
     lastDayOfNextMonth.getMonth(),
     lastDayOfNextMonth.getDate() - difference
   );

  }
  return resultDay.getDate();
}
  //therapy pop up close
  OncloseTherapyDetailspopup() {
    this.ObjTherapyPopUp = undefined
    this.isShowTherapydetails = false;
    // window.location.reload();
  }
  loadAllAppointmentsNew() {
    this.isShowScheduleModality = false;
    let _obj = {
      userid: this.selectedClinician,
      CurrentDate: this.filterType == 1 ? moment(this.filterdate).format("MM/DD/YYYY") : null,
      StartDate: this.filterType == 2 ? moment(this.filterweekStartDate).format("MM/DD/YYYY") : null,
      EndDate: this.filterType == 2 ? moment(this.filterweekEndDate).format("MM/DD/YYYY") : null,
      CurrentMonth: this.filterType == 3 ? this.filterMonth : '0',
      LoginUserId: localStorage.getItem('id')
    }
    this.spinnerservice.show();
    const dataToEncrypt = this.commonService.DataToEncrypt(_obj, false);
    this.drpClinicians = [];
    this.clinicianservice.getschedulerappointmentsForAdmin(dataToEncrypt).subscribe(
      response => {
        var res = JSON.parse(response);
        this.spinnerservice.hide();
        if (res != null) {
          //  let tmplist=res;
          //  this.drpClinicians= tmplist.filter(
          //   (t2, i, arr) => arr.findIndex(t => t.clinicianId === t2.clinicianId && t.clinicianId !=0) === i );
          if (localStorage.getItem('roleId') == "4") {
            this.appointments = res;
            this.allAppointments = res;
            this.appointments = this.appointments.filter(x => x.patientAccountId == localStorage.getItem('patientAccountId'))
            res = this.appointments;
          }
          else {
            this.allAppointments = res;
            if ((this.allAppointments.filter(x => x.locationId == localStorage.getItem('sfcclocation'))).length == 0) {
              this.isnodata = true;
              this.cdr.detectChanges();
              this.commonService.getStaffListV2(constantVariables.role.PrimaryClinician, localStorage.getItem('sfcclocation')).subscribe(
                (resp: any) => {
                  if (resp) {
                    var temp = resp
                    var temp1 = [];
                    temp.map(x => {
                      var _obj = {
                        clinicianId: x.staffId,
                        clinicianName: x.firstName
                      }
                      temp1.push(_obj);
                    })
                    this.drpClinicians = temp1;
                    if (this.drpClinicians.length == 0) {
                      this.isNoClinician = true;
                    } else {
                      this.isNoClinician = false;
                    }
                  }
                },
                err => {
                  this.spinnerservice.hide();
                },
              );
              this.getGrpTherapy();
              return;
            }

            this.allAppointments = this.allAppointments.filter(x => x.locationId == localStorage.getItem('sfcclocation') || x.locationId == "A");

            this.appointments = this.allAppointments;
            res = this.appointments;
            let tmplist = res;
            //  this.drpClinicians= tmplist.filter(
            //    (t2, i, arr) => arr.findIndex(t => t.clinicianId === t2.clinicianId && t.clinicianId !=0) === i );

            //}

            //clinician filter
            //  if(this.ExistingReArrangeDatas.length>0 && this.drpClinicians.length>0)
            //  {
            let tmprearnge = this.ExistingReArrangeDatas;
            //let arr1=this.drpClinicians;
            //  if(tmprearnge.length==arr1.length)
            //  {
            this.drpClinicians = [];
            this.drpClinicians = tmprearnge;
            //}
            //  }
            if (this.drpClinicians.length > 0) {
              this.selectedClinician = (localStorage.getItem("adminDayViewClinician") == "-1") ? this.drpClinicians[0].clinicianId :
                localStorage.getItem("adminDayViewClinician");
                localStorage.setItem("adminDayViewClinician", this.selectedClinician);
            }

          }
          if (this.appointments.length > 0) {
            this.events = [];
            this.recurringEvents = [];
            res = [];
            res = (this.selectedClinician != -1) ? this.appointments.filter(x => x.clinicianId == this.selectedClinician) : this.appointments;

            for (var i = 0; i < res.length; i++) {
              let thrpyid = 0;
              if (res[i].typeId == 2) {
                thrpyid = constantVariables.therapy.IndividualTheraphy;
              }
              else if (res[i].typeId == 3 && res[i].templateId == 1) {
                thrpyid = constantVariables.therapy.FamilyTheraphy;
              }
              else if (res[i].typeId == 5) {
                thrpyid = constantVariables.therapy.PsycatricTheraphy;
              }
              else if (res[i].typeId == 8) {
                thrpyid = constantVariables.therapy.PsycatricEvaluation;
              }
              else if (res[i].typeId == 9) {
                thrpyid = constantVariables.therapy.PsycatricMedication;
              }
              else if (res[i].typeId == 0 && res[i].templateId == 3) {
                thrpyid = constantVariables.therapy.Tour;
              }
              else if (res[i].typeId == 1) {
                thrpyid = constantVariables.therapy.Intake;
              }
              else if (res[i].typeId == 11) {
                thrpyid = constantVariables.therapy.Phonecall;
              }
              else if (res[i].typeId == 10) {
                thrpyid = constantVariables.therapy.School;
              }
              else if (res[i].typeId == 12) {
                thrpyid = constantVariables.therapy.Speech;
              }
              else if (res[i].typeId == 13) {
                thrpyid = constantVariables.therapy.Nutrition;
              }
              else if (res[i].typeId == 15) {
                thrpyid = constantVariables.therapy.PhonecallSceduler;
              }
              else if (res[i].typeId == 16) {
                thrpyid = constantVariables.therapy.SchoolmeetingScheduler;
              }
              else if (res[i].typeId == 17) {
                thrpyid = constantVariables.therapy.BlockTimeScheduler;
              }
              else if (res[i].typeId == 15) {
                thrpyid = constantVariables.therapy.PhonecallSceduler;
              }
              console.log(res[i].appointmentName);
              //  else  if(res[i].typeId!=1 && res[i].typeId!=2 && res[i].typeId!=3 )
              // else  if(res[i].typeId!=0 && res[i].typeId!=1 && res[i].typeId!=2 && res[i].typeId!=3 && res[i].typeId!=5 && res[i].typeId!=8 && res[i].typeId!=9 )
              //     {
              //       thrpyid=constantVariables.therapy.GroupTheraphy;
              //     }

              
              let _obj = {
                start: new Date(moment(res[i].scheduleDate).format("MM/DD/YYYY") + " " + moment(res[i].fromTime).format("HH:mm")),
                // end: res[i].scheduleEndDate == null ? (moment(res[i].scheduleDate).format("MM/DD/YYYY") + " " + moment(res[i].toTime).format("HH:mm a")) : new Date(moment(res[i].scheduleEndDate).format("MM/DD/YYYY") + " " + moment(res[i].toTime).format("HH:mm")),
                end: moment(res[i].toTime).format("HH:mm"),
                title: res[i].appointmentName,
                 color: {
                  primary: res[i].colorCode,
                  secondary: res[i].colorCode
                },
               
                cssClass: 'event-anchor',
                id: res[i].appointmentId,
                rrule: {
                  freq: RRule.DAILY,
                  dtstart: new Date(moment(res[i].scheduleDate).format("MM/DD/YYYY") + " " + moment(res[i].fromTime).format("HH:mm")),
                  until: res[i].scheduleEndDate == null ? (moment(res[i].scheduleDate).format("MM/DD/YYYY") + " " + moment(res[i].toTime).format("HH:mm")) : new Date(moment(res[i].scheduleEndDate).format("MM/DD/YYYY") + " " + moment(res[i].toTime).format("HH:mm")),
                  interval: 1
                },
                // tags :  res[i].typeId
                tags: thrpyid
              }

              this.recurringEvents = [...this.recurringEvents, _obj];

            }
            this.updateCalendarEventsonLoad();
            this.cdr.detectChanges();
            this.refresh.next();
            this.getGrpTherapy();
          }
          else {
            this.isnodata = true;
            this.cdr.detectChanges();

          }
        }

        //this.loadGroupTherapymeeting();
        // this.getGrpTherapy();
      },
      err => {
        this.spinnerservice.hide();
      },
    );


  }

  loadPhonecallMeeting() {
    if (this.appointments != null) {
      //let res = this.appointments.filter(x => x.templateId == 1 && x.typeId == 9);
      let res = (this.selectedClinician != -1) ? this.appointments.filter(x => x.templateId == 1 && x.typeId == 11 && x.clinicianId == this.selectedClinician) :
        this.appointments.filter(x => x.templateId == 1 && x.typeId == 11);
      this.events = [];
      this.recurringEvents = [];
      for (var i = 0; i < res.length; i++) {

        let _obj = {
          start: new Date(moment(res[i].scheduleDate).format("MM/DD/YYYY") + " " + moment(res[i].fromTime).format("HH:mm")),
          //  end: res[i].scheduleEndDate == null ? (moment(res[i].scheduleDate).format("MM/DD/YYYY") + " " + moment(res[i].toTime).format("HH:mm a")) : new Date(moment(res[i].scheduleEndDate).format("MM/DD/YYYY") + " " + moment(res[i].toTime).format("HH:mm")),
          end: moment(res[i].toTime).format("HH:mm"),
          title: res[i].appointmentName,
           color: {
                  primary: res[i].colorCode,
                  secondary: res[i].colorCode
                },
          cssClass: 'event-anchor',
          id: res[i].appointmentId,
          rrule: {
            freq: RRule.DAILY,
            dtstart: new Date(moment(res[i].scheduleDate).format("MM/DD/YYYY") + " " + moment(res[i].fromTime).format("HH:mm")),
            until: res[i].scheduleEndDate == null ? (moment(res[i].scheduleDate).format("MM/DD/YYYY") + " " + moment(res[i].toTime).format("HH:mm")) : new Date(moment(res[i].scheduleEndDate).format("MM/DD/YYYY") + " " + moment(res[i].toTime).format("HH:mm")),
          },
          //tags: constantVariables.therapy.IndividualTheraphy
          tags: constantVariables.therapy.Phonecall
        }
        this.recurringEvents = [...this.recurringEvents, _obj];
        this.updateCalendarEventsonLoad();
        this.cdr.detectChanges();
        this.refresh.next();
      }
    }
  }

  filterDayNext(event) {
    if (this.filterType == 2) {
      this.filterweekStartDate = moment(this.filterweekStartDate).add(7, 'days').format('LL');
      this.filterweekEndDate = moment(this.filterweekEndDate).add(7, 'days').format('LL');
      this.onAppointmentClinicianchange();
    } else if (this.filterType == 3) {
      this.filterMonth = Number(moment(this.viewDate).add(1, 'M').format('M'));
      this.filterMonthdisplay = moment(this.viewDate).add(1, 'months').format('MMM YYYY');
      this.filterMonthStartDate = new Date(this.viewDate.getFullYear(), this.viewDate.getMonth(), 1);
      this.filterMonthEndDate = new Date(this.viewDate.getFullYear(), this.viewDate.getMonth() + 1, 0);
      this.onAppointmentClinicianchange();
    }

  }
  filterDayPrev(event) {
    if (this.filterType == 2) {
      this.filterweekStartDate = moment(this.filterweekStartDate).subtract(7, 'days').format('LL');
      this.filterweekEndDate = moment(this.filterweekEndDate).subtract(7, 'days').format('LL');
      this.onAppointmentClinicianchange();
    } else if (this.filterType == 3) {
      this.filterMonth = Number(moment(this.viewDate).subtract(1, 'M').format('M'));
      this.filterMonthdisplay = moment(this.viewDate).subtract(1, 'months').format('MMM YYYY');
      this.filterMonthStartDate = new Date(this.viewDate.getFullYear(), this.viewDate.getMonth(), 1);
      this.filterMonthEndDate = new Date(this.viewDate.getFullYear(), this.viewDate.getMonth() + 1, 0);
      this.onAppointmentClinicianchange();
    }

  }

  loadSchoolMeeting() {
    if (this.appointments != null) {
      //let res = this.appointments.filter(x => x.templateId == 1 && x.typeId == 9);
      let res = (this.selectedClinician != -1) ? this.appointments.filter(x => x.templateId == 1 && x.typeId == 10 && x.clinicianId == this.selectedClinician) :
        this.appointments.filter(x => x.templateId == 1 && x.typeId == 10);
      this.events = [];
      this.recurringEvents = [];
      for (var i = 0; i < res.length; i++) {

        let _obj = {
          start: new Date(moment(res[i].scheduleDate).format("MM/DD/YYYY") + " " + moment(res[i].fromTime).format("HH:mm")),
          //  end: res[i].scheduleEndDate == null ? (moment(res[i].scheduleDate).format("MM/DD/YYYY") + " " + moment(res[i].toTime).format("HH:mm a")) : new Date(moment(res[i].scheduleEndDate).format("MM/DD/YYYY") + " " + moment(res[i].toTime).format("HH:mm")),
          end: moment(res[i].toTime).format("HH:mm"),
          title: res[i].appointmentName,
           color: {
                  primary: res[i].colorCode,
                  secondary: res[i].colorCode
                },
          cssClass: 'event-anchor',
          id: res[i].appointmentId,
          rrule: {
            freq: RRule.DAILY,
            dtstart: new Date(moment(res[i].scheduleDate).format("MM/DD/YYYY") + " " + moment(res[i].fromTime).format("HH:mm")),
            until: res[i].scheduleEndDate == null ? (moment(res[i].scheduleDate).format("MM/DD/YYYY") + " " + moment(res[i].toTime).format("HH:mm")) : new Date(moment(res[i].scheduleEndDate).format("MM/DD/YYYY") + " " + moment(res[i].toTime).format("HH:mm")),
          },
          //tags: constantVariables.therapy.IndividualTheraphy
          tags: constantVariables.therapy.School
        }
        this.recurringEvents = [...this.recurringEvents, _obj];
        this.updateCalendarEventsonLoad();
        this.cdr.detectChanges();
        this.refresh.next();
      }
    }
  }

  addEvent(type: string, event: MatDatepickerInputEvent<Date>) {
    //////
    // this.filterdate = moment(event.target.value).format('LL');
    // this.viewDate = moment(event.target.value).toDate();
    // this.activeDayIsOpen = false;
    // this.filterMonth = Number((moment(this.viewDate)).format('M'));
    // this.filterweekStartDate = moment(this.viewDate).add(7, 'days').format('LL');
    //this.filterweekEndDate = moment(this.viewDate).add(7, 'days').format('LL');

    //////
    this.filterdate = moment(event.target.value).format('LL');
    this.viewDate = new Date(moment(event.target.value).format('MM/DD/YYYY'))
    this.activeDayIsOpen = false;
    this.filterMonth = Number((moment(this.viewDate)).format('M'));
    this.filterweekStartDate = moment(this.viewDate).startOf('week').toString();
    this.filterweekEndDate = moment(this.viewDate).endOf('week').toString();
    this.filterMonthStartDate = new Date(this.viewDate.getFullYear(), this.viewDate.getMonth(), 1);
    this.filterMonthEndDate = new Date(this.viewDate.getFullYear(), this.viewDate.getMonth() + 1, 0);
    this.onAppointmentClinicianchange();
  }
  daySegmentClicked(day: any): void {
    let currentDate = new Date();
    if ((this.datepipe.transform(currentDate.setDate(currentDate.getDate() - 2), "yyyy-MM-dd")) > (this.datepipe.transform(day.date, "yyyy-MM-dd"))) {
      this.snackbar.error("Cannot schedule a meeting in past");
      return;
    }
    //console.log('hourClicked Hour', hour);
    this.ObjTherapyPopUp = new AdminTherapyPopUpObj();
    this.ObjTherapyPopUp.id = 0;
    this.ObjTherapyPopUp.start = null;
    this.ObjTherapyPopUp.scheduleDate = day.date;
    this.ObjTherapyPopUp.fromtime = null;
    this.ObjTherapyPopUp.SfccMasterId = Number(localStorage.getItem('sfcclocation'));
    this.ObjTherapyPopUp.ClinicianId = this.selectedClinician;
    this.ObjTherapyPopUp.View = constantVariables.therapyView.Month;
    this.isShowScheduleModality = true;
  }
  hourSegmentClicked(hour: any): void {
    let currentDate = new Date();
    if ((this.datepipe.transform(currentDate.setDate(currentDate.getDate() - 2), "yyyy-MM-dd")) > (this.datepipe.transform(hour, "yyyy-MM-dd"))) {
      this.snackbar.error("Cannot schedule a meeting in past");
      return;
    }
    //console.log('hourClicked Hour', hour);
    this.ObjTherapyPopUp = new AdminTherapyPopUpObj();
    this.ObjTherapyPopUp.id = 0;
    this.ObjTherapyPopUp.start = hour;
    this.ObjTherapyPopUp.scheduleDate = hour;
    this.ObjTherapyPopUp.fromtime = hour;
    this.ObjTherapyPopUp.SfccMasterId = Number(localStorage.getItem('sfcclocation'));
    this.ObjTherapyPopUp.ClinicianId = this.selectedClinician;
    this.ObjTherapyPopUp.View = constantVariables.therapyView.Week;
    this.isShowScheduleModality = true;
  }

  OnCloseScheduleModality() {
    this.ObjTherapyPopUp = undefined
    this.isShowScheduleModality = false;
    // window.location.reload();
  }

  loadSpeechTherapy() {
    if (this.appointments != null) {
      //let res = this.appointments.filter(x => x.templateId == 1 && x.typeId == 9);
      let res = (this.selectedClinician != -1) ? this.appointments.filter(x => x.templateId == 1 && x.typeId == 12 && x.clinicianId == this.selectedClinician) :
        this.appointments.filter(x => x.templateId == 1 && x.typeId == 12);
      this.events = [];
      this.recurringEvents = [];
      for (var i = 0; i < res.length; i++) {

        let _obj = {
          start: new Date(moment(res[i].scheduleDate).format("MM/DD/YYYY") + " " + moment(res[i].fromTime).format("HH:mm")),
          //  end: res[i].scheduleEndDate == null ? (moment(res[i].scheduleDate).format("MM/DD/YYYY") + " " + moment(res[i].toTime).format("HH:mm a")) : new Date(moment(res[i].scheduleEndDate).format("MM/DD/YYYY") + " " + moment(res[i].toTime).format("HH:mm")),
          end: moment(res[i].toTime).format("HH:mm"),
          title: res[i].appointmentName,
           color: {
                  primary: res[i].colorCode,
                  secondary: res[i].colorCode
                },
          cssClass: 'event-anchor',
          id: res[i].appointmentId,
          rrule: {
            freq: RRule.DAILY,
            dtstart: new Date(moment(res[i].scheduleDate).format("MM/DD/YYYY") + " " + moment(res[i].fromTime).format("HH:mm")),
            until: res[i].scheduleEndDate == null ? (moment(res[i].scheduleDate).format("MM/DD/YYYY") + " " + moment(res[i].toTime).format("HH:mm")) : new Date(moment(res[i].scheduleEndDate).format("MM/DD/YYYY") + " " + moment(res[i].toTime).format("HH:mm")),
          },
          //tags: constantVariables.therapy.IndividualTheraphy
          tags: constantVariables.therapy.School
        }
        this.recurringEvents = [...this.recurringEvents, _obj];
        this.updateCalendarEventsonLoad();
        this.cdr.detectChanges();
        this.refresh.next();
      }
    }
  }
  loadNutritionTherapy() {
    if (this.appointments != null) {
      //let res = this.appointments.filter(x => x.templateId == 1 && x.typeId == 9);
      let res = (this.selectedClinician != -1) ? this.appointments.filter(x => x.templateId == 1 && x.typeId == 13 && x.clinicianId == this.selectedClinician) :
        this.appointments.filter(x => x.templateId == 1 && x.typeId == 13);
      this.events = [];
      this.recurringEvents = [];
      for (var i = 0; i < res.length; i++) {

        let _obj = {
          start: new Date(moment(res[i].scheduleDate).format("MM/DD/YYYY") + " " + moment(res[i].fromTime).format("HH:mm")),
          //  end: res[i].scheduleEndDate == null ? (moment(res[i].scheduleDate).format("MM/DD/YYYY") + " " + moment(res[i].toTime).format("HH:mm a")) : new Date(moment(res[i].scheduleEndDate).format("MM/DD/YYYY") + " " + moment(res[i].toTime).format("HH:mm")),
          end: moment(res[i].toTime).format("HH:mm"),
          title: res[i].appointmentName,
           color: {
                  primary: res[i].colorCode,
                  secondary: res[i].colorCode
                },
          cssClass: 'event-anchor',
          id: res[i].appointmentId,
          rrule: {
            freq: RRule.DAILY,
            dtstart: new Date(moment(res[i].scheduleDate).format("MM/DD/YYYY") + " " + moment(res[i].fromTime).format("HH:mm")),
            until: res[i].scheduleEndDate == null ? (moment(res[i].scheduleDate).format("MM/DD/YYYY") + " " + moment(res[i].toTime).format("HH:mm")) : new Date(moment(res[i].scheduleEndDate).format("MM/DD/YYYY") + " " + moment(res[i].toTime).format("HH:mm")),
          },
          //tags: constantVariables.therapy.IndividualTheraphy
          tags: constantVariables.therapy.School
        }
        this.recurringEvents = [...this.recurringEvents, _obj];
        this.updateCalendarEventsonLoad();
        this.cdr.detectChanges();
        this.refresh.next();
      }
    }
  }
  loadBlockTime() {
    if (this.appointments != null) {
      //let res = this.appointments.filter(x => x.templateId == 1 && x.typeId == 9);
      let res = (this.selectedClinician != -1) ? this.appointments.filter(x => x.templateId == 3 && x.typeId == 200 && x.clinicianId == this.selectedClinician) :
        this.appointments.filter(x => x.templateId == 3 && x.typeId == 17);
      this.events = [];
      this.recurringEvents = [];
      for (var i = 0; i < res.length; i++) {
        console.log(res[i].appointmentName);
        let _obj = {
          start: new Date(moment(res[i].scheduleDate).format("MM/DD/YYYY") + " " + moment(res[i].fromTime).format("HH:mm")),
          //  end: res[i].scheduleEndDate == null ? (moment(res[i].scheduleDate).format("MM/DD/YYYY") + " " + moment(res[i].toTime).format("HH:mm a")) : new Date(moment(res[i].scheduleEndDate).format("MM/DD/YYYY") + " " + moment(res[i].toTime).format("HH:mm")),
          end: moment(res[i].toTime).format("HH:mm"),
          title: res[i].appointmentName,
           color: {
                  primary: res[i].colorCode,
                  secondary: res[i].colorCode
                },
          cssClass: 'event-anchor',
          id: res[i].appointmentId,
          rrule: {
            freq: RRule.DAILY,
            dtstart: new Date(moment(res[i].scheduleDate).format("MM/DD/YYYY") + " " + moment(res[i].fromTime).format("HH:mm")),
            until: res[i].scheduleEndDate == null ? (moment(res[i].scheduleDate).format("MM/DD/YYYY") + " " + moment(res[i].toTime).format("HH:mm")) : new Date(moment(res[i].scheduleEndDate).format("MM/DD/YYYY") + " " + moment(res[i].toTime).format("HH:mm")),
          },
          //tags: constantVariables.therapy.IndividualTheraphy
          tags: constantVariables.therapy.School
        }
        this.recurringEvents = [...this.recurringEvents, _obj];
        this.updateCalendarEventsonLoad();
        this.cdr.detectChanges();
        this.refresh.next();
      }
    }
  }
  hideModal() {
    this.isnodata = !this.isnodata;
  }


  getPatientlist() {
    let location = +localStorage.getItem('sfcclocation')
    this.spinnerservice.show()
    this.secondaryId = localStorage.getItem('id');
    this.treatmentservice.getPatientListforDS(-1, location,this.secondaryId).subscribe(res => {
      this.ddlpatientList = res;
      console.log(this.ddlpatientList);
      this.spinnerservice.hide();
    },
      err => {
        this.spinnerservice.hide();

      });
  }
  getClientName() {
    
    let location = +localStorage.getItem('sfcclocation')
    this.spinnerservice.show()
    if(location <= 0){
      location = -1;
    }
    this.clinicianservice.getClientName(-1, location).subscribe(res => {
      this.ddlclientNameList = res;
      this.spinnerservice.hide();
    },
      err => {
        this.spinnerservice.hide();
  
      });
  }
  clientnamedetails(event: any) {
    this.clientTherapydetails = [];
    this.appointmentType = 'all'
    this.clientdialogRef = this.dialog.open(this.clientModal,

      { width: '920px' });
    // this.isSchedulerview = true ;

    this.PatientNameDetails();
    this.isAutocompleteEnabled = !this.isAutocompleteEnabled;
  }
  SharePopupClose() {
    this.clientdialogRef = false;
  }
  filterOptions(value: any) {
    const filterValue = value.toLowerCase();
    return this.ddlclientNameList.filter((option: any) =>
      option.patientName.toLowerCase().includes(filterValue)
    );
  }
  PatientNameDetails() {
    this.clientTherapydetails = [];
    this.clientTherapydetailsDelete = [];
const patientName = this.filterControl.value
this.patientNameview = this.filterControl.value
    // this.patientNameDetails = this.filteredOptions ;
    const PatientAccountId = this.filteredOptions.find(e => e.patientName == patientName).patientAccountId;
    let _obj = {
      PatientAccountId: PatientAccountId
    }
    const dataToEncrypt = this.commonService.DataToEncrypt(_obj, false);
    this.clinicianservice.GetSearchAppointmentsPatient(dataToEncrypt).subscribe(
      response => {
        var res = JSON.parse(response);
        res.forEach(record => {
          const isDeleted = record.isDeleted;
          record['scheduleDate'] = moment(record['scheduleDate']).format("MM/DD/YYYY")
          if(this.appointmentType == "past" && (this.datepipe.transform(this.currentDate, "yyyy-MM-dd") > (this.datepipe.transform(record['scheduleDate'], "yyyy-MM-dd"))) && !isDeleted )
          {
            this.clientTherapydetails.push(record)

          } if(this.appointmentType == "future" && (this.datepipe.transform(this.currentDate, "yyyy-MM-dd")  < (this.datepipe.transform(record['scheduleDate'], "yyyy-MM-dd"))) && !isDeleted )
           {
            this.clientTherapydetails.push(record)

          } if (this.appointmentType == "all" && !isDeleted)
          {
            this.clientTherapydetails.push(record)

          }
          if (this.appointmentType == "Deleted" && isDeleted)
            {
              this.clientTherapydetailsDelete.push(record)
  
            }
        });

        this.spinnerservice.hide();
      },
      err => {
        this.spinnerservice.hide();
      },
    );

  }
  appointmentchangeValue(event) {
    this.appointmentType =  event.target.value
    this.PatientNameDetails();

  }

  chooseTheraphy(data:any) {
  //  console.log(data)
   this.clientdialogRef.close();
   this.date_view = data.scheduleDate;
  //  this.isShowDayView = true;
   this.roleAccess.isView = true;
   this.dayView = true;
   this.dayViewClick();
  }
  // clearSearchText() {
  //   this.filterControl = null; 
  // }
  setdefaultValue(){
    this.CalendarView = CalendarView;
    this.viewDate = moment().toDate();
    this.refresh = new Subject();
    this.events = [];
    this.activeDayIsOpen = false;
    this.dtoAppointmentType ='';
    this.dtoMeeting='';
    this.isShowMoreEvent = false;
    this.EVENT_LIMIT = 4;
    this.moreTaskEvents = [];
    this.recurringEvents = [];
    this.appointments = [];
    this.drpClinicians = [];
    this.selectedClinician='';
    this.allAppointments = [];
    this.isAllLocation = false;
    this.isShowDayView = false;
    this.isReArrangeClinician = false;
    this.ExistingReArrangeDatas = [];
    this.reArrangeClinicianDatas = [];
    this.rearrangeSelectedIndex = undefined;
    this.filterdate='';
    this.filterweekStartDate='';
    this.filterweekEndDate='';
    this.filterMonth='';
    this.filterMonthStartDate='';
    this.filterMonthEndDate='';
    this.filterMonthdisplay='';
    this.grouptherapyAppointments = [];
    this.filterType='';
    this.ObjTherapyPopUp = new AdminTherapyPopUpObj;
    this.isShowTherapydetails = false;
    this.isnodata = false;
    this.roleAccess= new RoelAccess;
    this.viewRightsMsg='';
    this.isShowScheduleModality =false;
    this.isNoClinician = false;
    this.ddlpatientList = [];
    this.filteredOptions = [];
    this.searchTerm = '';
    this.isSchedulerview = false
    this.patientNameDetails='';
    this.filterControl.reset();
    this.currentDate='';
    this.dateview  = false;
  }
  onClick() {
    this.filterControl.reset();
    this.isAutocompleteEnabled = true;
  }
  onKeyUp(event: KeyboardEvent){
    const inputValue = (event.target as HTMLInputElement).value;
    this.filteredOptions = this.filterOptions(inputValue);
    console.log(this.filteredOptions)
  }
  onGeneratePDF(appointmentType){
    this.appointmentType =  appointmentType;
    this.appoitmenttypefirst = this.appointmentType.charAt(0);
    this.spinnerservice.show();
  const patientName = this.filterControl.value;
  
    const PatientAccountId = this.filteredOptions.find(e => e.patientName == patientName).patientAccountId;
   
    this.clinicianservice.onGenerateSchedulerpopupPDF(PatientAccountId,this.appoitmenttypefirst).subscribe(
      (res: any) => {
        this.spinnerservice.hide();
        
            const linkSource = 'data:application/pdf;base64,' + res.base64File + '';
             this.downloadLink.nativeElement.href = res.base64File;
            this.downloadLink.nativeElement.download = res.fileName;
            this.downloadLink.nativeElement.click();
      },
      err => {
        this.spinnerservice.hide();
      },
    );
  
  }
}

