export class Medicationinfo {
    psychiatricMedicationDtlId:Number;
    psychiatricMedicationNotesId:Number;
    medications:string;
    dosage:string;
    frequency:string;
    sideEffects:string;
}

export class PsyMedFormInfo{
psychiatricMedicationNotesId:Number;
patientAccountId:Number;
meetingscheduleid:Number;
meetingscheduleTimeId:Number;
conversationDetails:String;
staffInitial:String;
staffSignDate:any;
psychiatristInitials:String;
psySignDate:any;
medication:Array<Medicationinfo>;
dob:any;
severity:number;
isSave:boolean;
staffInitial1:String;
isLicensed : boolean;
licenseNumber : string;
sSignature : boolean;
}