import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { SiteLayoutComponent } from './_Layout/site-layout/site-layout.component';
import { LoginComponent } from './login/login.component';
import { HomeComponent } from './home/home.component';
import { RegisterComponent } from './usermanagement/register/register.component';
import { ChangepasswordComponent } from './usermanagement/changepassword/changepassword.component';
import { ForgetpasswordComponent } from './usermanagement/forgetpassword/forgetpassword.component';
import { UserlistComponent } from './usermanagement/userlist/userlist.component';
import { UserManageComponent } from 'src/app/usermanagement/user-manage/user-manage.component';
import { TaskInfoComponent } from 'src/app/common/pages/task-info/task-info.component';

import { AuthGuard } from './auth/auth.guard';
import { RolesComponent } from './rolemanagement/roles/roles.component';
import { RolePermissionComponent } from './rolemanagement/role-permission/role-permission.component';
import { MastersComponent } from '../app/common/pages/masters/masters.component';
import { PhoneBookComponent } from './common/pages/masters/phone-book/phone-book.component'
import { ViewNotificationComponent } from './notification/view-notification/view-notification.component';
import { MessagesComponent } from 'src/app/common/pages/messages/messages.component';

import { LeadAccountComponent } from '../app/patient-lead/lead-account/lead-account.component';
import { LeadListviewComponent } from '../app/patient-lead/lead-listview/lead-listview.component';
import { LeadDetailviewComponent } from '../app/patient-lead/lead-detailview/lead-detailview.component';
import { LeadComponent } from '../app/patient-lead/lead/lead.component';
import { LeadKanbanComponent } from '../app/patient-lead/lead-kanban/lead-kanban.component';
import { LeadInfoComponent } from '../app/patient-lead/lead-info/lead-info.component';
import { ConsentComponent } from 'src/app/common/pages/template/consent/consent.component';
import { CrbaComponent } from 'src/app/patient-lead/crba/crba.component';
import { ConsentTemplatesComponent } from 'src/app/common/pages/template/consent-templates/consent-templates.component';
import { RequestTourComponent } from 'src/app/patient-lead/request-tour/request-tour.component';
import { FinancialProposalComponent } from 'src/app/patient-lead/financial-proposal/financial-proposal.component';
import { PriceCatalogueComponent } from 'src/app/common/pages/price-catalogue/price-catalogue.component';
import { NotesComponent } from 'src/app/common/pages/notes/notes.component';
import { TaskComponent } from 'src/app/common/pages/task/task.component';
import { CalenderComponent } from 'src/app/common/pages/calender/calender.component';
import { FinancialReviewComponent } from 'src/app/patient-lead/financial-proposal/financial-review/financial-review.component';
import { PatientListviewComponent } from 'src/app/patient/patient-listview/patient-listview.component';
import { SettingsComponent } from 'src/app/common/pages/settings/settings.component';
import { IntakeScheduleComponent } from 'src/app/patient/intake-schedule/intake-schedule.component';
import { PatientBreifcaseComponent } from 'src/app/patient/patient-breifcase/patient-breifcase.component';
import { SfccLocationComponent } from 'src/app/common/pages/settings/sfcc-location/sfcc-location.component';
import { ConfigureFormsComponent } from 'src/app/common/pages/template/configure-forms/configure-forms.component';
import { IntakeConsentComponent } from 'src/app/patient/intake/intake-consent/intake-consent.component';
import { IntakeChecklistComponent } from 'src/app/patient/intake/intake-checklist/intake-checklist.component';
import { IntakeSurveyComponent } from 'src/app/patient/intake/intake-survey/intake-survey.component';
import { ClinicianLayoutComponent } from 'src/app/staff/clinician/clinician-layout/clinician-layout.component';
import { IntakeNutritionalComponent } from 'src/app/patient/intake/intake-nutritional/intake-nutritional.component';
import { ClinicianMytaskComponent } from 'src/app/staff/clinician/clinician-mytask/clinician-mytask.component';
import { IntakeGenerateComponent } from 'src/app/patient/intake/intake-generate/intake-generate.component'
import { IntakeCmhaComponent } from 'src/app/patient/intake/intake-cmha/intake-cmha.component';
import {TreatmentplanListComponent} from 'src/app/staff/treatment/treatmentplan-list/treatmentplan-list.component';
import {ScheduleModalityComponent} from 'src/app/staff/treatment/schedule-modality/schedule-modality.component';
import { PainAssessmentComponent } from 'src/app/patient/pain-assessment/pain-assessment.component';
import { TreatmentplanComponent } from 'src/app/staff/treatment/treatmentplan/treatmentplan.component';
import { GoalsComponent } from 'src/app/common/pages/template/goals/goals.component';
import { GoalListComponent } from './common/pages/template/goals/goal-list/goal-list.component';
import { GroupTherapyComponent } from './common/pages/template/group-therapy/group-therapy.component';
import { ScheduleGrptherapyComponent } from './staff/treatment/schedule-grptherapy/schedule-grptherapy.component';
import { SchedulerCalenderComponent } from './common/pages/scheduler-calender/scheduler-calender.component';
import { DayScheduleComponent } from './staff/treatment/day-schedule/day-schedule.component';
import { ProgressTrackComponent } from './staff/treatment/progress-track/progress-track.component';
import { TreatmenthistoryComponent } from './staff/treatment/treatmenthistory/treatmenthistory.component';
import {  FamilyIndividualTheraphyComponent } from './staff/treatment/family-individual-theraphy/family-individual-theraphy.component';
import { LoginAuthGuard } from './auth/login.auth.guards';
import { BillListsComponent } from 'src/app/billing/bill-lists/bill-lists.component';
import { BillComponent } from 'src/app/billing/bill/bill.component'; 
import { IncidentReportingListComponent } from './staff/incident-reporting/incident-reporting-list/incident-reporting-list.component';
import { IncidentReportingComponent } from './staff/incident-reporting/incident-reporting/incident-reporting.component';
import { IncidentReviewComponent } from './staff/incident-reporting/incident-review/incident-review.component';
import { BillingMastersComponent } from 'src/app/billing/billing-masters/billing-masters.component';
import { TransactionCodeComponent } from 'src/app/billing/billing-masters/transaction-code/transaction-code.component';
import { BillingProviderComponent } from 'src/app/billing/billing-masters/billing-provider/billing-provider.component';
import { ClaimsListsComponent } from 'src/app/billing/claims-lists/claims-lists.component';
import { PsychiatricEvaluationProgressNotesComponent } from './staff/treatment/psychiatric-evaluation-progress-notes/psychiatric-evaluation-progress-notes.component';
import { PsychiatricTherapyProgressNotesComponent } from './staff/treatment/psychiatric-therapy-progress-notes/psychiatric-therapy-progress-notes.component';
import { PsychiatricMedicationProgressNotesComponent } from './staff/treatment/psychiatric-medication-progress-notes/psychiatric-medication-progress-notes.component';
import { DischargeSummaryComponent } from './staff/discharge-summary/discharge-summary/discharge-summary.component';
import { DischargeSummaryListComponent } from './staff/discharge-summary/discharge-summary-list/discharge-summary-list.component';
import { TreatmentSummaryComponent } from './staff/discharge-summary/treatment-summary/treatment-summary.component';
import { DischargedPatientListViewComponent } from './patient/discharge_patient_listview/discharged-patient-list-view/discharged-patient-list-view.component';
import { AdminDayViewScheduleComponent } from './common/pages/admin-day-view-schedule/admin-day-view-schedule.component';
import { AssignClinicianComponent } from 'src/app/common/pages/settings/assign-clinician/assign-clinician.component';
import { MeetingAppointmentComponent } from 'src/app/common/pages/settings/meeting-appointment/meeting-appointment.component';
import { PhonecallSchoolmeetScheduleComponent } from './patient/phonecall-schoolmeet-schedule/phonecall-schoolmeet-schedule.component';
import { PhonecallSchoolProgressNotesComponent } from './staff/treatment/phonecall-school-progress-notes/phonecall-school-progress-notes.component';
import { PatientPaymentDetailsComponent } from 'src/app/patient/patient-payment-details/patient-payment-details.component';
import { SucidalRiskAssessmentComponent } from './staff/treatment/sucidal-risk-assessment/sucidal-risk-assessment.component';
import { PatientslistsComponent } from 'src/app/patient/patientslists/patientslists.component';
import { SuicidalScreenerDocumentComponent } from './staff/treatment/sucidal-risk-assessment/suicidal-screener-document/suicidal-screener-document.component';
import { PatientInvoiceListsComponent } from 'src/app/patient/patient-invoice-lists/patient-invoice-lists.component';
import { ProfileComponent } from 'src/app/usermanagement/profile/profile.component';
import { ChangepasswordprofileComponent } from './usermanagement/changepasswordprofile/changepasswordprofile.component';
import { PatientDocumentsComponent } from './common/pages/patient-documents/patient-documents.component';
import { PatientdocumentsDetailsComponent } from './common/pages/patientdocuments-details/patientdocuments-details.component';
import { LandingPageComponent } from './common/pages/landing-page/landing-page.component';
import { AuthThresholdComponent } from './patient-lead/auth-threshold/auth-threshold.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DailyschedulerComponent } from './dailyscheduler/dailyscheduler.component';
import { ProgressnotesComponent } from './progressnotes/progressnotes.component';
import { AttendanceComponent } from './attendance/attendance.component';
import { AccordiandesignDailyschedulerComponent } from './accordiandesign-dailyscheduler/accordiandesign-dailyscheduler.component';
import { SkillTaughtComponent } from './common/pages/template/skill-taught/skill-taught.component';
import { CumulativeInsuranceComponent } from './patient-lead/cumulative-insurance/cumulative-insurance.component';
import { PageAuthGuard } from './auth/page-auth.guard';
import { constantVariables } from './constant/constant';
import { AuditListComponent } from './Audit/audit-list/audit-list.component';
import { AuditClinicianComponent } from './Audit/audit-clinician/audit-clinician.component';
//import { AuditClinicianComponent } from './Audit/audit-clinician/audit-clinician.component';
import { ClientDocumentsComponent } from './common/pages/client-documents/client-documents.component';
import { AdmindeclainlistLeadComponent } from './patient-lead/admindeclainlist-lead/admindeclainlist-lead.component';
import { PatientDeclainlistComponent } from './patient/patient-declainlist/patient-declainlist.component';
////import { AdmindeclainlistLeadComponent } from './patient-lead/admindeclainlist-lead/admindeclainlist-lead.component';
//import { PatientDeclainlistComponent } from './patient/patient-declainlist/patient-declainlist.component';
import { TreatmentNotesComponent } from './staff/treatment/treatment-notes/treatment-notes.component';
//import { MedicationPdfforchildrenComponent } from './staff/clinician/clinician-mytask/medication-pdfforchildren/medication-pdfforchildren.component';
//import { MedicationReviewsheetComponent } from './staff/clinician/clinician-mytask/medication-pdfforchildren/medication-reviewsheet/medication-reviewsheet.component';
import { TreatmentMeetingNotesComponent } from './staff/treatment/treatment-meeting-notes/treatment-meeting-notes.component';
import { IntakeUpdateRejoinersComponent } from './staff/intake-update-rejoiners/intake-update-rejoiners.component';
import {PendingDocumentsComponent} from './staff/pending-documents/pending-documents.component';
import {FinanciallistComponent} from './patient/financiallist/financiallist.component';

//patientslists PatientslistsComponent
const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'login' },
  { path: 'login', component: LoginComponent, canActivate:[LoginAuthGuard] },
  { path: 'register', component: RegisterComponent },
  { path: 'forgetpassword', component: ForgetpasswordComponent },
  { path: 'changepassword', component: ChangepasswordComponent },
  
  //{ path: 'layout', component: SiteLayoutComponent },
  {
    path: '',
    component: SiteLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', component: LeadListviewComponent, pathMatch: 'full',  },
      { path: 'home', component: HomeComponent },
      { path: 'register', component: RegisterComponent },
      { path: 'userlist', component: UserlistComponent },
      { path: 'user-manage', component: UserManageComponent,data: {roles:['Admin,Clinician'],pageId : constantVariables.pagelists.StaffManagement},canActivate:[PageAuthGuard] },
      { path: 'roles', component: RolesComponent },
      { path: 'rolepermission', component: RolePermissionComponent },
      { path: 'lead', component: LeadComponent ,data: {roles:['Admin,Clinician,Lead']},canActivate:[PageAuthGuard]},

      { path: 'leadaccount', component: LeadAccountComponent,data: {roles:['Admin,Clinician'],pageId : constantVariables.pagelists.Lead},canActivate:[PageAuthGuard] },
      { path: 'leadlist', component: LeadListviewComponent,data: {roles:['Admin,Clinician'],pageId : constantVariables.pagelists.Lead},canActivate:[PageAuthGuard] },
      { path: 'leaddetailview', component: LeadDetailviewComponent,data: {roles:['Admin,Clinician'],pageId : constantVariables.pagelists.PatientAccount},canActivate:[PageAuthGuard]  },
      { path: 'leadkanban', component: LeadKanbanComponent,data: {roles:['Admin,Clinician'],pageId : constantVariables.pagelists.PatientAccount},canActivate:[PageAuthGuard]  },
      { path: 'leadinfo', component: LeadInfoComponent,data: {activeTab: 'leadlist',roles:['Admin,Lead'],pageId : constantVariables.pagelists.Profile}, canActivate:[PageAuthGuard] },
      { path: 'masters', component: MastersComponent },
      { path: 'phonebook', component: PhoneBookComponent ,data: {roles:['Admin,Clinician'],pageId : constantVariables.pagelists.Settings},canActivate:[PageAuthGuard] },
      { path: 'viewnotification', component: ViewNotificationComponent },
      { path: 'messages', component: MessagesComponent,data: {roles:['Admin,Clinician,Lead'],pageId : constantVariables.pagelists.NoPage},canActivate:[PageAuthGuard] },
      { path: 'consent', component: ConsentComponent ,data: {roles:['Admin,Clinician'],pageId : constantVariables.pagelists.Templates},canActivate:[PageAuthGuard] },
      { path: 'crba', component: CrbaComponent,data: {roles:['Admin,Clinician'],pageId : constantVariables.pagelists.Lead},canActivate:[PageAuthGuard] },
      { path: 'consentlist', component: ConsentTemplatesComponent,data: {roles:['Admin,Clinician'],pageId : constantVariables.pagelists.Templates},canActivate:[PageAuthGuard]  },
      { path: 'request-tour', component: RequestTourComponent,data: {roles:['Admin,Clinician'],pageId : constantVariables.pagelists.Lead},canActivate:[PageAuthGuard]  },
      { path: 'financial-proposal', component: FinancialProposalComponent,data: {roles:['Admin,Clinician'],pageId : constantVariables.pagelists.Lead},canActivate:[PageAuthGuard]  },
      { path: 'price-catalogue', component: PriceCatalogueComponent,data: {roles:['Admin,Clinician'],pageId : constantVariables.pagelists.Lead},canActivate:[PageAuthGuard]  },
      { path: 'notes', component: NotesComponent },
      { path: 'task', component: TaskComponent,data: {roles:['Lead']},canActivate:[PageAuthGuard]  },
      { path: 'calender', component: CalenderComponent ,data: {roles:['Lead']},canActivate:[PageAuthGuard]},
      { path: 'financial-review', component: FinancialReviewComponent ,data: {roles:['Admin,Clinician,Lead'],pageId : constantVariables.pagelists.Lead},canActivate:[PageAuthGuard]},
      { path: 'patient', component: PatientListviewComponent, data: {roles:['Admin,Clinician'],pageId : constantVariables.pagelists.PatientAccount},canActivate:[PageAuthGuard]},
      { path: 'settings', component: SettingsComponent , data: {roles:['Admin,Clinician'],pageId : constantVariables.pagelists.Settings},canActivate:[PageAuthGuard]},
      { path: 'intake-schedule', component: IntakeScheduleComponent,data: {roles:['Admin,Clinician'],pageId : constantVariables.pagelists.ScheduleIntake},canActivate:[PageAuthGuard] },
      { path: 'patient-breifcase', component: PatientBreifcaseComponent,data: {roles:['Admin,Clinician,Lead'],pageId : constantVariables.pagelists.Briefcase},canActivate:[PageAuthGuard] },
      { path: 'sfcc-location', component: SfccLocationComponent ,data: {roles:['Admin,Clinician'],pageId : constantVariables.pagelists.Settings},canActivate:[PageAuthGuard]},
      { path: 'configure-forms', component: ConfigureFormsComponent,data: {roles:['Admin,Clinician,Lead'],pageId : constantVariables.pagelists.Templates},canActivate:[PageAuthGuard] },
      { path: 'intake-consent', component: IntakeConsentComponent,data: {roles:['Admin,Clinician,Lead'],pageId : constantVariables.pagelists.PatientAccount},canActivate:[PageAuthGuard]  },
      { path: 'intake-checklist', component: IntakeChecklistComponent ,data: {roles:['Admin,Clinician,Lead'],pageId : constantVariables.pagelists.PatientAccount},canActivate:[PageAuthGuard] },
      { path: 'intake-survey', component: IntakeSurveyComponent ,data: {roles:['Admin,Clinician,Lead'],pageId : constantVariables.pagelists.PatientAccount},canActivate:[PageAuthGuard] },
      { path: 'config-goal', component: GoalsComponent,data: {roles:['Admin,Clinician'],pageId : constantVariables.pagelists.Goals},canActivate:[PageAuthGuard]},
      { path: 'skill-taught', component:SkillTaughtComponent,data: {roles:['Admin,Clinician'],pageId : constantVariables.pagelists.Templates},canActivate:[PageAuthGuard]  },
      { path: 'clinician', component: ClinicianLayoutComponent,data: {roles:['Admin,Clinician'],pageId : constantVariables.pagelists.Task},canActivate:[PageAuthGuard]   },
      { path: 'myappointments', component: ClinicianMytaskComponent ,data: {roles:['Admin,Clinician'],pageId : constantVariables.pagelists.ProgressNoteFivepointRating},canActivate:[PageAuthGuard]  },
      { path: 'intake-nutritional', component: IntakeNutritionalComponent,data: {roles:['Admin,Clinician'],pageId : constantVariables.pagelists.NutritionalQuestion},canActivate:[PageAuthGuard] },
      { path: 'intake-generate', component: IntakeGenerateComponent ,data: {roles:['Admin,Clinician'],pageId : constantVariables.pagelists.GenerateIntake},canActivate:[PageAuthGuard] },
      { path: 'intake-chma', component: IntakeCmhaComponent ,data: {roles:['Admin,Clinician'],pageId : constantVariables.pagelists.TreatmentPlan},canActivate:[PageAuthGuard] },
      { path: 'pain-assessment', component: PainAssessmentComponent ,data: {roles:['Admin,Clinician'],pageId : constantVariables.pagelists.PainAssessment},canActivate:[PageAuthGuard]},
      { path: 'task-info', component: TaskInfoComponent,data: {roles:['Admin,Clinician'],pageId : constantVariables.pagelists.Task},canActivate:[PageAuthGuard]},
      { path: 'treatmentplan-list', component:TreatmentplanListComponent,data: {roles:['Admin,Clinician'],pageId : constantVariables.pagelists.TreatmentPlan},canActivate:[PageAuthGuard]},
      { path: 'treatment-notes', component:TreatmentNotesComponent,data: {roles:['Admin,Clinician'],pageId : constantVariables.pagelists.PatientAccount},canActivate:[PageAuthGuard]},
      
      { path: 'treatmentplan', component:TreatmentplanComponent,data: {roles:['Admin,Clinician'],pageId : constantVariables.pagelists.TreatmentPlan},canActivate:[PageAuthGuard]},
      { path: 'schedule-modality', component:ScheduleModalityComponent,data: {roles:['Admin,Clinician'],pageId : constantVariables.pagelists.ScheduleAppointmentsTherapies},canActivate:[PageAuthGuard]},
      {path: 'goal-list', component:GoalListComponent,data: {roles:['Admin,Clinician'],pageId : constantVariables.pagelists.Goals},canActivate:[PageAuthGuard]},
      {path: 'group-therapy',component:GroupTherapyComponent,data: {roles:['Admin,Clinician'],pageId : constantVariables.pagelists.ProgressNoteFivepointRating},canActivate:[PageAuthGuard]},
      {path: 'schedule-grptherapy',component:ScheduleGrptherapyComponent},
      {path: 'scheduler',component:SchedulerCalenderComponent,data: {roles:['Admin,Clinician'],pageId : constantVariables.pagelists.ScheduleAppointmentsTherapies},canActivate:[PageAuthGuard]},
      {path: 'day-schedule',component:DayScheduleComponent,data: {roles:['Admin,Clinician'],pageId : constantVariables.pagelists.ScheduleAppointmentsTherapies},canActivate:[PageAuthGuard]},
      {path: 'progess-track',component:ProgressTrackComponent,data: {roles:['Admin,Clinician'],pageId : constantVariables.pagelists.ScheduleAppointmentsTherapies},canActivate:[PageAuthGuard]},
      {path: 'treatment-history',component:TreatmenthistoryComponent,data: {roles:['Admin,Clinician'],pageId : constantVariables.pagelists.TreatmentHistory},canActivate:[PageAuthGuard]},
      {path: 'family-individual-theraphy',component:FamilyIndividualTheraphyComponent,data: {roles:['Admin,Clinician'],pageId : constantVariables.pagelists.ProgressNoteFivepointRating},canActivate:[PageAuthGuard]},
      {path: 'bill-lists',component:BillListsComponent,data: {roles:['Admin,Clinician'],pageId : constantVariables.pagelists.CreateBill},canActivate:[PageAuthGuard]},
      {path: 'bill',component:BillComponent,data: {roles:['Admin,Clinician'],pageId : constantVariables.pagelists.CreateBill},canActivate:[PageAuthGuard]},
      {path:'incidentreportingList', component:IncidentReportingListComponent,data: {roles:['Admin,Clinician'],pageId : constantVariables.pagelists.IncidentReporting},canActivate:[PageAuthGuard]},
      {path:'incident-report', component:IncidentReportingComponent,data: {roles:['Admin,Clinician'],pageId : constantVariables.pagelists.IncidentReporting},canActivate:[PageAuthGuard]},
      {path:'incident-review', component:IncidentReviewComponent,data: {roles:['Admin,Clinician'],pageId : constantVariables.pagelists.IncidentReporting},canActivate:[PageAuthGuard]},
      {path:'billing-masters', component:BillingMastersComponent,data: {roles:['Admin,Clinician'],pageId : constantVariables.pagelists.BillingMasters},canActivate:[PageAuthGuard]},
      {path:'transaction-form', component:TransactionCodeComponent,data: {roles:['Admin,Clinician'],pageId : constantVariables.pagelists.BillingMasters},canActivate:[PageAuthGuard]},
      {path:'billing-provider', component:BillingProviderComponent,data: {roles:['Admin,Clinician'],pageId : constantVariables.pagelists.BillingMasters},canActivate:[PageAuthGuard]} ,
      {path:'claims-lists', component:ClaimsListsComponent,data: {roles:['Admin,Clinician'],pageId : constantVariables.pagelists.InsuranClaims},canActivate:[PageAuthGuard]} ,
      {path:'psychiatricevaluation', component:PsychiatricEvaluationProgressNotesComponent,data: {roles:['Admin,Clinician'],pageId : constantVariables.pagelists.ProgressNoteFivepointRating},canActivate:[PageAuthGuard]} ,
      {path:'psychiatrictherapy', component:PsychiatricTherapyProgressNotesComponent,data: {roles:['Admin,Clinician'],pageId : constantVariables.pagelists.ProgressNoteFivepointRating},canActivate:[PageAuthGuard]} ,
      {path:'psychiatricmedication', component:PsychiatricMedicationProgressNotesComponent,data: {roles:['Admin,Clinician'],pageId : constantVariables.pagelists.ProgressNoteFivepointRating},canActivate:[PageAuthGuard]} ,
      {path:'dischargesummary-list', component:DischargeSummaryListComponent,data: {roles:['Admin,Clinician'],pageId : constantVariables.pagelists.DischargeSummary},canActivate:[PageAuthGuard]} ,
      {path:'discharge-summary', component:DischargeSummaryComponent,data: {roles:['Admin,Clinician'],pageId : constantVariables.pagelists.DischargeSummary},canActivate:[PageAuthGuard]} ,
      {path:'treatment-summary', component:TreatmentSummaryComponent,data: {roles:['Admin,Clinician'],pageId : constantVariables.pagelists.DischargeSummary},canActivate:[PageAuthGuard]} ,
      {path:'discharged-patient-list', component:DischargedPatientListViewComponent,data: {roles:['Admin,Clinician'],pageId : constantVariables.pagelists.PatientAccount},canActivate:[PageAuthGuard]} ,

      {path:'day-view-scheduler', component:AdminDayViewScheduleComponent,data: {roles:['Admin,Clinician'],pageId : constantVariables.pagelists.ScheduleAppointmentsTherapies},canActivate:[PageAuthGuard]} ,
      {path:'assign-clinician', component:AssignClinicianComponent,data: {roles:['Admin,Clinician'],pageId : constantVariables.pagelists.ScheduleAppointmentsTherapies},canActivate:[PageAuthGuard]}  ,
      {path:'meeting-appointment', component:MeetingAppointmentComponent,data: {roles:['Admin,Clinician'],pageId : constantVariables.pagelists.Settings},canActivate:[PageAuthGuard]},
      {path:'phone-schoolmeet-schedule', component:PhonecallSchoolmeetScheduleComponent,data: {roles:['Admin,Clinician'],pageId : constantVariables.pagelists.ScheduleAppointmentsTherapies},canActivate:[PageAuthGuard]},
      {path:'phone-schoolmeet-progressnotes', component:PhonecallSchoolProgressNotesComponent,data: {roles:['Admin,Clinician'],pageId : constantVariables.pagelists.ProgressNoteFivepointRating},canActivate:[PageAuthGuard]},
      {path:'patient-payment-details', component:PatientPaymentDetailsComponent,data: {roles:['Admin,Clinician'],pageId : constantVariables.pagelists.OutofpocketInvoice},canActivate:[PageAuthGuard]},
      {path:'sucidal-risk',component:SucidalRiskAssessmentComponent,data: {roles:['Admin,Clinician'],pageId : constantVariables.pagelists.SuicidalRiskAssessment},canActivate:[PageAuthGuard]},
      {path:'patientslists',component:PatientslistsComponent,data: {roles:['Admin,Clinician'],pageId : constantVariables.pagelists.PatientAccount},canActivate:[PageAuthGuard]},
      {path:'suicide-documents', component:SuicidalScreenerDocumentComponent,data: {roles:['Admin,Clinician'],pageId : constantVariables.pagelists.SuicidalRiskAssessment},canActivate:[PageAuthGuard]},
      {path:'patient-invoice-lists',component:PatientInvoiceListsComponent},
      {path:'profile',component:ProfileComponent,data: {roles:['Admin,Clinician,Lead'],pageId : constantVariables.pagelists.NoPage},canActivate:[PageAuthGuard]},
      {path:'changepasswordprofile',component:ChangepasswordprofileComponent,data: {roles:['Admin,Clinician,Lead'],pageId : constantVariables.pagelists.NoPage},canActivate:[PageAuthGuard]},
      {path:'patient-documents',component:PatientDocumentsComponent,data: {roles:['Admin,Clinician'],pageId : constantVariables.pagelists.Documents},canActivate:[PageAuthGuard]},
      {path:'patientdocuments-details',component:PatientdocumentsDetailsComponent,data: {roles:['Admin,Clinician'],pageId : constantVariables.pagelists.Documents},canActivate:[PageAuthGuard]},
      {path:'welcome',component:LandingPageComponent,data: {roles:['Admin,Clinician,Lead'],pageId : constantVariables.pagelists.NoPage},canActivate:[PageAuthGuard]},
      {path:'auth-threshold',component:AuthThresholdComponent,data: {roles:['Admin,Clinician'],pageId : constantVariables.pagelists.Authorization},canActivate:[PageAuthGuard]},
      { path: 'dashboard', component: DashboardComponent ,data: {roles:['Admin,Clinician'],pageId : constantVariables.pagelists.Dashboard},canActivate:[PageAuthGuard]},
      { path: 'dailyscheduler', component: DailyschedulerComponent, data: {roles:['Admin,Clinician'],pageId : constantVariables.pagelists.ScheduleAppointmentsTherapies},canActivate:[PageAuthGuard]},
      { path: 'progressnotes', component: ProgressnotesComponent, data: {roles:['Admin,Clinician'],pageId : constantVariables.pagelists.ProgressNoteFivepointRating},canActivate:[PageAuthGuard]},
      { path: 'grouptherapy', component: AccordiandesignDailyschedulerComponent,data: {roles:['Admin,Clinician'],pageId : constantVariables.pagelists.ScheduleAppointmentsTherapies},canActivate:[PageAuthGuard] },
      { path: 'attendance', component: AttendanceComponent ,data: {roles:['Admin,Clinician'],pageId : constantVariables.pagelists.ProgressNoteFivepointRating},canActivate:[PageAuthGuard]},
      { path: 'cumulative-insurance', component : CumulativeInsuranceComponent,data: {roles:['Admin,Clinician'],pageId : constantVariables.pagelists.PatientAccount},canActivate:[PageAuthGuard]},
      { path: 'audit-list', component : AuditListComponent,data: {roles:['Admin,Clinician'],pageId : constantVariables.pagelists.Audit},canActivate:[PageAuthGuard]},
      { path: 'audit-clinician', component : AuditClinicianComponent,data: {roles:['Admin,Clinician'],pageId : constantVariables.pagelists.Audit},canActivate:[PageAuthGuard]},
      {path : 'client-documents',component : ClientDocumentsComponent,data: {roles:['Admin,Clinician,Lead'],pageId :constantVariables.pagelists.ClientDocuments},canActivate:[PageAuthGuard]},
      {path:'admindeclainlist-lead',component:AdmindeclainlistLeadComponent,data: {roles:['Admin,Clinician,Lead'],pageId : constantVariables.pagelists.NoPage},canActivate:[PageAuthGuard]},
      {path : 'patient-declainlist', component : PatientDeclainlistComponent, data: {roles: ['Admin,Clinician,Lead'], pageId : constantVariables.pagelists.NoPage},canActivate:[PageAuthGuard]},
     // {path : 'medication-pdfforchildren', component : MedicationPdfforchildrenComponent, data:  {roles:['Admin,Clinician'],pageId : constantVariables.pagelists.ProgressNoteFivepointRating},canActivate:[PageAuthGuard]},
     // {path : 'medication-reviewsheet', component : MedicationReviewsheetComponent, data:  {roles:['Admin,Clinician'],pageId : constantVariables.pagelists.ProgressNoteFivepointRating},canActivate:[PageAuthGuard]},
      {path:'treatment-meeting-notes', component:TreatmentMeetingNotesComponent,data: {roles:['Admin,Clinician'],pageId : constantVariables.pagelists.ProgressNoteFivepointRating},canActivate:[PageAuthGuard]},
      {path:'intake-update-rejoiners', component:IntakeUpdateRejoinersComponent,data: {roles:['Admin,Clinician'],pageId : constantVariables.pagelists.ProgressNoteFivepointRating},canActivate:[PageAuthGuard]},
      {path:'pending-documents', component:PendingDocumentsComponent,data: {roles:['Admin,Clinician'],pageId : constantVariables.pagelists.ProgressNoteFivepointRating},canActivate:[PageAuthGuard]},
      {path:'financiallist', component:FinanciallistComponent}


    ]
  },
  // {
  //   path: '',
  //   component: ClinicianLayoutComponent,
  //   canActivate: [AuthGuard],
  //   children: [
  //     { path: '', component: ClinicianLayoutComponent, pathMatch: 'full' },
  //     { path: 'clinician', component: ClinicianLayoutComponent },
  //     { path: 'myappointments', component: ClinicianMytaskComponent },
  //     { path: 'intake-nutritional', component: IntakeNutritionalComponent },
  //     { path: 'intake-generate', component: IntakeGenerateComponent },
  //     { path: 'intake-chma', component: IntakeCmhaComponent },
  //     { path: 'pain-assessment', component: PainAssessmentComponent },
  //     { path: 'task-info', component: TaskInfoComponent},
  //     { path: 'treatmentplan-list', component:TreatmentplanListComponent},
  //     { path: 'treatmentplan', component:TreatmentplanComponent}
  //   ]
  // }

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
