import { Component, OnInit } from '@angular/core';
import { constantVariables } from 'src/app/constant/constant';
import { PageAccessService } from 'src/app/rolemanagement/PageRoleAccessService';
import { RoelAccess } from 'src/app/_models/_Common/roleAccessMaster';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'src/app/common/common.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ClinicianService } from 'src/app/staff/clinician/clinician.service';
import { TherapyDetailsPopUpComponent } from '../common/therapy-details-pop-up/therapy-details-pop-up.component';
import { Global } from 'src/app/global/global.model';
import { MatDialog } from '@angular/material/dialog';
import { ConsentEsignDialogComponent } from 'src/app/common/consentesign-dialog/consentesign-dialog.component';
import { SnackBarService } from 'src/app/services/snackBar.service'
import { TreatmentService } from '../staff/treatment/treatment.service';
import { ConfirmDialogComponent } from '../common/confirm-dialog/confirm-dialog.component';
import moment from 'moment';
import { MatTabChangeEvent } from '@angular/material/tabs';

@Component({
  selector: 'app-progressnotes',
  templateUrl: './progressnotes.component.html',
  styleUrls: ['./progressnotes.component.scss']
})
export class ProgressnotesComponent implements OnInit {
  roleAccess: RoelAccess;
  userId: any;
  grpTherapyId: any;
  selecteddate: any;
  paramClinicianId: any;
  dayScheduleSubGroupId: any;
  isPatientLogin: boolean;
  patientaccountid: any;
  patientList: any;
  isESignAttached: boolean;
  signatureImg: any;
  updatedsignatureImg: any;
  signatureImgSecondary: any;
  updatedsignatureImgSecondary: any;
  isformtype: string;
  defaultImage: any;
  selectedTab: number = 0;
  currentClient: any;
  progressActivityNoteDetails: any = {};
  groupTherapyName: string = '';
  scheduleDate: any;
  startTime: any;
  endTime: any;
  isLicensed: boolean = false;
  RoleId: any;
  isSubmitted: boolean = false;
  roleName: string
  disableAllProgressField: boolean = false;
  isClinician: boolean = false;
  isGroupLeader: boolean = false;
  patientAccountId: any;
  groupTherapyGoals: any;
  isNotPresenting: boolean = false;
  PopUp: boolean;
  editclinicalchellages = false;
  PopUpdata: any = { 'benchmarkTitle': '', 'nextBenchmark': '' };
  SelectedBenchmark: number;
  isHomeInstruction: boolean = false;
  isSave: boolean = false;
  PresentorAbsent : boolean = false;
  secondaryId : any;
  AutoFillSS:boolean=false;
  SSignature:any;
  GetClinicianSign: any[];  
  constructor(public roleaccessservice: PageAccessService, private router: Router, private route: ActivatedRoute,
    public commonService: CommonService, public spinnerservice: NgxSpinnerService, public clinicianservice: ClinicianService, public global: Global,
    private dialog: MatDialog, private snackbar: SnackBarService, public treatmentservice: TreatmentService) { }

  ngOnInit(): void {
    this.isLicensed = localStorage.getItem('isLicensed') == 'true' ? true : false;
    this.RoleId = localStorage.getItem('roleId');
    this.roleName = localStorage.getItem('roleName');
    const pageid = constantVariables.pagelists.ProgressNoteFivepointRating;
    this.roleAccess = this.roleaccessservice.GetRoleAccessByPageId(pageid);
    this.userId = Number(localStorage.getItem('id'));
    this.paramClinicianId = "0";
    this.route.queryParams.subscribe(params => {
      if (Object.keys(params).length != 0) {
        this.grpTherapyId = JSON.parse(this.commonService.FrontEndDecryption(params.id));
        if (params.dayScheduleSubGroupId) {
          this.dayScheduleSubGroupId = JSON.parse(this.commonService.FrontEndDecryption(params.dayScheduleSubGroupId));
        }
        if (params.paramClinicianId) {
          this.paramClinicianId = JSON.parse(this.commonService.FrontEndDecryption(params.paramClinicianId));
        }
        if (params.paramIsPatientLogin) {
          let ptLogin = JSON.parse(this.commonService.FrontEndDecryption(params.paramIsPatientLogin));
          this.isPatientLogin = (ptLogin == "true") ? true : false;
        }
        if (params.patientaccountid) {
          this.patientaccountid = JSON.parse(this.commonService.FrontEndDecryption(params.patientaccountid));
        }
        this.getgrouptherapy();
        this.verifyIsClinician();
        this.getAutoFillSignature();
      }
    });
  }

  // draftTab:any;
  // onTabChangeProgress(tab) {
  //   this.draftTab = tab;
  //   if(tab != 0){
  //     this.isSave = true;
  //   }
  //   if(tab == 0) {
  //     this.selectedTab = tab;
  //   }
  //   this.currentClient = this.patientList[tab];
  //   this.isSubmitted = false;
  //   this.getGroupTheraphyProgressNotes();
  // }
  getGroupTheraphyProgressNotes() {
    this.spinnerservice.show();
    this.secondaryId = localStorage.getItem('id');
    this.clinicianservice.getGroupTheraphyProgressNotes(this.currentClient.dayScheduleSubGroupId, this.currentClient.patientAccountId,this.secondaryId).subscribe(
      (res: any) => {
        if (res) {
          this.progressActivityNoteDetails = res;
          this.isHomeInstruction = this.progressActivityNoteDetails.isHomeInstruction;
          if (this.progressActivityNoteDetails.actionItems == null) {
            this.isNotPresenting = true;
            this.disableAllProgressField = true;
          } else {
            this.isNotPresenting = false;
            this.disableAllProgressField = false;
          }
          if (this.progressActivityNoteDetails?.leaderList?.length > 0) {
            var groupLeaderString = "";
            this.progressActivityNoteDetails?.leaderList?.map(leader => {
              groupLeaderString += leader.userName + ",";
            });
            this.isGroupLeader = groupLeaderString?.includes(this.userId.toString());
          }
          if (this.progressActivityNoteDetails?.actionList?.length > 0) {
            //this.isGroupLeader = this.progressActivityNoteDetails.groupLeader.includes(this.userId.toString());
            this.progressActivityNoteDetails?.groupTemplate.map(list => {
              var groupLeaderName = '';
              list.leaderList.map(x => {
                if (groupLeaderName == '') {
                  groupLeaderName = x.userName;
                } else {
                  groupLeaderName = groupLeaderName + ', ' + x.userName;
                }
                list.groupLeaderName = groupLeaderName;
              })
            });
          }
          // if(this.progressActivityNoteDetails.isGroupLeaderSigned)
          // {
          //   this.disableAllProgressField = true;
          // }
          let IsSignExist = this.progressActivityNoteDetails?.progressSign?.filter(x => x.clinicainIntern == this.userId);
          if (IsSignExist?.length > 0) {
           
           this.progressActivityNoteDetails.clinicianSign = IsSignExist[0].sign;
            
         // (document.getElementById('esign') as HTMLImageElement).src = IsSignExist[0].sign;

          } 
          // else {
          //   //this.progressActivityNoteDetails.clinicianSign = undefined;
            

          // (document.getElementById('esign') as HTMLImageElement).src = this.progressActivityNoteDetails.progressSigns;

          // }
          this.progressActivityNoteDetails.progressSign = this.progressActivityNoteDetails?.progressSign?.filter(x => x.clinicainIntern != this.userId);
          
          this.progressActivityNoteDetails.SecondaryclinicianSign = this.progressActivityNoteDetails.secondaryclinicianSign;
  
          this.patientAccountId = this.progressActivityNoteDetails.patientAccountId;
          var therapyType = 3; //For Group Therapy
          // this.treatmentservice.getTreatmentGoalsListBasedOnTherapy(this.patientAccountId,therapyType).subscribe(
          //   (res: any) => {
          //     if (res) {
          //       this.groupTherapyGoals=res;
          //     }
          //   },
          //   err => {
          //     this.spinnerservice.hide();
          //   },
          // );
          if (!this.progressActivityNoteDetails.isHomeInstruction) {
            this.treatmentservice.getTreatmentGoalsBenchmarkTrack(this.currentClient.patientAccountId, this.currentClient.dayScheduleSubGroupId).subscribe(
              (res: any) => {
                if (res) {
                  this.groupTherapyGoals = res;
                }
              },
              err => {
                this.spinnerservice.hide();
              },
            );
          }

          // if(this.progressActivityNoteDetails.isClinicianSigned){
          //   this.disableAllProgressField = true;
          // }
          // else
          // {
          //   this.disableAllProgressField = false;
          // }
          if (this.progressActivityNoteDetails.isGroupLeaderSigned) {
            this.disableAllProgressField = true;
          }
          else {
            this.disableAllProgressField = false;
          }
        }
        this.spinnerservice.hide();
      },
      err => {
        this.spinnerservice.hide();
      },
    );
  }
  OnstatusChange(item) {
    this.SelectedBenchmark = null
    item.Isstatuschnaged = true
    if (item.trackBenchIsSelected) {
      if (item.status == 3) {
        this.PopUp = true;
        this.PopUpdata = item;
      }
    }
    else {
      this.snackbar.error("Benchmark Already completed");
      for (let itemData of this.groupTherapyGoals) {
        if (itemData.trackbenchmarkId == item.trackbenchmarkId) {
          itemData.status = 3;
        }
      }
      //[...this.groupTherapyGoals] = this.groupTherapyGoals;

    }
    //  this.PopUp = true
    //  this.PopUpdata = item
  }
  Onclosepopup(popUpdata) {

    this.groupTherapyGoals.forEach(item => {
      if (item.trackbenchmarkId == popUpdata.trackbenchmarkId) {
        item.status = 2;
      }
    });
    this.PopUp = false
    return

  }
  OnBenchmarkselect() {
    this.spinnerservice.show();

    if (this.SelectedBenchmark == 1) {
      for (let item of this.groupTherapyGoals) {
        if (item.trackbenchmarkId == this.PopUpdata.trackbenchmarkId) {
          item.Isstatuschnaged = true;
          item.status = 3
          item.IsNextBenchMark = false;
          console.log(item)
          this.PopUp = false
          this.spinnerservice.hide()
          return
        }
      }
    }
    else if (this.SelectedBenchmark == 2) {
      for (let item of this.groupTherapyGoals) {
        if (item.trackbenchmarkId == this.PopUpdata.trackbenchmarkId) {
          item.Isstatuschnaged = true;
          item.status = 3
          item.IsNextBenchMark = true;
          console.log(item)
          this.PopUp = false
          this.spinnerservice.hide()
          return
        }
      }
    }
    this.spinnerservice.hide()
    //console.log(this.SelectedBenchmark)

  }
  getgrouptherapy() {
    //this.spinnerservice.show();
    this.clinicianservice.getSubGroupTherapyDetails(this.dayScheduleSubGroupId).subscribe(
      (res: any) => {
        //this.spinnerservice.hide();
        if (res != null) {
          this.patientList = res;
          this.groupTherapyName = this.patientList[0].groupTherapyName;
          this.scheduleDate = this.patientList[0].schduleDate;
          this.startTime = this.patientList[0].startTime;
          this.endTime = (moment(this.patientList[0].startTime)).add(this.patientList[0].duration, 'minutes').format('hh:mm A');
        }
      },
      (err) => {
        this.spinnerservice.hide();
      },
    );
  }
  selectUnselectAction(event, item, j, i) {
    event = !event;
    if (event) {
      this.progressActivityNoteDetails.actionCount[j].activityCount += 1;
    }
    else {
      this.progressActivityNoteDetails.actionCount[j].activityCount -= 1;

    }
    this.progressActivityNoteDetails.actionItems[i].activeItems[j].isSelected = event;
    item.activeItems[j].isSelected = event;

  }
  submit() {
    this.isSubmitted = true;
    // if (!this.isHomeInstruction && (!this.progressActivityNoteDetails.observedbehaviour || !this.progressActivityNoteDetails.followUpPlan ||
    //   this.progressActivityNoteDetails.actionList.filter(x => !x.comments).length > 0) && (this.isClinician || this.isGroupLeader)) {
    //   return;
    // }
    if (this.isGroupLeader && this.progressActivityNoteDetails.clinicianSign == undefined && this.isLicensed) {
      return;
    }
    this.progressActivityNoteDetails.clinicianIntern = this.userId;
    if (this.isGroupLeader && this.progressActivityNoteDetails.clinicianSign != "" && this.progressActivityNoteDetails.clinicianSign != undefined) {
      this.progressActivityNoteDetails.isGroupLeaderSigned = true;
    }
    //Secondary Clinician

    if (this.isGroupLeader && this.progressActivityNoteDetails.SecondaryclinicianSign == undefined && this.isLicensed) {
      return;
    }
    this.progressActivityNoteDetails.clinicianIntern = this.userId;
    if (this.isGroupLeader && this.progressActivityNoteDetails.SecondaryclinicianSign != "" && this.progressActivityNoteDetails.SecondaryclinicianSign != undefined) {
      this.progressActivityNoteDetails.isGroupLeaderSigned = true;
    }
console.log(this.progressActivityNoteDetails);

    this.spinnerservice.show();
    this.clinicianservice.PostGroupTherapyProgressNoteDetails(this.progressActivityNoteDetails).subscribe(
      (res: any) => {

        if (res) {
          if (!this.progressActivityNoteDetails.isHomeInstruction) {
            this.clinicianservice.PostGroupTherapyBenchmark(this.groupTherapyGoals).subscribe(
              (resBenchmark: any) => {
                this.spinnerservice.hide();
                this.snackbar.success(res);
                this.getGroupTheraphyProgressNotes();
              },
              err => {
                this.snackbar.error(err);

                this.spinnerservice.hide();

              },
            );
          } else {
            this.spinnerservice.hide();
            this.snackbar.success(res);
            this.getGroupTheraphyProgressNotes();
          }

        }
      },
      err => {
        this.snackbar.error(err);

        this.spinnerservice.hide();

      },
    );
  }
  openDialog(item) {
    // this.global.isconsentesign = false;
    // const dialogRef = this.dialog.open(ConsentEsignDialogComponent,
    //   {
    //     data: { parentinfoId: this.userId, isfutureSign: false, isParentsign: true },
    //     autoFocus: false
    //   });
    // dialogRef.afterClosed().subscribe(result => {
    //   this.isESignAttached = this.isESignAttached;
    //   if (this.isESignAttached != undefined && this.isESignAttached) {
      this.global.isconsentesign = false;
      let isParentsign =false;
      if(this.AutoFillSS==true)
       {
         isParentsign=true;
    
       }
       const dialogRef = this.dialog.open(ConsentEsignDialogComponent,
         {
        data: { parentinfoId: this.userId, isfutureSign: undefined, isParentsign: isParentsign } ,
         autoFocus: false 
    
       });
      dialogRef.afterClosed().subscribe(result => {
  
        this.isESignAttached = this.isESignAttached;
        this.isESignAttached = this.global.isconsentesign;
        if (this.isESignAttached != undefined && this.isESignAttached) {
          if(this.SSignature!='')
            {
             this.updatedsignatureImg=this.SSignature;
            }
        this.signatureImg = this.updatedsignatureImg;
        if (item = 1) {
          this.progressActivityNoteDetails.clinicianSign = this.updatedsignatureImg;
          (document.getElementById('esign') as HTMLImageElement).src = this.signatureImg;
        }
      }
      else if (!this.isESignAttached && (this.global.formtype != null && this.global.formtype != undefined)) {
        this.signatureImg = this.global.consentesign;
        this.isformtype = this.global.formtype;
        if (item = 1) {
          this.progressActivityNoteDetails.clinicianSign = this.signatureImg;
          (document.getElementById('esign') as HTMLImageElement).src = this.signatureImg;
        }
      }
      else {
        this.signatureImg = this.defaultImage;
        this.signatureImg = this.defaultImage;
        this.progressActivityNoteDetails.clinicianSign = this.defaultImage;
      }


    });
  }

  openDialogSecondary(item) {
    // this.global.isconsentesign = false;
    // const dialogRef = this.dialog.open(ConsentEsignDialogComponent,
    //   {
    //     data: { parentinfoId: this.userId, isfutureSign: false, isParentsign: true },
    //     autoFocus: false
    //   });
    this.global.isconsentesign = false;
    let isParentsign =false;
    if(this.AutoFillSS==true)
     {
       isParentsign=true;
  
     }
     const dialogRef = this.dialog.open(ConsentEsignDialogComponent,
       {
      data: { parentinfoId: this.userId, isfutureSign: undefined, isParentsign: isParentsign } ,
       autoFocus: false 
  
     });
    dialogRef.afterClosed().subscribe(result => {

      this.isESignAttached = this.isESignAttached;
      this.isESignAttached = this.global.isconsentesign;
      if (this.isESignAttached != undefined && this.isESignAttached) {
        if(this.SSignature!='')
          {
           this.updatedsignatureImgSecondary=this.SSignature;
          }
        this.signatureImgSecondary = this.updatedsignatureImgSecondary;
        if (item = 2) {
          this.progressActivityNoteDetails.SecondaryclinicianSign = this.updatedsignatureImgSecondary;
          (document.getElementById('esignsecond') as HTMLImageElement).src = this.signatureImgSecondary;
        }
      }
      else if (!this.isESignAttached && (this.global.formtype != null && this.global.formtype != undefined)) {
        this.signatureImgSecondary = this.global.consentesign;
        this.isformtype = this.global.formtype;
        if (item = 2) {
          this.progressActivityNoteDetails.SecondaryclinicianSign = this.signatureImgSecondary;
          (document.getElementById('esignsecond') as HTMLImageElement).src = this.signatureImgSecondary;
        }
      }
      else {
        this.signatureImgSecondary = this.defaultImage;
        this.signatureImgSecondary = this.defaultImage;
        this.progressActivityNoteDetails.SecondaryclinicianSign = this.defaultImage;
      }


    });
  }

  staffSign(item, obj) {
    // this.global.isconsentesign = false;
    // const dialogRef = this.dialog.open(ConsentEsignDialogComponent,
    //   {
    //     data: { parentinfoId: this.userId, isfutureSign: false, isParentsign: true },
    //     autoFocus: false
    //   });
    // dialogRef.afterClosed().subscribe(result => {
    //   this.isESignAttached = this.isESignAttached;
    //   if (this.isESignAttached != undefined && this.isESignAttached) {
      this.global.isconsentesign = false;
    let isParentsign =false;
    if(this.AutoFillSS==true)
     {
       isParentsign=true;
  
     }
     const dialogRef = this.dialog.open(ConsentEsignDialogComponent,
       {
      data: { parentinfoId: this.userId, isfutureSign: undefined, isParentsign: isParentsign } ,
       autoFocus: false 
  
     });
    dialogRef.afterClosed().subscribe(result => {

      this.isESignAttached = this.isESignAttached;
      this.isESignAttached = this.global.isconsentesign;
      if (this.isESignAttached != undefined && this.isESignAttached) {
        if(this.SSignature!='')
          {
           this.updatedsignatureImg=this.SSignature;
          }
        this.signatureImg = this.updatedsignatureImg;
        if (item = 1) {
          obj.staffInitials = this.updatedsignatureImg;
          //(document.getElementById('esign'+index) as HTMLImageElement).src = this.signatureImg;
        }
      }
      else if (!this.isESignAttached && (this.global.formtype != null && this.global.formtype != undefined)) {
        this.signatureImg = this.global.consentesign;
        this.isformtype = this.global.formtype;
        if (item = 1) {
          obj.staffInitials = this.signatureImg;
          //(document.getElementById('esign'+index) as HTMLImageElement).src = this.signatureImg;
        }
      }
      else {
        this.signatureImg = this.defaultImage;
        this.signatureImg = this.defaultImage;
        obj.staffInitials = this.defaultImage;
      }


    });
  }

  disableProgressNote(item): boolean {
    var isStaffSigned = item.isStaffOrInternSigned;
    var SignedUserId = item.signedStaffOrInternUserId;

    if (this.isClinician) {
      return false;
    }
    else if (isStaffSigned && SignedUserId) {
      if (SignedUserId != this.userId) {
        return true;
      }
      else {
        return false;
      }
    }
    else {
      return false;
    }
  }

  generatePdf() {
    this.spinnerservice.show();
    this.clinicianservice.GetGroupTheraphyProgressNotes(this.currentClient.dayScheduleSubGroupId, this.currentClient.patientAccountId).subscribe(
      (res: any) => {
        this.spinnerservice.hide();
        if (res != null) {
          window.open(res.filePath, '_blank')
          console.log(res);
        }
      },
      (err) => {
        this.spinnerservice.hide();
      },
    );
  }
  verifyIsClinician() {
    //this.spinnerservice.show();
    this.clinicianservice.VerifyIsClinician().subscribe(
      (res: any) => {
        //this.spinnerservice.hide();
        this.isClinician = res;
      },
      (err) => {
        this.spinnerservice.hide();
      },
    );
  }
  editData() {
    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: '',

        message: constantVariables.SuccessMessage.ProgressNoteEdit
      }
    });
    confirmDialog.afterClosed().subscribe(result => {
      if (result == true) {
        this.disableAllProgressField = false;
      }
      else {
        this.disableAllProgressField = true;
      }
    }
    );

  }
  Editclinicalchellages() {
    this.editclinicalchellages = true;
  }

  Savedraft(index?: number) {
    this.isSubmitted = true;
    this.progressActivityNoteDetails.clinicianIntern = this.userId;
    if (this.isGroupLeader && this.progressActivityNoteDetails.clinicianSign != "" && this.progressActivityNoteDetails.clinicianSign != undefined) {
      this.progressActivityNoteDetails.isGroupLeaderSigned = true;
    }
     if (this.isGroupLeader && this.progressActivityNoteDetails.SecondaryclinicianSign != "" && this.progressActivityNoteDetails.SecondaryclinicianSign != undefined) {
      this.progressActivityNoteDetails.isGroupLeaderSigned = true;
    }
    this.spinnerservice.show();
    this.clinicianservice.PostGroupTherapyProgressNoteDetails(this.progressActivityNoteDetails).subscribe(
      (res: any) => {
        if (res) {
          if (!this.progressActivityNoteDetails.isHomeInstruction) {
            this.clinicianservice.PostGroupTherapyBenchmark(this.groupTherapyGoals).subscribe(
              (resBenchmark: any) => {
                this.spinnerservice.hide();
                this.snackbar.success(res);
                this.getGroupTheraphyProgressNotes();
              },
              err => {
                this.snackbar.error(err);
                this.spinnerservice.hide();
              },
            );
          } else {
            this.spinnerservice.hide();
            this.snackbar.success(res);
            this.getGroupTheraphyProgressNotes();
          }

        }

        if (index) {
          this.isEdit = false;
          this.currentClient = this.patientList[index];
          this.isSubmitted = false;
          this.getGroupTheraphyProgressNotes();
        }
      },
      err => {
        this.snackbar.error(err);
        this.spinnerservice.hide();
      },
    );
  }

  PopupClose() {
    this.isSave = false
  }
  //   YesOptionSelected(){
  //     this.selectedTab = this.draftTab;
  //     this.Savedraft(); 
  //   }
  // NoOptionSelected(){
  //   this.selectedTab = this.draftTab;
  //   this.isSave = false
  // }
  onTabChange(event: MatTabChangeEvent) {
    this.selectedTab = event.index;
    if (event.index == 0) {
      if (this.isEdit == true) {
        var indexvalue = event.index
        this.draftConfirmation(indexvalue);
        return;
      }
      this.currentClient = this.patientList[this.selectedTab];
      this.isSubmitted = false; this.isEdit = false;
      this.getGroupTheraphyProgressNotes();
      return
    } else {
      if (this.isEdit == true) {
        var indexvalue = event.index
        this.draftConfirmation(indexvalue);
        return;
      }
      this.currentClient = this.patientList[this.selectedTab];
      this.isSubmitted = false; this.isEdit = false;
      this.getGroupTheraphyProgressNotes();
    }
  }

  draftConfirmation(indexvalue) {
    // this.currentClient = this.patientList[indexvalue];
    //     this.isSubmitted = false;
    //     this.getGroupTheraphyProgressNotes();
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: '',
        message: constantVariables.SuccessMessage.SaveasDraftMessage
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      // If the user confirms, switch to the next tab
      if (result) {
        this.Savedraft(indexvalue);
      } else {
        this.currentClient = this.patientList[indexvalue];
        this.isSubmitted = false; this.isEdit = false;
        this.getGroupTheraphyProgressNotes();
      }
    });

  }
  isEdit = false
  ChangeEdit() {
    this.isEdit = true;
  }
  onChangeStatusInsuranceInfo() {
    
    // this.IsActivePresent = true
    this.spinnerservice.show();
    if(this.currentClient.isAbsent) {
      this.currentClient.isAbsent = false;
    } else {
      this.currentClient.isAbsent = true;
    }
    this.progressActivityNoteDetails.PresentorAbsent = this.currentClient.isAbsent;
    this.clinicianservice.PostGroupTherapyProgressNoteDetails(this.progressActivityNoteDetails).subscribe(
      (res: any) => {
        this.snackbar.success(res);
        this.getGroupTheraphyProgressNotes();
        if(this.currentClient.isAbsent == true) {
          this.snackbar.success("Client marked as present");
        }
        else{
          this.snackbar.success("Client marked as absent");

        }
        this.spinnerservice.hide();
      });
  }
  secondarySign():any{
    if(this.progressActivityNoteDetails.licenseNumber != '' && this.progressActivityNoteDetails.licenseNumber != null ){
      return false;
    }
    else if(this.progressActivityNoteDetails.sSignature ){
     return false;
    }
    else{
      return true;
    }
  }
  getAutoFillSignature() {
    this.treatmentservice.getClinicianSign(this.userId).subscribe((res: any) => {  
        this.GetClinicianSign = res;
  
        if (this.GetClinicianSign.length > 0 && this.GetClinicianSign[0].clinicianSign !== '' && this.GetClinicianSign[0].clinicianSign.length > 0) {
          this.AutoFillSS=false;
          this.SSignature=this.GetClinicianSign[0].clinicianSign;
        }
        else
        {
          this.AutoFillSS=true;
          this.SSignature='';
        }
    });
  }
}
