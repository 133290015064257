
<!--Therapy Details Pop up-->
<div class="modal bulk-add emoji-popup1 schedule-popup " id="myModalschedulepopup" [style.display]="showTherapyPopup? 'block':'none'">
    <div class="modal-dialog mb-2">
        <div class="modal-content">
            <div class="modal-body p-0">
                <div class="clearfix popup-mytask position-relative">
                    <div class="modal-closing "><a class="cursor-pointer" (click)="OncloseTherapyDetailspopup()" ><i
                                class="fa fa-times"></i></a></div>
                    <div class="col-md-12 mytask p-0">
                        <div class="col-md-12">
                          <div class="row">
                            <div class="col-md-12">
                              <span class="head-top-schedule">{{ObjTherapyPopUp.ScheduleName}}</span>
                            </div>
                          </div>
                          <div class="row" >
                            <div class="col-md-6" *ngIf="ObjTherapyPopUp.tags!=3">
                              <div class="header-day" class="schedule-pop-head mt-2" >Client Info</div>
                              <div  class=" align-middle mb-3" >
                                <a  class="item-author text-color mb-1 d-block">{{ObjTherapyPopUp?.patientName}}</a>
                                <div  class="patient-details">
                                 
                                  <h5 >
                                    <i  class="fas fa-envelope mr-1"></i>{{ObjTherapyPopUp?.email}} </h5>
                                    <h5  class="mb-0">
                                      <i class='fas fa-phone-alt'></i>{{ObjTherapyPopUp?.contactNo | mask:
                                        '(000) 000-0000'}}</h5>
                                    </div>
                                  </div>
                            </div>
                            <div class="col-md-6">
                              <div class="header-day" class="schedule-pop-head mt-2 " >Clinician Info</div>
                              <div  class=" align-middle mb-3" >
                                <a  class="item-author text-color mb-1 d-block  ">{{ObjTherapyPopUp?.clinicianName}}</a>
                                <div  class="patient-details">
                                  <h5 >
                                    <i  class="fas fa-envelope mr-1"></i>{{ObjTherapyPopUp?.clinicianemail}} </h5>
                                    <h5  class="mb-0">
                                      <i class='fas fa-phone-alt'></i>{{ObjTherapyPopUp?.clinicianContactNo | mask:
                                        '(000) 000-0000'}}</h5>
                                    </div>
                                  </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-md-6">
                              <div  class="w-100 ">
                                <span  class="sub-except schedule-time-head text-sm mr-3 mt-1">Appointment Date </span>
                                <span  class="text-color text-success">{{ObjTherapyPopUp?.scheduleDate| date:'MM/dd/yyyy'}} </span>
                              </div>
                              <div  class="w-100">
                                <span  class="sub-except schedule-time-head text-sm mr-3 mt-1">Appointment Time</span>
                                <span  class="text-color text-success">{{ObjTherapyPopUp?.fromtime| date:'hh:mm a'}}</span>
                              </div>
                              <div  class="w-100 mb-2">
                                <span  class="sub-except schedule-time-head text-sm mr-3 mt-1">Duration </span>
                                <span  class="text-color text-success">{{ObjTherapyPopUp?.ScheduleDuration}}</span>
                              </div>
                            </div>
                            <div class="col-md-6">
                              <a class="mr-2 link-trans" *ngIf="ObjTherapyPopUp.tags!=GroupTherapy && ObjTherapyPopUp.MeetingType==2 && (roleAccess.isCreate || roleAccess.isEdit)"  [ngClass]="{'disabled':ObjTherapyPopUp.MeetingLink==null || IsPast }" (click)="OnMeetingLinkClick(ObjTherapyPopUp)" title="Virtual Meeting Link"><span><i class="fas fa-link mr-2"></i>Virtual Meeting Link</span></a>
                              
                             <a class="link-trans" title="Session details" *ngIf="!(ObjTherapyPopUp.tags==Tour || ObjTherapyPopUp.tags == Phonecall || ObjTherapyPopUp.tags == School || ObjTherapyPopUp.tags == PhonecallSceduler || ObjTherapyPopUp.tags == SchoolmeetingScheduler ) && roleAccess.isView && (ObjTherapyPopUp.GroupTherapyId == undefined || ObjTherapyPopUp.GroupTherapyId == 0)" (click)="OnLoadSessionDetails(ObjTherapyPopUp)"><span><i class="fas fa-info-circle mr-2"></i>Session details</span></a>
       
                             <button type="button" *ngIf ="(ObjTherapyPopUp.GroupTherapyId != undefined && ObjTherapyPopUp.GroupTherapyId != 0 && showDs != false)"  title="View Daily Scheduler" class="btn btn-primary mr-2 btn-sm" (click)="loadFormGroup(ObjTherapyPopUp)" >View DS</button>
                             <button type="button" *ngIf ="(ObjTherapyPopUp.GroupTherapyId != undefined && ObjTherapyPopUp.GroupTherapyId != 0 && showDs != false)"  title="View Daily Scheduler" class="btn btn-primary mr-2 btn-sm" (click)="checkIn(ObjTherapyPopUp)" >Check in</button>
                             <!-- <button class="primary-btn-filled-small"  *ngIf="ObjTherapyPopUp.tags==Intake" (click)="OnSuicidalAssesment(ObjTherapyPopUp)">Suicidal Risk Assessment</button> -->
                            </div>
                          </div>
                          <div class="row" *ngIf="isShowNotes">
                            <div class="col-md-12 border-top border-bottom pt-2 pb-2">
                              <textarea cols="2" cols="2" class="form-control font-italic" maxlength="400" [(ngModel)]="adminNotes"   placeholder="Enter admin  notes" ></textarea>
                              <mat-error *ngIf="isNotesErr" class="text-xs">
                                <mat-error *ngIf="adminNotes==null || adminNotes==''">
                                  Admin Notes is required
                                </mat-error>
                             </mat-error>
                            <div class="w-100 text-right mt-2">
                              <button class="btn btn-cancel float-right" (click)="OncancelNotes()">Cancel</button>
                              <button class="btn primary-btn-filled float-right mr-2" (click)="saveNotes(ObjTherapyPopUp)">Save</button>
                              
                            </div>
                            </div>
                            </div>
                            <div class="row" *ngIf="isShowAddress">
                              <div class="col-md-12 border-top border-bottom pt-2 pb-2">
                                <textarea cols="2" cols="2" class="form-control font-italic" maxlength="400" [(ngModel)]="Address"   placeholder="Enter Off-site Address" ></textarea>
                                <mat-error *ngIf="isAddErr" class="text-xs">
                                  <mat-error *ngIf="Address==null  || Address==''">
                                   Off-site Address is required
                                  </mat-error>
                                </mat-error>
                                <div class="w-100 text-right mt-2">
                                <button class="btn btn-cancel float-right" (click)="OncancelAddress()">Cancel</button>
                                <button class="btn primary-btn-filled float-right mr-2" (click)="saveAddress(ObjTherapyPopUp)">Save</button>
                                
                              </div>
                              </div>
                              </div>

                              <div class="row" *ngIf="isVirtualMeet">
                                  <div class="col-md-12 border-top border-bottom pt-2 pb-2 ">
                                    <input type="text"  [(ngModel)]="virtualMeettxt" maxlength="400" placeholder="Enter virtual meeting link" class="form-control "/>
                                    <mat-error *ngIf="isVmeetingErr" class="text-xs">
                                      <mat-error *ngIf="virtualMeettxt==null || virtualMeettxt==''">            
                                         Virtual meeting link is required
                                        </mat-error>
                                    </mat-error>
                                  </div>
                                  <div class="w-100 text-right mt-2 ">
                                    <button class="btn btn-cancel float-right" (click)="OnCancelVirtualmeet()">Cancel</button>
                                    <button class="btn primary-btn-filled float-right mr-2" (click)="saveVirtualMeet(ObjTherapyPopUp)">Save</button>
                                  </div>
                              </div>
                          <div class="row mt-2">
                            <div class="col-md-12 text-right mt-4 mb-3" *ngIf="!(ObjTherapyPopUp.tags==GroupTherapy)">
                              <button type="button" class="btn btn-primary mr-2 btn-sm" (click)="OnAddAdminNotes(ObjTherapyPopUp)" *ngIf="!IsPast && ObjTherapyPopUp.tags!=Tour && (roleAccess.isCreate || roleAccess.isEdit)">Add Notes</button>
                              <button type="button" class="btn btn-success mr-2 btn-sm" (click)="OnVirtualMeetingLink(ObjTherapyPopUp)" *ngIf="ObjTherapyPopUp.tags == School && ObjTherapyPopUp.MeetingType==2 && ObjTherapyPopUp.tags!=Intake && ObjTherapyPopUp.tags!=Tour && !IsPast && (roleAccess.isCreate || roleAccess.isEdit)">Add Virtual Meeting link</button>
                              <button type="button" class="btn btn-success mr-2 btn-sm" (click)="OnAddress(ObjTherapyPopUp)" *ngIf="ObjTherapyPopUp.tags == School && ObjTherapyPopUp.MeetingType==3 && ObjTherapyPopUp.tags!=Intake && ObjTherapyPopUp.tags!=Tour && (roleAccess.isCreate || roleAccess.isEdit)">Add Address</button>
                              
                              <button type="button" class="btn btn-success mr-2 btn-sm" (click)="onRescheduleMS(ObjTherapyPopUp)" *ngIf="(!(IsPast || ObjTherapyPopUp.tags == SchoolmeetingScheduler || ObjTherapyPopUp.tags == BlockTimeScheduler )) && ObjTherapyPopUp.tags != Tour &&  roleAccess.isEdit">Re-schedule</button>
                              <button type="button" class="btn btn-success mr-2 btn-sm" (click)="onRescheduleMS(ObjTherapyPopUp)" *ngIf="(!(IsPast) && ObjTherapyPopUp.Status != TourCompleted && ObjTherapyPopUp.tags == Tour && (ObjTherapyPopUp.ImmediateActionId == ReviewTour || ObjTherapyPopUp.ImmediateActionId == StaffAssigned || ObjTherapyPopUp.ImmediateActionId == Financial)) &&  roleAccess.isEdit">Re-schedule</button>
                              <button type="button" class="btn btn-danger mr-2 btn-sm" (click)="ondeleteMS()" *ngIf=" !(ObjTherapyPopUp.tags == School  || ObjTherapyPopUp.tags == SchoolmeetingScheduler ||ObjTherapyPopUp.tags == BlockTimeScheduler || (ObjTherapyPopUp.tags == Tour && ObjTherapyPopUp.Status == TourCompleted )) && roleAccess.isDelete ">Delete</button>
                           
                              <!-- Before Phone call delete and reschedule changes -->
                              <!-- <button type="button" class="btn btn-success mr-2 btn-sm" (click)="onRescheduleMS(ObjTherapyPopUp)" *ngIf="(!(IsPast || ObjTherapyPopUp.tags == School || ObjTherapyPopUp.tags == Phonecall || ObjTherapyPopUp.tags == PhonecallSceduler || ObjTherapyPopUp.tags == SchoolmeetingScheduler || ObjTherapyPopUp.tags == BlockTimeScheduler )) && ObjTherapyPopUp.tags != Tour &&  roleAccess.isEdit">Re-schedule</button>
                              <button type="button" class="btn btn-success mr-2 btn-sm" (click)="onRescheduleMS(ObjTherapyPopUp)" *ngIf="(!(IsPast) && ObjTherapyPopUp.Status != TourCompleted && ObjTherapyPopUp.tags == Tour && (ObjTherapyPopUp.ImmediateActionId == ReviewTour || ObjTherapyPopUp.ImmediateActionId == StaffAssigned || ObjTherapyPopUp.ImmediateActionId == Financial)) &&  roleAccess.isEdit">Re-schedule</button>
                              <button type="button" class="btn btn-danger mr-2 btn-sm" (click)="ondeleteMS()" *ngIf=" !(ObjTherapyPopUp.tags == School || ObjTherapyPopUp.tags == Phonecall || ObjTherapyPopUp.tags == PhonecallSceduler || ObjTherapyPopUp.tags == SchoolmeetingScheduler ||ObjTherapyPopUp.tags == BlockTimeScheduler || (ObjTherapyPopUp.tags == Tour && ObjTherapyPopUp.Status == TourCompleted )) && roleAccess.isDelete ">Delete</button>
                           -->
                            </div>
                          </div>
                          <!-- <div *ngIf="ObjTherapyPopUp.GroupTherapyId != undefined && groupTherapyList.length > 0">
                            <table class="table table-sm">
                                <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">Therapy Name</th>
                                        <th scope="col">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor ="let item of groupTherapyList;let i=index">
                                        <th scope="row">{{i+1}}</th>
                                        <td>{{item.themeName}}</td>
                                        <td>
                                            <button type="button" class="btn btn-primary mr-2 btn-sm" (click)="OnLoadSessionDetails(ObjTherapyPopUp)" >Session Details</button
                                        ></td>
                                    </tr>
                              </tbody>
                            </table>
                          </div> -->
                        </div>
                        
                    </div>
  
                </div>
            </div>
  
        </div>
    </div>
  </div> 
  




  <!--Therapy Details Pop up end-->

  <!--GroupTherapy Pop up-->
  <div class="modal bulk-add group-therapy emoji-popup1 schedule-popup " id="myModal1" *ngIf ="showGrpTherapyPopup"  [style.display]="showGrpTherapyPopup? 'block':'none'">
    <div class="modal-dialog mb-2">
        <div class="modal-content">
            <div class="modal-body p-0">
                <div class="clearfix popup-mytask position-relative">
                  <form (ngSubmit)="onFormGroup(ObjTherapyPopUp,formGroupDS)" id="formGroupDS"
                  autocomplete="off" #formGroupDS="ngForm" novalidate>
                    <div class="modal-closing "><a class="cursor-pointer" (click)="OncloseTherapyDetailspopup()" ><i
                                class="fa fa-times"></i></a></div>
                    
                        
                      <div  class="align-middle mb-3" >
                               
                        <p class="p-4 pb-0 mb-0">       
                          You're creating the daily schedule for <span class="fw700">{{ObjTherapyPopUp.ScheduleName}}</span> from  {{ObjTherapyPopUp.fromtime | date:'hh:mm a'}} to {{ObjTherapyPopUp.endTime | date:'hh:mm a'}}
                      </p>
                        
                      </div>
                            
                          <div class="mb-0">
                          
                            <!-- <mat-radio-group [(ngModel)]="selectedRadio"
                            #client="ngModel" name="client" required>
                           
                            <mat-radio-button [value]="IOP"  (click)="OnClientType(1)" class="mr-4">IOP</mat-radio-button>
                            <mat-radio-button [value]="PCP"  (click)="OnClientType(2)" >PCP</mat-radio-button>
                            </mat-radio-group>
                            <mat-error *ngIf="formGroupDS.submitted && selectedRadio.invalid">
                              <mat-error class="mat-form-field-subscript-wrapper mt-3"
                                *ngIf="selectedRadio.val.errors.required">clientType
                                is
                                required</mat-error>
                            </mat-error> -->
                          
                            <div class="options-goal mt-2" >
                             
                                <div class="ml-3 client-type-popup">
                                  <p class="px-0 py-0 mb-0">
                                    Form Type :  
                            <mat-radio-group aria-label="Select an option" [(ngModel)]="clienttype"
                      #selectedRadio="ngModel" name="selectedRadio" [ngClass]="{ 'is-invalid': formGroupDS.submitted && selectedRadio?.invalid }" required>
                    <mat-radio-button [value]='1'  (click)="OnClientType(1)" class="mr-4">IOP</mat-radio-button>
                      <mat-radio-button [value]='2'  (click)="OnClientType(2)" class="mr-4" >PCP</mat-radio-button>
                      <mat-radio-button [value]='3'  (click)="OnClientType(3)" >Home Instruction</mat-radio-button>
                      <div *ngIf="formGroupDS.submitted && selectedRadio?.invalid" class="ml-5">
                        <div class="error-message ml-5"  *ngIf="selectedRadio?.errors?.required">
                         This field is required</div>
                      </div>
                    </mat-radio-group>
                    </p>
                    </div>
                      </div>
                          </div>
                          <div class="row mt-0">
                           
                            <div class="col-md-12 text-right mt-2 mb-3">
                              <button type="submit" form="formGroupDS" class="btn btn-primary mr-2 btn-sm" >Assign Clients</button>
                            
                            </div>
                          </div>
                        
                    
                  </form>
                </div>
            </div>
          
        </div>
    </div>
  </div> 
  <!--GroupTherapy Pop end-->
  <!--Re-schedule pop up-->
  <div class="comment-popup  re-schedule-popup" >
    <div class="modal" tabindex="-1" role="dialog" id="re-schedule-popup" [style.display]="PopUpReschedule? 'block':'none'">
      <div class="modal-dialog mat-dialog-container " role="document">
        <div class="modal-content border-0">
          <div class="modal-header">
            <h6 class="modal-title"> Re-schedule</h6>
            <button type="button" class="close"  data-dismiss="modal" aria-label="Close" (click)="OnCancelReschedule()">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
  <div >
    <form (ngSubmit)="scheduleModality.form.valid  && getConflictsModality()" id="scheduleModality"
    autocomplete="off" #scheduleModality="ngForm" novalidate>
    <div class="col-md-12">
      <div class="row mb-2">
       <div class="col-md-4">
        <label class="control-label-new font-weight-bold">Provider</label>
       </div>
         <div class="col-md-8">
          <select class="form-control" name="Provider"
          [(ngModel)]="dtoScheduleModality.provider" #provider="ngModel" name="provider"
           >
          <option value="undefined">--Select Provider--</option>
          <option *ngFor="let a of ddlStafflist" value="{{a.staffId}}">
             {{a.firstName}} {{a.lastName}}
          </option>
       </select>
        </div>
      </div>
      <div class="row mb-2">
        <div class="col-md-4">
          <label class="control-label-new font-weight-bold">Start Date</label>
  
        </div>
  
         <div class="col-md-8">
          <mat-form-field class="example-full-width" >
            <mat-label>Choose a date</mat-label>
            <input required matInput [matDatepicker]="picker2" readonly
               [min]="currentdate"  (dateChange)="handleStartDatechange($event)"
               [(ngModel)]="dtoScheduleModality.startDate"
               #startDate="ngModel" name="startDate" />
            <mat-datepicker-toggle matSuffix [for]="picker2">
            </mat-datepicker-toggle>
            <mat-datepicker #picker2></mat-datepicker>
            <mat-error *ngIf="scheduleModality.submitted && startDate.invalid">
               <mat-error *ngIf="startDate.errors.required">Start Date is required
               </mat-error>
            </mat-error>
         </mat-form-field>
        </div>
      </div>
       <!-- <div class="row mb-2">
        <div class="col-md-4">
          <label class="control-label-new font-weight-bold">Start Time</label>
        </div>
         <div class="col-md-8">
        <input class="form-control" type="time" #startTime="ngModel" [(ngModel)]="dtoScheduleModality.startTime" name="startTime" [min]="currenttime"
            max="12:00" required>
              <mat-error *ngIf="scheduleModality.submitted && startTime.invalid">
                  <mat-error *ngIf="startTime.errors.required">Start Time is required
                  </mat-error>
               </mat-error>

                <mat-error *ngIf="scheduleModality.submitted && startTime.value<currenttime">
                    Start Time is invalid
               </mat-error>
        </div>
      </div>  -->
       <div class="row mb-2">
        <div class="col-md-4">
          <label class="control-label-new font-weight-bold">Start Time</label>
        </div>
         <div class="col-md-8">
          <mat-form-field class="w-100">
          <mat-select   #startTime="ngModel" (selectionChange)="OnTimeChange()" [(ngModel)]="dtoScheduleModality.startTime" name="startTime" [min]="currenttime"
          max="12:00" required
          required>
          <mat-option *ngFor="let item of meetingTimeslots" [value]="item.slotTime">
              {{item.slotTime | date: 'hh:mm a'}}
          </mat-option>
      </mat-select>
     
    </mat-form-field>
     <mat-error class="text-xs" *ngIf="IspastTime">
         Start Time is invalid
      </mat-error>
        </div>
      </div> 
      <div class="row mb-2">
        <div class="col-md-4">
          <label class="control-label-new font-weight-bold">Duration</label>
  
        </div>
  
        <div class="col-md-4">
          <select class="form-control" name="duration" [(ngModel)]="dtoScheduleModality.duration" #duration="ngModel" required>
          <option value="undefined">--Select Duration--</option>
          <option value=0>0 hr</option>
          <option value=60>1 hr</option>
          <option value=120>2 hr</option>
          <option value=180>3 hr</option>
          <option value=240>4 hr</option>
          <option value=300>5 hr</option>
          <option value=360>6 hr</option>
          <option value=420>7 hr</option>
          <option value=480>8 hr</option>
       </select>
       <mat-error *ngIf="scheduleModality.submitted && duration.invalid">
          <mat-error class="mat-form-field-subscript-wrapper mt-3"
             *ngIf="duration.errors.required">Duration is required</mat-error>
       </mat-error>
        </div>
        <div class="col-md-4">
          <select class="form-control " name="minutes" [(ngModel)]="dtoScheduleModality.minutes" #minutes="ngModel" required>
            <option value="undefined">mins</option>
            <option value=0>00 mins</option>
            <option value=15>15 mins</option>
            <option value=30>30 mins</option>
            <option value=45>45 mins</option>             
          </select>
          <mat-error *ngIf="scheduleModality.submitted && duration.invalid">
            <mat-error class="mat-form-field-subscript-wrapper mt-3" *ngIf="minutes.errors.required">Mins required</mat-error>
          </mat-error>
        </div>

      </div>
      <div class="row mb-2" *ngIf="ObjTherapyPopUp.tags !=Intake">
        <div class="col-md-4">
          <label class="control-label-new font-weight-bold">Location</label>
  
        </div>
  
         <div class="col-md-8">
          <select class="form-control" [(ngModel)]="dtoScheduleModality.location"
          #location="ngModel" name="location" required [ngClass]="{'disabled':true}">
          <option value="undefined">--Select Location--</option>
          <option *ngFor="let a of ddlSfccLocation" value="{{a.sfccMasterId}}">
             {{a.locationName}}
          </option>
       </select>
       <mat-error *ngIf="scheduleModality.submitted && location.invalid">
          <mat-error class="mat-form-field-subscript-wrapper mt-3"
             *ngIf="location.errors.required">Location
             is
             required</mat-error>
       </mat-error>
        </div>
      </div>
      <div class="row mb-2" *ngIf="ObjTherapyPopUp.tags !=Intake">
        <div class="col-md-4">
          <label class="control-label-new font-weight-bold">Meeting Room</label>
  
        </div>
  
         <div class="col-md-8">
          <!-- <select class="form-control" [(ngModel)]="dtoScheduleModality.meetingRoom"
           #meetingRoom="ngModel" name="meetingRoom" required [ngClass]="{'disabled':true}"> -->
           <select class="form-control" [(ngModel)]="dtoScheduleModality.meetingRoom"
           #meetingRoom="ngModel" name="meetingRoom" required >
           <option value="undefined">--Select Location--</option>
            <option *ngFor="let a of ddlMeetinglocation" value="{{a.meetingLocationsId}}">
               {{a.meetingName}}
            </option>
           </select>
           <mat-error *ngIf="scheduleModality.submitted && meetingRoom.invalid">
             <mat-error class="mat-form-field-subscript-wrapper mt-3"
                  *ngIf="meetingRoom.errors.required">Meeting Room is required</mat-error>
            </mat-error>
        </div>
      </div>
      <div class="row mb-2" *ngIf="ObjTherapyPopUp.tags !=Intake">
        <div class="col-md-4">
        <label class="control-label-new font-weight-bold">Meeting Type</label>
        </div>
        <div class="col-md-8">
           <select class="form-control"  [(ngModel)]="dtoScheduleModality.meetingType" #meetingType="ngModel" name="meetingType" required>
               <option [value]=0>Select Meeting Type</option>
               <option *ngFor="let item of ddlMeetingType " [value]="item.msMeetingTypeId" >{{item.meetingType}}</option>
           </select>
           <mat-error *ngIf="scheduleModality.submitted && meetingType.invalid">
            <mat-error class="mat-form-field-subscript-wrapper mt-3"
                 *ngIf="meetingType.errors.required">Meeting Type is required</mat-error>
           </mat-error>
     </div>
    </div>
      <div class="row mb-2">
        <div class="col-md-12">
          <textarea cols="3" class="form-control font-italic" placeholder="Comments" [(ngModel)]="dtoScheduleModality.rescheduleComments"
          #rescheduleComments="ngModel" name="rescheduleComments" maxlength="300"></textarea>
          <!-- <mat-error *ngIf="scheduleModality.submitted && rescheduleComments.invalid">
            <mat-error class="mat-form-field-subscript-wrapper mt-3"
                 *ngIf="rescheduleComments.errors.required"> Comments is required</mat-error>
           </mat-error> -->
        </div>
      </div>
  
    </div>
    <div class="text-right col-md-12 mt-2">
      <button mat-raised-button class="btn primary-btn-filled primary-btn-m h-40 mr-2" *ngIf=" (roleAccess.isCreate || roleAccess.isEdit)" >Reschedule</button>
      <button type="button" class="btn btn-cancel" data-dismiss="modal"  (click)="OnCancelReschedule()" >Cancel</button>
    </div>
  
  
  </form>
  </div>
  
  
  </div>
  </div>
  </div>
  </div>
  </div>
  <!--Re-schedule pop up end-->
   <!--Delete Pop up-->
   <div class="comment-popup  re-schedule-popup">
    <div class="modal" tabindex="-1" role="dialog" id="delete-popup" [style.display]="PopUpDelete? 'block':'none'">
      <div class="modal-dialog mat-dialog-container " role="document">
        <div class="modal-content border-0">
          <div class="modal-header">
            <h6 class="modal-title"> Delete</h6>
            <button type="button" class="close"  data-dismiss="modal" aria-label="Close" (click)="OncloseDeletePopup()">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
  <div >
  
  
    <div class="col-md-12">
      <div class="row mb-2">
        <div class="col-md-4">
  
            <label class="mt-2 control-label-new font-weight-bold">Reason for Delete</label>
        </div>
  
         <div class="col-md-8">
          <select  class="form-control"  [(ngModel)]="DeleteReason" name="DeleteReason">
            <option [value]=0>Select Reason</option>
            <option *ngFor="let item of ddlDeleteReason " [value]="item.deleteMSReasonMasterId">{{item.reason}}</option>
            <!-- <option value="2">reason1</option>
            <option value="3">reason2</option>
            <option value="4">reason3</option>
            <option value="5">reason4</option>
            <option value="6">reason5</option> -->
            </select>
            <mat-error class="text-xs" *ngIf="DeleteReason==0 && DeleteMeetingscheduleclick">
              Reason for Delete is required</mat-error>
        </div>
      </div>
      <div class="row mb-2">
        <div class="col-md-12">
          <textarea cols="3" class="form-control font-italic" placeholder="Comments" [(ngModel)]="DeleteComments" name="DeleteComments" maxlength="300"></textarea>
        </div>
      </div>
    </div>
    <div class="text-right col-md-12 mt-2">
      <button mat-raised-button class="btn primary-btn-filled primary-btn-m h-40 mr-2" (click)="deleteMeetingSchedule(ObjTherapyPopUp)" >Delete</button>
      <button type="button" class="btn btn-cancel" data-dismiss="modal"  (click)="OncloseDeletePopup()" >Cancel</button>
    </div>
  
  
  
  </div>
  
  
  </div>
  </div>
  </div>
  </div>
  </div>
  <!--Delete pop up end-->

  <!--Block Time Pop up-->
<div class="modal bulk-add emoji-popup1 schedule-popup " id="myModalBlockpopup" [style.display]="showBlockTimePopup? 'block':'none'">
  <div class="modal-dialog mb-2">
      <div class="modal-content">
          <div class="modal-body p-0">
              <div class="clearfix popup-mytask position-relative">
                  <div class="modal-closing "><a class="cursor-pointer" (click)="OncloseTherapyDetailspopup()" ><i
                              class="fa fa-times"></i></a></div>
                  <div class="col-md-12 mytask p-0">
                      <div class="col-md-12">
                        <div class="row">
                          <div class="col-md-12">
                            <span class="head-top-schedule">{{ObjTherapyPopUp.ScheduleName}}</span>
                          </div>
                        </div>
                        <div class="row" >
                        
                          <div class="col-md-6">
                            <div class="header-day" class="schedule-pop-head mt-2 " >Clinician Info</div>
                            <div  class=" align-middle mb-3" >
                              <a  class="item-author text-color mb-1 d-block  ">{{ObjTherapyPopUp?.clinicianName}}</a>
                             
                                </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-6">
                            <div  class="w-100 ">
                              <span  class="sub-except schedule-time-head text-sm mr-3 mt-1">Appointment Date </span>
                              <span  class="text-color text-success">{{ObjTherapyPopUp?.scheduleDate| date:'MM/dd/yyyy'}} </span>
                            </div>
                            <div  class="w-100">
                              <span  class="sub-except schedule-time-head text-sm mr-3 mt-1">Appointment Time</span>
                              <span  class="text-color text-success">{{ObjTherapyPopUp?.fromtime| date:'hh:mm a'}}</span>
                            </div>
                           
     <div  class="w-100 mb-2">
                                <span  class="sub-except schedule-time-head text-sm mr-3 mt-1">Duration </span>
                                <span  class="text-color text-success">{{ObjTherapyPopUp?.ScheduleDuration}}</span>
                              </div>
                            


                          </div>
             
                        </div>
                
                       
                        <div class="row mt-2">
                          <div class="col-md-12 text-right mt-4 mb-3" *ngIf="!(ObjTherapyPopUp.tags==GroupTherapy)">
                            
                            <button type="button" class="btn btn-success mr-2 btn-sm" (click)="onRescheduleBlockScheduler(ObjTherapyPopUp)" *ngIf="((!IsPast)) &&  roleAccess.isEdit">Re-schedule</button>
                            <button type="button" class="btn btn-danger mr-2 btn-sm" (click)="onBlockdeleteMS()" *ngIf=" (!IsPast) && roleAccess.isDelete">Delete</button>
                          </div>
                        </div>
                       
                      </div>
                      
                  </div>

              </div>
          </div>

      </div>
  </div>
</div> 

  <!--Re-schedule Block Time pop up-->
  <div class="comment-popup  re-schedule-popup" >
    <div class="modal" tabindex="-1" role="dialog" id="re-schedule-popup" [style.display]="PopUpBlockReschedule? 'block':'none'">
      <div class="modal-dialog mat-dialog-container " role="document">
        <div class="modal-content border-0">
          <div class="modal-header">
            <h6 class="modal-title"> Re-schedule</h6>
            <button type="button" class="close"  data-dismiss="modal" aria-label="Close" (click)="OnCancelBlockReschedule()">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
  <div >
    <form (ngSubmit)="scheduleModality.form.valid" id="scheduleModality"
    autocomplete="off" #scheduleModality="ngForm" >
    <div class="col-md-12">
      <div class="row mb-2">
       <div class="col-md-4">
        <label class="control-label-new font-weight-bold">Provider</label>
       </div>
       <div class="col-md-8">
        <select class="form-control" name="Provider"
        [(ngModel)]="dtoScheduleModality.provider" #provider="ngModel" name="provider"
         >
        <option value="undefined">--Select Provider--</option>
        <option *ngFor="let a of ddlStafflist" value="{{a.staffId}}">
           {{a.firstName}} {{a.lastName}}
        </option>
     </select>
      </div>
      </div>
      
   
      <div class="row mb-2">
        <div class="col-md-4">
          <label class="control-label-new font-weight-bold">Start Date</label>
  
        </div>
  
        <div class="col-md-8">
          <mat-form-field class="example-full-width" >
            <mat-label>Choose a date</mat-label>
            <input required matInput [matDatepicker]="picker3" readonly
               [min]="currentdate"  (dateChange)="handleStartDatechange($event)"
               [(ngModel)]="dtoScheduleModality.startDate"
               #startDate="ngModel" name="startDate" />
            <mat-datepicker-toggle matSuffix [for]="picker3">
            </mat-datepicker-toggle>
            <mat-datepicker #picker3></mat-datepicker>
            <mat-error *ngIf="scheduleModality.submitted && startDate.invalid">
               <mat-error *ngIf="startDate.errors.required">Start Date is required
               </mat-error>
            </mat-error>
         </mat-form-field>
        </div>
      </div>

       <div class="row mb-2">
        <div class="col-md-4">
          <label class="control-label-new font-weight-bold">Start Time</label>
        </div>
         <div class="col-md-8">
          <mat-form-field class="w-100">
          <mat-select   #startTime="ngModel" (selectionChange)="OnTimeChange()" [(ngModel)]="dtoScheduleModality.startTime" name="startTime" [min]="currenttime"
          max="12:00" 
          required>
          <mat-option *ngFor="let item of meetingTimeslots" [value]="item.slotTime">
              {{item.slotTime | date: 'hh:mm a'}}
          </mat-option>
      </mat-select>
     
    </mat-form-field>
     <mat-error class="text-xs" *ngIf="IspastTime">
         Start Time is invalid
      </mat-error>
        </div>
      </div> 
      <div class="row mb-2">
        <div class="col-md-4">
          <label class="control-label-new font-weight-bold">Duration</label>
  
        </div>
  
        <div class="col-md-4">
          <select class="form-control" name="duration" [(ngModel)]="dtoScheduleModality.duration" #duration="ngModel" required>
          <option value="undefined">--Select Duration--</option>
          <option value=0>0 hr</option>
          <option value=60>1 hr</option>
          <option value=120>2 hr</option>
          <option value=180>3 hr</option>
          <option value=240>4 hr</option>
          <option value=300>5 hr</option>
          <option value=360>6 hr</option>
          <option value=420>7 hr</option>
          <option value=480>8 hr</option>
       </select>
       <mat-error *ngIf="scheduleModality.submitted && duration.invalid">
          <mat-error class="mat-form-field-subscript-wrapper mt-3"
             *ngIf="duration.errors.required">Duration is required</mat-error>
       </mat-error>
        </div>
        <div class="col-md-4">
          <select class="form-control " name="minutes" [(ngModel)]="dtoScheduleModality.minutes" #minutes="ngModel" required>
            <option value="undefined">mins</option>
            <option value=0>00 mins</option>
            <option value=15>15 mins</option>
            <option value=30>30 mins</option>
            <option value=45>45 mins</option>             
          </select>
          <mat-error *ngIf="scheduleModality.submitted && duration.invalid">
            <mat-error class="mat-form-field-subscript-wrapper mt-3" *ngIf="minutes.errors.required">Mins required</mat-error>
          </mat-error>
        </div>

      </div>

      <div class="row mb-2">
        <div class="col-md-12">
          <textarea cols="3" class="form-control font-italic" placeholder="Comments" [(ngModel)]="dtoScheduleModality.rescheduleComments"
          #rescheduleComments="ngModel" name="rescheduleComments" maxlength="300"></textarea>
       
        </div>
      </div>
  
    </div>
    <div class="text-right col-md-12 mt-2">
      <button mat-raised-button class="btn primary-btn-filled primary-btn-m h-40 mr-2" (click)="getConflictsModalityBlockTime(dtoScheduleModality)"  >Reschedule</button>
      <button type="button" class="btn btn-cancel" data-dismiss="modal"  (click)="OnCancelBlockReschedule()" >Cancel</button>
    </div>
  
  
  </form>
  </div>
  
  
  </div>
  </div>
  </div>
  </div>
  </div>
  <!--Re-schedule pop up end-->
   <!--Delete Block Pop up-->
   <div class="comment-popup  re-schedule-popup">
    <div class="modal" tabindex="-1" role="dialog" id="delete-popup" [style.display]="PopUpBlockDelete? 'block':'none'">
      <div class="modal-dialog mat-dialog-container " role="document">
        <div class="modal-content border-0">
          <div class="modal-header">
            <h6 class="modal-title"> Delete</h6>
            <button type="button" class="close"  data-dismiss="modal" aria-label="Close" (click)="OncloseBlockDeletePopup()">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
  <div >
  
  
    <div class="col-md-12">
      <div class="row mb-2">
        <div class="col-md-4">
  
            <label class="mt-2 control-label-new font-weight-bold">Reason for Delete</label>
        </div>
  
         <div class="col-md-8">
          <select  class="form-control"  [(ngModel)]="DeleteReason" name="DeleteReason">
            <option [value]=0>Select Reason</option>
            <option *ngFor="let item of ddlDeleteReason " [value]="item.deleteMSReasonMasterId">{{item.reason}}</option>
           
            </select>
            <mat-error class="text-xs" *ngIf="DeleteReason==0 && DeleteMeetingBlockscheduleclick">
              Reason for Delete is required</mat-error>
        </div>
      </div>
      <!-- <div class="row mb-2">
        <div class="col-md-12">
          <textarea cols="3" class="form-control font-italic" placeholder="Comments" [(ngModel)]="DeleteComments" name="DeleteComments" maxlength="300"></textarea>
        </div>
      </div> -->
    </div>
    <div class="text-right col-md-12 mt-2">
      <button mat-raised-button class="btn primary-btn-filled primary-btn-m h-40 mr-2" (click)="deleteMeetingBlockSchedule(ObjTherapyPopUp)" >Delete</button>
      <button type="button" class="btn btn-cancel" data-dismiss="modal"  (click)="OncloseBlockDeletePopup()" >Cancel</button>
    </div>
  
  
  
  </div>
  
  
  </div>
  </div>
  </div>
  </div>
  </div>
  <!--Delete pop up end-->


<!--Therapy Details Pop up end-->
