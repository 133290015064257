<div class="clnical">

    <div class="col-md-12 d-flex">
        <span *ngIf="global.CSummaryUpdatedOn !=null" class="status-msg mr-3 mt-2 cursor-pointer text-underline">Last
            Updated on:
            <span>{{global.globalUtctimeformat(global.CSummaryUpdatedOn) | date:
                'MM/dd/yyyy hh:mm a'}}</span></span>
                <span *ngIf="global.CSummaryUpdatedByName !=null" class="status-msg mr-3 mt-2 cursor-pointer text-underline">Last
                    Updated By:
                    <span>{{global.CSummaryUpdatedByName }}</span></span>
        <div class="text-right ml-auto">
            <button type="button" (click)="onPrevcmhatab()" mat-stroked-button
                class="btn btn-cancel primary-btn-m h-40 mr-2">Prev</button>
            <!-- <button type="button" (click)="onSaveChmadetails(0)" mat-stroked-button
                            class="btn primary-btn-filled primary-btn-m mb-2 h-40 mr-2 ">{{isMovedChma ? 'Next' : 'Save &
                            Next'}}</button> -->
            <button type="button" (click)="onSaveChmadetails(0)" mat-stroked-button *ngIf="roleAccess.isCreate||roleAccess.isEdit"
                class="btn primary-btn-filled primary-btn-m mb-2 h-40 mr-2 ">{{ 'Save &
                Next'}}</button>
            <!-- *ngIf="!isMovedChma" -->
            <button type="button" (click)="onSaveChmadetails(1)" mat-stroked-button *ngIf="roleAccess.isCreate||roleAccess.isEdit"
                class="btn primary-btn-outlined primary-btn-m mb-2 h-40 mr-2">Save &
                Exit</button>
            <button type="button" class="btn primary-btn-filled primary-btn-m mb-2 h-40 mr-2" mat-stroked-button
                *ngIf="!global.isClinicalSummary" (click)="onSaveChmadetails(2)">Save as Draft</button>
        </div>
    </div>
    <!-- [ngClass]="{'disabled':isMovedChma}" -->
    <div class="col-md-12 set1-temp" >
        <div class="row mb-2">
            <label class="col-md-12 set-label">Clinical Summary</label>
          
                <!-- <mat-form-field class="example-full-width custom-textarea clinicalSummary mt-2">
                <textarea matInput  cols="2" class="w-100" maxlength="3000" [(ngModel)]="clinicalsummary"
                    #explanation="ngModel"></textarea>
                </mat-form-field> -->

                <mat-form-field class="example-full-width custom-textarea mt-2">
                    <textarea matInput cdkTextareaAutosize cdkAutosizeMinRows="5"  class="w-100 custom-textarea" maxlength="3000" [(ngModel)]="clinicalsummary"    
                    (input)="updateWordCount()"
                    (focusin)="updateWordCount()"
                    (focusout)="isShowCount = false"
                    #explanation="ngModel"></textarea>
                   
                </mat-form-field>
                <div class="count-text" *ngIf="isShowCount">Remaining Characters: {{ wordCount }}</div>
          

        </div>
        <div class="row mb-2">
            <div class="form-group">
                <label class="col-md-12 col-form-label p-0 mb-2" for="exampleFormControlFile1">Upload a CSV file / PDF -
                    ASEBA Report</label>

                <input multiple title="Add Files"
                    accept="application/msword, application/vnd.ms-excel, application/pdf, .csv" type="file"
                    (change)="onFileChange($event)" class="form-control-file mt-3 pt-1" #taskfile />
                <div class="col-md-9 mt-1 p-0">
                    <mat-chip-list class="mt-2">

                        <mat-chip *ngFor="let a of UploadedFileList;let x = index" (click)="downloadUploadedFile(a)">
                            {{a.fileName}}
                            <mat-icon matChipRemove (click)="deleteUploadImage(x)">
                                cancel</mat-icon>
                        </mat-chip>

                    </mat-chip-list>
                </div>
            </div>
        </div>
        <div class="row mb-2">
            <div class="col-md-12">
                <div class="row">
                    <!-- <label class=" set-label"></label> -->
                    <div class="col-md-3 ">
                        <mat-form-field class="w-100">
                            <mat-label>Provider’s Name (printed)</mat-label>
                            <!-- <input matInput name="providerName" [(ngModel)]="providerName"
                            #providerName="ngModel" /> -->
                            <input matInput [(ngModel)]="providerName" name="providerName" maxlength="50" 
                            required>
                            
                        </mat-form-field>
                        <div *ngIf="isReqName && providerName == ''" class="text-danger">Provider’s Name is required</div>
                    </div>
                    <div class="col-md-3 ">
                        <mat-form-field class="w-100">
                            <mat-label>Date</mat-label>
                            <input matInput value="{{signDate}}" />
                        </mat-form-field>
                    </div>

                </div>
            </div>
            <div class="col-md-12 ">
                <div class="col-md-12 p-0 mt-3">
                    <img id='esign' height="50px" width="300px" style="border: 1px solid rgb(79,79,79);">
                    <button type="button" (click)="openDialog()"
                        class="btn primary-btn-filled primary-btn-m h-40 mr-2 mt-2" type="button">
                        Add E-Sign
                    </button>
                    <span *ngIf="isReqeSign && signatureImg == undefined" class="text-danger">Please add eSign</span>
                </div>
                <div class="col-md-12 p-0 mt-4">
                    <div class=" set-label">Secondary Signature</div>
                    <img id='esign1' height="50px" width="300px" style="border: 1px solid rgb(79,79,79);">
                    <button type="button" [disabled]="secondarySign()" (click)="openDialogs()"
                        class="btn primary-btn-filled primary-btn-m h-40 mr-2 mt-2" type="button">
                        Add E-Sign
                    </button>
                    <span *ngIf="isReqeSign && signatureImg == undefined" class="text-danger">Please add eSign</span>
                </div>
            </div>
        </div>

    </div>



    <div class="col-md-12">
        <div class="text-right">
            <button type="button" (click)="onPrevcmhatab()" mat-stroked-button
                class="btn btn-cancel primary-btn-m h-40 mr-2">Prev</button>
            <!-- <button type="button" (click)="onSaveChmadetails(0)" mat-stroked-button
                class="btn primary-btn-filled primary-btn-m mb-2 h-40 mr-2 ">{{isMovedChma ? 'Next' : 'Save &
                Next'}}</button> -->
                <button type="button" (click)="onSaveChmadetails(0)" mat-stroked-button *ngIf="roleAccess.isCreate||roleAccess.isEdit"
                class="btn primary-btn-filled primary-btn-m mb-2 h-40 mr-2 ">{{ 'Save &
                Next'}}</button>
                <!-- *ngIf="!isMovedChma" -->
            <button type="button" (click)="onSaveChmadetails(1)" mat-stroked-button  *ngIf="roleAccess.isCreate||roleAccess.isEdit"
                class="btn primary-btn-outlined primary-btn-m mb-2 h-40 mr-2">Save &
                Exit</button>

        </div>
    </div>

</div>