import { map } from 'rxjs/operators';
import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ClinicianService } from 'src/app/staff/clinician/clinician.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { CommonService } from 'src/app/common/common.service';
import { TreatmentService } from 'src/app/staff/treatment/treatment.service';
import { SnackBarService } from 'src/app/services/snackBar.service';
import { Global } from 'src/app/global/global.model';
import { constantVariables } from 'src/app/constant/constant';
import * as moment from 'moment';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from 'src/app/common/confirm-dialog/confirm-dialog.component';
import { dtoScheduleModality, dtoScheduleConflictModality, dtoReScheduleModality } from 'src/app/_models/_Treatment/scheduleModality';
import {
  DayScheduleGroupTherapy,
  DayScheduleSubGroupAssign,
  DayScheduleSubGroup,
  DtoPostInactiveAppointments
} from 'src/app/_models/_Treatment/_dayschedule';
import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
} from '@angular/cdk/drag-drop';
import { NgForm } from '@angular/forms';
import { SettingsService } from 'src/app/common/pages/settings/settings.service';
import { ReturnStatement } from '@angular/compiler';
import { I, X } from '@angular/cdk/keycodes';
import { PageAccessService } from 'src/app/rolemanagement/PageRoleAccessService';
import { RoelAccess } from 'src/app/_models/_Common/roleAccessMaster';

@Component({
  selector: 'app-day-schedule',
  templateUrl: './day-schedule.component.html',
  styleUrls: ['./day-schedule.component.scss'],
})
export class DayScheduleComponent implements OnInit {
  grpTherapyId: any;
  ddlPrimarystaff: any;
  ddlSfccLocation: any;
  isNewSubgroup: boolean = false;
  isCreateSubgroup: boolean = false;
  _dtoGrpTherapyMeta: any;
  _dtoGrpTherapyUsers: any = [];

  _dtoDaySchGrpTherapy: DayScheduleGroupTherapy;
  _dtoDayScheduleSubGroup: DayScheduleSubGroup;
  _dtoAssignedUsers: Array<DayScheduleSubGroupAssign>;
  lstsubgroup: any = [];
  _dtoSelectedUsers: any = [];
  mintime: any;
  maxtime: any;
  endtime: boolean = false;
  assignKidsError: boolean = false;
  subGroupCopy: any = [];
  patientExist: string = '';
  clinicianExist: string = '';
  todaysDate: any = new Date();
  selecteddate: string;

  errorMessage:string;
  showErrorMessage:boolean=false;

  pcpError:boolean=false;
  isSave:boolean=true;
  _dtoPostInactiveAppointments:DtoPostInactiveAppointments;
  therapyId;
  isIndividualFamilyTherapy:boolean=false;
  isGroupyTherapyOld:boolean=false;
  selectedMsId:any;
  _dtoPatientInfo: any ;
  dsaId :any;
  dsgSubgrpId:any;
  _dtoGrpTherapydata:any;
  IsgroupTherapy:boolean=false;
  PopUpDelete: boolean=false;
  ddlDeleteReason: any=[];
  DeleteReason=0;
  DeleteComments:string
  DeleteMeetingscheduleclick: boolean=false;
  PopUpReschedule: boolean=false;
  ddlStafflist: any;
  dtoScheduleModality: dtoReScheduleModality;
  @ViewChild('scheduleModality') currentForm: NgForm;
  currentdate: string;
  currenttime: string= '00.00';
  ddlMeetinglocation: any;
  ddlMeetingType: any;
  dtoScheduleConflictModalitylist: any;
  scheduleModalityList: any[];
  dateList: any[];
  errConflicts: boolean;
  isShowNotes:boolean=false;
  adminNotes:any;
  isVirtualMeet:boolean=false;
  virtualMeettxt:any;
  isNotesErr=false;
  isVmeetingErr=false;
  paramClinicianId:any;
  grpTherapyParameter:any;
  _dtogrouptherapyAllPtAppointments:any=[];
  drpPatients:any;
  selectedPatient:any;
  datanotExists:boolean=false;
  paramDsaId:any;
  meetingTimeslots: any;
  roleAccess: RoelAccess;
  IsPast: boolean;
  IspastTime: boolean;
  meetingscheduletimeid: any;
  clicked: boolean
  Intake = constantVariables.therapy.IntakeTherapy;
  therapyReaschedule:boolean=false;
  constructor(
    public global: Global,
    private snackbar: SnackBarService,
    private route: ActivatedRoute,
    private router: Router,
    public commonService: CommonService,
    public clinicianservice: ClinicianService,
    public spinnerservice: NgxSpinnerService,
    public treatmentservice: TreatmentService,
    private date: DatePipe,
    private dialog: MatDialog,
    private datepipe :DatePipe,
    public settingsService: SettingsService,
    public roleaccessservice:PageAccessService
  ) {}

  ngOnInit(): void {
    const pageid = constantVariables.pagelists.ScheduleAppointmentsTherapies;
    this.roleAccess = this.roleaccessservice.GetRoleAccessByPageId(pageid);
    this._dtoDaySchGrpTherapy = new DayScheduleGroupTherapy();
    this._dtoDayScheduleSubGroup = new DayScheduleSubGroup();
    this._dtoPostInactiveAppointments = new DtoPostInactiveAppointments();
    this.dtoScheduleModality = new dtoReScheduleModality();
    this.currentdate = moment().format("YYYY-MM-DD HH:mm:ss")
    this.currenttime= moment().format("HH:mm")
    this.grpTherapyParameter="";
this.paramDsaId=0;
    //this.isNewSubgroup = true;
    this.route.queryParams.subscribe((params) => {
      if (Object.keys(params).length != 0) {
        this.grpTherapyId = Number(this.commonService.FrontEndDecryption(params.id));
        this.selectedMsId= Number(this.commonService.FrontEndDecryption(params.id));
        this.therapyId = Number(this.commonService.FrontEndDecryption(params.theraphyid));
       
        if (this.therapyId == constantVariables.therapy.GroupTheraphy) {
            // this.isNewSubgroup = true;
            // this.isIndividualFamilyTherapy=false;
            // this.isGroupyTherapyOld=true;
            // this.getsubgrouptherapy();
            // this.getPrimaryStaff();
            // this.getddlLocation();
            // this.getDayScheduleForAssign();
            this.isNewSubgroup = false;
            this.isVirtualMeet=false;
            this.isIndividualFamilyTherapy=true;
            if(params.paramClinician)
            {
              this.paramClinicianId=JSON.parse(this.commonService.FrontEndDecryption(params.paramClinician));
            }
            
           this.selecteddate = this.commonService.FrontEndDecryption(params.date).replace(/"/g, '');
          //  this.dsgSubgrpId=Number(this.commonService.FrontEndDecryption(params.dsgsubgrpId));
           // this.selectedMsId= Number(this.commonService.FrontEndDecryption(params.dsaId));
           // this.grpTherapyId = Number(this.commonService.FrontEndDecryption(params.grpid));

           if(params.dsaid)
           {
             this.paramDsaId=JSON.parse(this.commonService.FrontEndDecryption(params.dsaid));
           }
            this.IsgroupTherapy=true;
            this.onLoadGroupTherapy();
          }
        
        // else if (this.therapyId ==constantVariables.therapy.IndividualTheraphy || this.therapyId ==constantVariables.therapy.FamilyTheraphy) {
          else if (this.therapyId !=constantVariables.therapy.GroupTheraphy){
            this.selecteddate = this.commonService.FrontEndDecryption(params.date)
            .replace(/"/g, '');
            // if((this.date.transform(new Date(), "yyyy-MM-dd"))> (this.date.transform(this.selecteddate, "yyyy-MM-dd")))
            // {
            // this.isSave=false;
            // }
            if(params.mstid)
            {
              this.meetingscheduletimeid= Number(this.commonService.FrontEndDecryption(params.mstid))
            }
            this.isNewSubgroup = false;
            this.isIndividualFamilyTherapy=true;
            this.isGroupyTherapyOld=false;
             this.isVirtualMeet=false;
            this.onLoadFamilyIndividualTherapy();
            }
          this.LoadMasters();
           if((this.date.transform(new Date(), "yyyy-MM-dd"))> (this.date.transform(this.selecteddate, "yyyy-MM-dd")))
            {
            this.isSave=false;
            }
       }
    });
  }

  getddlLocation() {
    this.spinnerservice.show();
    this.treatmentservice.getLocations().subscribe(
      (res) => {
        this.spinnerservice.hide();
        if (res != null) {
          this.ddlSfccLocation = res;
        }
      },
      (err) => {
        this.spinnerservice.hide();
      }
    );
  }
  onNewSubGroup() {
    this.isNewSubgroup = true;
  }
  getDayScheduleForAssign() {
    this.spinnerservice.show();
    this.treatmentservice
      .getDayScheduleForAssign(this.grpTherapyId, this.selecteddate)
      .subscribe((res) => {
        this.spinnerservice.hide();
        this._dtoGrpTherapyMeta = res[0];
        this._dtoGrpTherapyUsers = res;
        this.mintime = moment(this._dtoGrpTherapyMeta.startTime).format(
          'HH:mm'
        );
        this.maxtime = moment(this._dtoGrpTherapyMeta.startTime, 'HHmm')
          .add(this._dtoGrpTherapyMeta.duration, 'minutes')
          .format('HH:mm');
        // res.forEach(function (item) {
        //   if ( this._dtoGrpTherapyUsers.indexOf(item) < 0) {
        //     this._dtoGrpTherapyUsers.push(item);
        //   }
        // });
      });
  }
  onCreateSubGroup() {
    this.isCreateSubgroup = true;
    this._dtoAssignedUsers = [];
    this._dtoDaySchGrpTherapy = new DayScheduleGroupTherapy();
    this._dtoDayScheduleSubGroup = new DayScheduleSubGroup();
  }
  drop(event) {
      if (
      this._dtoAssignedUsers.filter(
        (x) => x.patientAccountId == event.item.data.patientAccountId
      ).length == 0
    ) {
      event.item.data.isSelected = true;
      let _obj = {
        dayScheduleAssignId: 0,
        dayScheduleSubGroupId: 0,
        groupTherapyId: this.grpTherapyId,
        patientAccountId: event.item.data.patientAccountId,
        patientName: event.item.data.userName,
        clinicianId: null,
        clinicianName: null,
      };
      this._dtoAssignedUsers.push(_obj);
      if (this._dtoAssignedUsers.length > 0) {
        this.assignKidsError = false;
      }
    }
  }
  onremoveassigned(item) {
    let obj = this._dtoGrpTherapyUsers.filter(
      (x) =>
        x.userName == item.patientName &&
        x.patientAccountId == item.patientAccountId
    );
    if (obj.length > 0) {
      obj[0].isSelected = false;
    }
    const objIndex = this._dtoAssignedUsers.findIndex(
      (x) =>
        x.patientName === item.patientName &&
        x.patientAccountId == item.patientAccountId
    );
    if (objIndex > -1) {
      this._dtoAssignedUsers.splice(objIndex, 1);
    }
    this.clinicianExist = '';
    this.patientExist = '';
  }
  getPrimaryStaff() {
    this.commonService
      .getStaffList(constantVariables.role.PrimaryClinician)
      .subscribe((res: any) => {
        if (res) {
          this.ddlPrimarystaff = res;
        }
      });
  }
  postdayschdule(form: NgForm) {
    if (!form.valid) {
      return;
    }
    var dt = new Date();
    var substartTime = new Date(
      dt.getFullYear(),
      dt.getMonth(),
      dt.getDate(),
      parseInt(this._dtoDayScheduleSubGroup.startTime.split(':')[0]),
      parseInt(this._dtoDayScheduleSubGroup.startTime.split(':')[1]),
      parseInt('00')
    );
    var subendTime = new Date(
      dt.getFullYear(),
      dt.getMonth(),
      dt.getDate(),
      parseInt(this._dtoDayScheduleSubGroup.endTime.split(':')[0]),
      parseInt(this._dtoDayScheduleSubGroup.endTime.split(':')[1]),
      parseInt('00')
    );

    //EndTimeLimit
    var tempStartTime = new Date(this._dtoGrpTherapyMeta.startTime);
    var getEndTimeLimit = new Date(this._dtoGrpTherapyMeta.startTime).setHours(
      tempStartTime.getHours() + (this._dtoGrpTherapyMeta.duration / 60)
    );
    var tempConfirmDate = new Date(getEndTimeLimit);
    this.errorMessage='Select time between ' +this.date.transform(this._dtoGrpTherapyMeta.startTime, 'h:mm a') +' and ' +this.date.transform(tempConfirmDate, 'h:mm a');

    var subStartTimeNew = new Date(
      'May 26, 2016 ' + this._dtoDayScheduleSubGroup.startTime
    ).getTime();
    var subEndTimeNew = new Date(
      'May 26, 2016 ' + this._dtoDayScheduleSubGroup.endTime
    ).getTime();
    var startTimeNew = new Date(
      'May 26, 2016 ' + tempStartTime.toLocaleTimeString()
    ).getTime();
    var endTimeNew = new Date('May 26, 2016 ' +tempConfirmDate.toLocaleTimeString()
    ).getTime();

    if((subEndTimeNew <= subStartTimeNew)){
      this.endtime=true;
      this.showErrorMessage=false;
      return
    }
    if(subStartTimeNew < startTimeNew || subStartTimeNew > endTimeNew){
      this.showErrorMessage=true;
      return
    }
    if(subEndTimeNew > endTimeNew || subEndTimeNew < startTimeNew){
      this.showErrorMessage=true;
      return
    }
    if (this._dtoAssignedUsers.length == 0 || null) {
      this.assignKidsError = true;
      return;
    }
  //  console.log(this._dtoDaySchGrpTherapy);

    var errorPatient = '';
    var errorClinician = '';
    for (let j = 0; j < this._dtoAssignedUsers.length; j++) {
      let filtersubgroup = this.subGroupCopy.filter(
        (x) => x.patientAccountId == this._dtoAssignedUsers[j].patientAccountId
      );
      for (let i = 0; i < filtersubgroup.length; i++) {
        const startime = moment(filtersubgroup[i].startTime).format('HH:mm');
        const endtime = moment(filtersubgroup[i].endTime).format('HH:mm');
        var bookedsttime = new Date(
          dt.getFullYear(),
          dt.getMonth(),
          dt.getDate(),
          parseInt(startime.split(':')[0]),
          parseInt(startime.split(':')[1]),
          parseInt('00')
        );

        var bookedettime = new Date(
          dt.getFullYear(),
          dt.getMonth(),
          dt.getDate(),
          parseInt(endtime.split(':')[0]),
          parseInt(endtime.split(':')[1]),
          parseInt('00')
        );
        if (
          (substartTime >= bookedsttime && substartTime <= bookedettime) ||
          (subendTime >= bookedsttime && subendTime <= bookedettime)
        ) {
          errorPatient +=
            '<br>' +
            filtersubgroup[i].userName +
            ' already associated with the theme ' +
            filtersubgroup[i].themeName +
            ' at ' +
            moment(filtersubgroup[i].startTime).format('HH:mm a') +
            '-' +
            moment(filtersubgroup[i].endTime).format('HH:mm a') +
            '';
        }
      }
    }
    for (let j = 0; j < this._dtoAssignedUsers.length; j++) {
      let count = 0;
      let filtersubgroup = this.subGroupCopy.filter(
        (x) =>
          Number(x.clinicianId) == Number(this._dtoAssignedUsers[j].clinicianId)
      );
      for (let i = 0; i < filtersubgroup.length; i++) {
        const startime = moment(filtersubgroup[i].startTime).format('HH:mm');
        const endtime = moment(filtersubgroup[i].endTime).format('HH:mm');
        var bookedsttime = new Date(
          dt.getFullYear(),
          dt.getMonth(),
          dt.getDate(),
          parseInt(startime.split(':')[0]),
          parseInt(startime.split(':')[1]),
          parseInt('00')
        );

        var bookedettime = new Date(
          dt.getFullYear(),
          dt.getMonth(),
          dt.getDate(),
          parseInt(endtime.split(':')[0]),
          parseInt(endtime.split(':')[1]),
          parseInt('00')
        );
        if (
          (substartTime >= bookedsttime && substartTime <= bookedettime) ||
          (subendTime >= bookedsttime && subendTime <= bookedettime)
        ) {
          if (!errorClinician.includes(filtersubgroup[i].clinicianName)) {
            errorClinician +=
              '<br>' +
              filtersubgroup[i].clinicianName +
              ' already associated with the theme ' +
              filtersubgroup[i].themeName +
              ' at ' +
              moment(filtersubgroup[i].startTime).format('HH:mm a') +
              '-' +
              moment(filtersubgroup[i].endTime).format('HH:mm a') +
              '';
            count++;
          }
        }
      }
    }
    this.patientExist = errorPatient;
    this.clinicianExist = errorClinician;
    // }
    if (this.patientExist?.length > 0) {
      return;
    }
    if (this.clinicianExist?.length > 0) {
      return;
    }
    var stt = new Date(
      'May 26, 2016 ' + this._dtoDayScheduleSubGroup.startTime
    ).getTime();

    var endt = new Date(
      'May 26, 2016 ' + this._dtoDayScheduleSubGroup.endTime
    ).getTime();

    if (stt < endt) {
      this._dtoDaySchGrpTherapy.schduleDate = this.selecteddate;
      this._dtoDaySchGrpTherapy.groupTherapyId = this.grpTherapyId;
      this._dtoDaySchGrpTherapy.dayScheduleSubGroupAssign =
        this._dtoAssignedUsers;
      this._dtoDaySchGrpTherapy.dayScheduleSubGroup =
        this._dtoDayScheduleSubGroup;
      let obj = JSON.stringify(this._dtoDaySchGrpTherapy);
      const dataToEncrypt = this.commonService.DataToEncrypt(
        this._dtoDaySchGrpTherapy,
        false
      );

      this.spinnerservice.show();
      this.treatmentservice.postdayschdule(dataToEncrypt).subscribe(
        (res: any) => {
          this.spinnerservice.hide();
          this.snackbar.success(JSON.parse(res).message);
          this.getsubgrouptherapy();
          this.clearsubgroup();
          this.isCreateSubgroup = true;
          form.resetForm();
        },
        (err) => {
          this.spinnerservice.hide();
          this.snackbar.error(err.error);
        }
      );
    } else {
      return;
    }
  }
  getsubgrouptherapy() {
    this.treatmentservice
      .getsubgrouptherapy(this.grpTherapyId, this.selecteddate)
      .subscribe((res: any) => {
        if (res) {
          this.lstsubgroup = res;
          for (let i = 0; i < this.lstsubgroup.length; i++) {
            this.subGroupCopy = [
              ...this.subGroupCopy,
              ...this.lstsubgroup[i].dtoSpDayScheduleSubgroupDetails,
            ];
          }
          if (res?.length == 0) {
            this.onCreateSubGroup();
          }
        }
      });
  }
  clearsubgroup() {
    this.isCreateSubgroup = false;
    this._dtoAssignedUsers = [];
    // this._dtoDaySchGrpTherapy = new DayScheduleGroupTherapy();
    // this._dtoDayScheduleSubGroup = new DayScheduleSubGroup();
  }
  onCloseSubGroup() {
    this.isCreateSubgroup = false;
    this.clinicianExist = '';
    this.patientExist = '';
  }

  checkTime(){
    //EndTimeLimit
    var tempStartTime = new Date(this._dtoGrpTherapyMeta.startTime);
    var getEndTimeLimit = new Date(this._dtoGrpTherapyMeta.startTime).setHours(
      tempStartTime.getHours() + (this._dtoGrpTherapyMeta.duration / 60)
    );
    var tempConfirmDate = new Date(getEndTimeLimit);
    this.errorMessage='Select time between ' +this.date.transform(this._dtoGrpTherapyMeta.startTime, 'h:mm a') +' and ' +this.date.transform(tempConfirmDate, 'h:mm a');

    var subStartTimeNew = new Date(
      'May 26, 2016 ' + this._dtoDayScheduleSubGroup.startTime
    ).getTime();
    var subEndTimeNew = new Date(
      'May 26, 2016 ' + this._dtoDayScheduleSubGroup.endTime
    ).getTime();
    var startTimeNew = new Date(
      'May 26, 2016 ' + tempStartTime.toLocaleTimeString()
    ).getTime();
    var endTimeNew = new Date('May 26, 2016 ' +tempConfirmDate.toLocaleTimeString()
    ).getTime();

    if((subStartTimeNew < startTimeNew) || (subStartTimeNew > endTimeNew)){
      this.showErrorMessage=true;
      this.endtime=false;
      return
    }else{
      this.showErrorMessage=false;
      this.endtime=false;
    }
    if((subStartTimeNew >= subEndTimeNew)){
      this.endtime=true;
      this.showErrorMessage=false;
      return
    }
  }

  check() {
    //EndTimeLimit
    var tempStartTime = new Date(this._dtoGrpTherapyMeta.startTime);
    var getEndTimeLimit = new Date(this._dtoGrpTherapyMeta.startTime).setHours(
      tempStartTime.getHours() + (this._dtoGrpTherapyMeta.duration / 60)
    );
    var tempConfirmDate = new Date(getEndTimeLimit);
    this.errorMessage='Select time between ' +this.date.transform(this._dtoGrpTherapyMeta.startTime, 'h:mm a') +' and ' +this.date.transform(tempConfirmDate, 'h:mm a');

    var subStartTimeNew = new Date(
      'May 26, 2016 ' + this._dtoDayScheduleSubGroup.startTime
    ).getTime();
    var subEndTimeNew = new Date(
      'May 26, 2016 ' + this._dtoDayScheduleSubGroup.endTime
    ).getTime();
    var startTimeNew = new Date(
      'May 26, 2016 ' + tempStartTime.toLocaleTimeString()
    ).getTime();
    var endTimeNew = new Date('May 26, 2016 ' +tempConfirmDate.toLocaleTimeString()
    ).getTime();

    if((subEndTimeNew <= subStartTimeNew)){
      this.endtime=true;
      this.showErrorMessage=false;
      return
    }

    if((subEndTimeNew > endTimeNew) || (subEndTimeNew < startTimeNew)){
      this.showErrorMessage=true;
      this.endtime=false;
      return
    }else{
      this.showErrorMessage=false;
      this.endtime=false;
    }
  }

  chengeRadioValue(value){
    if(value=='iop'){
      this._dtoDayScheduleSubGroup.isIop=true;
      this._dtoDayScheduleSubGroup.isPcp=false;
      this.pcpError=false;
    }else if(value=='pcp'){
      this._dtoDayScheduleSubGroup.isIop=false;
      this._dtoDayScheduleSubGroup.isPcp=true;
      this.pcpError=false;
    }
  }
  onDeleteDaySchedule(item,dayscheduledtl)
  {
   //console.log(item);
   this.spinnerservice.show();
    this._dtoPostInactiveAppointments.PatientAccountId=dayscheduledtl.patientAccountId;
    this._dtoPostInactiveAppointments.DayScheduleSubGroupId=item.dayScheduleSubGroupId;
    this._dtoPostInactiveAppointments.DayScheduleAssignId=dayscheduledtl.dayScheduleAssignId;
    this._dtoPostInactiveAppointments.GroupTherapyId=item.groupTherapyId;
    this._dtoPostInactiveAppointments.TherapyDate=this.selecteddate;
    this._dtoPostInactiveAppointments.TheraphyId=3;
    this._dtoPostInactiveAppointments.UserId=localStorage.getItem('id');
    const dataToEncrypt = this.commonService.DataToEncrypt(this._dtoPostInactiveAppointments, false);
   //  console.log(this._dtoPostInactiveAppointments);
    this.treatmentservice.postinactiveAppointments(dataToEncrypt).subscribe(
      (res: any) => {
         this.spinnerservice.hide();
        if(JSON.parse(res).id==1){this.snackbar.success(JSON.parse(res).message);
          this.router.navigate(['/scheduler']);}
        else if(JSON.parse(res).id==0){this.snackbar.error(JSON.parse(res).message);}
         },
      err => {
        this.spinnerservice.hide();
        this.snackbar.error(err.error);
      },
    );
  }
  onLoadFamilyIndividualTherapy()
  {
    this.spinnerservice.show();
    this.isVirtualMeet=false;
    this.treatmentservice.getIndFamSchedulePtDetails(this.selecteddate,this.therapyId,this.selectedMsId,this.meetingscheduletimeid).subscribe(
      (res: any) => {

        this.spinnerservice.hide();
        if (res.length>0){

          this._dtoPatientInfo=res[0];
        // console.log(this._dtoPatientInfo);
         this.adminNotes=this._dtoPatientInfo.adminNotes;
          this.IsPast = (this.datepipe.transform(new Date(), "yyyy-MM-dd"))> (this.datepipe.transform(this._dtoPatientInfo.therapyDate, "yyyy-MM-dd"))

         if(this._dtoPatientInfo.meetingType==2)
         {
         this.virtualMeettxt=this._dtoPatientInfo.meetingLinks;
         this.isVirtualMeet=true;
         }
         this.isShowNotes=(this.adminNotes!="")?true:false;
         this.getStaffList();
        }
      },
      err => {
        this.spinnerservice.hide();
      },
    );

  }
  deleteMeetingSchedule()
  {
    this.DeleteMeetingscheduleclick = true
    this.spinnerservice.show();
    if(!this.IsgroupTherapy){
      if(this._dtoPatientInfo.isPatientVisit==true)
         {
        this.spinnerservice.hide();
       this.snackbar.error('Unable to delete the scheduled appointment');
        return;
       }
       if(this.DeleteReason==0){
        this.spinnerservice.hide();
         return;
       }
      this._dtoPostInactiveAppointments.PatientAccountId=this._dtoPatientInfo.patientAccountId;
      this._dtoPostInactiveAppointments.DayScheduleAssignId=this._dtoPatientInfo.meetingScheduleTimeId;
      this._dtoPostInactiveAppointments.DayScheduleSubGroupId=this._dtoPatientInfo.meetingScheduleId;
      this._dtoPostInactiveAppointments.TheraphyId=this._dtoPatientInfo.therapyId;
      this._dtoPostInactiveAppointments.TherapyDate=this._dtoPatientInfo.therapyDate;
      this._dtoPostInactiveAppointments.UserId=localStorage.getItem('id');
    }
    else
    {
      this._dtoPostInactiveAppointments.PatientAccountId=this._dtoGrpTherapydata.PatientAccountId;
    this._dtoPostInactiveAppointments.DayScheduleSubGroupId=this._dtoGrpTherapydata.DayScheduleSubGroupId;
    this._dtoPostInactiveAppointments.DayScheduleAssignId=this._dtoGrpTherapydata.DayScheduleAssignId;
    this._dtoPostInactiveAppointments.GroupTherapyId=this._dtoGrpTherapydata.GroupTherapyId;
    this._dtoPostInactiveAppointments.TherapyDate=this._dtoGrpTherapydata.Scheduledate;
    this._dtoPostInactiveAppointments.TheraphyId=3;
    this._dtoPostInactiveAppointments.UserId=localStorage.getItem('id');

    }
    this._dtoPostInactiveAppointments.Reason = this.DeleteReason;
    this._dtoPostInactiveAppointments.Comments = this.DeleteComments;
    const dataToEncrypt = this.commonService.DataToEncrypt(this._dtoPostInactiveAppointments, false);
    this.treatmentservice.postinactiveAppointments(dataToEncrypt).subscribe(
      (res: any) => {
         this.spinnerservice.hide();
         if(this.IsgroupTherapy){this._dtoGrpTherapydata.IsDeleted=true;}
         else if(!this.IsgroupTherapy){this._dtoPatientInfo.isDeleted=true;}

         if(JSON.parse(res).id==1){this.snackbar.success(JSON.parse(res).message);
          this.router.navigate(['/scheduler']);}
           else if(JSON.parse(res).id==0){this.snackbar.error(JSON.parse(res).message);}

         },
      err => {
        this.spinnerservice.hide();
        this.snackbar.error(err.error);
      },
    );

  }

  ondeleteMS() {
    // const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
    //   data: {
    //     title: '',
    //     message: constantVariables.SuccessMessage.DeleteConfirmMessage
    //   }
    // });
    // confirmDialog.afterClosed().subscribe(result => {
    //   if (result === true) {
    //     this.spinnerservice.show();
    //     this.deleteMeetingSchedule();

    //   }
    // });
    this.PopUpDelete = true
  }

  OncloseDeletePopup(){
    this.DeleteReason=0;
    this.DeleteComments="";
    this.DeleteMeetingscheduleclick = false
    this.PopUpDelete = false

  }

  GetDeleteReasonForMs(){
    this.spinnerservice.show();
    this.treatmentservice.GetDeleteReasonForMs().subscribe(
      (res: any) => {
        this.spinnerservice.hide();
        if (res.length>0){
          this.ddlDeleteReason=res;
        // console.log(this._dtoPatientInfo);
        }
      },
      err => {
        this.spinnerservice.hide();
      },
    );
  }
  tmpgp=[];
  themeDropDown:any;
  selectedTheme:any;
  captureTotalResponse:any;
  onLoadGroupTherapy()
   {
   this.spinnerservice.show();
   this.datanotExists=false;
   this._dtogrouptherapyAllPtAppointments=[];
   if(this.paramDsaId!=0){this.grpTherapyId=0;}
   this.clinicianservice.getGrpTherapyPatientAppointmentLists(this.grpTherapyId,this.selecteddate,this.paramClinicianId).subscribe(
    (res: any) => {

        this.spinnerservice.hide();
      if(res.objInputString!=null) {

        let res1= this.commonService.decrypdata(res.objInputString);
        // console.log(res1);

        this._dtogrouptherapyAllPtAppointments=res1;
        this.tmpgp=res1;

      if(this.paramDsaId==0)
      {
        res1=this.tmpgp;
        res1=res1.filter(x=>x.GroupTherapyId==this.grpTherapyId);
        this.drpPatients= res1.filter(
          (t2, i, arr) =>arr.findIndex(t => t.PatientAccountId === t2.PatientAccountId && t.PatientAccountId !=0) === i );
          this.selectedPatient=this.drpPatients.find(x=>x.PatientAccountId==res1[0].PatientAccountId).PatientAccountId;
          this.themeDropDown=res1.filter(x => x.PatientAccountId == this.selectedPatient && x.GroupTherapyId==this.grpTherapyId
            && this.date.transform(x.ScheduleDate, "yyyy-MM-dd")==this.date.transform(this.selecteddate, "yyyy-MM-dd"));
          this.selectedTheme=this.themeDropDown[0].DayScheduleSubGroupId;
            this._dtoGrpTherapydata = res1[0];
       }
       else
       {
        res1=this.tmpgp;
        this.drpPatients= res1.filter(
            (t2, i, arr) => arr.findIndex(t => t.DayScheduleAssignId === t2.DayScheduleAssignId && t.DayScheduleAssignId !=0 && t.DayScheduleAssignId==this.paramDsaId) === i );
            let tmp=res1.find(x=>x.DayScheduleAssignId==this.paramDsaId)
            this.selectedPatient=this.drpPatients.find(x=>x.DayScheduleAssignId==this.paramDsaId).PatientAccountId;
            this.selectedTheme=this.drpPatients.find(x=>x.DayScheduleAssignId==this.paramDsaId).DayScheduleSubGroupId;
            this._dtoGrpTherapydata = tmp;
       }

        this.adminNotes=this._dtoGrpTherapydata.AdminNotes;
       this.virtualMeettxt=this._dtoGrpTherapydata.MeetingLinks;
        this.isVirtualMeet=true;

        this.isShowNotes=(this.adminNotes!="")?true:false;
      }
      else{
        this._dtoGrpTherapydata=[];
        this.datanotExists=true;
      }
    },
    err => {
      this.datanotExists=true;
      this.spinnerservice.hide();

    },
  );
}
onPatientListchange(id)
{
  let obj=this._dtogrouptherapyAllPtAppointments;
  if(id==1){
  this._dtoGrpTherapydata=obj.filter(x => x.PatientAccountId == this.selectedPatient && this.date.transform(x.ScheduleDate, "yyyy-MM-dd")==this.date.transform(this.selecteddate, "yyyy-MM-dd"));
  this.selectedTheme = this._dtoGrpTherapydata[0].DayScheduleSubGroupId;
  this.themeDropDown=this._dtoGrpTherapydata
 }
  if(id==2){
    this._dtoGrpTherapydata=obj.filter(x => x.DayScheduleSubGroupId==this.selectedTheme && x.PatientAccountId == this.selectedPatient && x.GroupTherapyId==this.grpTherapyId
    && this.date.transform(x.ScheduleDate, "yyyy-MM-dd")==this.date.transform(this.selecteddate, "yyyy-MM-dd"));
    this.selectedPatient=this._dtoGrpTherapydata[0].PatientAccountId;
  }
  if(this._dtoGrpTherapydata.length>0)
  {
    this._dtoGrpTherapydata=this._dtoGrpTherapydata[0];
  }
}
  // onLoadGroupTherapyPatient()
  // {
  //   this.spinnerservice.show();
  //   this.treatmentservice.gtherapyDaySchedulePT(this.grpTherapyId,this.selecteddate,this.paramClinicianId).subscribe(
  //      (res: any) => {
  //         this.spinnerservice.hide();
  //       if(res.objInputString!=null)
  //       {

  //        let res1= this.commonService.decrypdata(res.objInputString);
  //         this._dtoGrpTherapydata = res1[0];
  //         this._dtogrouptherapyAllPtAppointments =   res1;
  //         console.log(this._dtoGrpTherapydata );


  //        this.adminNotes=this._dtoGrpTherapydata.AdminNotes;
  //       this.virtualMeettxt=this._dtoGrpTherapydata.MeetingLinks;
  //        this.isVirtualMeet=true;

  //        this.isShowNotes=(this.adminNotes!="")?true:false;

  //        }
  //     },
  //     err => {
  //       this.spinnerservice.hide();
  //     },
  //   );
  // }
  // onLoadGroupTherapyOld()
  // {

  //   this.spinnerservice.show();
  //   this.treatmentservice.gtherapyDaySchedulePT(this.dsgSubgrpId,this.grpTherapyId,this.selectedMsId).subscribe(
  //      (res: any) => {
  //         this.spinnerservice.hide();
  //       if(res.objInputString!=null)
  //       {

  //        let res1= this.commonService.decrypdata(res.objInputString);
  //         this._dtoGrpTherapydata = res1[0];
  //       //  this._dtoGrpTherapydata =   this._dtoGrpTherapydata[0];
  //         console.log(this._dtoGrpTherapydata );

  //        this.adminNotes=this._dtoGrpTherapydata.AdminNotes;
  //       this.virtualMeettxt=this._dtoGrpTherapydata.MeetingLinks;
  //        this.isVirtualMeet=true;

  //        this.isShowNotes=(this.adminNotes!="")?true:false;

  //        }
  //     },
  //     err => {
  //       this.spinnerservice.hide();
  //     },
  //   );

  // }

  OnCancelReschedule(){
    this.PopUpReschedule=false;
    this.dtoScheduleModality = new dtoReScheduleModality();
   this.currentForm.resetForm()
  }
  onRescheduleMS(){
    
    let date = '2021-05-28'
    this.PopUpReschedule = true;
    this.dtoScheduleModality.provider = this._dtoPatientInfo.staffId;
    this.dtoScheduleModality.startDate = this._dtoPatientInfo.therapyDate;
    this.dtoScheduleModality.startTime = moment(date).format("YYYY-MM-DD") + " " +moment(this._dtoPatientInfo.startTime).format('HH:mm')+":00.000";;
   // console.log(this._dtoPatientInfo.startTime)
    this.dtoScheduleModality.location = this._dtoPatientInfo.sfccMasterId;
    this.dtoScheduleModality.meetingType =  this._dtoPatientInfo.meetingType;
   // this.dtoScheduleModality.duration = this._dtoPatientInfo.durationInMeetingScheduleTime
    this.dtoScheduleModality.meetingRoom = this._dtoPatientInfo.meetingRoom
    this.dtoScheduleModality.therapyId = this._dtoPatientInfo.therapyId;
    const totalMinutes = this._dtoPatientInfo.durationInMeetingScheduleTime;
      var duration = Math.floor(totalMinutes / 60) * 60;
      this.dtoScheduleModality.duration = duration;
      this.dtoScheduleModality.minutes = totalMinutes % 60;
      if(this.datepipe.transform(new Date(),"yyyy-MM-dd")<this.datepipe.transform(this._dtoPatientInfo.therapyDate,"yyyy-MM-dd")){
        this.currenttime = '00.00'
      }else{
        this.currenttime = moment().format("HH:mm");
      }
  }
  getStaffList()
  {

  this.commonService.getStaffListV2(constantVariables.role.PrimaryClinician,this._dtoPatientInfo.sfccMasterId).subscribe(
      (res: any) => {
        if (res) {
          this.ddlStafflist = res;        
          this.dtoScheduleModality.location = this._dtoPatientInfo.sfccMasterId;
          }   
        },
      err => {
        this.spinnerservice.hide();
      },
    );
  }
  getStaffList1() {
    this.spinnerservice.show();

    this.treatmentservice.getStaffList().subscribe(
      (res: any) => {
        this.spinnerservice.hide();
        if (res != null) {
          this.ddlStafflist = res;

        }
      },
      err => {
        this.spinnerservice.hide();

      },
    );
  }

  handleStartDatechange(event) {
    const m: moment.Moment = event.value;
    const dateIsAfter = moment(this.dtoScheduleModality.startTime).isAfter(moment(this.currenttime));
     // console.log("Date of Birth: " + m.toDate());
     // console.log(this.currentdate)
      if(this.datepipe.transform(m.toDate(),"yyyy-MM-dd")==this.datepipe.transform(this.currentdate,"yyyy-MM-dd")){
        this.currenttime = moment().format("HH:mm");
      }
      else{
        this.currenttime = '00.00'
      }
      this.OnTimeChange();
  }
  getMeetingLocation() {
    this.spinnerservice.show();
    this.settingsService.getMeetinglocation().subscribe(
      res => {
        this.spinnerservice.hide();
        this.ddlMeetinglocation = res;
        // this.getAllScheduleModality();
      },
      error =>
        this.spinnerservice.hide()
    );
  }

LoadMasters(){
  this.GetDeleteReasonForMs();
 
  this.getMeetingLocation();
  this.getddlLocation();
  this.GetMeetingTypeMaster();
  this.getSchedulerTimeSlots();
}
GetMeetingTypeMaster(){
  this.spinnerservice.show();
  this.treatmentservice.GetMeetingTypeMaster().subscribe(
    (res: any) => {
      this.spinnerservice.hide();
      if (res.length>0){
        this.ddlMeetingType=res;
      // console.log(this._dtoPatientInfo);
      }
    },
    err => {
      this.spinnerservice.hide();
    },
  );
}
getConflictsModality(){
  if( moment(this.dtoScheduleModality.startTime).format("HH:mm")<this.currenttime){
    this.IspastTime=true;
    return
  }
  var offset = new Date().getTimezoneOffset();
  this.scheduleModalityList = [];
  this.dtoScheduleConflictModalitylist = []
  let starttime = moment(this.dtoScheduleModality.startTime).format("HH:mm");
  let _obj = {
    StartDate: moment.utc(this.dtoScheduleModality.startDate).utcOffset(-offset).format("MM/DD/YYYY"),
    EndDate: moment.utc(this.dtoScheduleModality.startDate).utcOffset(-offset).format("MM/DD/YYYY"),
    FromTime: moment(this.dtoScheduleModality.startTime).format("HH:mm"),
    ToTime:moment(starttime,'HH:mm').add(((Number(this.dtoScheduleModality.duration)+Number(this.dtoScheduleModality.minutes))), 'minute').format('HH:mm A'),
    //ToTime: moment(starttime,'HH:mm').add(this.dtoScheduleModality.duration, 'minute').format('HH:mm A'),
    Provider: this.dtoScheduleModality.provider
  }

  this.spinnerservice.show();
  if(moment(this._dtoPatientInfo.startTime).format("HH:mm") == moment(this.dtoScheduleModality.startTime).format("HH:mm") && this.dtoScheduleModality.startDate == this._dtoPatientInfo.therapyDate && this.dtoScheduleModality.duration == this._dtoPatientInfo.durationInMeetingScheduleTime){
    this.DirectPostScheduleModality(starttime);
  }else{
  const dataToEncrypt = this.commonService.DataToEncrypt(_obj, false);
  this.treatmentservice.getConfilctsForModality(dataToEncrypt).subscribe(
    (res: any) => {
      this.dtoScheduleConflictModalitylist = JSON.parse(res);
      this.spinnerservice.hide();
      if(this.dtoScheduleConflictModalitylist.length==0){
        this.DirectPostScheduleModality(starttime);
      }
      else{
        this.dateList = [];
      //  this.dateList = this.getDaysBetweenDates(moment(this.dtoScheduleModality.startDate).utcOffset(-offset), moment(this.dtoScheduleModality.endDate).utcOffset(-offset));
        this.onBetweenappointment(this.dtoScheduleConflictModalitylist);
        this.skipAllConflicts();
      }
    },
    err => {
      this.spinnerservice.hide();

    },
  );
  }
}
DirectPostScheduleModality(starttime){
  this.scheduleModalityList =[]
  this.scheduleModalityList.push({
    // day: data[i].day,
    scheduleDate: moment(this.dtoScheduleModality.startDate).format('MM/DD/YYYY'),
    isChecked: false,
    fromTime: moment(this.dtoScheduleModality.startTime).format("HH:mm"),
    toTime:moment(starttime,'HH:mm').add(((Number(this.dtoScheduleModality.duration)+Number(this.dtoScheduleModality.minutes))), 'minute').format('HH:mm A'),
    //toTime:  moment(starttime,'HH:mm').add(this.dtoScheduleModality.duration, 'minute').format('HH:mm A'),
    isConflict: false})
  this.postSchduleModality();
}
getDayBWDates(start, end, dayName, lstday) {
  var result = [];
  for (let i = 0; i < lstday.length; i++) {
    var days = { sun: 0, mon: 1, tue: 2, wed: 3, thu: 4, fri: 5, sat: 6 };
    var day = days[dayName.toLowerCase().substr(0, 3)];
    var current = new Date(start.toDate());
    current.setDate(current.getDate() + (lstday[i] - current.getDay() + 7) % 7);
    while (current <= end.toDate()) {
      result.push(new Date(+current));
      current.setDate(current.getDate() + 7);
    }
  }
  return result;
}

onBetweenappointment(data) {
  this.scheduleModalityList = [];
  let starttime = moment(this.dtoScheduleModality.startTime).format("HH:mm");
  for (let i = 0; i < data.length; i++) {
    this.scheduleModalityList.push({
      // day: data[i].day,
      scheduleDate: moment(data[i].scheduleDate).format('MM/DD/YYYY'),
      isChecked: false,
      fromTime: moment(this.dtoScheduleModality.startTime).format("HH:mm"),
      toTime:moment(starttime,'HH:mm').add(((Number(this.dtoScheduleModality.duration)+Number(this.dtoScheduleModality.minutes))), 'minute').format('HH:mm A'),
      //toTime: moment(starttime,'HH:mm').add(this.dtoScheduleModality.duration, 'minute').format('HH:mm A'),
      isConflict: this.getIsConflict(data[i].scheduleDate),
      // location: data[i].location,
      // locationName: this.getLocationName(data[i].location),
    });
  }




}


getDaysBetweenDates(startDate, endDate) {
  // var now = startDate.clone();
  let betweendates = [];
  while (startDate.isSameOrBefore(endDate)) {
    betweendates.push(new Date(startDate));
    startDate.add(1, 'days');
  }
  return betweendates;
};

skipAllConflicts() {
  this.errConflicts = false;
  this.scheduleModalityList = this.scheduleModalityList.filter((item) => item.isConflict != true);
  if(this.scheduleModalityList.length!=0){
    this.postSchduleModality();
  }
  else{
    this.snackbar.error(this._dtoPatientInfo.staffName + constantVariables.SuccessMessage.FailedToReschedule + moment(this.dtoScheduleModality.startDate).format('MM/DD/YYYY') + " " + this.dtoScheduleModality.startTime)
  }
}

getIsConflict(conflictDate: any) {
  let isConflictlist = this.dtoScheduleConflictModalitylist.filter((item) => item.isConflict == true);
  for (let i = 0; i < isConflictlist.length; i++) {
    let firstDate = moment(isConflictlist[i].scheduleDate).format('MM/DD/YYYY');
    let secondDate = moment(conflictDate).format('MM/DD/YYYY');
    if ((firstDate == secondDate) && isConflictlist[i].isConflict == true) {

      return true;
    }
    else{
      return false;
    }
  }

}

postSchduleModality() {
  let checkconfilcts = this.scheduleModalityList.filter((item) => item.isConflict == true).length;
   if (checkconfilcts > 0) {
    this.errConflicts = true;
    return;
  }
  else{
    let obj;
      let MeetingTimesArray = [];
      MeetingTimesArray.push(this.scheduleModalityList[0])
      //this.dtoScheduleModality.duration = Number(this.dtoScheduleModality.duration) + Number(this.durationmin);
      obj = {
        MeetingCategoryId: 1,
        MeetingScheduleId:this._dtoPatientInfo.meetingScheduleId,
        MeetingLocationId: this.dtoScheduleModality.location,
        PatientAccountId: this._dtoPatientInfo.patientAccountId,
        PatientInfoId: this._dtoPatientInfo.patientInfoId,
        ScheduleName: this._dtoPatientInfo.scheduleName,
        Provider: this.dtoScheduleModality.provider,
        MeetingTimes: MeetingTimesArray,
        ModalityDetails: this.dtoScheduleModality,
        PreviousMeetingScheduleTimeId : this._dtoPatientInfo.meetingScheduleTimeId,
        Commands:this.dtoScheduleModality.rescheduleComments,
        MeetingTypeId: this.dtoScheduleModality.meetingType
      }
  this.spinnerservice.show();
  const dataToEncrypt = this.commonService.DataToEncrypt(obj, false);
  this.treatmentservice.postReSchduleMeeting(dataToEncrypt).subscribe(
    (res: any) => {
      this.spinnerservice.hide();
     //// this.snackbar.success(this.therapyName + " " + JSON.parse(res).message);
      this.snackbar.success(res);
      this.router.navigate(['/scheduler']);

    },
    err => {
      this.spinnerservice.hide();
      this.snackbar.error(err.error);
    },
  );
}
}
showNotesClick()
{//this.adminNotes="";
  this.isShowNotes=true;
}
saveNotes()
{
  this.isNotesErr=false;
  if(this.adminNotes=="")
  {
   //this.snackbar.error("Notes is empty,enter valid notes");
   this.isNotesErr=true;
   return;
  }
  let notesobj;
  notesobj = {
    TherapyId: this.therapyId,
    MeetingScheduleTimeDsaId: (this.IsgroupTherapy)?this._dtoGrpTherapydata.DayScheduleAssignId:this._dtoPatientInfo.meetingScheduleTimeId,
    ScheduleNotes:this.adminNotes,
    UserId:localStorage.getItem("id")
  }
  this.spinnerservice.show();
  const dataToEncrypt = this.commonService.DataToEncrypt(notesobj, false);
  this.treatmentservice.postSchedularAdminNotes(dataToEncrypt).subscribe(
    (res: any) => {

      this.isShowNotes=true;
      this.spinnerservice.hide();
      this.snackbar.success(JSON.parse(res).message);

      },
    err => {
      this.spinnerservice.hide();
      this.snackbar.error(err.error);
    },
  );
}


showVirtualmeet()
{
  this.isVirtualMeet=true;
 }
saveVirtualMeet()
{
  this.isVmeetingErr=false;
  let vmobj;
  if(this.virtualMeettxt=="")
  {
   //this.snackbar.error("Text is empty,enter valid meeting link");
   this.isVmeetingErr=true;
   return;
  }
  vmobj = {
    TherapyId: this.therapyId,
    MeetingScheduleTimeDsaId: (this.IsgroupTherapy)?this._dtoGrpTherapydata.DayScheduleAssignId:this._dtoPatientInfo.meetingScheduleTimeId,
    MeetingLinks:this.virtualMeettxt,
    UserId:localStorage.getItem("id")
  }
  this.spinnerservice.show();
  const dataToEncrypt = this.commonService.DataToEncrypt(vmobj, false);
  this.treatmentservice.postSchedularAdminMeetingLink(dataToEncrypt).subscribe(
    (res: any) => {
      this.isVirtualMeet=true;
      this.spinnerservice.hide();
      this.snackbar.success(JSON.parse(res).message);
      },
    err => {
      this.spinnerservice.hide();
      this.snackbar.error(err.error);
    },
  );
}
getSchedulerTimeSlots() {
  this.settingsService.getSchedulerTimeSlots().subscribe(
    res => {
      if (res != null) {
        this.meetingTimeslots = res;
      }
    },
    err => {
      this.spinnerservice.hide();
    },
  );
}


OnTimeChange(){
  // const dateIsAfter = moment(this.dtoScheduleModality.startTime).isAfter(moment(this.currenttime));
  if( moment(this.dtoScheduleModality.startTime).format("HH:mm")>this.currenttime){
    this.IspastTime=false;
    }
    else{
      this.IspastTime = true;
    }
  
}
actionMethod(){
 this.clicked = false;
}

}
