import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import Html2Canvas from "html2canvas";
import { SnackBarService } from 'src/app/services/snackBar.service'
import { CommonService } from 'src/app/common/common.service';
import { PatientService } from 'src/app/patient/patient.service';
import { constantVariables } from 'src/app/constant/constant';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { DomSanitizer } from "@angular/platform-browser";
import { Global } from 'src/app/global/global.model';
import * as moment from 'moment';
import { PageAccessService } from 'src/app/rolemanagement/PageRoleAccessService';
import { RoelAccess } from 'src/app/_models/_Common/roleAccessMaster';

declare var anaconfig: any;
@Component({
  selector: 'app-pain-assessment',
  templateUrl: './pain-assessment.component.html',
  styleUrls: ['./pain-assessment.component.scss']
})
export class PainAssessmentComponent implements OnInit {
  @ViewChild('one') d1: ElementRef;
  @ViewChild('canvas') canvas: ElementRef;
  @ViewChild('downloadLink') downloadLink: ElementRef;
  @ViewChild('anawrapper') mainpage: ElementRef;
  queMCSA = constantVariables.questionType.MCSA;
  showPainLevel: boolean = false;
  xpoint: number = -1;
  ypoint: number = -1;
  base64image: any;
  patientAccountId: any;
  pdfPatientInformationIntake: any = null;
  report: any = '';
  showmessage: boolean = false;
  public selectedBody: any = '';
  painAssessment: any;
  isMovedChma: boolean = false;
  _dtoPatientInfo: any;
  roleAccess: RoelAccess;
  isClear:boolean=false;
  painAssessmentId:any;
  isShowPopupForDownload:boolean = false;
  constructor(public patientservice: PatientService, private sanitizer: DomSanitizer,
    private router: Router, private route: ActivatedRoute, public commonService: CommonService, public spinnerservice: NgxSpinnerService
    , private snackbar: SnackBarService,public global: Global,public roleaccessservice:PageAccessService) { }

  ngOnInit(): void {
    const pageid = constantVariables.pagelists.PainAssessment;
    this.roleAccess = this.roleaccessservice.GetRoleAccessByPageId(pageid);
    this.route.queryParams.subscribe(params => {
      if (Object.keys(params).length != 0) {
        this.patientAccountId = Number(this.commonService.FrontEndDecryption(params.id).replace(/"/g, ""));
        this.isMovedChma = Number(this.commonService.FrontEndDecryption(params.isChma).replace(/"/g, "")) == 0 ? false : true;
      }
      this.getPainAssessment();
    });

  }

  ngAfterContentInit() {


    var slides = document.getElementsByClassName("level");
    for (var i = 0; i < slides.length; i++) {
      var itme = slides.item(i)
      this.addAction(itme.id);
    }

    var slides = document.getElementsByClassName("ana");
    for (var i = 0; i < slides.length; i++) {
      var itme = slides.item(i)
      this.addEvent(itme.id);
    }
  }
  addAction(id) {

    var _obj = document.getElementById(id)
    _obj.addEventListener("mousedown", this.setPain);

  }
  // onGeneratePDF() {
  //   if (document.getElementById("selected").innerHTML != "") {
  //     document.getElementById("templevel1").remove();
  //     document.getElementById(document.getElementById("selected").innerHTML).style.fill = document.getElementById("prvcolor").innerHTML
  //   }
  //   this.spinnerservice.show();

  //   this.painAssessment.report = this.mainpage.nativeElement.innerHTML
  //   // const _obj={
  //   //   Report:this.mainpage.nativeElement.innerHTML,
  //   //   PatientAccountId:this.patientAccountId, 
  //   // }
  //   this.spinnerservice.show();
  //  // this.patientservice.SavePainAssessment(this.painAssessment).subscribe(
  //  //   (res: any) => {
  //       this.patientservice.getPainAssessmentPdf(this.patientAccountId).subscribe(
  //         (res: any) => {
            
  //           var result = res;
  //           this.pdfPatientInformationIntake = result.filePath
  //           this.downloadLink.nativeElement.href = result.base64File;
  //           this.downloadLink.nativeElement.download = result.fileName;
  //           this.downloadLink.nativeElement.click();
  //           this.spinnerservice.hide();
  //         },
  //         err => {
  //           this.spinnerservice.hide();
  //         },
  //       );
  //     // },
  //     // err => {
  //     //   this.spinnerservice.hide();
  //     // },
  //  // );
  // }
  mouseoverevent(event) {

    var d1 = document.getElementById('anawrapper')
    var d2 = document.getElementById('basea')
    document.getElementById("selectedcolor").innerHTML = document.getElementById(event.currentTarget.id).style.fill
    document.getElementById(event.currentTarget.id).style.fill = "rgba(255, 0, 0, 0.3)"
    // document.getElementById("anatip").style.display="block"
    // document.getElementById("anatip").innerHTML = anaconfig[event.currentTarget.id].hover
    //     var top = Number(d1.getBoundingClientRect().top);
    //  var left = d1.getBoundingClientRect().left;

    event.currentTarget.innerText = anaconfig[event.currentTarget.id].hover;
    this.xpoint = event.clientX - event.offsetX;
    this.ypoint = event.clientY - event.offsetY + 100;
    if (event.currentTarget.id != document.getElementById("selected").innerHTML) {
      d1.insertAdjacentHTML('beforeend', '<div id="templevel" style="top:' + event.layerY + 'px;left:' + event.clientX + 'px;position: absolute;" class="two"><span style="color: black;">' + anaconfig[event.currentTarget.id].hover + '</span> </div>')
    }
  }
  mouseleaveevent(event) {

    if (event.currentTarget.id != document.getElementById("selected").innerHTML) {

      document.getElementById(event.currentTarget.id).style.fill = document.getElementById("selectedcolor").innerHTML;

    }
    if (document.getElementById("templevel") != null)
      document.getElementById("templevel").remove();

    // document.getElementById("anatip").innerHTML ="";
    // document.getElementById("anatip").style.display="none"
  }

  addEvent(id) {
    var _obj = document.getElementById(id)

    _obj.addEventListener("mousedown", this.updateDisplay, id);
    _obj.addEventListener("mouseover", this.mouseoverevent, id);
    _obj.addEventListener("mouseleave", this.mouseleaveevent, id);

    // function handleClick(event) {
    //     console.log("Clicked!");
    //     this.removeEventListener("click", handleClick);
    // }
  }
  ClearPain(){
    this.isClear=true;
    this.getPainAssessment();
  }
  getPainAssessment() {
    this.spinnerservice.show();
    this.patientservice.getPainAssessment(this.patientAccountId,this.isClear).subscribe(
      (res: any) => {
        

        this.painAssessment = res;
        this._dtoPatientInfo = res.patientDetailedInfo;
        this.painAssessmentId=res.painAssessmentId;
        this.calculateAge();
        this.isClear=false;
       // console.log(this.painAssessment)
        this.painAssessment.report = this.sanitizer.bypassSecurityTrustHtml(res.report);
        this.report = this.painAssessment.report;
        setTimeout(() => {

          var slides = document.getElementsByClassName("ana");
          for (var i = 0; i < slides.length; i++) {
            var itme = slides.item(i)
            this.addEvent(itme.id);
          }
          this.spinnerservice.hide();
        }, 200)

      },
      err => {
        this.spinnerservice.hide();
        this.isClear=false;
      },
    );
  }
  onMouseMove: { (event1: MouseEvent): void } = (event: MouseEvent) => {



  }
  updateDisplay(event) {
    
    var d1 = document.getElementById('anawrapper')
    // alert(event.pageX);
    // alert(event.pageY);
    if (document.getElementById("selected").innerHTML != "") {
      document.getElementById(document.getElementById("selected").innerHTML).style.fill = document.getElementById("prvcolor").innerHTML
    }

    document.getElementById("prvcolor").innerHTML = document.getElementById("selectedcolor").innerHTML
    document.getElementById(event.currentTarget.id).style.fill = "rgba(255, 0, 0, 0.3)"
    document.getElementById('remove').style.display = "none"
    document.getElementById('remove1').style.display = "none"
    var d2 = document.getElementById('basea')
    this.selectedBody = event.currentTarget.id;
    document.getElementById("selected").innerHTML = event.currentTarget.id
    if (document.getElementById("templevel") != null)
      document.getElementById("templevel").remove();
    if (document.getElementById("templevel1") != null)
      document.getElementById("templevel1").remove();
    d1.insertAdjacentHTML('beforeend', '<div id="templevel1" style="top:' + event.layerY + 'px;left:' + event.clientX + 'px;position: absolute;" class="two"><span style="color: black;">Select pain Level for ' + anaconfig[event.currentTarget.id].hover + '</span> </div>')

    //  document.getElementById("anatip2").style.display="inline-block"
    //  document.getElementById("anatip1").style.display="inline-block"
    var x = event.clientX + 300;
    if (document.getElementById("selectedcolor").innerHTML != "transparent" && document.getElementById("selectedcolor").innerHTML != "") {
      document.getElementById('remove').style.display = "inline-block"
      document.getElementById('remove1').style.display = "inline-block"
      //  d2.insertAdjacentHTML('beforeend', '<div id="remove" style="top:'+ event.layerY +'px;left:'+ x +'px;position: absolute;" class="two"><span ><i class="fas fa-times mr-2"></i></span> </div>')
      //  var _obj = document.getElementById('remove')
      //  _obj.addEventListener("mousedown", this.removeColor);
    }
    // var d2 = document.getElementById(event);
    // d2.style.fill = "rgba(255, 0, 0, 0.3)";
    //this.setImage(event.clientX,event.clientY)
    //d2.append('<div style="top:'+ event.pageY +'px;left:'+ event.pageX +'px;position: absolute;" class="two"><img style="width: 20px;height: 20px; " src="../../../assets/images/tick-survey.png"> </div>')

  }
  removeColor() {
    
    var d2 = document.getElementById(document.getElementById("selected").innerHTML);
    d2.style.fill = "transparent";
    document.getElementById("selected").innerHTML = "";
    document.getElementById("templevel1").remove();
    document.getElementById('remove').style.display = "none"
    document.getElementById('remove1').style.display = "none"
    document.getElementById("anatip2").style.display = "none"
    document.getElementById("anatip1").style.display = "none"
  }
  downloadImage() {
    if (document.getElementById("selected").innerHTML != "") {
      document.getElementById("templevel1").remove();
      document.getElementById(document.getElementById("selected").innerHTML).style.fill = document.getElementById("prvcolor").innerHTML
    }

    
    Html2Canvas(this.mainpage.nativeElement).then(canvas => {
      ;
      this.base64image = canvas.toDataURL();
     // console.log(this.base64image)
    });
    this.painAssessment.report = this.mainpage.nativeElement.innerHTML
    // const _obj={
    //   Report:this.mainpage.nativeElement.innerHTML,
    //   PatientAccountId:this.patientAccountId, 
    // }
    this.spinnerservice.show();
    this.patientservice.SavePainAssessment(this.painAssessment).subscribe(
      (res: any) => {
        
        this.painAssessment = JSON.parse(res);
        this._dtoPatientInfo.updatedDate=this.painAssessment.updateDate;
        //this.painAssessment.report=this.sanitizer.bypassSecurityTrustHtml(JSON.parse(res).report);
        this.spinnerservice.hide();
        this.snackbar.success("Pain Assessment has been submitted successfully");
        this.router.navigate(['/clinician-mytask']);
        //this.downloadLink.nativeElement.href = canvas.toDataURL('image/png');
        //  this.downloadLink.nativeElement.download = 'marble-diagram.png';
        //  this.downloadLink.nativeElement.click();

      },
      err => {
        this.snackbar.error(err.error);
        this.spinnerservice.hide();
      },
    );
    // Html2Canvas(this.d1.nativeElement).then(canvas => {
    //   ;
    //  this.base64image = canvas.toDataURL();
    //  this.canvas.nativeElement.src = canvas.toDataURL();
    //  this.downloadLink.nativeElement.href = canvas.toDataURL('image/png');
    //  this.downloadLink.nativeElement.download = 'marble-diagram.png';
    //  this.downloadLink.nativeElement.click();
    //   //alert(base64image)
    // });
  }
  setImage(x, y) {
    var human = document.getElementById("one")
    var top = Number(human.getBoundingClientRect().top);
    var left = human.getBoundingClientRect().left;
    ;
    this.xpoint = x - left;
    this.ypoint = y - top;
    if (document.getElementById("templevel") != null)
      document.getElementById("templevel").remove();
    human.insertAdjacentHTML('beforeend', '<div style="top:' + this.ypoint + 'px;left:' + this.xpoint + 'px;position: absolute;" class="two"><span id="templevel"  class="badge badge-pill badge-danger mr-3">-</span> </div>');
  }
  setPain(event) {
    
    var d2 = document.getElementById(document.getElementById("selected").innerHTML);
    switch (event.currentTarget.id) {
      case 'level1':
      case 'level2':
        d2.style.fill = "#a3d17a";
        break
      case 'level3':
      case 'level4':
        d2.style.fill = "#b5d562";
        break
      case 'level5':
      case 'level6':
        d2.style.fill = "#f9e415";
        break
      case 'level7':
      case 'level8':
        d2.style.fill = "#fdba40";
        break
      case 'level9':
      case 'level10':
        d2.style.fill = "#f79544";
        break
      case 'level11':
      case 'level12':
        d2.style.fill = "#f1635c";
        break
    }
    document.getElementById("selectedcolor").innerHTML = d2.style.fill;
    document.getElementById("selected").innerHTML = "";
    document.getElementById("prvcolor").innerHTML = d2.style.fill;
    document.getElementById("anatip2").style.display = "none"
    document.getElementById('remove').style.display = "none"
    document.getElementById('remove1').style.display = "none"
    document.getElementById("templevel1").remove();
    // d2.style.fill = color;
    //   if(this.xpoint != -1 && this.ypoint != -1)
    //   {
    //     document.getElementById("templevel").remove();
    //     var human=document.getElementById("one")
    //      if(level == 0)
    //      human.insertAdjacentHTML('beforeend', '<div style="top:'+ this.ypoint +'px;left:'+ this.xpoint+'px;position: absolute;" class="two"><span  class="badge badge-pill badge-danger mr-3" style="margin-right: 1rem!important;color: #fff;background-color: #dc3545;padding-right: .6em;padding-left: .6eborder-radius: 10rem !important;display: inline-block;padding: .25em .4em;'+
    //    'font-size: 95%;   font-weight: 700;   line-height: 1;   text-align: center;   white-space: nowrap;   vertical-align: baseline;border-radius: 10rem;">'+level+'</span> </div>');

    //     human.insertAdjacentHTML('beforeend', '<div style="top:'+ this.ypoint +'px;left:'+ this.xpoint+'px;position: absolute;" class="two"><span  class="badge badge-pill badge-danger mr-3" style="margin-right: 1rem!important;color: #fff;background-color: #dc3545;padding-right: .6em;padding-left: .6eborder-radius: 10rem !important;display: inline-block;padding: .25em .4em;'+
    //    'font-size: 95%;   font-weight: 700;   line-height: 1;   text-align: center;   white-space: nowrap;   vertical-align: baseline;border-radius: 10rem;">'+level+'</span> </div>');
    //   this.xpoint=-1;
    //   this.ypoint=-1; 
    // }
  }

  onBack() {
    if(this.global.ismytask == undefined || this.global.ismytask){
      window.close(); 
    }
    else{
      this.router.navigateByUrl('/clinician');
    }
  }
  calculateAge() {
     this._dtoPatientInfo.age = moment().diff(this._dtoPatientInfo.dob, 'years');

  }

  onGeneratePDF(){
    this.spinnerservice.show();
    this.patientservice.getPainAssessmentPdf(this.patientAccountId,this.painAssessmentId).subscribe(
      (res: any) => {
        this.spinnerservice.hide();
        this.isShowPopupForDownload=!this.isShowPopupForDownload;
        //this.pdfPatientInformationIntake = res.filePath;
      //   if(item==1){
      //     window.open(this.pdfPatientInformationIntake, '_blank');
      //   }
      //   else if(item==0){
      //   const linkSource = 'data:application/pdf;base64,'+res.base64File+'';
      //   this.downloadLink.nativeElement.href = res.base64File;
      //   this.downloadLink.nativeElement.download = res.fileName;
      //   this.downloadLink.nativeElement.click();}
      },
      err => {
        this.spinnerservice.hide();
      },
    );
  }
  OnDownloadDetailspopupShowOrClose(){
    this.isShowPopupForDownload=!this.isShowPopupForDownload;
  }
  OnDownloadPdf(){
    // if(type == 1){
    //   // var blob = new Blob([this.painAssessment.documentLink]);
    //   //     let saveAs = require('file-saver');
    //   //     let file = this._dtoPatientInfo?.patientName + '.pdf';
    //   //     saveAs(blob, file);
    //   //     this.spinnerservice.hide();
    //   //     this.snackbar.success('PDF Downloaded successfully');
    //     this.downloadLink.nativeElement.href =`data:application/pdf;base64,${ this.painAssessment.fileBase64}`;
    //     this.downloadLink.nativeElement.download = this._dtoPatientInfo?.patientName + '.pdf';
    //     this.downloadLink.nativeElement.click();
    // }
    // else{
    //   window.open(this.painAssessment.documentLink, '_blank');
    // }
    window.open(this.painAssessment.documentLink, '_blank');
  }
}
