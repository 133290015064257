import { Component, OnInit, Input, ElementRef, ViewChild } from '@angular/core';
import { SnackBarService } from 'src/app/services/snackBar.service'
import { Global } from 'src/app/global/global.model';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'src/app/common/common.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from 'src/app/common/confirm-dialog/confirm-dialog.component';
import { NgForm } from '@angular/forms';
import { constantVariables } from 'src/app/constant/constant';
import { cloneDeep } from "lodash";
import * as moment from 'moment';
import { PatientService } from 'src/app/patient/patient.service';
import { chmadetails } from 'src/app/_models/_Patient/_CHMADetails';
import { IntakeCmhaComponent } from 'src/app/patient/intake/intake-cmha/intake-cmha.component';
import { ConsentEsignDialogComponent } from 'src/app/common/consentesign-dialog/consentesign-dialog.component';
import { chmaEditPatientDetails } from 'src/app/_models/_Patient/_ChmaEditPatientDetails';
import { PageAccessService } from 'src/app/rolemanagement/PageRoleAccessService';
import { TreatmentService } from 'src/app/staff/treatment/treatment.service';
@Component({
  selector: 'app-cmha-clinical',
  templateUrl: './cmha-clinical.component.html',
  styleUrls: ['./cmha-clinical.component.scss']
})
export class CmhaClinicalComponent implements OnInit {
  // @ViewChild(IntakeCmhaComponent, { static: false }) private chma;
  isMovedChma: boolean;
  patientInfoId: any;
  patientAccountId: any;
  userId: any;
  userName: any;
  clinicalsummary: any;
  providerName: any;
  signDate: any;

  lstCHMADetails: any;
  dtoButtonFieldList: any;
  signatureImg: any;
  updatedsignatureImg: any;
  secondarySignature: any;
  updatedsecondarysignatureImg: any;
  isESignAttached: boolean;
  isformtype: string;
  isfutureSign: boolean;
  dynamicimgsrcid: any = [];
  isAttachEsign: boolean;
  defaultImage: any;
  isParentsign: boolean = false;
  isReqeSign: boolean = false;
  isReqName : boolean = false;
  UploadFileList = [];
  FilePath: any;
  fileType: any;
  asebareportPath: any;
  UploadedFileList = [];
  patientDetails=new chmaEditPatientDetails();
  @ViewChild('taskfile') eletaskfile: ElementRef;
  public removeEventListener: () => void;
  public anchors;
  roleAccess: any;
  wordCount: number = 0;
  isShowCount : boolean = false;
 maxlength : number = 3000;
 secondaryId : any;
 AutoFillSS:boolean=false;
 SSignature:any;
 GetClinicianSign: any[];  

  constructor(public global: Global, public patientservice: PatientService, private chma: IntakeCmhaComponent,
    private dialog: MatDialog, private snackbar: SnackBarService,
    private router: Router, private route: ActivatedRoute,public treatmentservice: TreatmentService,
    public commonService: CommonService, public spinnerservice: NgxSpinnerService,public roleaccessservice:PageAccessService) { }

  ngOnInit(): void {
    const pageid = constantVariables.pagelists.PrepareCHMA;
    this.roleAccess = this.roleaccessservice.GetRoleAccessByPageId(pageid);
    this.patientInfoId = Number(localStorage.getItem("patientId"));
    this.patientAccountId = Number(localStorage.getItem("patientAccountId"));
    this.userId = Number(localStorage.getItem('id'));
    this.userName = localStorage.getItem('username');
    this.getAutoFillSignature();

    this.route.queryParams.subscribe(params => {
      if (Object.keys(params).length != 0) {
        this.patientAccountId = Number(this.commonService.FrontEndDecryption(params.id).replace(/"/g, ""));
        this.isMovedChma = Number(this.commonService.FrontEndDecryption(params.isChma).replace(/"/g, "")) == 0 ? false : true;
      }
      this.lstCHMADetails = new chmadetails();
      this.getCHMADetails();


    });
  }
  updateWordCount() {
    this.isShowCount = true;
    this.wordCount =this.maxlength - (this.clinicalsummary != null ? this.clinicalsummary.length : 0);
  }
  getCHMADetails() {
    this.spinnerservice.show();
    this.secondaryId = localStorage.getItem('id');
    this.patientservice.getCHMADetails(this.patientAccountId,this.secondaryId).subscribe(
      (res: any) => {
        var offset = new Date().getTimezoneOffset();
        this.spinnerservice.hide();
        if (res != null) {
          this.lstCHMADetails = cloneDeep(res);
          this.clinicalsummary = res.clinicalSummary;
          if (res.documents) {
            this.UploadedFileList = res.documents;
          }
          this.providerName = res.providerName == null ? this.userName : res.providerName;
          this.signDate = res.signDate == null ? moment.utc(new Date()).utcOffset(-offset).format("MM/DD/YYYY") : moment(res.signDate).format("MM/DD/YYYY");
          this.defaultImage=res.defaultEsign;
          this.signatureImg = res.eSignature == null ? this.defaultImage : res.eSignature;
          (document.getElementById('esign') as HTMLImageElement).src = this.signatureImg;
          this.secondarySignature = res.secondarySign == null ? this.defaultImage : res.secondarySign;
          (document.getElementById('esign1') as HTMLImageElement).src = this.secondarySignature;
        } else {
          this.clinicalsummary = "";
          this.providerName = this.userName;
          this.signDate = moment.utc(new Date()).utcOffset(-offset).format("MM/DD/YYYY");
          this.signatureImg = this.defaultImage;
          (document.getElementById('esign') as HTMLImageElement).src = this.signatureImg;
          this.secondarySignature = this.defaultImage;
          (document.getElementById('esign1') as HTMLImageElement).src = this.secondarySignature;
        }
      },
      err => {
        this.spinnerservice.hide();
      },
    );
  }
  onSaveChmadetails(type) {
   // if (!this.isMovedChma) {
      if (this.signatureImg == undefined) {
        this.isReqeSign = true;
        return
      }
      if(this.providerName == "")
      {
        this.isReqName = true;
        return
      }
      this.lstCHMADetails.patientAccountId = Number(this.patientAccountId);
      this.lstCHMADetails.clinicalSummary = this.clinicalsummary;
      this.lstCHMADetails.providerName = this.providerName;
      this.lstCHMADetails.documents = this.UploadedFileList;
      //this.lstCHMADetails.signDate = moment(this.signDate).format("MM/DD/YYYY");
      this.lstCHMADetails.eSignature = this.signatureImg;
      this.lstCHMADetails.secondarySign = this.secondarySignature;
      this.spinnerservice.show();
      //const dataToEncrypt = this.commonService.DataToEncrypt(this.lstCHMADetails, false);
      this.patientservice.postChmaDeatils(this.lstCHMADetails).subscribe(
        (res: any) => {
          this.onSaveChmaStatus(type);
          this.snackbar.success(JSON.parse(res).message);


        },
        err => {
          this.spinnerservice.hide();
          this.snackbar.error(err.error);
        },
      );
   // } else {
    //  this.chma.onTabChange(7);
   // }


  }
  onSaveChmaStatus(type) {

    let _obj = {
      PatientAccountId: this.patientAccountId,
      FormType: 6,
      patientdetails:this.patientDetails,
      Type: type
    }
    // const dataToEncrypt = this.commonService.DataToEncrypt(_obj, false);
    this.patientservice.postChmaStatus(_obj).subscribe(
      (res: any) => {
        this.spinnerservice.hide();
        //this.chma.onbacktoMytask();
        this.chma.getChmaStatus();
        if (type == 1) {
          window.close()
        }
        else if(type != 2)  {
          this.chma.onTabChange(7);
        }
      },
      err => {
        this.spinnerservice.hide();
        this.snackbar.error(err.error);
      },
    );


  }
  openDialog() {
    // this.global.isconsentesign = false;

    // const dialogRef = this.dialog.open(ConsentEsignDialogComponent,
    //   { data: { parentinfoId: this.userId, isfutureSign: false, isParentsign: true },
    //   autoFocus: false 
    // });
    // dialogRef.afterClosed().subscribe(result => {
    //   this.isESignAttached = this.global.isconsentesign;
    //   if (this.global.isconsentesign) {
      this.global.isconsentesign = false;
      let isParentsign =false;
      if(this.AutoFillSS==true)
       {
         isParentsign=true;
    
       }
       const dialogRef = this.dialog.open(ConsentEsignDialogComponent,
         {
        data: { parentinfoId: this.userId, isfutureSign: undefined, isParentsign: isParentsign } ,
         autoFocus: false 
    
       });
        dialogRef.afterClosed().subscribe(result => {
          this.isESignAttached = this.global.isconsentesign;
          if (this.global.isconsentesign) {
            if(this.SSignature!='')
              {
              this.updatedsignatureImg=this.SSignature;
              }

        this.signatureImg = this.updatedsignatureImg;
        this.isAttachEsign = true;

      }
      else if (!this.global.isconsentesign && (this.global.formtype != null && this.global.formtype != undefined)) {
        this.signatureImg = this.global.consentesign;
        this.isformtype = this.global.formtype;
        this.isAttachEsign = true;

      }
      else {
        this.signatureImg = this.defaultImage;
      }
      (document.getElementById('esign') as HTMLImageElement).src = this.signatureImg;
    });
  }

  openDialogs() {
    // this.global.isconsentesign = false;

    // const dialogRef = this.dialog.open(ConsentEsignDialogComponent,
    //   { data: { parentinfoId: this.userId, isfutureSign: false, isParentsign: true },
    //   autoFocus: false 
    // });
    this.global.isconsentesign = false;
  let isParentsign =false;
  if(this.AutoFillSS==true)
   {
     isParentsign=true;

   }
   const dialogRef = this.dialog.open(ConsentEsignDialogComponent,
     {
    data: { parentinfoId: this.userId, isfutureSign: undefined, isParentsign: isParentsign } ,
     autoFocus: false 

   });
    dialogRef.afterClosed().subscribe(result => {
      this.isESignAttached = this.global.isconsentesign;
      if (this.global.isconsentesign) {
        if(this.SSignature!='')
          {
           this.updatedsecondarysignatureImg=this.SSignature;
          }
        this.secondarySignature = this.updatedsecondarysignatureImg;
        //this.isAttachEsign = true;

      }
      else if (!this.global.isconsentesign && (this.global.formtype != null && this.global.formtype != undefined)) {
        this.secondarySignature = this.global.consentesign;
        this.isformtype = this.global.formtype;
        this.isAttachEsign = true;

      }
      else {
        this.secondarySignature = this.defaultImage;
      }
      (document.getElementById('esign1') as HTMLImageElement).src = this.secondarySignature;
    });
  }

  toggleHelp(input, e: MouseEvent): void {
    input.helpOpen = !input.helpOpen;
    if ((e.target as HTMLElement).tagName == 'IMG') {
      var id = (e.target as HTMLElement).id;
      let toArray = id.split("_");
      this.openDialog();
      this.dynamicimgsrcid.push('Img_' + toArray[1]);
    }
  }
  onPrevcmhatab() {
    this.chma.onTabChange(5);
  }
  onFileChange(event) {
    const reader = new FileReader();
    var _validFileExtensions = [".csv", ".pdf", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", "application/vnd.ms-excel"];
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      if (file.type != '') {
        this.fileType = file.type;
      }
      else {
        var fileExt = file.name.split(".");
        this.fileType = fileExt[1];
      }
      if ((Array.from(event.target.files).length + this.UploadedFileList?.length) <= 3) {
        if (this.fileType == 'csv' || this.fileType == 'application/pdf' || this.fileType == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || this.fileType == 'application/vnd.ms-excel') {
          reader.readAsDataURL(file);

          reader.onload = () => {

            this.UploadFileList = [];
            var files = Array.from(event.target.files);
            for (var i = 0; i < files.length; i++) {

              this.UploadFileList.push(files[i]);

            }
            const frmData = new FormData();
            for (var i = 0; i < this.UploadFileList.length; i++) {
              frmData.append("doc", this.UploadFileList[i]);
            }
            frmData.append('DocumentTypeId', constantVariables.DocumentTypeId.TreatmentSummary);
            this.spinnerservice.show();
            this.patientservice.uploadChmaTreatmentSummaryDeatils(frmData).subscribe((res: any) => {

              if (res) {
                this.eletaskfile.nativeElement.value = "";
                this.spinnerservice.hide();
                if (this.UploadedFileList?.length == 0) {
                  this.UploadedFileList = res;
                } else {
                  this.UploadedFileList.push(...res);
                }

              }
            }, err => {
              this.spinnerservice.hide();
              this.snackbar.error(err.error);
            });
            this.eletaskfile.nativeElement.value = "";
          };
        } else {
          this.eletaskfile.nativeElement.value = "";
          this.snackbar.error(file.name + " is invalid, allowed extensions are: " + _validFileExtensions.join(", "))
        }
      } else {
        this.eletaskfile.nativeElement.value = "";
        this.snackbar.error("Maximum 3 files allowed")
      }
    }
  }

  deleteUploadImage(index) {
    this.UploadedFileList.splice(index, 1);
    this.eletaskfile.nativeElement.value = "";
  }

  downloadUploadedFile(item) {
    const link = document.createElement('a');
    link.setAttribute('target', '_blank');
    link.setAttribute('href', item.filePath);
    link.setAttribute('download', item.filename);
    document.body.appendChild(link);
    link.click();
    link.remove();

  }
  secondarySign():any{
    if(this.lstCHMADetails.licenseNumber != '' && this.lstCHMADetails.licenseNumber != null ){
      return false;
    }
    else if(this.lstCHMADetails.sSignature ){
     return false;
    }
    else{
      return true;
    }
  }
  getAutoFillSignature() {
    this.treatmentservice.getClinicianSign(this.userId).subscribe((res: any) => {  
        this.GetClinicianSign = res;
  
        if (this.GetClinicianSign.length > 0 && this.GetClinicianSign[0].clinicianSign !== '' && this.GetClinicianSign[0].clinicianSign.length > 0) {
          this.AutoFillSS=false;
          this.SSignature=this.GetClinicianSign[0].clinicianSign;
        }
        else
        {
          this.AutoFillSS=true;
          this.SSignature='';
        }
    });
  }
}
