<div class="discharge-form">
 
    <div  class="d-flex">
      <div  class="head-section mt-2 mb-2">
        DISCHARGE SUMMARY
      </div>
      <div  class="ml-auto">
      
          <!--  --> 
        <button class="btn primary-btn-filled primary-btn-m mb-2 h-40 mr-2" mat-stroked-button   (click)="OnTreatmentsummary()">{{DischargeSUmmaryInfo.treatmentSummaryId==0?'Create Treatment Summary':'Treatment Summary'}}</button> 
         <button class="btn primary-btn-filled primary-btn-m mb-2 h-40 mr-2" mat-stroked-button  (click)="OnpreviewClick()" *ngIf="roleAccess.isView">Preview</button>
        <button  tabindex="0" class="btn back-btn-new mr-2 h-40" (click)="OnBack()">
          <i  class="fas fa-chevron-left mr-2"></i>Back</button>
          <button class="btn generate-btn mr-2 pdf_color pdf" (click)="dischargepdf()" ><i class="far fa-file-pdf mr-2"></i>Generate
            PDF</button>
        </div>
      </div>
      <div class="col-md-12 bg-white p-2">
        <form (ngSubmit)="DisSumFrm.form.valid " id="DisSumFrm" autocomplete="off" #DisSumFrm="ngForm"
          novalidate>
          <div class="col-md-12">
            <div class="row">
              <div class="col-md-4">
                <ng-select appAlphabetOnly autocomplete="off" [items]="ddlpatientList" placeholder="Client Name *"
                bindLabel="patientName" bindValue="patientAccountId" addTagText="Add item" required (change)="onPatientchange($event)"
                [(ngModel)]="DischargeSUmmaryInfo.patientAccountId" addTagText="Add item" name="patientAccountId" #patientAccountId="ngModel"
               [ngClass]="{ 'invalid': DisSumFrm.submitted && patientAccountId.invalid }" required [ngClass]="{'disabled': DischargeSummaryId!=undefined }">
              </ng-select>
              <mat-error *ngIf="DisSumFrm.submitted && patientAccountId.invalid">
                <mat-error class="mat-form-field-subscript-wrapper" *ngIf="patientAccountId.errors.required">
                  Client Name is required</mat-error>
            </mat-error>
              <!--<mat-error *ngIf="IncReportfrm.submitted && PatientInfoId.invalid">
                <mat-error class="mat-form-field-subscript-wrapper" *ngIf="PatientInfoId.errors.required">
                  Client Name is required</mat-error>
            </mat-error>-->
                <!--<mat-form-field class="w-100">
                  <mat-label>Client’s Name</mat-label>
                  <input matInput>
                </mat-form-field>-->

              </div>
              <div class="col-md-2 dateicon-hide" [ngClass]="{'disabled': true }">
                <mat-form-field class="w-100">
                  <mat-label>DOB</mat-label>
                  <input matInput [matDatepicker]="picker"  [(ngModel)]="DischargeSUmmaryInfo.dob" #dob="ngModel" name="dob" 
                   [ngClass]="{ 'is-invalid': DisSumFrm.submitted && dob.invalid }"
                  required>
                  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                  <mat-datepicker #picker></mat-datepicker>
                  <mat-error *ngIf="DisSumFrm.submitted && dob.invalid">
                    <mat-error  *ngIf="dob.errors.required">
                        DOB is required</mat-error>
                </mat-error>
                </mat-form-field>
              </div>
              <div class="col-md-4" [ngClass]="{'disabled': true }">
               <!-- <mat-form-field class="w-100" >
                  <mat-label>Clinician’s Name</mat-label>
                  <input matInput>
                </mat-form-field>-->
                <ng-select appAlphabetOnly placeholder="Clinician *" [items]="ddlstafflist" bindLabel="staffName" 
                bindValue="userId"   [(ngModel)]="DischargeSUmmaryInfo.clinicianId" addTagText="Add item"
                name="clinicianId" #clinicianId="ngModel" >
              </ng-select>
              </div>
              <div class="col-md-2">
                <mat-form-field class="w-100">
                  <mat-label>Date</mat-label>
                  <input matInput [matDatepicker]="Dispicker"  [(ngModel)]="DischargeSUmmaryInfo.dsDate" #dsDate="ngModel" name="dsDate"
                  [ngClass]="{ 'is-invalid': DisSumFrm.submitted && dsDate.invalid }"
                  required>
                  <mat-datepicker-toggle matSuffix [for]="Dispicker"></mat-datepicker-toggle>
                  <mat-datepicker #Dispicker></mat-datepicker>
                  <mat-error *ngIf="DisSumFrm.submitted && dsDate.invalid">
                    <mat-error  *ngIf="dsDate.errors.required">
                      Date is required</mat-error>
                </mat-error>
                </mat-form-field>
              </div>
            </div>
          </div>
          <div class="col-md-12">
            <mat-radio-group aria-label="Select an option" [(ngModel)]="Isdischarge" name="Isdischarge" required>
              <mat-radio-button [value]="1" class="mr-2" (click)="onDischargeType(1)">Discharge</mat-radio-button>
              <mat-radio-button [value]="2" (click)="onDischargeType(2)">Dropout</mat-radio-button>
            </mat-radio-group>
            <mat-error *ngIf="DisSumFrm.submitted && Isdischarge==undefined">
              <mat-error class="text-xs" *ngIf="Isdischarge==undefined">
                Discharge Type is required</mat-error>
          </mat-error>
          </div>
          <div class="col-md-12 " *ngIf="DischargeSUmmaryInfo.isDischarge == true">
            <mat-form-field class="w-100 custom-textarea">
              <mat-label>Enter the details for follow-up</mat-label>
              <textarea matInput [(ngModel)]="DischargeSUmmaryInfo.dischargeDetails" #dischargeDetails="ngModel" name="dischargeDetails" row="3" maxlength="1500"
              [ngClass]="{ 'is-invalid': DisSumFrm.submitted && dischargeDetails.invalid }"
              required></textarea>
              <mat-error *ngIf="DisSumFrm.submitted && dischargeDetails.invalid">
                <mat-error  *ngIf="dischargeDetails.errors.required">
                  This field is required</mat-error>
            </mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-12 " *ngIf="DischargeSUmmaryInfo.isDropOut == true">
            <mat-form-field class="w-100 custom-textarea">
              <mat-label>Provide a reason for dropout</mat-label>
              <textarea matInput [(ngModel)]="DischargeSUmmaryInfo.dropoutReason" #dropoutReason="ngModel" name="dropoutReason" row="3" maxlength="1500"
              [ngClass]="{ 'is-invalid': DisSumFrm.submitted && dropoutReason.invalid }"
              required></textarea>
              <mat-error *ngIf="DisSumFrm.submitted && dropoutReason.invalid">
                <mat-error  *ngIf="dropoutReason.errors.required">
                  This field is required</mat-error>
            </mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-12"  [ngClass]="{'disabled': DischargeSummaryId!=undefined }">
            <div class="custom-head mt-3 mb-2"><h2>Diagnosis</h2></div>
            <div  class="basic-info diag-sec mb-3 row">
              <div  class="col-md-4">
                <mat-form-field class="w-100">
                  <mat-label>Chooose the Diagnosis Code for the Patient</mat-label>
                  <mat-select multiple #DCode [(ngModel)]="DischargeSUmmaryInfo.diagnosisCode" #diagnosisCode="ngModel"
                  name="diagnosisCode" required [ngClass]="{ 'is-invalid': DisSumFrm.submitted && diagnosisCode.invalid }">

                  <mat-option #matOption *ngFor="let item of ddldiagnosiscode" [value]="item.value"
                    (click)="onDiagnosisCodeselect(item,matOption.selected)" [matOption.selected]="item.isActive">
                    {{item.value}}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="DisSumFrm.submitted && diagnosisCode.invalid">
                  <mat-error  *ngIf="diagnosisCode.errors.required">
                    This field is required</mat-error>
              </mat-error>
                </mat-form-field>
              </div>
              <!--<div  class="col-md-8">
                <div class="chip-container-content ">
                  <mat-chip-list aria-label="Fish selection">
                    <mat-chip>F90.2 - Attention-deficit hyperactivity disorder, combined type </mat-chip>
                 
                    <mat-chip  selected>F41.9-Anxiety disorder, unspecified </mat-chip>
                    <mat-chip  selected>F84.0-Autistic disorder </mat-chip>
                  </mat-chip-list>
                </div>
              </div>-->
              <div class="col-md-8" *ngIf="DischargeSUmmaryInfo.diagnosisCode?.length > 0">
                <div class="chip-container-content">
                  <mat-chip-list aria-label="Fish selection">
                    <ng-container *ngFor="let item of ddldiagnosiscode">
                      <mat-chip *ngIf="item.isActive">{{item.label}}
                        <!--<mat-icon matChipRemove (click)="onRemoveDiagnosisCode(item)">cancel</mat-icon>-->
                      </mat-chip>
                    </ng-container>
                  </mat-chip-list>
                </div>
              </div>
              </div>
          </div>
          <div class="col-md-12">
            <!--<div class="custom-head mt-3 mb-2"><h2>Medication</h2></div>
            <div class="col-md-12 mt-3 mb-3 p-0">
              <div class="medication-dosage">
                <div  class="row inputElement">
                  <div  class="col-md-3">
                    <label >Medications</label>
                  </div>
                  <div  class="col-md-3">
                    <label >Dosage</label>
                  </div>
                  <div  class="col-md-3">
                    <label>Frequency</label>
                  </div>
                  <div  class="col-md-3">
                    <label >Side effects</label>
                  </div>
                </div>
                <div  class="loop-section position-relative mb-2 ">
                  <div class="row inputElement">
                    <div  class="col-md-3">
                      <input  maxlength="50" placeholder="Medicine" type="text" required="" class="form-control "  >
                    </div>
                    <div  class="col-md-3">
                      <input  maxlength="50" placeholder="Dosage" type="text" required="" class="form-control "  >
                    </div>
                    <div  class="col-md-3">
                      <input  maxlength="50" placeholder="Frequency" type="text" required="" class="form-control "  >
                    </div>
                    <div  class="col-md-3">
                      <input  maxlength="50" placeholder="Side effects" type="text" required="" class="form-control "  >
                    </div>
                  </div>
                  <a  class="add " style="cursor: pointer;">
                    <i  class="fa fa-lg fa-plus-circle"></i><span  class="sr-only">Add</span>
                  </a>
                  <a  class="remove" style="cursor: pointer;">
                    <i  class="fa fa-lg fa-minus-circle"></i>
                    <span  class="sr-only">Remove</span>
                  </a>
                </div>
              </div>
            </div>-->
            <div class="medication-dosage">
              <div class="row inputElement" *ngIf="_dtoMedications.length > 0 ">
                <div class="col-md-3"><label>Medications</label></div>
                <div class="col-md-3"><label>Dosage</label></div>
                <div class="col-md-3"><label>Frequency</label></div>
                <div class="col-md-3"><label>Side Effects</label></div>
              </div>
              <div class="loop-section position-relative mb-2"
                *ngFor="let med of _dtoMedications;let i= index;let last=last">
                <div class="row  inputElement">
                  <div class="col-md-3">
                    <input class="form-control" maxlength="50" [(ngModel)]="med.medications" #medications="ngModel"
                      name="medications{{i}}" placeholder="Medicine" type="text" >
                
                  </div>
                  <div class="col-md-3"><input class="form-control" maxlength="30" [(ngModel)]="med.dosage"
                      #dosage="ngModel" name="dosage{{i}}" placeholder="Dosage" type="text" >
                  
                  </div>
                  <div class="col-md-3">
                    <select class="w-100 form-control d-inline-block status-filter" placeholder="Dosage"
                      [(ngModel)]="med.frequency" #frequency="ngModel" name="frequency{{i}}" >
                      <option value="null">--Select Frequency--</option>
                      <option [ngValue]=1>Daily</option>
                      <option [ngValue]=2>Weekly</option>
                      <option [ngValue]=3>Monthly</option>
                    </select>
                  </div>
                  <!--<div class="col-md-3">
                    <input class="form-control" maxlength="50" [(ngModel)]="med.frequency" #medications="ngModel"
                      name="frequency{{i}}" placeholder="Frequency" type="text" required>
                   </div>-->
                  <div class="col-md-3"><input class="form-control" maxlength="50" [(ngModel)]="med.sideEffects"
                      #sideEffects="ngModel" name="sideEffects{{i}}" placeholder="Side Effects" type="text">
                  </div>
                </div>
                <a class="add" style="cursor: pointer;" *ngIf="last && (roleAccess.isEdit || roleAccess.isCreate)" (click)="addMedicationInfo()"><i
                  class="fa fa-lg fa-plus-circle"></i><span class="sr-only">Add</span></a>
              <a class="remove" style="cursor: pointer;"  (click)="removeclinicalinformation(med,i)" *ngIf="roleAccess.isEdit || roleAccess.isCreate"><i
                  class="fa fa-lg fa-minus-circle"></i><span class="sr-only">Remove</span></a>
              </div>
              <div class="col-md-12 set1-temp" *ngIf="_dtoMedications.length == 0">
                <div class=" col-md-12 row">
                    <div class="col-md-6 p-0">
                        <label class="additional">Medication history not available, would you like to
                            add?</label>
        
                    </div>
                    <div class="col-md-4 p-0">
                        <button type="button" (click)="addMedicationInfo()" mat-stroked-button *ngIf="roleAccess.isEdit || roleAccess.isCreate"
                            class="btn primary-btn-filled primary-btn-m mb-2 h-40 mr-2">Add</button>
                    </div>
        
                </div>
            </div>
            </div>
          </div>
          
          <div class="col-md-12">
            <div class="custom-head mt-3 mb-3"><h2>Course of Treatment</h2></div>
            <div class="col-md-12 p-0">
              <div class="row">
                <div class="col-md-3">
                  <mat-form-field class="w-100 ">
                    <mat-label>Start Date of Services</mat-label>
                    <input matInput [matDatepicker]="Startpicker" [(ngModel)]="DischargeSUmmaryInfo.startDate" #startDate="ngModel" name="startDate"
                    [ngClass]="{ 'is-invalid': DisSumFrm.submitted && startDate.invalid }" required [max]="StartDateRestriction">
                    <mat-datepicker-toggle matSuffix [for]="Startpicker"></mat-datepicker-toggle>
                    <mat-datepicker #Startpicker></mat-datepicker>
                      <mat-error *ngIf="DisSumFrm.submitted && startDate.invalid">
                        <mat-error  *ngIf="startDate.errors.required">
                          Start Date of Services is required</mat-error>
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="col-md-3">
                  <mat-form-field class="w-100 ">
                    <mat-label> Termination of Services</mat-label>
                    <input matInput [matDatepicker]="Terminatepicker" [(ngModel)]="DischargeSUmmaryInfo.terminateDate" #terminateDate="ngModel" name="terminateDate"
                    [ngClass]="{ 'is-invalid': DisSumFrm.submitted && terminateDate.invalid }" required [min]="DischargeSUmmaryInfo.startDate">
                    <mat-datepicker-toggle matSuffix [for]="Terminatepicker"></mat-datepicker-toggle>
                    <mat-datepicker #Terminatepicker></mat-datepicker>
                    <mat-error *ngIf="DisSumFrm.submitted && terminateDate.invalid">
                      <mat-error  *ngIf="terminateDate.errors.required">
                        Termination Date of Service is required</mat-error>
                  </mat-error>
                  </mat-form-field>
                </div>
              </div>
             
            </div>
            <div class="col-md-12 p-0">
              <div class="treat-label">
                Presenting Problem
              </div>
            
                <textarea class="w-100 form-control mb-3" [(ngModel)]="DischargeSUmmaryInfo.presentingProblem" #presentingProblem="ngModel" name="presentingProblem" row="3" maxlength="1500"
                [ngClass]="{ 'is-invalid': DisSumFrm.submitted && presentingProblem.invalid }" required> ____ presents with the following symptoms and behaviors:</textarea>
                <mat-error *ngIf="DisSumFrm.submitted && presentingProblem.invalid">
                  <mat-error class="text-xs" *ngIf="presentingProblem.errors.required">
                    Presenting Problem is required</mat-error>
              </mat-error>
            </div>
            <div class="col-md-12 p-0">
              <div class="treat-label">
                Objectives of Treatment
              </div>
                <textarea row="3" maxlength="1500" class="w-100 form-control mb-3" [(ngModel)]="DischargeSUmmaryInfo.objectives" #objectives="ngModel" name="objectives" 
                [ngClass]="{ 'is-invalid': DisSumFrm.submitted && objectives.invalid }" required>
                    Objectives of treatment are as follows:
                      Goal #1.
                      Goal #2.
                </textarea>
                <mat-error *ngIf="DisSumFrm.submitted && objectives.invalid">
                  <mat-error class="text-xs" *ngIf="objectives.errors.required">
                    Objectives of treatment is required</mat-error>
              </mat-error>
            </div>
            <div class="col-md-12 p-0">
              <div class="treat-label">
                Significant Findings
              </div>
              <textarea  row="3" maxlength="1500" class="w-100 form-control mb-3" [(ngModel)]="DischargeSUmmaryInfo.significantFindings" #significantFindings="ngModel" name="significantFindings"
              [ngClass]="{ 'is-invalid': DisSumFrm.submitted && significantFindings.invalid }" required>
              Over the course of treatment counselors observed
              </textarea>
              <mat-error *ngIf="DisSumFrm.submitted && significantFindings.invalid">
                <mat-error class="text-xs"  *ngIf="significantFindings.errors.required">
                  Significant Findings is required</mat-error>
            </mat-error>
            </div>
            <div class="col-md-12 p-0">
              <div class="treat-label">
                Clinical Condition at Termination
              </div>
              <textarea row="3" maxlength="1500" class="w-100 form-control mb-3" [(ngModel)]="DischargeSUmmaryInfo.clinicalCondition" #clinicalCondition="ngModel" name="clinicalCondition"
              [ngClass]="{ 'is-invalid': DisSumFrm.submitted && clinicalCondition.invalid }" required>
At termination ____ presented with the following symptoms:
              </textarea>
              <mat-error *ngIf="DisSumFrm.submitted && clinicalCondition.invalid">
                <mat-error class="text-xs" *ngIf="clinicalCondition.errors.required">
                  Clinical Condition at Termination is required</mat-error>
            </mat-error>
            </div>
            <div class="col-md-12 p-0">
              <div class="treat-label">
                Interventions in Treatment
              </div>
              <textarea row="3" maxlength="1500" class="w-100 form-control mb-3" [(ngModel)]="DischargeSUmmaryInfo.treatmentInterventions" #treatmentInterventions="ngModel" name="treatmentInterventions"
              [ngClass]="{ 'is-invalid': DisSumFrm.submitted && treatmentInterventions.invalid }" required>
_____ attended the IOP program at Stepping Forward Counseling Center # of days per week from _____ to ____ . With this, ____ was placed in a small group of peers (6-18) around her age. ____ was placed on a token economy-based behavior modification program which provided reinforcements when ____ met enough of ____ treatment goals throughout the group. Additional interventions included: implementation of coping skills, movement therapy, relaxation training (deep breathing), social skills training, mindfulness, cognitive behavior therapy, to increase ___ flexibility and increase self-esteem and help support relationship building. Finally, ___ was provided with praise, validation, as well as visual, physical and verbal prompts in order to encourage ____ to work on ___ specific treatment goals.
              </textarea>
              <mat-error *ngIf="DisSumFrm.submitted && treatmentInterventions.invalid">
                <mat-error class="text-xs"  *ngIf="treatmentInterventions.errors.required">
                  Interventions in treatment is required</mat-error>
            </mat-error>
            </div>
            <div class="col-md-12 p-0">
              <div class="treat-label">
                Treatment Provided/Response
              </div>
              <textarea row="3" maxlength="1500" class="w-100 form-control mb-3" [(ngModel)]="DischargeSUmmaryInfo.treatmentProvided" #treatmentProvided="ngModel" name="treatmentProvided"
              [ngClass]="{ 'is-invalid': DisSumFrm.submitted && treatmentProvided.invalid }" required>
The treatment provided for ___ was group therapy through behavior modification. ____ was involved in interpersonal effectiveness, social skills training, distress tolerance and life skills training. With the treatment provided ____ made mild progress toward both treatment goals. Further, ___ mildly improved peer engagement and taking responsibility for ___ actions and utilization of coping skills. ___ continued to present with fragile self-esteem and poor social skills.
              </textarea>
              <mat-error *ngIf="DisSumFrm.submitted && treatmentProvided.invalid">
                <mat-error class="text-xs" *ngIf="treatmentProvided.errors.required">
                  Treatment Provided/Response is required</mat-error>
            </mat-error>
            </div>
            <div class="col-md-12 p-0">
              <div class="treat-label">
                Reason for Termination
              </div>
              <textarea  row="3" maxlength="1500" class="form-control w-100 mb-3" [(ngModel)]="DischargeSUmmaryInfo.terminationReason" #terminationReason="ngModel" name="terminationReason"
              [ngClass]="{ 'is-invalid': DisSumFrm.submitted && terminationReason.invalid }" required>
Parents enrolled ___ for ___ weeks of IOP. ___ was terminated upon parent request. </textarea>
                <mat-error *ngIf="DisSumFrm.submitted && terminationReason.invalid">
                  <mat-error class="text-xs" *ngIf="terminationReason.errors.required">
                    Reason for termination is required</mat-error>
                </mat-error>
            </div>
            <div class="col-md-12 p-0">
              <div class="custom-head mt-3 mb-3"><h2>Discharge Plan</h2></div>
              <div class="treat-label">
                Recommendation for Further Need for Treatment
              </div>
            
                <textarea  row="3" maxlength="1500" class="form-control w-100 mb-3" [(ngModel)]="DischargeSUmmaryInfo.dischargePlan" #dischargePlan="ngModel" name="dischargePlan"
                [ngClass]="{ 'is-invalid': DisSumFrm.submitted && dischargePlan.invalid }" required>
 It is recommended that ____ continue to work on </textarea>
  <mat-error *ngIf="DisSumFrm.submitted && dischargePlan.invalid">
    <mat-error class="text-xs" *ngIf="dischargePlan.errors.required">
      Discharge Plan is required</mat-error>
  </mat-error>
            </div>
            <div class="col-md-12 p-0">
              <div class="treat-label">
                After-Care Treatment Recommendations
              </div>
              <textarea row="3" maxlength="1500" class="w-100 form-control mb-3" [(ngModel)]="DischargeSUmmaryInfo.treatmentRecommendations" #treatmentRecommendations="ngModel" name="treatmentRecommendations"
              [ngClass]="{ 'is-invalid': DisSumFrm.submitted && treatmentRecommendations.invalid }" required>
- Receive communication skills training focused on engaging with peers effectively (social skills groups)
              </textarea>
              <mat-error *ngIf="DisSumFrm.submitted && treatmentRecommendations.invalid">
                <mat-error class="text-xs" *ngIf="treatmentRecommendations.errors.required">
                  After-Care Treatment Recommendations is required</mat-error>
              </mat-error>
            </div>
            <div class="col-md-12 p-0">
              <div class="treat-label">
                Follow-up Activities and Interventions Planned
              </div>
              <textarea row="3" maxlength="1500" class="w-100 form-control mb-3" [(ngModel)]="DischargeSUmmaryInfo.followup" #followup="ngModel" name="followup"
              [ngClass]="{ 'is-invalid': DisSumFrm.submitted && followup.invalid }" required>
                </textarea>
                <mat-error *ngIf="DisSumFrm.submitted && followup.invalid">
                  <mat-error class="text-xs" *ngIf="followup.errors.required">
                    Follow-up activities and interventions planned is required</mat-error>
                </mat-error>
            </div>
            <div class="col-md-12 p-0">
              <div class="treat-label">
                Comments
              </div>
              <textarea  row="3" maxlength="1500" class="w-100 form-control mb-3" [(ngModel)]="DischargeSUmmaryInfo.comments" #comments="ngModel" name="comments"
              [ngClass]="{ 'is-invalid': DisSumFrm.submitted && comments.invalid }" required>
                </textarea>
                <mat-error *ngIf="DisSumFrm.submitted && comments.invalid">
                  <mat-error class="text-xs" *ngIf="comments.errors.required">
                    Comments is required</mat-error>
                </mat-error>
            </div>
            <div class="col-md-12 p-0">
              <div  class="form-group clearfix m-0">
                <div class="float-left col-md-4 p-0 mt-3 pt-1">
                  <label  class="treat-label w-100">Clinician's Signature</label>
                  <img  id="esign_3" height="50px" width="300px">
                  <button  type="button" class="btn primary-btn-filled primary-btn-m h-40 mr-2 mt-2" (click)="openDialog()" > Add E-Sign </button>
                  <div class="float-left col-md-6 p-0 mt-3 pt-1" >
                    <!-- <mat-error *ngIf="DisSumFrm.submitted && DischargeSUmmaryInfo.clinicianSignature == undefined">
                      <mat-error class="mat-form-field-subscript-wrapper" *ngIf="DischargeSUmmaryInfo.clinicianSignature == undefined">
                        Clinician's Signature is required</mat-error>
                  </mat-error> -->
                       </div>
                
                </div>
                <div class="float-left col-md-4 p-0 mt-3 pt-1">
                  <label  class="treat-label w-100">Secondary Signature</label>
                  <img  id="esign_1" height="50px" width="300px">
                  <button  type="button" class="btn primary-btn-filled primary-btn-m h-40 mr-2 mt-2" [disabled]="secondarySign()" (click)="openDialog2()" > Add E-Sign </button>
                  <div class="float-left col-md-6 p-0 mt-3 pt-1" >
                    <!-- <mat-error *ngIf="DisSumFrm.submitted && DischargeSUmmaryInfo.clinicianSignature == undefined">
                      <mat-error class="mat-form-field-subscript-wrapper" *ngIf="DischargeSUmmaryInfo.clinicianSignature == undefined">
                        Clinician's Signature is required</mat-error>
                  </mat-error> -->
                       </div>
                
                </div>
                <div  class="float-right col-md-2 p-0">
                  <mat-form-field class="w-100 ">
                    <mat-label>Date </mat-label>
                    <input matInput [matDatepicker]="picker3" [(ngModel)]="DischargeSUmmaryInfo.clinicianSignDate" #clinicianSignDate="ngModel" name="clinicianSignDate">
                    <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
                    <mat-datepicker #picker3></mat-datepicker>
                  </mat-form-field>
                  </div>
                </div>
            </div>

           
            <div class="col-md-12" *ngIf="Isedit || DischargeSummaryId==undefined">
              <div class="text-right">
              <button class="btn primary-btn-filled primary-btn-m mb-2 h-40 mr-2" mat-stroked-button type="submit" form="DisSumFrm" *ngIf="roleAccess.isEdit || roleAccess.isCreate" (click)="PostDisSummaryFrm()">{{Isedit?'Update':'Save'}}</button>
              <button class="btn btn-cancel primary-btn-m mb-2 h-40 mr-2" mat-stroked-button  *ngIf="roleAccess.isEdit || roleAccess.isCreate" (click)="OnBack()">Cancel</button>
            </div>
            </div>
          </div>
        </form>
      </div>
   
  </div>
      <!--Preview popup-->
      <div class="Activity-popup">
        <div class="modal"  tabindex="-1" role="dialog" [style.display]="PopUpPreview? 'block':'none'" >
           <div class="modal-dialog modal-lg" role="document">
              <div class="modal-content">
                 <div class="modal-header">
                    <h6 class="modal-title">Preview</h6>
                    <button type="button" class="close"  data-dismiss="modal" (click)="OnclosePopup()"
                    aria-label="Close" >
                    <span aria-hidden="true">&times;</span>
                 </button>
                 </div>
                 <div class="modal-body">
                  <div class="individual-threaphy-container">
                  <div  class="history-list col-md-12 p-0">
                    <!--<div class="row top-details-psych">
                      <div class="col-md-3"><label>Client Name</label>
                      <p>{{_dtoPatientInfo.patientName }}</p></div>
                      <div class="col-md-3"><label>Psychiatrist Name</label>
                        <p>{{_dtoPatientInfo.clinicianName}}</p></div>
                        <div class="col-md-3"><label> Start Date</label>
                          <p>{{_dtoPatientInfo.startDate | date:'MM/dd/YYYY'}}</p></div>
                          <div class="col-md-3"><label> Start Time</label>
                            <p>{{_dtoPatientInfo.startTime | date:'hh:mm a'}}</p></div>                         
                     </div>-->

                     <div class="row" >
                      <div class="col-md-12 mb-2  ">
                         <label class="control-label-new font-weight-bold" > Client Name </label>
                     <div class="cli_value_family">
                      <span>{{PatientName}}</span>
                    </div>
                  </div>
                </div>
                <div class="row" >
                  <div class="col-md-12 mb-2  ">
                     <label class="control-label-new font-weight-bold" > DOB </label>
                 <div class="cli_value_family">
                  <span>{{DischargeSUmmaryInfo.dob | date:'MM/dd/YYYY' }}</span>
                </div>
              </div>
            </div>

                    <div class="row" >
                      <div class="col-md-12 mb-2  ">
                        <label class="control-label-new font-weight-bold" > Clinician </label>
                    <div class="cli_value_family">
                      <span>{{ClinicianName[0]?.staffName }}</span>
                    </div>
                  </div>
                </div>

                <div class="row" >
                  <div class="col-md-12 mb-2  ">
                    <label class="control-label-new font-weight-bold" > Date </label>
                <div class="cli_value_family">
                  <span>{{DischargeSUmmaryInfo.dsDate | date:'MM/dd/YYYY' }}</span>
                </div>
              </div>
            </div>
              <div class="row" *ngIf="DischargeSUmmaryInfo.isDischarge == true">
                <div class="col-md-12 mb-2  ">
                  <label class="control-label-new font-weight-bold" > Discharge </label>
              <div class="cli_value_family">
                <span>{{DischargeSUmmaryInfo.isDischarge == true?'Yes':DischargeSUmmaryInfo.isDischarge == false?'No':''}}</span>
              </div>
            </div>
          </div>
              <div class="row" *ngIf="DischargeSUmmaryInfo.isDropOut == true">
                <div class="col-md-12 mb-2  ">
                  <label class="control-label-new font-weight-bold" > Dropout </label>
              <div class="cli_value_family">
                <span>{{DischargeSUmmaryInfo.isDropOut == true?'Yes':DischargeSUmmaryInfo.isDropOut == false?'No':''}}</span>
              </div>
            </div>
          </div>
          <div class="row" *ngIf="DischargeSUmmaryInfo.isDischarge == true">
            <div class="col-md-12 mb-2  ">
              <label class="control-label-new font-weight-bold" > Enter the details for follow-up </label>
          <div class="cli_value_family">
            <span>{{DischargeSUmmaryInfo.dischargeDetails}}</span>
          </div>
        </div>
      </div>
            <div class="row" *ngIf="DischargeSUmmaryInfo.isDropOut == true">
              <div class="col-md-12 mb-2  ">
                <label class="control-label-new font-weight-bold" > Provide a reason for dropout </label>
            <div class="cli_value_family">
              <span>{{DischargeSUmmaryInfo.dropoutReason}}</span>
            </div>
          </div>
        </div>
              <div class="row" >
                <div class="col-md-12 mb-2  ">
                  <label class="control-label-new font-weight-bold" > Diagnosis </label>
              <div class="cli_value_family">
                <!--<span>{{DischargeSUmmaryInfo.diagnosisCode}}</span>-->
                <ng-container *ngFor="let item of ddldiagnosiscode; let i=index">
                  <span class="cli_value_family"  *ngIf="item.isActive">
                    <span >{{item.label}} <small>,</small></span>
                  </span>
                  </ng-container> 
              </div>
            </div>
          </div>
    <div class="row">
      <div class="col-md-12 mb-2  ">
      <div class="medication-dosage">
        <div class="row inputElement">
          <div class="col-md-3"><label>Medications</label></div>
          <div class="col-md-3"><label>Dosage</label></div>
          <div class="col-md-3"><label>Frequency</label></div>
          <div class="col-md-3"><label>Side Effects</label></div>
        </div>
        <div class="loop-section position-relative mb-2"
          *ngFor="let med of _dtoMedications;let i= index;let last=last">
          <div class="row  inputElement">
            <div class="col-md-3">    
              <div class="cli_value_family">
                <span>{{med.medications}}</span>
              </div>
            </div>
            <div class="col-md-3">
            <div class="cli_value_family">
              <span>{{med.dosage}}</span>
            </div>
            </div>
            <div class="col-md-3">
              <div class="cli_value_family">
              <span>{{med.frequency == 1 ? 'Daily' :med.frequency == 2 ? 'Weekly' :med.frequency==3? 'Monthly':''
              }}</span>
              </div>
            </div>
            <!--<div class="col-md-3">
              <input class="form-control" maxlength="50" [(ngModel)]="med.frequency" #medications="ngModel"
                name="frequency{{i}}" placeholder="Frequency" type="text" required>
            
            </div>-->
            <div class="col-md-3">
              <div class="cli_value_family">
                <span>{{med.sideEffects}}</span>
              </div>
            </div>
          </div>
     </div>
     </div>
     </div>
     </div>

          
        <div class="row" >
          <div class="col-md-12 mb-2  ">
            <div class="custom-head mt-3 mb-3"><h2>Course of Treatment</h2></div>
      </div>
    </div>
    <div class="row" >
      <div class="col-md-12 mb-2  ">
        <label class="control-label-new font-weight-bold" > Start Date of Services </label>
    <div class="cli_value_family">
      <span>{{DischargeSUmmaryInfo.startDate | date:'MM/dd/YYYY' }}</span>
    </div>
  </div>
</div>

    <div class="row" >
      <div class="col-md-12 mb-2  ">
        <label class="control-label-new font-weight-bold" > Termination of Services</label>
    <div class="cli_value_family">
      <span>{{DischargeSUmmaryInfo.terminateDate | date:'MM/dd/YYYY' }}</span>
    </div>
  </div>
</div>
    <div class="row" >
      <div class="col-md-12 mb-2  ">
        <label class="control-label-new font-weight-bold" > Presenting Problem </label>
    <div class="cli_value_family">
      <span>{{DischargeSUmmaryInfo.presentingProblem }}</span>
    </div>
  </div>
  </div>

  <div class="row" >
    <div class="col-md-12 mb-2  ">
      <label class="control-label-new font-weight-bold" > Objectives of Treatment</label>
  <div class="cli_value_family">
    <span>{{DischargeSUmmaryInfo.objectives }}</span>
  </div>
</div>
</div>

<div class="row" >
  <div class="col-md-12 mb-2  ">
    <label class="control-label-new font-weight-bold" > Significant Findings</label>
<div class="cli_value_family">
  <span>{{DischargeSUmmaryInfo.significantFindings }}</span>
</div>
</div>
</div>
<div class="row" >
  <div class="col-md-12 mb-2  ">
    <label class="control-label-new font-weight-bold" > Clinical Condition at Termination</label>
<div class="cli_value_family">
  <span>{{DischargeSUmmaryInfo.clinicalCondition }}</span>
</div>
</div>
</div>
<div class="row" >
  <div class="col-md-12 mb-2  ">
    <label class="control-label-new font-weight-bold" > Interventions in Treatment</label>
<div class="cli_value_family">
  <span>{{DischargeSUmmaryInfo.treatmentInterventions }}</span>
</div>
</div>
</div>
<div class="row" >
  <div class="col-md-12 mb-2  ">
    <label class="control-label-new font-weight-bold" > Treatment Provided/Response</label>
<div class="cli_value_family">
  <span>{{DischargeSUmmaryInfo.treatmentProvided }}</span>
</div>
</div>
</div>
<div class="row" >
  <div class="col-md-12 mb-2  ">
    <label class="control-label-new font-weight-bold" > Reason for Termination</label>
<div class="cli_value_family">
  <span>{{DischargeSUmmaryInfo.terminationReason }}</span>
</div>
</div>
</div>
<div class="row" >
  <div class="col-md-12 mb-2  ">
    <div class="custom-head mt-3 mb-3"><h2>Discharge Plan</h2></div>
</div>
</div>
<div class="row" >
  <div class="col-md-12 mb-2  ">
    <label class="control-label-new font-weight-bold" > Recommendation for Further Need for Treatment</label>
<div class="cli_value_family">
  <span>{{DischargeSUmmaryInfo.dischargePlan }}</span>
</div>
</div>
</div>
<div class="row" >
  <div class="col-md-12 mb-2  ">
    <label class="control-label-new font-weight-bold" > After-Care Treatment Recommendations</label>
<div class="cli_value_family">
  <span>{{DischargeSUmmaryInfo.treatmentRecommendations }}</span>
</div>
</div>
</div>
<div class="row" >
  <div class="col-md-12 mb-2  ">
    <label class="control-label-new font-weight-bold" >Follow-up Activities and Interventions Planned</label>
<div class="cli_value_family">
  <span>{{DischargeSUmmaryInfo.followup }}</span>
</div>
</div>
</div>
<div class="row" >
  <div class="col-md-12 mb-2  ">
    <label class="control-label-new font-weight-bold" > Comments</label>
<div class="cli_value_family">
  <span>{{DischargeSUmmaryInfo.comments }}</span>
</div>
</div>
</div>
<div class="row" >
  <div class="col-md-12 mb-2  ">
   <label class="control-label-new font-weight-bold" >Clinician's Signature</label>
  <div class="cli_value_family">
  <span><img  id="esign_prev" height="50px" width="300px" src="{{DischargeSUmmaryInfo.clinicianSignature}}" /></span>
  </div>
  </div>
  </div>
 
            <!---->
              </div> 
      
              </div> 
                   </div>
              </div>
            </div>
          </div>
        </div> 
        <a mimetype="application/octet-stream" #downloadLink></a> 
