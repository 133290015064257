import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { constantVariables } from 'src/app/constant/constant';
import { Global } from 'src/app/global/global.model';
import { PageAccessService } from 'src/app/rolemanagement/PageRoleAccessService';
import { SnackBarService } from 'src/app/services/snackBar.service';
import { ClinicianService } from 'src/app/staff/clinician/clinician.service';
import { TreatmentService } from 'src/app/staff/treatment/treatment.service';
import { dtoReScheduleModality } from 'src/app/_models/_Treatment/scheduleModality';
import { AdminTherapyPopUpObj } from 'src/app/_models/_Treatment/_TherapyPopUpObj';
import { CommonService } from '../common.service';
import { SettingsService } from '../pages/settings/settings.service';
import { FormControl } from '@angular/forms';
import { SchedulerCalenderComponent } from '../pages/scheduler-calender/scheduler-calender.component';
import { ScheduleModalityComponent } from 'src/app/staff/treatment/schedule-modality/schedule-modality.component';

@Component({
  selector: 'app-schedule-modality-pop-up',
  templateUrl: './schedule-modality-pop-up.component.html',
  styleUrls: ['./schedule-modality-pop-up.component.scss']
})
export class ScheduleModalityPopUpComponent implements OnInit {
  @Input() ObjTherapyPopUp:AdminTherapyPopUpObj;
  @ViewChild('scheduler') scheduler = {} as TemplateRef<any>
  searchpatientName:any;
  dtoScheduleModality: dtoReScheduleModality;
  IspastTime:boolean=false;
  meetingTimeslots: any;
  currenttime: string;
  PopUpReschedule: boolean;
  currentForm: any;
  currentdate: string | number | Date;
  ddlStafflist: any;
  LocationId: any;
  ddlMeetingType: any;
  ddlMeetinglocation: any;
  ddlSfccLocation: any;
  ddlpatientList: any;
  ddlTherapy: any;
  ddlFrequency: any;
  dtoTreatmentDiagnosis: any;
  _dtoModality: any;
  _dtoTreatmentInfo: any;
  scheduleModalityList: any;
  errConflicts: boolean;
  dtoScheduleConflictModalitylist: any;
  dateList: any[];
  patient: any;
  therapyName: string;
  scheduleModalityId: any;
  durationhr:any;
  durationmin:any;
  IsDurationInvalid: boolean;
  isScheduleOrBlock:boolean=false;
  isSchedule:boolean=false;
  isBlockTime:boolean =false;
  purposeOfBlockTime:any;
  date =  new Date();
  checkList = [];
  minDate:any;
  isFormSubmitted:boolean=false;
  cityCtrl= new FormControl();

  filteredClientName: any[] | undefined;
  isShowTherapydetails : boolean;
  conflictpopup : boolean = false;
  dtoScheduleConflictModalitylists: any;
  isViewModality : boolean = false;
  currentTime: string;
  selectedDay: any = [];
  IsMonthly : boolean =false;
  secondaryId : any;
  clinicianidforpopup : any;
  ddlSfccLocationforclinician : any;
  constructor(public global: Global, public settingsService: SettingsService, private cdr: ChangeDetectorRef,
    private snackbar: SnackBarService, private route: ActivatedRoute, private router: Router, private dialog: MatDialog, public commonService: CommonService, public clinicianservice: ClinicianService, public spinnerservice: NgxSpinnerService, public treatmentservice: TreatmentService,
    public datepipe: DatePipe, public roleaccessservice:PageAccessService,public schedulerCalenderComponent : SchedulerCalenderComponent) { 
      this.cityCtrl = new FormControl();

    }

  ngOnInit(): void {
    this.minDate = new Date(new Date().setDate(new Date().getDate() - 2));
    this.dtoScheduleModality = new dtoReScheduleModality();
    this.isScheduleOrBlock = true;
    this.currenttime = '00.00';
    this.UpdateDetails();
    this.LoadMasters()
   
    
  }

//   filterClients(event: any) {
//     let filtered: any[] = [];
//     let query = event.query;

//     for (let i = 0; i < (this.ddlpatientList as any[]).length; i++) {
//         let Clients = (this.ddlpatientList as any[])[i];
//         if (Clients.patientName.toLowerCase().indexOf(query.toLowerCase()) == 0) {
//             filtered.push(Clients);
//         }
//     }

//     this.filteredClientName = filtered;
// }
filterClients(searchText: string) {
  if (!searchText) {
    this.filteredClientName = this.ddlpatientList; // Assuming this.clientNames contains all client names
    return;
  }

  this.filteredClientName = this.ddlpatientList.filter(client => {
    // Filter logic, you can adjust this based on your requirements
    return client.patientName.toLowerCase().includes(searchText.toLowerCase());
  });
}

  LoadMasters(){
    let locationId=+localStorage.getItem('sfcclocation')
    this.getPatientlist(locationId);
    this.getStaffList();
    this.getMeetingLocation();
    this.getddlLocation();
    this.getddlLocationforclinician();
    this.GetMeetingTypeMaster();
    this.getSchedulerTimeSlots();
    this.GetBlocKTimeMaster();  
  }   
  UpdateDetails() {
       
    let date = '2021-05-28'
    this.PopUpReschedule = true;
    this.dtoScheduleModality.provider = this.ObjTherapyPopUp.ClinicianId;
    this.dtoScheduleModality.startDate =this.ObjTherapyPopUp.scheduleDate;
    //this.dtoScheduleModality.startTime = null;
    this.dtoScheduleModality.startTime = this.ObjTherapyPopUp.fromtime != null ? moment(date).format("YYYY-MM-DD") + " " + moment(this.ObjTherapyPopUp.fromtime).format('HH:mm')+":00.000" : null;
   // console.log(this._dtoPatientInfo.startTime)
    this.dtoScheduleModality.location = this.ObjTherapyPopUp.SfccMasterId;


    // var d = new Date(this.dtoScheduleModality.startDate);
    // this.dtoScheduleModality.startDate  = d.setMinutes( d.getMinutes() + d.getTimezoneOffset() );
// This will return an ISO string matching your local time.
if(this.ObjTherapyPopUp.View === 1)
{
  const dateFormatChange = new Date(this.dtoScheduleModality.startDate)
    this.dtoScheduleModality.startDate = new Date(dateFormatChange.getFullYear(), dateFormatChange.getMonth(), dateFormatChange.getDate(), dateFormatChange.getHours(), dateFormatChange.getMinutes() - dateFormatChange.getTimezoneOffset()).toISOString();
}
    
  }
  getSchedulerTimeSlots() {
    this.settingsService.getSchedulerTimeSlots().subscribe(
      res => {
        if (res != null) {
          this.meetingTimeslots = res;
          this.cdr.detectChanges();  
  
        }
      },
      err => {
        this.spinnerservice.hide();
      },
    );
  }
  
OnTimeChange(){
  if(new Date()<this.dtoScheduleModality.startDate){
    this.currenttime = '00.00'
  }else{
    this.currenttime = moment().format("HH:mm");
  }
  if(moment(this.dtoScheduleModality.startDate).format("MM/DD/YYYY") == moment(this.date).format("MM/DD/YYYY")){
    if( moment(this.dtoScheduleModality.startTime).format("HH:mm")>this.currenttime ){
      this.IspastTime=false;
      }
      else{
        this.IspastTime = true;
      }
  }else{
    this.IspastTime=false;
  }
}

OnCancelReschedule(){
  this.commonService.ScheduleModality.next(false);
 
}

handleStartDatechange(event) {
  const m: moment.Moment = event.value;

   // console.log("Date of Birth: " + m.toDate());
   // console.log(this.currentdate)
    if(this.datepipe.transform(m.toDate(),"yyyy-MM-dd")==this.datepipe.transform(this.currentdate,"yyyy-MM-dd")){
      this.currenttime = moment().format("HH:mm");
    }
    else{
      this.currenttime = '00.00'
    }
    this.OnTimeChange();
}

getStaffList()
{
this.commonService.getStaffListV2(constantVariables.role.PrimaryClinician,this.ObjTherapyPopUp.SfccMasterId).subscribe(
    (res: any) => {
      if (res) {
        this.ddlStafflist = res;  
        this.cdr.detectChanges();  
        var client =  this.ddlStafflist.filter(x=>x.staffId==this.dtoScheduleModality.provider)[0];
        //console.log(this.ddlStafflist);
        
        //console.log(client);        
        this.ObjTherapyPopUp.clinicianName = client?.firstName + " " +client?.lastName;
        }   
      },
    err => {
      this.spinnerservice.hide();
    },
  );
}
  
GetMeetingTypeMaster(){
  this.spinnerservice.show();
  this.treatmentservice.GetMeetingTypeMaster().subscribe(
    (res: any) => {
      this.spinnerservice.hide();
      if (res.length>0){
        this.ddlMeetingType=res;
        this.ddlMeetingType = this.ddlMeetingType.filter(x=>x.commonAppointmentTypeid ==0)
      // console.log(this._dtoPatientInfo);
      }
    },
    err => {
      this.spinnerservice.hide();
    },
  );
}

getMeetingLocation() {
  this.spinnerservice.show();
  let locationId=+localStorage.getItem('sfcclocation')
  this.settingsService.getMeetingRoom(locationId).subscribe(
    (res: any) => {
      
      this.spinnerservice.hide();
      // this.ddlMeetinglocation = this.commonService.decrypdata(res);
      this.ddlMeetinglocation = res.filter(x=>x.isActive != false);
      
      this.cdr.detectChanges();  
      // this.getAllScheduleModality();
    },
    error =>
      this.spinnerservice.hide()
  );
}
getddlLocation() {
  this.spinnerservice.show();
  this.treatmentservice.getLocations().subscribe(
    (res) => {
      this.spinnerservice.hide();
      if (res != null) {
        this.ddlSfccLocation = res;
        this.cdr.detectChanges();  
      }
    },
    (err) => {
      this.spinnerservice.hide();
    }
  );
}
getPatientlist(locationId:any){
  this.spinnerservice.show();
  this.secondaryId = localStorage.getItem('id');
  this.treatmentservice.getPatientListforDS(-1,locationId,this.secondaryId).subscribe(res=>{
  this.ddlpatientList = res;
  this.spinnerservice.hide();
  },
  err => {
    this.spinnerservice.hide();

  });
}

getTreatmentPlanMaster() {
  this.spinnerservice.show();
  this.treatmentservice.getTreatmentPlanMaster().subscribe(
    (res: any) => {
      this.spinnerservice.hide();

      this.ddlTherapy = this.commonService.decrypdata(res.therapy);
     // this.ddlFrequency = this.commonService.decrypdata(res.frequency);
      //To get diagnosis info once Therapy is got
      //console.log(this.ddlTherapy)
   // this.getTreatmentPlanDiagnosis();
    },
    err => {
      this.spinnerservice.hide();

    },
  );
}

getTreatmentPlandetails(Patientaccountid) {
  var patient = this.ddlpatientList.filter(x=>x.patientAccountId== Patientaccountid)[0].chmaTreatmentSummaryId;
   
  this.spinnerservice.show();

  this.treatmentservice.getTreatmentPlanList(patient).subscribe(
    (res: any) => {
      this.spinnerservice.hide();
      if (res != null) {
        this._dtoTreatmentInfo = res[0];
        //console.log(this._dtoTreatmentInfo)
       // this.patientInfoId = this._dtoTreatmentInfo.patientInfoId;
       // this.treatmentPlanDate = this._dtoTreatmentInfo.treatmentPlanDate;
      //  this.IntakeStartTme = this._dtoTreatmentInfo.fromTime;
      //  this.IntakeEndTime = this._dtoTreatmentInfo.toTime;
       // var enddate = moment(this.treatmentPlanDate).add(90, 'days').toDate();
       // this.treatmentenddate = enddate;
       // this.dtoScheduleModality.endDate = this.treatmentenddate;

      }
    },
    err => {
      this.spinnerservice.hide();

    },
  );
}

getPatientId(event : any ){
  var patientID = this.filteredClientName.find(e => e.patientName == event.option.value).patientAccountId;
  this.dtoScheduleModality.patientAccountId=patientID;
  this.getModalityForSchedule(this.dtoScheduleModality.patientAccountId)
}

getModalityForSchedule(patientaccountid) {  
  if(patientaccountid=='undefined'){
    this.dtoScheduleModality.patientAccountId=undefined;
    return;
  }
  this.patient = this.ddlpatientList.filter(x=>x.patientAccountId== patientaccountid)[0];
  this.spinnerservice.show();
   this.treatmentservice.getTreatmentModalityForSchedule(this.patient.chmaTreatmentSummaryId).subscribe(
    (res: any) => {
      this.spinnerservice.hide();
      if (res != null) {
        this._dtoModality = res;
        this._dtoModality = this._dtoModality.filter(x=>x.therapyId!=3 && x.therapyId!=16 && x.therapyId !=5 && x.therapyId != 6)
        // this._dtoModality = this._dtoModality.filter(x=>x.therapyId!=3 || x.therapyId==14 || x.therapyId==15)
        // this._dtoModality = this._dtoModality.filter(x=> (x.therapyId==4 && x.meetingScheduleId==0)|| (x.therapyId==7 && x.meetingScheduleId==0) || (x.therapyId==8 && x.meetingScheduleId==0) || (x.therapyId==1 || x.therapyId==2 || x.therapyId==11 || x.therapyId==12) || x.therapyId==14 || x.therapyId==15);
        this.cdr.detectChanges();
      }
    },
    err => {
      this.spinnerservice.hide();

    },
  );
}

getConflictsModality(){
  var selectedDateTimeforBlockTime = moment(this.dtoScheduleModality.startDate).format("MM/DD/YYYY HH:mm");
  var CurrentDateTime = moment(this.date).format("MM/DD/YYYY HH:mm")

  if(!this.isBlockTime){
  if(this.dtoScheduleModality.location==undefined || this.dtoScheduleModality.patientAccountId==undefined||this.dtoScheduleModality.provider==undefined||
    this.dtoScheduleModality.meetingRoom==undefined||this.dtoScheduleModality.meetingType==undefined){
    return;
  }
}
  if( (moment(this.dtoScheduleModality.startTime).format("HH:mm")<this.currenttime &&  moment(this.dtoScheduleModality.startDate).format("MM/DD/YYYY") <= moment(this.date).format("MM/DD/YYYY"))|| (this.durationhr==undefined || this.durationmin==undefined) || (Number(this.durationhr)==0 && Number(this.durationmin)==0)){
    // if(moment(this.dtoScheduleModality.startTime).format("HH:mm")<this.currenttime){
   if(selectedDateTimeforBlockTime < CurrentDateTime){
    this.IspastTime=true;
   }
   if((Number(this.durationhr)==0 && Number(this.durationmin)==0)){
    this.IsDurationInvalid=true;
   }
    return
  }
  var offset = new Date().getTimezoneOffset();
  this.scheduleModalityList = [];
  this.dtoScheduleConflictModalitylist = []
  let starttime = moment(this.dtoScheduleModality.startTime).format("HH:mm");
  let startTime = moment(this.dtoScheduleModality.startTime); 
  let newTime = startTime; 
  let formattedTime = newTime.add(1, 'minutes').format("HH:mm");
  let newToTime = moment(starttime,'HH:mm').add(((Number(this.durationhr)+Number(this.durationmin - 1))), 'minute').format('HH:mm A')

  let _obj = {
    StartDate: moment.utc(this.dtoScheduleModality.startDate).format("MM/DD/YYYY"),
    EndDate:moment.utc(this.dtoScheduleModality.startDate).format("MM/DD/YYYY"),
    FromTime: formattedTime,
    ToTime: newToTime,
    Provider: this.dtoScheduleModality.provider
  }
  this.spinnerservice.show();
  const dataToEncrypt = this.commonService.DataToEncrypt(_obj, false);
  this.treatmentservice.getConfilctsForModalityScheduler(dataToEncrypt).subscribe(
    (res: any) => {
      this.dtoScheduleConflictModalitylist = JSON.parse(res);
      this.spinnerservice.hide();
      if(this.dtoScheduleConflictModalitylist.length==0){
        this.scheduleModalityList =[]
        this.scheduleModalityList.push({
          // day: data[i].day,
          scheduleDate: moment(this.dtoScheduleModality.startDate).utc().format('MM/DD/YYYY'),
          isChecked: false,
          fromTime: moment(this.dtoScheduleModality.startTime).format("HH:mm"),
          toTime:moment(starttime,'HH:mm').add(((Number(this.durationhr)+Number(this.durationmin))), 'minute').format('HH:mm A'),
          isConflict: false})
          if(this.isBlockTime){
            this.postBlockTime();
          }else{
            this.postSchduleModality();
          }
        
      }
      else{
        this.dateList = [];
      //  this.dateList = this.getDaysBetweenDates(moment(this.dtoScheduleModality.startDate).utcOffset(-offset), moment(this.dtoScheduleModality.endDate).utcOffset(-offset));
        this.onBetweenappointment(this.dtoScheduleConflictModalitylist);
        this.skipAllConflicts();
      }
    },
    err => {
      this.spinnerservice.hide();

    },
  );
}
getDayBWDates(start, end, dayName, lstday) {
  var result = [];
  for (let i = 0; i < lstday.length; i++) {
    var days = { sun: 0, mon: 1, tue: 2, wed: 3, thu: 4, fri: 5, sat: 6 };
    var day = days[dayName.toLowerCase().substr(0, 3)];
    var current = new Date(start.toDate());
    current.setDate(current.getDate() + (lstday[i] - current.getDay() + 7) % 7);
    while (current <= end.toDate()) {
      result.push(new Date(+current));
      current.setDate(current.getDate() + 7);
    }
  }
  return result;
}

onBetweenappointment(data) {
  this.scheduleModalityList = [];
  let starttime = moment(this.dtoScheduleModality.startTime).format("HH:mm");
  for (let i = 0; i < data.length; i++) {
    this.scheduleModalityList.push({
      // day: data[i].day,
      scheduleDate: moment(data[i].scheduleDate).format('MM/DD/YYYY'),
      isChecked: false,
      fromTime: moment(this.dtoScheduleModality.startTime).format("HH:mm"),
      toTime: moment(starttime,'HH:mm').add((Number(this.durationhr)+Number(this.durationmin)), 'minute').format('HH:mm A'),
      isConflict: this.getIsConflict(data[i].scheduleDate),
      // location: data[i].location,
      // locationName: this.getLocationName(data[i].location),
    });
  }




}


getDaysBetweenDates(startDate, endDate) {
  // var now = startDate.clone();
  let betweendates = [];
  while (startDate.isSameOrBefore(endDate)) {
    betweendates.push(new Date(startDate));
    startDate.add(1, 'days');
  }
  return betweendates;
};

skipAllConflicts() {
  this.errConflicts = false;
  
    if(this.isBlockTime){
      this.checkList = this.scheduleModalityList.filter((item) => item.isConflict == true );
      if(this.checkList.length==0){
      this.postBlockTime();
      }  else{
        var name =this.ddlStafflist.filter(x=>x.staffId ==this.dtoScheduleModality.provider)[0]
        this.snackbar.error(name.firstName +' '+name.lastName + constantVariables.SuccessMessage.FailedToReschedule + moment(this.dtoScheduleModality.startDate).format('MM/DD/YYYY') + " " + moment(this.dtoScheduleModality.startTime).format("hh:mm a"))
        //this.snackbar.error(this.ObjTherapyPopUp.clinicianName + constantVariables.SuccessMessage.FailedToReschedule + moment(this.dtoScheduleModality.startDate).format('MM/DD/YYYY') + " " + moment(this.dtoScheduleModality.startTime).format("HH:mm"))
      }
    }else{
      this.checkList = this.scheduleModalityList.filter((item) => item.isConflict == true );
      if(this.checkList.length==0){
      this.postSchduleModality();
      } else{
        var name =this.ddlStafflist.filter(x=>x.staffId ==this.dtoScheduleModality.provider)[0]
        this.snackbar.error(name.firstName +' '+name.lastName + constantVariables.SuccessMessage.FailedToReschedule + moment(this.dtoScheduleModality.startDate).format('MM/DD/YYYY') + " " + moment(this.dtoScheduleModality.startTime).format("hh:mm a"))
      }
    }
   
    // this.checkList = this.scheduleModalityList.filter((item) => item.isConflict == true);
    // if(this.scheduleModalityList.length==0){
    //   if(this.isBlockTime){
    //     this.postBlockTime();
    //     }  else{
    //       this.scheduleModalityList = this.scheduleModalityList.filter((item) => item.isConflict != true);
    //       this.postSchduleModality();
    //     }
    //   }else{
    //     this.snackbar.error(this.ObjTherapyPopUp.clinicianName + constantVariables.SuccessMessage.FailedToReschedule + moment(this.dtoScheduleModality.startDate).format('MM/DD/YYYY') + " " + moment(this.dtoScheduleModality.startTime).format("HH:mm"))
    //   }

}

getIsConflict(conflictDate: any) {
  let isConflictlist = this.dtoScheduleConflictModalitylist.filter((item) => item.isConflict == true);
  for (let i = 0; i < isConflictlist.length; i++) {
    let firstDate = moment(isConflictlist[i].scheduleDate).format('MM/DD/YYYY');
    let secondDate = moment(conflictDate).format('MM/DD/YYYY');
    if ((firstDate == secondDate) && isConflictlist[i].isConflict == true) {

      return true;
    }
    else{
      return false;
    }
  }

}
postBlockTime(){
  let checkconfilcts = this.scheduleModalityList.filter((item) => item.isConflict == true).length;
  if (checkconfilcts > 0) {
   this.errConflicts = true;
   return;
 }
 else{
   let obj;
     this.dtoScheduleModality.duration = Number(this.durationhr) + Number(this.durationmin);
     let starttime = moment(this.dtoScheduleModality.startTime).format("HH:mm");
     obj = {
       BlockTimeId: this.dtoScheduleModality.blockTimeId,
       ClinicianId:this.dtoScheduleModality.provider,
       StartTime: moment(this.dtoScheduleModality.startTime).format("HH:mm"),
       EndTime: moment(starttime,'HH:mm').add((Number(this.durationhr)+Number(this.durationmin)), 'minute').format('HH:mm A'),
       Notes: this.dtoScheduleModality.notes,
       ScheduleDate: moment(this.dtoScheduleModality.startDate).format('MM/DD/YYYY'),
       Duration: this.dtoScheduleModality.duration,
       LocationId: this.dtoScheduleModality.location
     }
 this.spinnerservice.show();
 const dataToEncrypt = this.commonService.DataToEncrypt(obj, false);
 this.treatmentservice.SaveBlockTimeDetails(dataToEncrypt).subscribe(
   (res: any) => {
     this.spinnerservice.hide();
     this.snackbar.success(JSON.parse(res).message);
     window.location.reload();
   },
   err => {
     this.spinnerservice.hide();
     this.snackbar.error(err.error);
   },
 );
}
}

postSchduleModality() {
  let checkconfilcts = this.scheduleModalityList.filter((item) => item.isConflict == true).length;
   if (checkconfilcts > 0) {
    this.errConflicts = true;
    return;
  }
  else{
    let obj;
      let MeetingTimesArray = [];
      this.dtoScheduleModality.duration = Number(this.durationhr) + Number(this.durationmin);
      this.scheduleModalityList[0].scheduleDate =  moment(this.dtoScheduleModality.startDate).utc().format('MM/DD/YYYY');
      MeetingTimesArray.push(this.scheduleModalityList[0])
      obj = {
        MeetingCategoryId: 1,
        MeetingScheduleId:0,
        MeetingLocationId: this.dtoScheduleModality.location,
        PatientAccountId: this.dtoScheduleModality.patientAccountId,
        PatientInfoId: this.dtoScheduleModality.patientInfoId,
        ScheduleName: this.therapyName + "-" + this.patient?.patientName,
        Provider: this.dtoScheduleModality.provider,
        MeetingTimes: MeetingTimesArray,
        ModalityDetails: this.dtoScheduleModality,
        MeetingTypeId: this.dtoScheduleModality.meetingType,
        AdminNotes:this.dtoScheduleModality.adminNotes
      }
  this.spinnerservice.show();
  const dataToEncrypt = this.commonService.DataToEncrypt(obj, false);
  this.treatmentservice.postSchduleModality(dataToEncrypt).subscribe(
    (res: any) => {
      this.spinnerservice.hide();
      this.snackbar.success(JSON.parse(res).message);
      this.isShowTherapydetails = false;
      this.schedulerCalenderComponent.loadAllAppointmentsNew();
      // window.location.reload();
    
    },
    err => {
      this.spinnerservice.hide();
      this.snackbar.error(err.error);
    },
  );
}

}

ModalityChange(therapyId){
  if(therapyId=="undefined"){
    this.dtoScheduleModality.therapyId=undefined;
    return;
  }
  this.therapyName = this._dtoModality.filter(x=>x.therapyId == therapyId)[0].therapyName;
  this.dtoScheduleModality.chmatreatmentSummaryId = this._dtoModality.filter(x=>x.therapyId == therapyId)[0].chmatreatmentSummaryId;
 // console.log(this.dtoScheduleModality.chmatreatmentSummaryId )
  this.scheduleModalityId = this._dtoModality.filter(x=>x.therapyId == therapyId)[0].meetingScheduleId;
  if(this.scheduleModalityId !=0){
    this.dtoScheduleModality.meetingRoom = this._dtoModality.filter(x=>x.therapyId == therapyId)[0].meetingRoom;
    this.dtoScheduleModality.provider = this._dtoModality.filter(x=>x.therapyId == therapyId)[0].provider;
  }
}
ScheduleAppointment(){
  this.isSchedule = true;
  this.isScheduleOrBlock = false;
}
BlockTime(){
  this.isBlockTime = true;
  this.isScheduleOrBlock = false;
  this.currenttime = moment().format("HH:mm");
  // this.dtoScheduleModality.startDate=moment(this.date).format("MM/DD/YYYY")
  // var client =  this.ddlStafflist.filter(x=>x.staffId==this.ObjTherapyPopUp.ClinicianId)[0];
  //       this.ObjTherapyPopUp.clinicianName = client.firstName + " " +client.lastName;
}
GetBlocKTimeMaster()
{
  this.spinnerservice.show();
  this.treatmentservice.getBlockTimeMaster().subscribe(
    (res: any) => {
      this.spinnerservice.hide();
      if(res!=null)
      {
        this.purposeOfBlockTime = res;
      }
    },
    err => {
      this.spinnerservice.hide();

    },
  );
}
getMeetingLocationById(locationId:any) {
  if(locationId==0){
    return;
  }
  this.dtoScheduleModality.location=locationId;
  this.getStaffListByLocationId(locationId);
  this.getPatientlist(locationId);

  // this.getPatientlist(-1);
  this.spinnerservice.show();
  this.ddlMeetinglocation=null;
  this.dtoScheduleModality.meetingRoom=undefined;
  this.settingsService.getMeetingRoom(locationId).subscribe(
    (res: any) => {
      this.spinnerservice.hide();
      this.ddlMeetinglocation = res.filter(x=>x.isActive != false);
      this.cdr.detectChanges();  
    },
    error =>
      this.spinnerservice.hide()
  );
}
getStaffListByLocationId(locationId:any)
{
this.commonService.getStaffListV2(constantVariables.role.PrimaryClinician,locationId).subscribe(
    (res: any) => {
      if (res) {
        this.ddlStafflist = res;  
        this.cdr.detectChanges();  
        this.dtoScheduleModality.provider= undefined;
        var client =  this.ddlStafflist.filter(x=>x.staffId==this.dtoScheduleModality.provider)[0];
        //console.log(this.ddlStafflist);
        
        //console.log(client);        
        this.ObjTherapyPopUp.clinicianName = client?.firstName + " " +client?.lastName;
        }   
      },
    err => {
      this.spinnerservice.hide();
    },
  );
}
modalityChange(item:any){
  if(item=='undefined'){
    this.dtoScheduleModality.provider=undefined;
  }
}
meetingRoomChange(item:any){
  if(item=='undefined'){
    this.dtoScheduleModality.meetingRoom=undefined;
  }
}
meetingTypeChange(item:any){
  if(item=='undefined'){
    this.dtoScheduleModality.meetingType=undefined;
  }
}
hoursChange(item:any){
  if(item=='undefined'){
    this.durationhr=undefined;
  }
  this.IsDurationInvalid = false;
}
minChange(item:any){
  if(item=='undefined'){
    this.durationmin=undefined;
  }
  this.IsDurationInvalid = false;
}

getSpecificWeekdayBlock(startDate, endDate, weekDaysOnMonth, weekOfMonth) {
  var occurrenceDates = [];
  var currentDate = new Date(startDate);
 
  while (currentDate <= endDate) {
      var currentMonth = currentDate.getMonth();
      while (currentDate.getMonth() === currentMonth) {
          if (weekDaysOnMonth.includes(currentDate.getDay()) && Math.ceil(currentDate.getDate() / 7) === weekOfMonth) {
              occurrenceDates.push(new Date(currentDate));
          }
          currentDate.setDate(currentDate.getDate() + 1);
      }
  }
 
  return occurrenceDates;
}
getddlLocationforclinician() {
  this.spinnerservice.show();
  this.clinicianidforpopup = localStorage.getItem("adminDayViewClinician");
  this.treatmentservice.getddlLocationforclinician(this.clinicianidforpopup).subscribe(
    (res) => {
      this.spinnerservice.hide();
      if (res != null) {
        this.ddlSfccLocationforclinician = res;
        this.cdr.detectChanges();  
      }
    },
    (err) => {
      this.spinnerservice.hide();
    }
  );
}

}
