export class TherapyPopObj{
    id:any;
    start:any;
    tags:any;
    patientName:string;
    email:string;
    contactNo:any;
    fromtime:any;
    AdminNotes:string;
    MeetingType:any;
    MeetingLink:any;
    scheduleDate:any;
    ScheduleName:string;
    OffSiteAddress:string;
    ScheduleDuration:string;
    SfccMasterId:any;
    GroupTherapyId : number;
    clinicianName:string;
    clinicianemail:string;
    clinicianContactNo:any;
    ScheduleTimeId:any;
    patientAccountId:any;
    Location:any;
    MeetingRoom:any;
    Duration:any;
    DayScheduleSubGroupId:any;
    DayScheduleAssignId:any;
    isPatientVisit:boolean;
    ClinicianId:any;
   endTime :any;
   AppointmentId:number;
   ImmediateActionId:number;
   Status:number;
   View:any;
   GroupTherapyLength:number;
}

export class AdminTherapyPopUpObj{
    id:any;
    start:any;
    tags:any;
    patientName:string;
    email:string;
    contactNo:any;
    fromtime:any;
    AdminNotes:string;
    MeetingType:any;
    MeetingLink:any;
    scheduleDate:any;
    ScheduleName:string;
    clinicianName:string;
    clinicianemail:string;
    clinicianContactNo:any;
    ScheduleTimeId:any;
    patientAccountId:any;
    Location:any;
    MeetingRoom:any;
    Duration:any;
    DayScheduleSubGroupId:any;
    DayScheduleAssignId:any;
    GroupTherapyId:any;
    isPatientVisit:boolean;
    ClinicianId:any;
    OffSiteAddress:string;
   ScheduleDuration:string;
   SfccMasterId:any;
   endTime :any;
   AppointmentId:number;
   ImmediateActionId:number;
   Status:number;
   View:any;
   GroupTherapyLength:number;
}

export class currentDatas{
    firstMedication : any;
firstReason : any;
secondMedication : any; 
secondReason: any;
thirdMedication: any;
thirdReason: any;
pstartDate : any;
pstartDates : any;
Meetingscheduleid : any;
signatureImg: any;
  updatedsignatureImg: any;
  isAttachEsign: boolean;
  defaultImage: any;
  ESignature : any;
  startdate : any;
  currentDatePhysician : any;
}
export class ReviewDto{
  PatientAccountId : any;
  Meetingscheduleid : any;
  Dob : any;
  CurrentDate : any;
  currentDate2 : any;
  currentDate3 : any;
  MedicationName : any;
  staffName:any;
  MedicationHelps : any;
  MedicationTime : any;
  TimesPerDay : any;
  AngerMoody : boolean = false;
  Depression : boolean = false;
  Adhd : boolean = false;
  Anxiety : boolean = false;
  Impulsivity : boolean = false;
  OtherSideEffectsangerMoody : any;
  Dizzy  : any;
  StomachHurts  : any;
  Sleepy  : any;
  Shaky  : any;
  OtherSideEffects  : any;
  OtherSpecify  : any;
  MedicationOnFeelGood  : any;
  MedicationImportant  : any;
  MedicationOnSummer  : any;
  MedicationOnTimeAction : any;
  ClinicianName:any;
}
export class IntakeUpdateDto{
    patientAccountId: number;
    date: any;
    behaviors: string;
    currentDiagnosis: string;
    currentMedications: string;
    currentsupport: string;
    schoolPlacement: string;
    homodicalorsuicidal: string;
    stressors: string;
    familySituation: string;
    hospitalizations: string;
    recommendedGoals: string;
    currenthobbies: string;
    strengths: string;
    clinicianName: string;
    signatureImg: string;
    clientname: string;
    dob: string;
    updatedOn: any;

}