<div class="cmha-meta-data">
    <!-- <div class="col-md-12">
        <div class="text-right">
            <button type="button" (click)="onnext()" mat-stroked-button
                class="btn primary-btn-filled primary-btn-m mb-2 h-40 mr-2">Save & Next</button>
           
        </div>
    </div> -->
    <div class="col-md-12 d-flex">
        <span *ngIf="global.patientUpdatedOn !=null" class="status-msg mr-3 mt-2 cursor-pointer text-underline">Last
            Updated on:
            <span>{{global.globalUtctimeformat(global.patientUpdatedOn) | date:
                'MM/dd/yyyy hh:mm a'}}</span></span>
                <span *ngIf="global.patientUpdatedByName !=null" class="status-msg mr-3 mt-2 cursor-pointer text-underline">Last
                    Updated By:
                    <span>{{global.patientUpdatedByName }}</span></span>
        <div class="text-right ml-auto">
            <!-- <button type="button" (click)="onnext(0)" mat-stroked-button
            class="btn primary-btn-filled primary-btn-m mb-2 h-40 mr-2">{{isMovedChma ? 'Next' : 'Save
            &
            Next'}}</button> -->
            <button type="button" (click)="onnext(0)" mat-stroked-button  *ngIf="roleAccess.isCreate||roleAccess.isEdit"
            class="btn primary-btn-filled primary-btn-m mb-2 h-40 mr-2">{{'Save
            &
            Next'}}</button>
            <!-- *ngIf="!isMovedChma" -->

        <button type="button" (click)="onnext(1)" mat-stroked-button  *ngIf="roleAccess.isCreate||roleAccess.isEdit"
            class="btn  primary-btn-outlined primary-btn-m mb-2 h-40 mr-2">Save & Exit</button>

        </div>
    </div>
    <!--Restrict after assigning clinician-->
    <!--[ngClass]="{'disabled':isMovedChma}"-->
    <div >
        <div class="lead-box pt-0">
            <div class="col-md-12">
               
                <div class="row">
                    <div class="col-md-6">
                        <div class="row text-right">
                            <a class="float-right ml-auto mr-3 font-15" style="cursor: pointer;" (click)="editPatientDetailsClick()">
                                <i class="mr-1 fa fa-edit"></i>Edit Client Details
                            </a>
                        </div>
                        <form id="frmPatientDetails" autocomplete="off" #frmPatientDetails="ngForm" [ngClass]="{'disabled':!isEditPatientDetails}">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group row">
                                    <label for="staticEmail" class="col-md-12 col-form-label">First Name</label>
                                    <div class="col-md-12 form-control-pf-editable">

                                        <div class="form-control-pf-value">
                                            <input type="text" #clientFirstName="ngModel"
                                            placeholder="First Name" name="clientFirstName"
                                            appAlphabetOnly maxlength="50"
                                            [(ngModel)]="lstPatientDetails[0].clientFirstName"
                                            class="form-control form-control-pf-editor w-100" autocomplete="off"
                                            aria-label="name" required/>
                                            <mat-error *ngIf="isPatientDetailsFormSubmitted && clientFirstName.invalid">
                                                <mat-error *ngIf="clientFirstName.control.errors.required">
                                                    First Name is required
                                                </mat-error>
                                            </mat-error>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group row">
                                    <label for="staticEmail" class="col-md-12 col-form-label">Last Name</label>
                                    <div class="col-md-12 form-control-pf-editable ">

                                        <div class="form-control-pf-value">
                                            <input type="text" #clientLastName="ngModel"
                                            placeholder="Last Name" name="clientLastName"
                                            appAlphabetOnly maxlength="50"
                                            [(ngModel)]="lstPatientDetails[0].clientLastName"
                                            class="form-control form-control-pf-editor w-100" autocomplete="off"
                                            aria-label="name" required/>
                                            <mat-error *ngIf="isPatientDetailsFormSubmitted && clientLastName.invalid">
                                                <mat-error *ngIf="clientLastName.errors.required">
                                                    Last Name is required
                                                </mat-error>
                                            </mat-error>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group row">
                                    <label for="staticEmail" class="col-md-12 col-form-label">Parent's Name</label>
                                    <div class="col-md-12 form-control-pf-editable ">

                                        <div class="form-control-pf-value">

                                            <input type="text" #parentFirstName="ngModel"
                                            placeholder="Parent First Name" name="parentFirstName"
                                            appAlphabetOnly maxlength="50"
                                            [(ngModel)]="lstPatientDetails[0].parentFirstName" required
                                            class="form-control form-control-pf-editor w-100" autocomplete="off"
                                            aria-label="name" required/>
                                            <mat-error *ngIf="isPatientDetailsFormSubmitted && parentFirstName.invalid">
                                                <mat-error *ngIf="parentFirstName.errors.required">
                                                    Parent's First name is required
                                                </mat-error>
                                            </mat-error>
                                            
                                        </div>
                                        <div class="form-control-pf-value">

                                            <input type="text" #parentLastName="ngModel"
                                            placeholder="Parent Last Name" name="parentLastName"
                                            appAlphabetOnly maxlength="50"
                                            [(ngModel)]="lstPatientDetails[0].parentLastName" required
                                            class="form-control form-control-pf-editor w-100" autocomplete="off"
                                            aria-label="name" required/>
                                            <mat-error *ngIf="isPatientDetailsFormSubmitted && parentLastName.invalid">
                                                <mat-error *ngIf="parentLastName.errors.required">
                                                    Parent's last name is required
                                                </mat-error>
                                            </mat-error>
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group row">
                                    <label for="staticEmail" class="col-md-12 col-form-label">Client's DOB</label>
                                    <div class="col-md-12 form-control-pf-editable ">

                                        <div class="form-control-pf-value" >
                                        
                                            <input matInput [matDatepicker]="picker3"  [(ngModel)]="lstPatientDetails[0].dob" #dob="ngModel"
                                            name="dob" class="form-control w-75 d-inline-block" required>
                                            <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
                                            <mat-datepicker #picker3></mat-datepicker>
                                            <mat-error *ngIf="isPatientDetailsFormSubmitted && dob.invalid">
                                                <mat-error *ngIf="dob.errors.required">
                                                    Date Of Birth is required
                                                </mat-error>
                                            </mat-error>

                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group row">
                                    <label for="staticEmail" class="col-md-12 col-form-label">Grade</label>
                                    <div class="col-md-12 form-control-pf-editable ">

                                        <div class="form-control-pf-value">
                                            <mat-select class="form-control" [(ngModel)]="lstPatientDetails[0].gradeLevel" #GradeLevel="ngModel" required name="GradeLevel" >
                                            <mat-option *ngFor="let item of ddlgrade" [value]="item.MasterId">
                                              {{item.MasterName}}
                                            </mat-option>
                                          </mat-select>
                                          <mat-error *ngIf="isPatientDetailsFormSubmitted && GradeLevel.invalid">
                                              <mat-error *ngIf="GradeLevel.errors.required">
                                                  Grade is required
                                              </mat-error>
                                          </mat-error>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group row">
                                    <label for="staticEmail" class="col-md-12 col-form-label">School</label>
                                    <div class="col-md-12 form-control-pf-editable ">

                                        <div class="form-control-pf-value">
                                            <input class="form-control" type="text" #schoolName="ngModel"
                                            placeholder="School Name" name="schoolName"
                                            appAlphabetOnly maxlength="50"
                                            [(ngModel)]="lstPatientDetails[0].schoolName"
                                            class="form-control form-control-pf-editor w-100" autocomplete="off"
                                            aria-label="name" required/>

                                            <mat-error *ngIf="isPatientDetailsFormSubmitted && schoolName.invalid">
                                                <mat-error *ngIf="schoolName.errors.required">
                                                    School name is required
                                                </mat-error>
                                            </mat-error>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group row">
                                    <label for="staticEmail" class="col-md-12 col-form-label"> Street Address</label>
                                    <div class="col-md-12 form-control-pf-editable  ">

                                        <div class="form-control-pf-value">
                                            <input type="text" #streetAddress="ngModel"
                                            placeholder="Street Address" name="streetAddress"
                                            maxlength="50" [(ngModel)]="lstPatientDetails[0].streetAddress"
                                            class="form-control form-control-pf-editor w-100" autocomplete="off"
                                            aria-label="name" required/>
                                            <mat-error *ngIf="isPatientDetailsFormSubmitted && streetAddress.invalid">
                                                <mat-error *ngIf="streetAddress.errors.required">
                                                    Street address is required
                                                </mat-error>
                                            </mat-error>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="col-md-6">
                <div class="form-group row">
                  <label for="staticEmail" class="col-md-12 col-form-label"> Address2</label>
                  <div class="col-md-12 form-control-pf-editable  ">
                   
                    <div  class="form-control-pf-value">
                     
                      <span>Cecilia Chapman 711-2880 Nulla St.</span>
                     
                    </div>
                    </div>
                </div>
              </div> -->

                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group row">
                                    <label for="staticEmail" class="col-md-12 col-form-label">State</label>
                                    <div class="col-md-12 form-control-pf-editable ">

                                        <div class="form-control-pf-value">
                                            <mat-select class="form-control" [(ngModel)]="lstPatientDetails[0].stateId" #StatesId="ngModel" 
                                             name="StatesId" (selectionChange)="onStateChnage($event.value)" required>
                                            <mat-option *ngFor="let item of ddlStates" [value]="item.StatesId">
                                              {{item.StateName}}
                                            </mat-option>
                                          </mat-select>
                                          <mat-error *ngIf="isPatientDetailsFormSubmitted && StatesId.invalid">
                                              <mat-error *ngIf="StatesId.errors.required">
                                                  State is required
                                              </mat-error>
                                          </mat-error>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group row">
                                    <label for="staticEmail" class="col-md-12 col-form-label">City</label>
                                    <div class="col-md-12 form-control-pf-editable ">

                                        <div class="form-control-pf-value">
                                                <!-- <span class="form-control" *ngIf="!isEditPatientDetails" name="CityName" >{{CityName}}</span> -->

                                            <mat-select class="form-control"  placeholder="City"  [(ngModel)]="lstPatientDetails[0].cityId" #cityId="ngModel" name="cityId" required (selectionChange)="onChangeProviderinfoCity($event)">
                                                <!-- <cdk-virtual-scroll-viewport [itemSize]="35" [style.height.px]=5*35 > -->
                                                 <mat-option *ngFor="let item of ddlCitiesProviderinfo" [value]="item.CitiesId"
                                                  >{{item.CityName}}</mat-option>
                                                <!-- </cdk-virtual-scroll-viewport> -->
                                            </mat-select>
                                            <mat-error *ngIf="isPatientDetailsFormSubmitted && lstPatientDetails[0].cityId==0">
                                                <mat-error *ngIf="lstPatientDetails[0].cityId==0">
                                                    City is required
                                                </mat-error>
                                            </mat-error>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group row">
                                    <label for="staticEmail" class="col-md-12 col-form-label">Zip Code</label>
                                    <div class="col-md-12 form-control-pf-editable ">

                                        <div class="form-control-pf-value">
                                            <input  type="text" #zip="ngModel"
                                            placeholder="Zip Code" name="zip"
                                            maxlength="50" mask='00000' [(ngModel)]="lstPatientDetails[0].zip" required
                                            class="form-control form-control-pf-editor w-100" autocomplete="off"
                                            aria-label="name" required />
                                            <mat-error *ngIf="isPatientDetailsFormSubmitted && zip.invalid">
                                                <mat-error *ngIf="zip.errors.required">
                                                    Zip Code is required
                                                </mat-error>
                                            </mat-error>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">

                            </div>

                        </div>
                    </form>
                    </div>
                    <div class="col-md-6 Psychi-current-section">
                        <form (ngSubmit)="frmPsychiatrist.form.valid  && postfrmPsychiatrist(frmPsychiatrist)"
                            id="frmPsychiatrist" autocomplete="off" #frmPsychiatrist="ngForm" novalidate>
                                <div class="row"    >
                                   <div class="col-md-6">
                                    <div class="form-group row">
                                        <label for="staticEmail" class="col-md-12 col-form-label">Current
                                            Psychiatrist</label>
                                        <div class="col-md-12 form-control-pf-editable "
                                            [ngClass]="{'form-control-pf-edit':isEditPsychiatrist}">

                                        <div class="form-control-pf-value " *ngIf="!isEditPsychiatrist">
                                                <span class="sr-only">Edit Name: </span>
                                                <span>{{dtoChmaproviderPsychatric?.FirstName}}</span>
                                            </div> 
                                            <div class="form-control-pf-textbox w-100" *ngIf="isEditPsychiatrist">
                                                <input type="text" #psychiatristFirstName="ngModel"
                                                    placeholder="Psychiatrist Name" name="psychiatristFirstName"
                                                    appAlphabetOnly maxlength="50"
                                                    [(ngModel)]="dtoChmaproviderPsychatric.FirstName" required
                                                    class="form-control form-control-pf-editor w-100" autocomplete="off"
                                                    aria-label="name" />
                                                <mat-error
                                                    *ngIf="frmPsychiatrist.submitted && psychiatristFirstName.invalid">
                                                    <mat-error *ngIf="psychiatristFirstName.errors.required">
                                                        Psychiatrist
                                                        Name
                                                        is required
                                                    </mat-error>
                                                </mat-error>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group row">
                                        <label for="staticEmail" class="col-md-12 col-form-label">Phone Number</label>
                                        <div class="col-md-12 form-control-pf-editable "
                                            [ngClass]="{'form-control-pf-edit':isEditPsychiatrist}">
                                                
                                            <div class="form-control-pf-value" *ngIf="!isEditPsychiatrist">
                                                <span class="sr-only">Edit Name: </span>
                                                <span>{{dtoChmaproviderPsychatric?.PhoneNumber | mask: '(000)
                                                    000-0000'}}</span>
                                            </div> 
                                            <div class="form-control-pf-textbox w-100" *ngIf="isEditPsychiatrist">
                                                <input type="text" class="form-control form-control-pf-editor w-100"
                                                    placeholder="Phone" autocomplete="off" aria-label="name"
                                                    #PsychiatristPhone="ngModel" name="PsychiatristPhone"
                                                    mask='(000) 000-0000' required
                                                    [(ngModel)]="dtoChmaproviderPsychatric.PhoneNumber" />
                                                <mat-error
                                                    *ngIf="frmPsychiatrist.submitted && PsychiatristPhone.invalid">
                                                    <mat-error *ngIf="PsychiatristPhone.errors.required">Psychiatrist
                                                        phone
                                                        is required
                                                    </mat-error>
                                                </mat-error>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-2 p-0 mt-4 pt-3 d-flex">
                                    <button *ngIf="!isEditPsychiatrist" type="button" title="Edit"
                                        class="btn  form-control-pf-edit" (click)="onEditPsychiatrist()"
                                        aria-label="edit"><i class="fas fa-pencil-alt ml-2"></i></button>
                                    <button *ngIf="isEditPsychiatrist" type="submit" form="frmPsychiatrist" title="Save"
                                        class="btn form-control-pf-save" aria-label="Save"><i
                                            class="fas fa-check"></i></button>
                                    <button *ngIf="isEditPsychiatrist" type="button" title="Cancel"
                                        class="btn btn-default form-control-pf-cancel" aria-label="Cancel"
                                        (click)="onCancelPsychiatrist()"><i class="fas fa-times"></i></button>
                                </div>
                            </div>
                      
                        </form>
                        <form (ngSubmit)="frmPhysician.form.valid  && postfrmPhysician(frmPhysician)" id="frmPhysician"
                            autocomplete="off" #frmPhysician="ngForm" novalidate>
                            <div class="row" >

                                <div class="col-md-6">
                                    <div class="form-group row">
                                        <label for="staticEmail" class="col-md-12 col-form-label">Current
                                            Physicians</label>
                                        <div class="col-md-12 form-control-pf-editable"
                                            [ngClass]="{'form-control-pf-edit':isEditPhysicians}">

                                            <div class="form-control-pf-value" *ngIf="!isEditPhysicians">
                                                <span class="sr-only">Edit Name: </span>
                                                <span>{{dtoChmaproviderPhysian?.FirstName}}</span>
                                            </div>
                                            <div class="form-control-pf-textbox w-100" *ngIf="isEditPhysicians">
                                                <input type="text" #physicianFirstName="ngModel"
                                                    name="physicianFirstName" placeholder="Physicians Name"
                                                    [(ngModel)]="dtoChmaproviderPhysian.FirstName" required
                                                    appAlphabetOnly maxlength="50"
                                                    class="form-control form-control-pf-editor w-100" autocomplete="off"
                                                    aria-label="name" />
                                                <mat-error *ngIf="frmPhysician.submitted && physicianFirstName.invalid">
                                                    <mat-error *ngIf="physicianFirstName.errors.required">Physician name
                                                        is required
                                                    </mat-error>
                                                </mat-error>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group row">
                                        <label for="staticEmail" class="col-md-12 col-form-label">Phone Number</label>
                                        <div class="col-md-12 form-control-pf-editable"
                                            [ngClass]="{'form-control-pf-edit':isEditPhysicians}">

                                            <div class="form-control-pf-value" *ngIf="!isEditPhysicians">
                                                <span class="sr-only">Edit Name: </span>
                                                <span>{{dtoChmaproviderPhysian?.PhoneNumber | mask: '(000) 000-0000'}}</span>
                                            </div>
                                            <div class="form-control-pf-textbox w-100" *ngIf="isEditPhysicians">
                                                <input type="text" class="form-control form-control-pf-editor w-100"
                                                    placeholder="Phone" autocomplete="off" aria-label="name"
                                                    #physicianPhone="ngModel" name="physicianPhone"
                                                    mask='(000) 000-0000' required
                                                    [(ngModel)]="dtoChmaproviderPhysian.PhoneNumber" />
                                                <mat-error *ngIf="frmPhysician.submitted && physicianPhone.invalid">
                                                    <mat-error *ngIf="physicianPhone.errors.required">Psychiatrist phone
                                                        is required
                                                    </mat-error>
                                                </mat-error>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-2 p-0 mt-4 pt-3 d-flex">
                                    <button *ngIf="!isEditPhysicians" type="button" title="Edit"
                                        class="btn  form-control-pf-edit" (click)="onEditPhysican()"
                                        aria-label="edit"><i class="fas fa-pencil-alt ml-2"></i></button>
                                    <button *ngIf="isEditPhysicians" type="submit" form="frmPhysician" title="Save"
                                        class="btn  form-control-pf-save" aria-label="Save"><i
                                            class="fas fa-check"></i></button>
                                    <button *ngIf="isEditPhysicians" type="button" class="btn  form-control-pf-cancel"
                                        aria-label="Cancel" title="Cancel" (click)="onCancelPhysican()"><i
                                            class="fas fa-times"></i></button>
                                </div>


                            </div>
                        </form>
                        <form (ngSubmit)="frmTherapist.form.valid  && postfrmTherapist(frmTherapist)" id="frmTherapist"
                            autocomplete="off" #frmTherapist="ngForm" novalidate>
                            <div class="row">

                                <div class="col-md-6">
                                    <div class="form-group row">
                                        <label for="staticEmail" class="col-md-12 col-form-label">Current
                                            Therapist</label>
                                        <div class="col-md-12 form-control-pf-editable"
                                            [ngClass]="{'form-control-pf-edit':isEditTherapist}">

                                            <div class="form-control-pf-value" *ngIf="!isEditTherapist">
                                                <span class="sr-only">Edit Name: </span>
                                                <span>{{dtoChmaproviderTherapy?.FirstName}}</span>
                                            </div>
                                            <div class="form-control-pf-textbox w-100" *ngIf="isEditTherapist">
                                                <input type="text" #therapistFirstName="ngModel"
                                                    name="therapistFirstName" placeholder="Therapist Name"
                                                    [(ngModel)]="dtoChmaproviderTherapy.FirstName" required
                                                    appAlphabetOnly maxlength="50"
                                                    class="form-control form-control-pf-editor w-100" autocomplete="off"
                                                    aria-label="name" />
                                                <mat-error *ngIf="frmTherapist.submitted && therapistFirstName.invalid">
                                                    <mat-error *ngIf="therapistFirstName.errors.required">Therapist name
                                                        is required
                                                    </mat-error>
                                                </mat-error>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group row">
                                        <label for="staticEmail" class="col-md-12 col-form-label">Phone Number</label>
                                        <div class="col-md-12 form-control-pf-editable"
                                            [ngClass]="{'form-control-pf-edit':isEditTherapist}">
                                             <div class="form-control-pf-value" *ngIf="!isEditTherapist">
                                                <span class="sr-only">Edit Name: </span>
                                                <span>{{dtoChmaproviderTherapy?.PhoneNumber | mask: '(000) 000-0000'}}</span>
                                            </div>
                                            <div class="form-control-pf-textbox w-100" *ngIf="isEditTherapist">
                                                <input type="text" mask='(000) 000-0000'
                                                    class="form-control form-control-pf-editor w-100" autocomplete="off"
                                                    placeholder="Phone" aria-label="name" #therapistPhone="ngModel"
                                                    name="therapistPhone" required
                                                    mask='(000) 000-0000' 
                                                    [(ngModel)]="dtoChmaproviderTherapy.PhoneNumber" />
                                                <mat-error *ngIf="frmTherapist.submitted && therapistPhone.invalid">
                                                    <mat-error *ngIf="therapistPhone.errors.required">Therapist phone
                                                        is required
                                                    </mat-error>
                                                </mat-error>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-2 p-0 mt-4 pt-3 d-flex">
                                    <button *ngIf="!isEditTherapist" type="button" title="Edit"
                                        class="btn form-control-pf-edit" (click)="onEditTherapist()"
                                        aria-label="edit"><i class="fas fa-pencil-alt ml-2"></i></button>
                                    <button *ngIf="isEditTherapist" type="submit" form="frmTherapist" title="Save"
                                        class="btn  form-control-pf-save" aria-label="Save"><i
                                            class="fas fa-check"></i></button>
                                    <button *ngIf="isEditTherapist" type="button" title="Cancel"
                                        class="btn  form-control-pf-cancel" aria-label="Cancel"
                                        (click)="onCancelTherapist()"><i class="fas fa-times"></i></button>
                                </div>


                            </div>
                        </form>
                        <div class="row">

                            <div class="col-md-6">
                                <div class="form-group row">
                                    <label for="staticEmail" class="col-md-12 col-form-label">Frequency</label>
                                    <div class="col-md-12 "
                                        [ngClass]="{'form-control-pf-edit':isEditFrequency,'form-control-pf-editable':!isEditFrequency}">

                                        <div class="form-control-pf-value" *ngIf="!isEditFrequency">
                                            <span class="sr-only">Edit Name: </span>
                                            <span>{{dtofrequency == 1 ? 'Daily' : dtofrequency == 2 ? 'Weekly' : dtofrequency == 3 ? 'Monthly' : dtofrequency == 4 ? 'Bi-Weekly': ''}}</span>
                                        </div>
                                        <div class="form-control-pf-textbox w-100 mt-2" *ngIf="isEditFrequency">
                                            <mat-radio-group aria-label="Select an option" [(ngModel)]="dtofrequency"
                                                #frequency="ngModel" name="frequency" required>
                                                <mat-radio-button class="mr-3" [value]="1">Daily </mat-radio-button>
                                                <mat-radio-button class="mr-3" [value]="2">Weekly</mat-radio-button>
                                                <mat-radio-button class="mr-3" [value]="3">Monthly</mat-radio-button>
                                                <mat-radio-button class="mr-3" [value]="4">Bi-Weekly</mat-radio-button>
                                            </mat-radio-group>

                                            <mat-error *ngIf="isReqFrequency">Frequency
                                                is required
                                            </mat-error>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div class="col-md-2 p-0 mt-4 pt-3 d-flex">
                                <button *ngIf="!isEditFrequency" type="button" title="Edit"
                                    class="btn form-control-pf-edit" (click)="onEditFrequency()" aria-label="edit"><i
                                        class="fas fa-pencil-alt ml-2"></i></button>
                                <button *ngIf="isEditFrequency" type="button" (click)="postfrmFrequncy()" title="Save"
                                    class="btn form-control-pf-save" aria-label="Save"><i
                                        class="fas fa-check"></i></button>
                                <button *ngIf="isEditFrequency" type="button" title="Cancel"
                                    class="btn  form-control-pf-cancel" aria-label="Cancel"
                                    (click)="onCancelFrequency()"><i class="fas fa-times"></i></button>
                            </div>

                        </div>
                    </div>



                </div>



            </div>
        </div>
    </div>
    <div class="col-md-12">
        <div class="text-right">
              <button type="button" (click)="onnext(0)" mat-stroked-button  *ngIf="roleAccess.isCreate||roleAccess.isEdit"
                class="btn primary-btn-filled primary-btn-m mb-2 h-40 mr-2">{{'Save
                &
                Next'}}</button>
                <!-- *ngIf="!isMovedChma" -->
            <button type="button" (click)="onnext(1)" mat-stroked-button  *ngIf="roleAccess.isCreate||roleAccess.isEdit"
                class="btn  primary-btn-outlined primary-btn-m mb-2 h-40 mr-2">Save & Exit</button>
        </div>
    </div>
</div>