import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { BehaviorSubject, Observable, ReplaySubject, Subject } from 'rxjs';
import { CommonService } from 'src/app/common/common.service';
@Injectable({
  providedIn: 'root'
})
export class TreatmentService {

  constructor(private http: HttpClient, public commonService: CommonService) { }

  //Added Behavior Subject to get the latest Goals in Goals information and Goals Config
  public getGoals= new BehaviorSubject(true);
  public changeInGoalInformation=new BehaviorSubject(true);
  public getSuggestedGoals = new BehaviorSubject(true);
  public InitModalityPage = new BehaviorSubject(true);
  public DiagnosisProvider = new BehaviorSubject(true);
  
  public PresentingProblem = new BehaviorSubject(true);
  public TreatmentPlanStatus = new BehaviorSubject(true);
  //End of Behavior Subject part


  getTreatmentPlanDiagnosis(id) {
    return this.http.get<any>(environment.BaseURI + 'gateway/GetTreatment/GetTreatmentPlanDiagnosis?id=' + this.commonService.DataToEncrypt(id, true));
  }
  getTreatmentPlanConsent(id,secondaryId) {
    return this.http.get<any>(environment.BaseURI + 'gateway/GetTreatment/GetTreatmentPlanConsent?id=' + this.commonService.DataToEncrypt(id, true) + '&SecondaryId=' + this.commonService.DataToEncrypt(secondaryId, true));
  }
  getTreatmentPlanList(id) {
    return this.http.get<any>(environment.BaseURI + 'gateway/GetTreatment/getTreatmentPlanList?id=' + this.commonService.DataToEncrypt(id, true));
  }
  getTreatmentPlanStatus(id) {
    return this.http.get<any>(environment.BaseURI + 'gateway/GetTreatment/GetTreatmentPlanStatus?id=' + this.commonService.DataToEncrypt(id, true));
  }
  removeTreatmentGaols(id) {
    return this.http.get<any>(environment.BaseURI + 'gateway/GetTreatment/RemoveTreatmentGaols?id=' + this.commonService.DataToEncrypt(id, true));
  }
  getGoalsMaster() {
    return this.http.get<any>(environment.BaseURI + 'gateway/Master/GetGoalsMaster');
  }
  GetSuggestGoals(id) {
    return this.http.get<any>(environment.BaseURI + 'gateway/Goal/GetSuggestGoals?Id=' + this.commonService.DataToEncrypt(id, true));
  }
  getGoalsbyId(id, treatmentid) {
    return this.http.get<any>(environment.BaseURI + 'gateway/GetTreatment/GetGoalsById?id=' + this.commonService.DataToEncrypt(id, true) + '&treatmentid=' + this.commonService.DataToEncrypt(treatmentid, true));
  }
  getTreatmentPlanMaster() {
    return this.http.get<any>(environment.BaseURI + 'gateway/Master/GetTreatmentPlanMaster');
  }
  getUserList(id) {
    return this.http.get<any>(environment.BaseURI + 'gateway/GetTask/GetUserList?id=' + this.commonService.DataToEncrypt(id, true));
  }
  getTreatmentGoalsList(id) {
    return this.http.get<any>(environment.BaseURI + 'gateway/GetTreatment/GetTreatmentGoals?id=' + this.commonService.DataToEncrypt(id, true));
  }
  getTreatmentGoalsListBasedOnTherapy(id,therapyId) {
    return this.http.get<any>(environment.BaseURI + 'gateway/GetTreatment/GetTreatmentGoalsBasedOnTherapy?id=' + this.commonService.DataToEncrypt(id, true)+"&therapyId="+this.commonService.DataToEncrypt(therapyId, true));
  }
  getTreatmentGoalsBenchmarkTrack(id,therapyId) {
    return this.http.get<any>(environment.BaseURI + 'gateway/GetTreatment/GetTreatmentGoalsBenchmarkTrack?id=' + this.commonService.DataToEncrypt(id, true)+"&therapyId="+this.commonService.DataToEncrypt(therapyId, true));
  }
  removeTreatmentModality(id) {
    return this.http.get<any>(environment.BaseURI + 'gateway/GetTreatment/RemoveTreatmentModality?id=' + this.commonService.DataToEncrypt(id, true));
  }

  getScheduldeModalityDetails(enPatAcntId, enClinId) {
    return this.http.get<any>(environment.BaseURI + 'gateway/GetTreatment/GetScheduldeModalityDetails?enPatAcntId=' + this.commonService.DataToEncrypt(enPatAcntId, true) + '&enClinId=' + this.commonService.DataToEncrypt(enClinId, true));
    //return this.http.get<any>('https://localhost:44339/api/GetTreatment/GetScheduldeModalityDetails?enPatAcntId=' + this.commonService.DataToEncrypt(enPatAcntId, true) +'&enClinId=' + this.commonService.DataToEncrypt(enClinId, true));
  }

  getScheduldeConflictModalityDetails(enPatAcntId, enClinId, enStartDate, enEndDate, enAppointTime, enendTime) {
    return this.http.get<any>(environment.BaseURI + 'gateway/GetTreatment/GetScheduldeConflictModalityDetails?enPatAcntId=' + this.commonService.DataToEncrypt(enPatAcntId, true) + '&enClinId=' + this.commonService.DataToEncrypt(enClinId, true) + '&enStartDate=' + enStartDate + '&enEndDate=' + enEndDate + '&enAppointTime=' + enAppointTime + '&enendTime=' + enendTime);
    //return this.http.get<any>('https://localhost:44339/api/GetTreatment/GetScheduldeConflictModalityDetails?enPatAcntId=' + this.commonService.DataToEncrypt(enPatAcntId, true) +'&enClinId=' + this.commonService.DataToEncrypt(enClinId, true) +'&enStartDate=' + this.commonService.DataToEncrypt(enStartDate, true)+'&enEndDate=' + this.commonService.DataToEncrypt(enEndDate, true)+'&enAppointTime=' + this.commonService.DataToEncrypt(enAppointTime, true));
    //return this.http.get<any>('https://localhost:44339/api/GetTreatment/GetScheduldeConflictModalityDetails?enPatAcntId=' + this.commonService.DataToEncrypt(enPatAcntId, true) +'&enClinId=' + this.commonService.DataToEncrypt(enClinId, true) +'&enStartDate=' + enStartDate +'&enEndDate=' + enEndDate + '&enAppointTime=' + enAppointTime + '&enendTime=' + enendTime);
  }

  getScheduleModality(enPatAcntId, enClinId) {
    return this.http.get<any>(environment.BaseURI + 'gateway/GetTreatment/GetScheduleModality?enPatAcntId=' + this.commonService.DataToEncrypt(enPatAcntId, true) + '&enClinId=' + this.commonService.DataToEncrypt(enClinId, true));
    //return this.http.get<any>('https://localhost:44339/api/GetTreatment/GetScheduleModality?enPatAcntId=' + this.commonService.DataToEncrypt(enPatAcntId, true) +'&enClinId=' + this.commonService.DataToEncrypt(enClinId, true));
  }
  getViewModality(Id) {
    return this.http.get<any>(environment.BaseURI + 'gateway/GetTreatment/GetViewModality?Id=' + this.commonService.DataToEncrypt(Id, true));

  }

  postTreatmentPlanDiagnosis(data) {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http.post(environment.BaseURI + 'gateway/PostTreatment/PostTreatmentPlanDiagnosis',
      data, { headers: headers, responseType: 'text' })
      .pipe(map(function (response) { return response; }));
  }
  postTreatmentPlanConsent(data) {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http.post(environment.BaseURI + 'gateway/PostTreatment/PostTreatmentConsent',
      data, { headers: headers, responseType: 'text' })
      .pipe(map(function (response) { return response; }));
  }
  postTreatmentDiagnosis(data) {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http.post(environment.BaseURI + 'gateway/PostTreatment/PostTreatmentDiagnosis',
      data, { headers: headers, responseType: 'text' })
      .pipe(map(function (response) { return response; }));
  }
  postTreatmentModality(data) {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http.post(environment.BaseURI + 'gateway/PostTreatment/PostTreatmentModality',
      data, { headers: headers, responseType: 'text' })
      .pipe(map(function (response) { return response; }));
  }

  postScheduleModality(data) {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    //return this.http.post('https://localhost:44339/api/PostTreatment/PostScheduleModality',
    return this.http.post(environment.BaseURI + 'gateway/PostTreatment/PostScheduleModality',
      data, { headers: headers, responseType: 'text' })
      .pipe(map(function (response) { return response; }));
  }

  postTreatmentGoals(data) {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http.post(environment.BaseURI + 'gateway/PostTreatment/PostTreatmentGoals',
      data, { headers: headers, responseType: 'text' })
      .pipe(map(function (response) { return response; }));
  }
  getModalityForSchedule(id) {
    return this.http.get<any>(environment.BaseURI + 'gateway/GetTreatment/GetModalityForSchedule?id=' + this.commonService.DataToEncrypt(id, true));
  }
  getTreatmentModalityForSchedule(id) {
    return this.http.get<any>(environment.BaseURI + 'gateway/GetTreatment/GetTreatmentModalityForSchedule?id=' + this.commonService.DataToEncrypt(id, true));
  }
  getStaffList() {
    return this.http.get<any>(environment.BaseURI + 'gateway/Master/GetStaffList');
  }
  getConfilctsForModality(data) {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http.post(environment.BaseURI + 'gateway/GetTreatment/GetConflictsForSchedule',
      data, { headers: headers, responseType: 'text' })
      .pipe(map(function (response) { return response; }));
  }
  getConfilctsForModalityScheduler(data) {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http.post(environment.BaseURI + 'gateway/GetTreatment/GetConflictsForScheduler',
      data, { headers: headers, responseType: 'text' })
      .pipe(map(function (response) { return response; }));
  }
  getBlockTimeConfilcts(data) {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http.post(environment.BaseURI + 'gateway/GetTreatment/GetBlockTimeConflicts',
      data, { headers: headers, responseType: 'text' })
      .pipe(map(function (response) { return response; }));
  }
  postSchduleModality(data) {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http.post(environment.BaseURI + 'gateway/PostTreatment/PostScheduleModality',
      data, { headers: headers, responseType: 'text' })
      .pipe(map(function (response) { return response; }));
  }
  SaveBlockTimeDetails(data) {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http.post(environment.BaseURI + 'gateway/GetClinician/SaveBlockTimeDetails',
      data, { headers: headers, responseType: 'text' })
      .pipe(map(function (response) { return response; }));
  }
  getGroupTherapy(id) {
    return this.http.get<any>(environment.BaseURI + 'gateway/GetTreatment/GetGroupTherapy?Id=' + this.commonService.DataToEncrypt(id, true));
  }
  getGroupTherapyDetails(id) {
    return this.http.get<any>(environment.BaseURI + 'gateway/GetTreatment/GetGroupTherapyDetails?id=' + this.commonService.DataToEncrypt(id, true));
  }
  postBookSlotGrpTherapy(data) {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http.post(environment.BaseURI + 'gateway/PostTreatment/PostBookSlotGrpTherapy',
      data, { headers: headers, responseType: 'text' })
      .pipe(map(function (response) { return response; }));
  }
  getDayScheduleForAssign(id,date) {
    return this.http.get<any>(environment.BaseURI + 'gateway/GetTreatment/GetDayScheduleForAssign?Id=' + this.commonService.DataToEncrypt(id, true)+'&encdate='+this.commonService.DataToEncrypt(date, true));
  }
  getLocations() {
    return this.http.get<any>(environment.BaseURI + 'gateway/GetPriceCatalogue/GetLocations');
  }
  postdayschdule(data) {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    return this.http.post(environment.BaseURI + 'gateway/PostTreatment/PostDaySchdule',
      data, { headers: headers, responseType: 'text' })
      .pipe(map(function (response) { return response; }));
  }
  getsubgrouptherapy(id,date) {
    return this.http.get<any>(environment.BaseURI + 'gateway/GetTreatment/GetSubGroupDaySchedule?Id=' + this.commonService.DataToEncrypt(id, true)+'&encdate='+this.commonService.DataToEncrypt(date, true));
  }
  getTreatmentHistory(id) {
    return this.http.get<any>(environment.BaseURI + 'gateway/GetTreatment/GetTreatmentHistory?Id=' + this.commonService.DataToEncrypt(id, true));
  }
  getMultipleTreatmentPlan(id) {
    return this.http.get<any>(environment.BaseURI + 'gateway/GetTreatment/GetMultipleTreatmentPlan?Id=' + this.commonService.DataToEncrypt(id, true));
  }
  getClinicianActivityLog(id) {
    return this.http.get<any>(environment.BaseURI + 'gateway/GetClinician/GetClinicianActivityLog?id=' + this.commonService.DataToEncrypt(id, true));
  }
  getAuthActivityLog(id,insId) {
    return this.http.get<any>(environment.BaseURI + 'gateway/LeadContact/GetAuthActivityLog?id=' + this.commonService.DataToEncrypt(id, true)+'&insuranceId='+this.commonService.DataToEncrypt(insId, true));
  }
  getGoalsWithKpi(id) {
    return this.http.get<any>(environment.BaseURI + 'gateway/GetTreatment/GetTreatmentGoalsWithKpi?id=' + this.commonService.DataToEncrypt(id, true));
  }

  postKpiGoal(data){
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    //console.log(data);
    return this.http.post(environment.BaseURI + 'gateway/PostTreatment/PostKpiGoal',data,{ headers: headers, responseType: 'text' });

  }
  getLeadHistory(id){
    return this.http.get<any>(environment.BaseURI + 'gateway/LeadContact/GetActivityLogs?id=' + this.commonService.DataToEncrypt(id, true));
  }

  GetSuggestGoalsBasedOnTherapy(id) {
    return this.http.get<any>(environment.BaseURI + 'gateway/Goal/GetSuggestGoalsBasedOnTherapy?Id=' + this.commonService.DataToEncrypt(id, true));
  }
  GetAllGoalsBasedOnTherapy(id) {
     return this.http.get<any>(environment.BaseURI + 'gateway/Goal/GetAllGoalsBasedOnTherapy?Id=' + this.commonService.DataToEncrypt(id, true));
  }

  getGoalsMasterTemplate() {
    return this.http.get<any>(environment.BaseURI + 'gateway/Master/GetTemplateGoalMaster');
  }
  getPatientListforIR(id,secondaryId){
    return this.http.get<any>(environment.BaseURI + 'gateway/Master/GetPatientListIR?id='+this.commonService.DataToEncrypt(id, true)+ '&SecondaryId=' + this.commonService.DataToEncrypt(secondaryId, true));
  }
  getCLinicalStaffList(){
    return this.http.get<any>(environment.BaseURI + 'gateway/Master/GetClinicalStaffList');
  }
  getRestraintMaster(){
    return this.http.get<any>(environment.BaseURI + 'gateway/Master/GetIncidentReportMaster');
  }
PostIncidentReport(data){
  const headers = new HttpHeaders().set(
    'Content-Type',
    'application/json; charset=utf-8'
  );
  return this.http.post(environment.BaseURI + 'gateway/InicentReport/PostIncidentReport',
    data, { headers: headers, responseType: 'text' })
    .pipe(map(function (response) { return response; }));
}
getIncidentReportList(){
  return this.http.get<any>(environment.BaseURI + 'gateway/GetTreatment/GetIncidentReportList');
}

getIncidentById(id,secondaryId){
  return this.http.get<any>(environment.BaseURI + 'gateway/GetTreatment/GetIncidentReportById?id='+this.commonService.DataToEncrypt(id, true) + '&SecondaryId=' + this.commonService.DataToEncrypt(secondaryId, true));
}

PostIncidentReview(data){
  const headers = new HttpHeaders().set(
    'Content-Type',
    'application/json; charset=utf-8'
  );
  return this.http.post(environment.BaseURI + 'gateway/InicentReport/PostIncidentReview',
    data, { headers: headers, responseType: 'text' })
    .pipe(map(function (response) { return response; }));
} 

getIncidentReviewById(id,secondaryId){
  return this.http.get<any>(environment.BaseURI + 'gateway/InicentReport/GetIncidentReviewByid?id='+this.commonService.DataToEncrypt(id, true)+ '&SecondaryId=' + this.commonService.DataToEncrypt(secondaryId, true));
}
CloseIncidentReports(id){
  const headers = new HttpHeaders().set(
    'Content-Type',
    'application/json; charset=utf-8'
  );
  return this.http.post(environment.BaseURI + 'gateway/InicentReport/CloseIncidents  ',
    id, { headers: headers, responseType: 'text' })
    .pipe(map(function (response) { return response; }));
}
getGoalsWithbenchmark(id) {
  return this.http.get<any>(environment.BaseURI + 'gateway/GetTreatment/GetTreatmentbenchmark?id=' + this.commonService.DataToEncrypt(id, true));
}
PostTreatmentBenchmark(data) {
  const headers = new HttpHeaders().set(
    'Content-Type',
    'application/json; charset=utf-8'
  );
  return this.http.post(environment.BaseURI + 'gateway/PostTreatment/PostTreatmentBenchmark',
    data, { headers: headers, responseType: 'text' })
    .pipe(map(function (response) { return response; }));
}
postinactiveAppointments(data) {
  const headers = new HttpHeaders().set(
    'Content-Type',
    'application/json; charset=utf-8'
  );
  return this.http.post(environment.BaseURI + 'gateway/PostTreatment/PostInactiveAppointments',
    data, { headers: headers, responseType: 'text' })
    .pipe(map(function (response) { return response; }));
}


getIndFamSchedulePtDetails(tdate,tid,msid,mstid) {
  return this.http.get<any>(environment.BaseURI + 'gateway/GetTreatment/GetIndFamSchedulePtDetails?Therapydate=' + this.commonService.DataToEncrypt(tdate, true)+'&Therapyid='+this.commonService.DataToEncrypt(tid, true)+'&MSId='+this.commonService.DataToEncrypt(msid, true)+'&mstid='+this.commonService.DataToEncrypt(mstid,true));
}
getDischargeSummaryList(){
  return this.http.get<any>(environment.BaseURI + 'gateway/GetTreatment/GetDischargeSummaryList');
}
getDischargeSummayByid(id,secondaryId){
  return this.http.get<any>(environment.BaseURI + 'gateway/GetTreatment/GetDischargeSummaryById?id='+this.commonService.DataToEncrypt(id, true) + '&SecondaryId=' + this.commonService.DataToEncrypt(secondaryId, true));
}

getPatientListforDS(id,locationId,secondaryId){
  return this.http.get<any>(environment.BaseURI + 'gateway/Master/GetPatientListForDischarge?id='+this.commonService.DataToEncrypt(id, true)+'&locationId='+this.commonService.DataToEncrypt(locationId, true)+ '&SecondaryId=' + this.commonService.DataToEncrypt(secondaryId, true));
}
getDayScheduleForAssignClinician(id) {
  return this.http.get<any>(environment.BaseURI + 'gateway/GetTreatment/GTherapyPTListAssignClinician?Id=' + this.commonService.DataToEncrypt(id, true));
}
// gtherapyDaySchedulePT(dsgSubgrpId,tid,dsaid) {
//   return this.http.get<any>(environment.BaseURI + 'gateway/GetTreatment/GTherapyDaySchedulePT?dsgSubgrpId=' + this.commonService.DataToEncrypt(dsgSubgrpId, true)+'&TheraphyId='+this.commonService.DataToEncrypt(tid, true)+'&DayScheduleAssigndId='+this.commonService.DataToEncrypt(dsaid, true));
// }
GetDeleteReasonForMs(){
  return this.http.get<any>(environment.BaseURI + 'gateway/Master/GetDeleteReasonMaster');
}
GetMeetingTypeMaster(){
  return this.http.get<any>(environment.BaseURI + 'gateway/Master/GetMeetingTypeMaster');
}
postReSchduleMeeting(data) {
  const headers = new HttpHeaders().set(
    'Content-Type',
    'application/json; charset=utf-8'
  );
  return this.http.post(environment.BaseURI + 'gateway/PostTreatment/PostReScheduleMeeting',
    data, { headers: headers, responseType: 'text' })
    .pipe(map(function (response) { return response; }));
}
postSchedularAdminNotes(data) {
  const headers = new HttpHeaders().set(
    'Content-Type',
    'application/json; charset=utf-8'
  );
  return this.http.post(environment.BaseURI + 'gateway/PostTreatment/PostSchedularAdminNotes',
    data, { headers: headers, responseType: 'text' })
    .pipe(map(function (response) { return response; }));
}
postSchedularAdminMeetingLink(data) {
  const headers = new HttpHeaders().set(
    'Content-Type',
    'application/json; charset=utf-8'
  );
  return this.http.post(environment.BaseURI + 'gateway/PostTreatment/PostSchedularAdminMeetingLink',
    data, { headers: headers, responseType: 'text' })
    .pipe(map(function (response) { return response; }));
}

postSchedularoffsiteaddress(data) {
  const headers = new HttpHeaders().set(
    'Content-Type',
    'application/json; charset=utf-8'
  );
  return this.http.post(environment.BaseURI + 'gateway/PostTreatment/PostSchedulerOffSiteAddress',
    data, { headers: headers, responseType: 'text' })
    .pipe(map(function (response) { return response; }));
}
getSuicidalAssessment(id) {
  return this.http.get<any>(environment.BaseURI + 'gateway/GetTreatment/getSuicidalassessmentinfo?Id=' + this.commonService.DataToEncrypt(id, true));
}

postSuicidalAssessmentForm(data) {
  const headers = new HttpHeaders().set(
    'Content-Type',
    'application/json; charset=utf-8'
  );
  return this.http.post(environment.BaseURI + 'gateway/PostTreatment/PostSucidalAssessmentForm',
    data, { headers: headers, responseType: 'text' })
    .pipe(map(function (response) { return response; }));
}

getSuicidalsafetycontract(id) {
  return this.http.get<any>(environment.BaseURI + 'gateway/GetTreatment/getSuicidalSafetyContractinfo?Id=' + this.commonService.DataToEncrypt(id, true));
}

removeSuicidalSafetyContact(id) {
  console.log(id)
  return this.http.get<any>(environment.BaseURI + 'gateway/PostTreatment/RemoveSuicidalSafetyContact?Id=' + this.commonService.DataToEncrypt(id, true));
}
PostSuicidalSafetyContract(data) {
  const headers = new HttpHeaders().set(
    'Content-Type',
    'application/json; charset=utf-8'
  );
  return this.http.post(environment.BaseURI + 'gateway/PostTreatment/PostSuicidalSafetyContract',
    data, { headers: headers, responseType: 'text' })
    .pipe(map(function (response) { return response; }));
}

getSuicidalScreener(id) {
  return this.http.get<any>(environment.BaseURI + 'gateway/GetTreatment/getSuicideScreener?Id=' + this.commonService.DataToEncrypt(id, true));
}


PostSuicideScreener(data) {
  const headers = new HttpHeaders().set(
    'Content-Type',
    'application/json; charset=utf-8'
  );
  return this.http.post(environment.BaseURI + 'gateway/PostTreatment/PostSuicideScreener',
    data, { headers: headers, responseType: 'text' })
    .pipe(map(function (response) { return response; }));
}

getScreenerDocuments(data) {
  const headers = new HttpHeaders().set(
    'Content-Type',
    'application/json; charset=utf-8'
  );
  return this.http.post(environment.BaseURI + 'gateway/GetTreatment/GetSuicideScreenerDocuments',
    data, { headers: headers, responseType: 'text' })
    .pipe(map(function (response) { return response; }));
}

getSuicidepdf(id,type): Observable<any> {
  return this.http.get<any>(environment.BaseURI + 'gateway/GeneratePdf/GetSuicideScreenerPdf?id=' + this.commonService.DataToEncrypt(id, true)+'&formtype='+this.commonService.DataToEncrypt(type,true));    
}
GetActivityLogsForSuicide(id){
  return this.http.get<any>(environment.BaseURI + 'gateway/GetTreatment/GetActivityLogsForSuicide?id=' + this.commonService.DataToEncrypt(id, true));
}

removetreatmentbenchmark(id) {
  return this.http.get<any>(environment.BaseURI + 'gateway/PostTreatment/RemoveTreatmentBenchmark?Id=' + this.commonService.DataToEncrypt(id, true));
}
PostPresentingProblems(data) {
  const headers = new HttpHeaders().set(
    'Content-Type',
    'application/json; charset=utf-8'
  );
  return this.http.post(environment.BaseURI + 'gateway/PostTreatment/PostPresentingProblems',
    data, { headers: headers, responseType: 'text' })
    .pipe(map(function (response) { return response; }));
}
ReassignPresentingProblems(data) {
  const headers = new HttpHeaders().set(
    'Content-Type',
    'application/json; charset=utf-8'
  );
  return this.http.post(environment.BaseURI + 'gateway/PostTreatment/ReassignPresentingProblems',
    data, { headers: headers, responseType: 'text' })
    .pipe(map(function (response) { return response; }));
}
getProviderList(id) {
  return this.http.get<any>(environment.BaseURI + 'gateway/Master/GetPatientProviderlist?id=' + this.commonService.DataToEncrypt(id, true));
}
getPresentingProblems(chma,type) {
  return this.http.get<any>(environment.BaseURI + 'gateway/GetTreatment/GetPresentingProblems?chma='+this.commonService.DataToEncrypt(chma, true)+'&type='+this.commonService.DataToEncrypt(type, true));
}
getBlockTimeMaster() {
  return this.http.get<any>(environment.BaseURI + 'gateway/Master/GetBlockTime');
}
postUploadDocuments(data) {
  const headers = new HttpHeaders().set(
    'Content-Type',
    'multipart/form-data'
  );
  return this.http.post(environment.BaseURI + 'gateway/Upload/UploadDocuments', data)
    .pipe(map(res => {

      return res;
    }));
}
getGroupMeetingDetails(id) {
  return this.http.get<any>(environment.BaseURI + 'gateway/GetTreatment/GetGroupMeetingDetails?Id=' + this.commonService.DataToEncrypt(id, true));
}
loadFormGroupDetails(id,date,clinicianId) {
  return this.http.get<any>(environment.BaseURI + 'gateway/PostTreatment/GetFormGroupDetails?Id=' + this.commonService.DataToEncrypt(id, true)+'&clinician='+this.commonService.DataToEncrypt(clinicianId, true)+'&encdate='+this.commonService.DataToEncrypt(date, true));
}
GetGroupCount(id,date) {
  return this.http.get<any>(environment.BaseURI + 'gateway/PostTreatment/GetGroupCount?Id=' + this.commonService.DataToEncrypt(id, true)+'&encdate='+this.commonService.DataToEncrypt(date, true));
}
async GetGroupCountAsync(id,date) {
  let count = await this.http.get<any>(environment.BaseURI + 'gateway/PostTreatment/GetGroupCount?Id=' + this.commonService.DataToEncrypt(id, true)+'&encdate='+this.commonService.DataToEncrypt(date, true)).toPromise();
  return count;
}
getdischargesummarypdf(id) {
  return this.http.get<any>(environment.BaseURI + 'gateway/GeneratePdf/GetDischargeSummaryPdf?id=' + this.commonService.DataToEncrypt(id, true));
}
gettreatmentsummarypdf(id) {
  return this.http.get<any>(environment.BaseURI + 'gateway/GeneratePdf/GetTreatmentmentSummaryPdf?id=' + this.commonService.DataToEncrypt(id, true));
}
postGTListOrder(data){
  const headers = new HttpHeaders().set(
    'Content-Type',
    'application/json; '
  );
  return this.http.post(environment.BaseURI + 'gateway/PostTreatment/SaveGTListOrder',
    data, { headers: headers, responseType: 'text' })
    .pipe(map(function (response) { return response; }));
}

getClinicianName(id) {
  return this.http.get<any>(environment.BaseURI + 'gateway/GetClinician/GetClinicianName?id=' + this.commonService.DataToEncrypt(id, true));
}
getAdditionalProviderId(id) {
  return this.http.get<any>(environment.BaseURI + 'gateway/GetClinician/GetAdditionalProviderId?id=' + this.commonService.DataToEncrypt(id, true));
}

getProviderId(id) {
  return this.http.get<any>(environment.BaseURI + 'gateway/GetClinician/GetProviderId?id=' + this.commonService.DataToEncrypt(id, true));
}
getSupportStaffId(id) {
  return this.http.get<any>(environment.BaseURI + 'gateway/GetClinician/GetSupportStaffId?id=' + this.commonService.DataToEncrypt(id, true));
}
postTreatmentPlanComments(data) {
  const headers = new HttpHeaders().set(
    'Content-Type',
    'application/json; charset=utf-8'
  );
  return this.http.post(environment.BaseURI + 'gateway/PostTreatment/PostTreatmentPlanComments',
    data, { headers: headers, responseType: 'text' })
    .pipe(map(function (response) { return response; }));
}

onDeletetreatmentcomment(id){
  return this.http.get<any>(environment.BaseURI + 'gateway/GetTreatment/DeleteTreatmentplanComments?id=' + id);
}
getClinicianSign(id) {
  return this.http.get<any>(environment.BaseURI + 'gateway/GetClinician/GetClinicianSign?id=' + this.commonService.DataToEncrypt(id, true));
}
getSuicideHistorypdf(id,type): Observable<any> {
  return this.http.get<any>(environment.BaseURI + 'gateway/GeneratePdf/GetSuicideScreenerPdfHistory?id=' + this.commonService.DataToEncrypt(id, true)+'&formtype='+this.commonService.DataToEncrypt(type,true));    
}
getddlLocationforclinician(id) {
  return this.http.get<any>(environment.BaseURI + 'gateway/GetPriceCatalogue/GetddlLocationforclinician?id=' + id);
}
}
