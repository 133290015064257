<div class="daily-scheduler-page cmnts-height">
    
    <div *ngIf="noRecords" class="no-patients">
        No patient is assigned to selected therapy
        <div class="image-nopatient"> <img class="img-fluid" src="../../../../assets/images/no-patients-assigned.png"></div>
      </div>
   <div *ngIf="!noRecords">
    <div>
        <div class="row">
            <div class="col-md-10">
                <h2 class="head-section mt-1 fs16">
                    <span class="ml-0 head-span fw-500">Daily Scheduler :</span>
                    <span class="ml-2 head-span">{{_dtoGroupTherapyDetails?.typeName}}</span>
                </h2>
               
            </div>
            <!-- <div class="col-md-4 text-right ml-auto pr-0">
               
               
                <button
                  class="btn back-btn-new mr-2 float-none"
                  (click)="onGeneratePDF(1)"
                >
                  <i class="far fa-file-pdf mr-2"></i>Generate PDF
                </button>
<button>
    <a  [routerLink]="isClinician ? ['/clinician'] : ['/scheduler'] " class="float-right back_btn_link fs14">Back</a>

</button>
</div> -->
  <!-- <button
                  class="btn back-btn-new float-none mr-2"
                  (click)="onbacktoMytask()"
                >
                  <i class="fas fa-chevron-left mr-2"></i>Back
                </button> -->
              
                <div class="d-flex bd-highlight"> 
                    <div class=" bd-highlight">
                     <button class="btn generate-btn " (click)="onGeneratePDF(1)" ><i class="far fa-file-pdf mr-2"></i>Generate PDF</button>
                   </div> 
                     <div class=" bd-highlight"><button  tabindex="0" class="btn back-btn-new " (click)="OnBack()">
                       <i  class="fas fa-chevron-left "></i> <a  [routerLink]="isClinician ? ['/clinician'] : ['/scheduler'] " class="float-right back_btn_link fs14">Back</a>
                   </button></div>
                    </div>
            <!-- <div class="col-md-2">
            <a  [routerLink]="isClinician ? ['/clinician'] : ['/scheduler'] " class="float-right back_btn_link fs14">Back</a>
            </div> -->
            

            <!-- <div class="col-md-2">
            <a  [routerLink]="isClinician ? ['/clinician'] : ['/scheduler'] " class="float-right back_btn_link fs14">Back</a>
            </div> -->
            <!-- <button type="button" mat-stroked-button [routerLink]="['/scheduler']"
            class="btn primary-btn-outlined-small ng-star-inserted float-right my-4 mr-2"> -->
          
        </div>
       
    </div>
  <div class="row mt-2">
    <div class="col-md-6">
    <div class="list-group-heading mt-4 pl-0">
        <ul class="list-group list-group-horizontal">
            <li class="list-group-item pl-0">
                <p class="mb-0">
                    <label class="list-item-label fs16 fw500 mb-0">

                        Date : </label>
                    <span class="list-item-date fw-500">{{this.scheduleDate |
                        date:'mediumDate'}}</span>
                </p>
            </li>
            <li class="list-group-item ml-4">
                <p class="mb-0">
                    <label class="list-item-label fs16 fw500 mb-0">Time : </label>
                    <span class="list-item-date fw-500">{{_dtoGroupTherapyDetails?.startTime | date:'shortTime'}} to
                        {{groupTherapyEndTimeDetails}}</span>
                </p>
            </li>
            <li class="list-group-item ml-4">
                <label class="list-item-label fs16 fw500 mb-0">Client Type : </label>
                <span class="list-item-label fw-500 ml-2">{{typeSelected}}</span></li>
        </ul>
    </div>
</div>
<div class="col-md-4">
    <!-- <div>
        <mat-form-field class="w-50">
            <mat-label>Clinician / Staff</mat-label>
            <mat-select multiple [(ngModel)]="clinicians" #clinician="ngModel" name="clinician" required>
                <mat-option #matOption *ngFor="let item of allClinicians" [value]="item" (click)="MulticlinicianChange()">
                    {{item.firstName}} {{item.lastName}}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-error *ngIf="isFormSubmitted && clinicians.length==0">
            <mat-error *ngIf="isFormSubmitted && clinicians.length==0">Clinicians are required</mat-error>
        </mat-error>
    </div> -->
</div>
<div class="col-md-2 text-right">
  
        <button *ngIf="_dtoOutputList.length <= 4 && disable != 'true'" (click)="addGroup()"
        class="btn primary-btn-outlined-small ng-star-inserted float-right mb-2 mr-0">Add Group</button>
    
</div>
</div>
    <!-- <div class="row fs12">
                <div class="col-md-3 text-left ml-4 pr-0">
                    <label class="mr-2">Clinician / Staff</label>
                    <mat-form-field class="">
                        <mat-label class="ml-2">Clinician / Staff</mat-label>
                        <mat-select>
                            <mat-option>
                                name
                            </mat-option>
                        </mat-select>
                    </mat-form-field>   

                </div>
                <div class="col-md-3 text-left ml-0 px-0">
                    <label class="mr-2">Intern / Support</label>
                    <mat-form-field class="">
                        <mat-label>Intern / Support</mat-label>
                        <mat-select>
                            <mat-option>
                                name
                            </mat-option>
                        </mat-select>
                    </mat-form-field>   

                </div>
                <div class="col-md-5 text-right px-0 ml-5">
                    <button class="btn primary-btn-filled-small mb-2 h-40 float-right" (click)="createGroup()"
                    >Create Group</button>
                </div>
                
            </div> -->
    <div class="row mx-0 client-details-scheduler mt-3">
        <div class="col-md-3 p-0 mt-0" id="collapseNone">
            <div>
                <h3 class="scheduler-heading scheduler-head-padding fs14 text-center py-4 mb-0 border-right">Client List</h3>
                <div class="dots-content pb-0 py-2">
                    <div id="1" cdkDropList [cdkDropListData]="_dtoGrpTherapyUsers" 
                        [cdkDropListConnectedTo]="['2','3','4','5','6']" class="client-details-lists"
                        (cdkDropListDropped)="drop($event)">
                        <div class="example-box pl-4" *ngFor="let item of _dtoGrpTherapyUsers" cdkDrag [disabled]="disable" >
                            <span style="cursor: pointer;">
                                <img src="../../assets/images/dots-six-vertical.svg" style="width: 15px; height: 15px;"
                                    alt="dotsimage" class="dots-six-vertical">
                                <!-- <img src="../../assets/images/avator.jpg" style="width: 25px; height: 25px;"
                                    alt="profile" class="profile-icon" /> -->
                                <span class="px-2">{{item.UserName}}</span>
                            </span>
                        </div>
                    </div>
                    <h5 class="comments_heading mt-3 font-500">Comments :</h5>
                    <quill-editor #tempEditor 
                            style="height: 100%;" [preserveWhitespace]="true"
                            [(ngModel)]="_dtoOutputList[0].dsComments" placeholder="Enter Text"
                            #dsComments="ngModel" name="dsComments"  [modules]="quillConfig">
              </quill-editor>
                    <!-- <app-word-counter-textarea class="counter-textarea" [text]="_dtoOutputList[0].dsComments" [maxlength]="3000" 
                     [(ngModel)]="_dtoOutputList[0].dsComments"   (textChange)="_dtoOutputList[0].dsComments = $event"
                     #dsComments="ngModel" name="dsComments" ngDefaultControl></app-word-counter-textarea> -->
                    <!-- <app-word-counter-textarea class="w-100" [text]="_dtoConsent.referralcomments" [maxlength]="3000"
                    [(ngModel)]="_dtoConsent.referralcomments" (textChange)="_dtoConsent.referralcomments = $event"
            #referralcomments="ngModel" name="referralcomments" ngDefaultControl></app-word-counter-textarea> -->
                </div>
             
            </div>
        </div>

       



        <div class="col-md-9 pl-0 pr-0">
        <div class="row">
            <div class="col-md-12">
                <div class="row">        
               <!--For looping the options-->
              <div class="role-permission col-md-12" *ngFor="let item of _dtoOutputList; let i = index;" >
                <div class="permission-container"  [ngClass]="item.isActive?'active':''"> 
                  <div  class="example-accordion-item-header daily-scheduler-page-header " (click)="item.isActive=!item.isActive">Group {{ i+1 }} <i [ngClass]="item.isActive?'fas fa-chevron-up ml-2':'fas fa-chevron-down ml-2'" ></i></div>
                  <div class="expand-table" >
                    <div class="card-body p-3">
                        <form>
                        <!-- <button *ngIf="_dtoOutputList.length>1 && !_dtoOutputList[i].isDelete" (click)="deleteGroup(i)">delete</button> -->
                        <div class="row mx-0 text-right">
                            <div class="col-md-12">
                        <button (click)="deleteGroup(i)" *ngIf="_dtoOutputList.length>1 && !_dtoOutputList[i].isDelete" class="mb-4 btn primary-btn-outlined-small float-right">delete</button>
                        </div>
                        </div>
                        <div class="row mx-0">
                        <div class="col-md-12 assign-clients">
                            <h6 class="py-2">Drag & drop clients below</h6>
                            <span>
                                <div id={{i+2}} cdkDropList [cdkDropListData]="_dtoOutputList[i].dropList"
                                    [cdkDropListConnectedTo]="arrayFunction(i+1)" class="example-list"
                                    (cdkDropListDropped)="drop($event)"  onmousedown="'disable' != 'true' ? event.preventDefault() : event.returnValue = false">
                                    <div class="example-box" *ngFor="let item of _dtoOutputList[i].dropList"
                                        cdkDrag>
                                        <span style="cursor: pointer;">
                                            <img src="../../assets/images/dots-six-vertical.svg"
                                                style="width: 15px; height: 15px;" alt="dotsimage"
                                                class="dots-six-vertical">
                                            <!-- <img src="../../assets/images/avator.jpg"
                                                style="width: 25px; height: 25px;" alt="profile"
                                                class="profile-icon" /> -->
                                            <span class="px-2">{{item.UserName}}</span>
                                        </span>
                                    </div>
                                </div>
                                <mat-error *ngIf="isFormSubmitted && _dtoOutputList[i].dropListError">
                                    <mat-error
                                        *ngIf="isFormSubmitted && _dtoOutputList[i].dropListError">Patients are
                                        required</mat-error>
                                </mat-error>
                            </span>
                        </div>
                    </div>
                        <!-- <div class="row">
                            <div class="col-md-4">
                                <mat-form-field class="w-100">
                                    <mat-label>Clinician/Staff</mat-label>
                                    <mat-select [(ngModel)]="_dtoOutputList[i].clinician"
                                        #clinicianstaff="ngModel" name="clinicianstaff{{i}}" [disabled]="disable">
                                        <mat-option #matOption *ngFor="let itemClinician of clinicians"
                                            [value]="itemClinician" (click)="clinicianChange(i,itemClinician,'',false)">
                                            {{itemClinician.firstName}} {{itemClinician.lastName}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <mat-error *ngIf="isFormSubmitted && _dtoOutputList[i].clinicianError && _dtoOutputList[i].clinician.length == 0">
                                    <mat-error *ngIf="isFormSubmitted && _dtoOutputList[i].clinicianError">Clinician/Staff is  required</mat-error>
                                </mat-error>
                            </div>
                            <div class="col-md-4">
                                <mat-form-field class="w-100" >
                                    <mat-label>Support/Intern</mat-label>
                                    <mat-select multiple [(ngModel)]="_dtoOutputList[i].support" 
                                        name="support{{i}}" id="support{{i}}" [disabled]="disable">
                                        <mat-option #matOption *ngFor="let itemStaff of supportListData[i].supportData"
                                            [value]="itemStaff">
                                            {{itemStaff.firstName}} {{itemStaff.lastName}}
                                        </mat-option>
                                    </mat-select>
                                   
                                </mat-form-field>
                                 <mat-error *ngIf="isFormSubmitted && _dtoOutputList[i].supportError && _dtoOutputList[i].support.length == 0">
                                    <mat-error *ngIf="isFormSubmitted && _dtoOutputList[i].supportError">Support/Intern is required
                                    </mat-error>
                                </mat-error>
                            </div>
                            <div class="col-md-4">
                                <mat-form-field class="w-100">
                                    <mat-label>Group Leader</mat-label>
                                    <mat-select [(ngModel)]="_dtoOutputList[i].leader" #leader="ngModel"
                                        name="leader" [disabled]="disable">
                                        <mat-option #matOption [value]="_dtoOutputList[i]?.clinician?.staffId">
                                            {{_dtoOutputList[i]?.clinician?.firstName}}
                                            {{_dtoOutputList[i]?.clinician?.lastName}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <mat-error *ngIf="isFormSubmitted && _dtoOutputList[i].leaderError && _dtoOutputList[i].leader.length == 0">
                                    <mat-error *ngIf="isFormSubmitted && _dtoOutputList[i].leaderError">Group Leader is required
                                    </mat-error>
                                </mat-error>
                            </div>
                        </div> -->
                        <div class="row">
                            <div class="col-md-4">
                                <!-- <label class="m-2">Staff</label>
                    <mat-form-field class="">
                        <mat-label>Staff</mat-label>
                        <mat-select>
                            <mat-option>
                                name
                            </mat-option>
                        </mat-select>
                    </mat-form-field>  -->
                                <div>
                                    <mat-form-field class="w-100">
                                        <mat-label>Therapy</mat-label>
                                        <mat-select multiple name="therapy{{i}}" id="therapy{{i}}" required
                                            [(ngModel)]="_dtoOutputList[i].selectedTherapy" [disabled]="disable" 
                                            >
                                            <mat-option #matOption (click)="onMultiChange(_dtoOutputList[i],itemTherapy,i)"
                                                *ngFor="let itemTherapy of _dtoGroupMeetingTherapyMaster"
                                                [value]="itemTherapy">
                                                {{itemTherapy.therapyName}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                    <mat-error *ngIf="isFormSubmitted && _dtoOutputList[i].therapyError">
                                        <mat-error
                                            *ngIf="isFormSubmitted && _dtoOutputList[i].therapyError && _dtoOutputList[i].selectedTherapy.length==0">Therapy
                                            is required
                                        </mat-error>
                                    </mat-error>

                                </div>
                                <!-- <mat-form-field class="">
                        <mat-label>Location</mat-label>
                        <mat-select
                        >
                            <mat-option>
                                location
                            </mat-option>  
                        </mat-select>
                    </mat-form-field> -->

                            </div>
                        </div>
                     
                        <h3 *ngIf="_dtoOutputList[i].tmpList.length > 0" class="scheduler-heading fs14 text-left py-4 fw700">Therapy Details</h3>
               
                        <div  class="therapy-details-div"
                        cdkDropList
                (cdkDropListDropped)="drops($event,i,y)"
                cdkDropListData="_dtoOutputList[i].tmpList"
                [cdkDropListDisabled]="dragDisabled"
                        >
                        <div *ngFor="let therapyVal of _dtoOutputList[i].tmpList;let y=index;" class="dragCursor" (mousedown)="dragDisabled = false;" cdkDrag  >
                            <div >
                                <div class="row py-2">
                                    <div class="col-md-11">
                                        <!-- <h5 class="scheduler-heading fs14 text-left py-4">Therapy 1</h5> -->
                                    </div>
                             
                                    <div class="col-md-1" *ngIf ="(disable != 'true') && ( _dtoOutputList[i].tmpList.length > 1)" (click)="removeTherapy(therapyVal,y,i)">
                                        <i class="fa fa-trash" aria-hidden="true" style="color: red;"></i>
                                    </div>
                                 </div>
                                 <div class="row" >
                                    <div class="col-md-3">
                                        <mat-form-field class="w-100" >
                                            <mat-label>Selected Therapy</mat-label>
                                         <input matInput [disabled]="true" name="therapyList{{i}}{{y}}" id="therapyList{{i}}{{y}}" required
                                                [(ngModel)]="_dtoOutputList[i].tmpList[y].therapyName">
                                                <!-- <mat-option #matOption
                                                    *ngFor="let itemTherapy of _dtoGroupMeetingTherapyMaster"
                                                    [value]="itemTherapy.groupMeetingTherapyId">
                                                    {{itemTherapy.therapyName}}
                                                </mat-option> -->
                                        </mat-form-field>
                                    </div>
                                    <div class="pdng_top col-md-2">
                                        <label>
                                            <input type="checkbox" id="gtIntervention{{i}}{{y}}" name="gtIntervention{{i}}{{y}}"  [(ngModel)]="_dtoOutputList[i].tmpList[y].gtIntervention"  /> Group Intervention
                                          </label>
                                   </div>
                                    <div class="col-md-2">
                                        <!-- <label class="m-2">Group Leader</label>
                        <mat-form-field class="">
                            <mat-label>Group Leader</mat-label>
                            <mat-select>
                                <mat-option>
                                    name
                                </mat-option>
                            </mat-select>
                        </mat-form-field>  -->
                                        <mat-form-field class="w-100">
                                            <mat-label class="control-label-new">Start Time</mat-label>
                                            <input matInput type="time" id="startTime{{i}}{{y}}" [disabled]="disable"
                                                [(ngModel)]="_dtoOutputList[i].tmpList[y].startTime" name="startTime{{i}}{{y}}" required
                                                (change)="onTimeChange(_dtoOutputList[i].tmpList[y],1,i,y)">
                                        </mat-form-field>
                                        <mat-error *ngIf="_dtoOutputList[i].tmpList[y].startTimeError">
                                            <mat-error
                                            *ngIf="OntimeChangeError && !isFormSubmitted && _dtoOutputList[i].tmpList[y].startTimeError">{{_dtoOutputList[i].tmpList[y].startTimeErrorMessage}}</mat-error>
                                 
                                            <mat-error
                                                *ngIf="!OntimeChangeError && isFormSubmitted && _dtoOutputList[i].tmpList[y].startTimeError">{{_dtoOutputList[i].tmpList[y].startTimeErrorMessage}}</mat-error>
                                        </mat-error>
                                    </div>
                                    <div class="col-md-2">
                                        <mat-form-field class="w-100">
                                            <mat-label class="control-label-new">End Time</mat-label>
                                            <input matInput type="time" id="endTime{{i}}{{y}}"
                                                [(ngModel)]="_dtoOutputList[i].tmpList[y].endTime" name="endTime{{i}}{{y}}" required
                                                (change)="onTimeChange(_dtoOutputList[i].tmpList[y],2,i,y)"  [disabled]="disable">
                                        </mat-form-field>
                                        <mat-error *ngIf="_dtoOutputList[i].tmpList[y].endTimeError">
                                            <mat-error
                                            *ngIf="OntimeChangeError && !isFormSubmitted && _dtoOutputList[i].tmpList[y].endTimeError">{{_dtoOutputList[i].tmpList[y].endTimeErrorMessage}}</mat-error>
                                   
                                            <mat-error
                                                *ngIf="!OntimeChangeError && isFormSubmitted && _dtoOutputList[i].tmpList[y].endTimeError">{{_dtoOutputList[i].tmpList[y].endTimeErrorMessage}}</mat-error>
                                        </mat-error>
                                    </div>
                                    <div class="col-md-3 len_clmn">
                                       
                                            <mat-label>length</mat-label>
                                            <input class="ds_length" id="DSlength{{i}}{{y}}" type="text" name="dSlength{{i}}{{y}}" [(ngModel)]="_dtoOutputList[i].tmpList[y].dSlength">
                                            
                                        <!-- <div class="form-group mb-0 mt-2 ml-auto d-flex">
                            <input id="exampleFormControlInput2" type="email" placeholder="search"
                              class="form-control w85">
                            
                              <i class="fa fa-plus-circle m-auto" aria-hidden="true"></i>
                          </div> -->
                                        <!-- <div class="form-group mb-0 mt-4 ml-auto d-flex">
                            <input id="exampleFormControlInput3" type="email" placeholder="search"
                              class="form-control w85">                                 
                            <span><img src="../../assets/images/cross-icon.svg" alt="close" class="ml-2 mt-2"/></span>
                          </div> -->
                                    </div>
                                   
                                </div>
                                <div class="row">
                                    <div class="col-md-3">
                                        <!-- <label class="m-2">Location</label> -->
                                        <mat-form-field class="w-100">
                                            <mat-label>Room</mat-label>
                                            <mat-select name="locationList{{i}}{{y}}" id="locationList{{i}}{{y}}" required
                                                [(ngModel)]="_dtoOutputList[i].tmpList[y].meetingLocationsId" [ngModelOptions]="{ standalone: true }" [disabled]="disable">
                                                <mat-option #matOption *ngFor="let itemLocation of ddlMeetinglocation"
                                                    [value]="itemLocation.meetingLocationsId">
                                                    {{itemLocation.meetingName}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                        <mat-error *ngIf="isFormSubmitted && _dtoOutputList[i].tmpList[y].locationError && _dtoOutputList[i].tmpList[y].meetingLocationsId == 0">
                                            <mat-error
                                                *ngIf="isFormSubmitted && _dtoOutputList[i].tmpList[y].locationError">Room is required</mat-error>
                                        </mat-error>
                                        <!-- <div class="form-group mb-0 mt-2 ml-auto d-flex">
                            <input id="exampleFormControlInput2" type="email" placeholder="search"
                              class="form-control w85">
                            
                              <i class="fa fa-plus-circle m-auto" aria-hidden="true"></i>
                          </div> -->
                                        <!-- <div class="form-group mb-0 mt-4 ml-auto d-flex">
                            <input id="exampleFormControlInput3" type="email" placeholder="search"
                              class="form-control w85">                                 
                            <span><img src="../../assets/images/cross-icon.svg" alt="close" class="ml-2 mt-2"/></span>
                          </div> -->
                                    </div>
                                    <div class="col-md-2">
                                        <!-- <label class="m-2">Clinician</label> -->
                                        <!-- <mat-form-field class="">
                                <mat-label>Clinician</mat-label>
                                <mat-select>
                                    <mat-option>
                                        name
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>  -->
                                        <mat-form-field class="w-100">
                                            <mat-label>Clinician/Staff</mat-label>
                                            <mat-select multiple [(ngModel)]="_dtoOutputList[i].tmpList[y].clinician"
                                                #clinicianstaff="ngModel" name="clinicianstaff{{i}}{{y}}" [disabled]="disable">
                                                <mat-option #matOption *ngFor="let itemClinician of _dtoOutputList[i].tmpList[y].clinicians"
                                                    [value]="itemClinician" (click)="clinicianChange(i,itemClinician,'',false,y)">
                                                    {{itemClinician.firstName}} {{itemClinician.lastName}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                        <mat-error *ngIf="isFormSubmitted && _dtoOutputList[i].tmpList[y].clinicianError && !_dtoOutputList[i].tmpList[y].clinician">
                                            <mat-error *ngIf="isFormSubmitted && _dtoOutputList[i].tmpList[y].clinicianError">Clinician/Staff is  required</mat-error>
                                        </mat-error>
                                    </div>
                                    <div class="col-md-2">      
                                        <!-- <label class="m-2">Staff</label> -->
                                        <mat-form-field class="w-100" >
                                            <mat-label>Support/Intern</mat-label>
                                            <mat-select multiple [(ngModel)]="_dtoOutputList[i].tmpList[y].support" 
                                                name="support{{i}}{{y}}" id="support{{i}}{{y}}" [disabled]="disable">
                                                <mat-option #matOption *ngFor="let itemStaff of _dtoOutputList[i].tmpList[y].supportData"
                                                    [value]="itemStaff" (click)="stafChange(i,itemStaff,y,false)">
                                                    {{itemStaff.firstName}} {{itemStaff.lastName}}
                                                </mat-option>
                                            </mat-select>
                                           
                                        </mat-form-field>
                                         <!-- <mat-error *ngIf="isFormSubmitted && _dtoOutputList[i].tmpList[y].supportError && _dtoOutputList[i].tmpList[y].support.length == 0">
                                            <mat-error *ngIf="isFormSubmitted && _dtoOutputList[i].tmpList[y].supportError">Support/Intern is required
                                            </mat-error>
                                        </mat-error> -->
                                        <!-- <mat-form-field class="">
                                <mat-label class="control-label-new">Start Time</mat-label>
                                <input matInput type="time" name="startTime" required 
                                 > 
                            </mat-form-field> -->
                                    </div>
                                    <div class="col-md-2">
                                        <!-- <label class="m-2">Group Leader</label> -->
                                        <mat-form-field class="w-100">
                                            <mat-label>Group Leader</mat-label>
                                            <mat-select multiple [(ngModel)]="_dtoOutputList[i].tmpList[y].leader" #leader="ngModel"
                                                name="leader{{i}}{{y}}" [disabled]="disable">
                                                <mat-option #matOption *ngFor="let itemLeader of _dtoOutputList[i].tmpList[y].leaderListData"
                                                [value]="itemLeader" (click)="leaderChange(i,itemLeader,y)">
                                                {{itemLeader.firstName}} {{itemLeader.lastName}}
                                            </mat-option>
                                                <!-- <mat-option #matOption [value]="_dtoOutputList[i]?.tmpList[y].leaderData">
                                                    {{_dtoOutputList[i]?.tmpList[y]?.clinician?.firstName}}
                                                    {{_dtoOutputList[i]?.tmpList[y]?.clinician?.lastName}}
                                                </mat-option> -->
                                            </mat-select>
                                        </mat-form-field>
                                        <mat-error *ngIf="isFormSubmitted && _dtoOutputList[i].tmpList[y].leaderError && _dtoOutputList[i].tmpList[y].leader.length == 0">
                                            <mat-error *ngIf="isFormSubmitted && _dtoOutputList[i].tmpList[y].leaderError">Group Leader is required
                                            </mat-error>
                                        </mat-error>
                                        <!-- <mat-form-field class="">
                                <mat-label class="control-label-new">End Time</mat-label>
                                <input matInput type="time"  name="endTime" required> 
                            </mat-form-field> -->
                                    </div>
                                    <div class="col-md-3" *ngIf="clientType != 3">
                                        <!-- <label class="m-2">Group Leader</label> -->
                                        <mat-form-field class="w-100">
                                            <mat-label>Skills Taught</mat-label>
                                            <mat-select multiple [(ngModel)]="_dtoOutputList[i].tmpList[y].skillTaught" #skillTaught="ngModel"
                                                name="skillTaught{{i}}{{y}}" [disabled]="disable" (selectionChange)="getAllSkillTaughtQuestionsBasedOnTherapies(_dtoOutputList[i].tmpList[y]);" >
                                                <mat-option #matOption *ngFor="let skillTaughtItem of _dtoOutputList[i].tmpList[y].skillTaughtNames"
                                                [value]="skillTaughtItem.skillTaughtMasterId">
                                                {{skillTaughtItem.skillTaught}}
                                            </mat-option>
                                                <!-- <mat-option #matOption [value]="_dtoOutputList[i]?.tmpList[y].leaderData">
                                                    {{_dtoOutputList[i]?.tmpList[y]?.clinician?.firstName}}
                                                    {{_dtoOutputList[i]?.tmpList[y]?.clinician?.lastName}}
                                                </mat-option> -->
                                            </mat-select>
                                        </mat-form-field>
                                        <mat-error *ngIf="isFormSubmitted && _dtoOutputList[i].tmpList[y].leaderError && _dtoOutputList[i].tmpList[y].leader.length == 0">
                                            <mat-error *ngIf="isFormSubmitted && _dtoOutputList[i].tmpList[y].leaderError">Skills Taught is required
                                            </mat-error>
                                        </mat-error>
                                        <!-- <mat-form-field class="">
                                <mat-label class="control-label-new">End Time</mat-label>
                                <input matInput type="time"  name="endTime" required> 
                            </mat-form-field> -->
                                    </div>
                                </div>
                                <div class="row" *ngIf="clientType != 3">
                                    <div class="col-md-12">
                                        <!-- <mat-error *ngIf="DisSumFrm.submitted && dropoutReason.invalid">
                          <mat-error  *ngIf="dropoutReason.errors.required">
                            This field is required</mat-error>
                      </mat-error> -->
                                    <mat-form-field class="w-100 custom-textarea">
                                        <mat-label>Skills Taught</mat-label>
                                        <textarea matInput maxlength="400" [(ngModel)]="_dtoOutputList[i].tmpList[y].comments"
                                        id="comments{{i}}{{y}}" name="comments{{i}}{{y}}" 
                                        rows="5"
                                        required [ngModelOptions]="{ standalone: true }" [disabled]="disable"></textarea>
                                       
                                      </mat-form-field>
                                      <mat-error *ngIf="isFormSubmitted && _dtoOutputList[i].tmpList[y].commentsError && _dtoOutputList[i].tmpList[y].comments==''">
                                        <mat-error
                                            *ngIf="isFormSubmitted && _dtoOutputList[i].tmpList[y].commentsError">Skills Taught is required</mat-error>
                                    </mat-error>
                                </div>
                                <!-- <div *ngIf ="disable != 'true'">
                                    <span (click)="removeTherapy(therapyVal,y,i)"><img
                                            src="../../assets/images/cross-icon.svg" alt="close"
                                            class="ml-2 mt-2" /></span>
                                </div> -->
                            </div> 
                            </div>
                         
                        </div>
                            
                         
                        </div>
                    </form>
                    </div>
                  </div>
                </div>
               
              
              </div>
        
        
        
                </div>
        
            </div>
           
        
        </div>
        </div>












        <div class="w-100 text-center my-4">

            <button type="submit" class="btn primary-btn-filled-small float-right mr-2" *ngIf = "disable != 'true'"
                (click)="postScheduleGroup()">{{!isUpdate ?
                    'Schedule Group':'Update Group'}}</button>
            <button type="submit" class="btn primary-btn-outlined-small ng-star-inserted float-right mr-2" *ngIf = "disable != 'true'" (click)="cancelGroup()">
                CANCEL</button>
        </div>
    </div>

 
   </div>
</div>

<!-- 
                      <div class="page-header" text="Daily Secheduder :" style=" text-align: center; font-size: 15px; font-weight: 500; margin-bottom: 25px; color: #173a64;">{{pdfDailySchedule.DailySechuder}} </div>
                    -->
