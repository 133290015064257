import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { crba } from 'src/app/_models/_Lead/crba';
import { DtoProgramDetails } from 'src/app/_models/_Lead/crba';
import { DtoBenefitDetails } from 'src/app/_models/_Lead/crba';
import { DtoAuthorizationsDetails } from 'src/app/_models/_Lead/crba';
import { DtoClinicalInformation } from 'src/app/_models/_Lead/crba';
import { DtoAuthorizationsRequiredInfo } from 'src/app/_models/_Lead/crba';
import { ConfirmDialogComponent } from 'src/app/common/confirm-dialog/confirm-dialog.component';
import { SnackBarService } from 'src/app/services/snackBar.service';
import { NgForm } from '@angular/forms';
import { constantVariables } from 'src/app/constant/constant';
import { Global } from 'src/app/global/global.model';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'src/app/common/common.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { LeadService } from 'src/app/patient-lead/lead.service';
import { cloneDeep } from "lodash";
import * as _ from "lodash";
import * as moment from 'moment';
import { DtoInsurance } from 'src/app/_models/_Lead/crba';
import { DtoClientAddress } from 'src/app/_models/_Lead/crba';
import { RoelAccess } from 'src/app/_models/_Common/roleAccessMaster';
import { PatientService } from 'src/app/patient/patient.service';

@Component({
  selector: 'app-crba',
  templateUrl: './crba.component.html',
  styleUrls: ['./crba.component.scss']
})
export class CrbaComponent implements OnInit {
  @ViewChild('downloadLink') downloadLink: ElementRef;
  crbainfo: crba;
  programdays = [
    { key: '0', text: 'Monday' },
    { key: '1', text: 'Tuesday' },
    { key: '2', text: 'Wednesday' },
    { key: '3', text: 'Thursday' },
    { key: '4', text: 'Friday' },
    { key: '5', text: 'Saturday' },

  ];
  prgdays: any = [];
  clinicalInfo: DtoClinicalInformation[] = [];
  patientinfoid: any;
  insuranceDetailsId : any;
  currentdate: any;
  isValidDate: any;
  basicDetails: any;
  afterDate: boolean = false;
  ddlInsuranceType: any = [];
  ddlInsuranceRelationShip: any = [];
  AuthorizationEditable: boolean=true;
  pageFrom:number;
  roleAccess: RoelAccess;
  patientaccIdcrba : any;
  generatePdf : boolean;
  insuranceDetailsIdforview : any;
  ddlStates : any;
  ddlCities : any;
  ddlCitiesLoad: any = [];
  lstPatientDetails: any = [];
  ddlCitiesProviderinfo:any;
  CityName : any;
  HistoryId:Number;
  pageFrom1:number;

  constructor(public leadservice: LeadService, public global: Global,
    private snackbar: SnackBarService, private router: Router, private route: ActivatedRoute,
    public commonService: CommonService, public spinnerservice: NgxSpinnerService,public patientservice: PatientService) { }

  ngOnInit(): void {
  
    this.currentdate = new Date();
    this.crbainfo = new crba();
    this.crbainfo.programDetails = new DtoProgramDetails();
    this.crbainfo.authorizationsDetails = new DtoAuthorizationsDetails();
    this.crbainfo.authorizationsRequiredInfo = new DtoAuthorizationsRequiredInfo();
    this.crbainfo.benefitDetails = new DtoBenefitDetails();
    this.crbainfo.insuranceDetails = new DtoInsurance();
    this.crbainfo.clientAddress = new DtoClientAddress();

    if(Number(localStorage.getItem("HistoryId"))>0)
      this.HistoryId=Number(localStorage.getItem("HistoryId"))
    else
    this.HistoryId=0;

    if (this.clinicalInfo && this.clinicalInfo.length == 0) {
      this.clinicalInfo.push({
        clinicalInformationId: 0,
        authorizationsDetailsId: 0,
        information: "",
        isActive: true
      });
    }
    this.route.queryParams.subscribe(params => {
      if (params != null) {
        if (params.id){
          this.patientinfoid = this.commonService.FrontEndDecryption(params.id).replace(/"/g, "");
        }
        if (params.id && params.patientaccId && params.InsurancetypeId){
          this.patientinfoid = this.commonService.FrontEndDecryption(params.id).replace(/"/g, "");
          this.patientaccIdcrba = this.commonService.FrontEndDecryption(params.patientaccId).replace(/"/g, "");
          this.insuranceDetailsId = this.commonService.FrontEndDecryption(params.InsurancetypeId).replace(/"/g, "");
          localStorage.setItem('InsuranceId',this.insuranceDetailsId);

        }
        if (params.id  && params.InsurancetypeId){
          this.patientinfoid = this.commonService.FrontEndDecryption(params.id).replace(/"/g, "");
          //this.patientaccIdcrba = this.commonService.FrontEndDecryption(params.patientaccId).replace(/"/g, "");
          this.patientaccIdcrba = 0;

          this.insuranceDetailsId = this.commonService.FrontEndDecryption(params.InsurancetypeId).replace(/"/g, "");
          localStorage.setItem('InsuranceId',this.insuranceDetailsId);

        }

        if(params.page != undefined)
        {
          this.pageFrom = JSON.parse(this.commonService.FrontEndDecryption(params.page));
        }
        if(params.PageId != undefined)
          {
            this.pageFrom1 = JSON.parse(this.commonService.FrontEndDecryption(params.PageId));
          }
        
          localStorage.setItem('patientId', this.patientinfoid);
        // this.getCrbaview()
        this.getstate();
        this.getBasicDetails();
        this.getInsuranceMaster();
        
        
      }
    });
    setTimeout(function(){
      document.getElementById("addinfo").click(); 
   }, 1000);
   
  }


  getBasicDetails() {
    //this.spinnerservice.show();
    

    this.leadservice.getBasicDetails(this.patientinfoid).subscribe(
      (res: any) => {

        this.spinnerservice.hide();
        if (res) {
          this.basicDetails = res;

        }
      },
      err => {
        this.spinnerservice.hide();
      },
    );
  }
  getCrbaview() {
    this.spinnerservice.show();
    if(this.insuranceDetailsId){
      this.insuranceDetailsIdforview = this.insuranceDetailsId
      //this.generatePdf = true;
    }
    else {
      this.insuranceDetailsIdforview = 0
    }
    this.leadservice.getCrbaviewInsurance(this.patientinfoid,this.insuranceDetailsIdforview,this.HistoryId).subscribe(
      (res: any) => {
        this.spinnerservice.hide();
        if (res) {
            this.crbainfo = cloneDeep(res);
            
            if(this.crbainfo.benefitDetails.stateId != null && this.crbainfo.benefitDetails.stateId != 0){
              let stateItem = this.ddlStates.filter(x => x.StatesId == this.crbainfo.benefitDetails.stateId);
              this.ddlCitiesProviderinfo = this.ddlCitiesLoad.filter((item) => item.StatesId == stateItem[0].StatesId); 
            }
            if(this.crbainfo.benefitDetails.cityId != null && this.crbainfo.benefitDetails.cityId != 0){
              this.crbainfo.benefitDetails.cityId =  this.crbainfo.benefitDetails.cityId;
            }
          this.clinicalInfo = this.crbainfo.authorizationsDetails.clinicalInformations;
          if (this.clinicalInfo && this.clinicalInfo.length == 0) {
            this.clinicalInfo.push({
              clinicalInformationId: 0,
              authorizationsDetailsId: 0,
              information: "",
              isActive: true
            });
          }
            // this.prgdays = JSON.parse('[' + this.crbainfo.programDetails.programDays.replace(/'/g, '') + ']');
            
          if (this.crbainfo.programDetails.programDays) {
            this.prgdays = this.crbainfo.programDetails.programDays.split(',');
          }
        }
      },
      err => {
        this.spinnerservice.hide();
      },
      
    );
  }

  redirectLeadInfo() {
    this.router.navigate(['/leadinfo'], { queryParams: { id: this.commonService.FrontEndEncryption(this.patientinfoid) } });
  }
  markprogramdays(item, event) {

    if (event.checked) {
      this.prgdays.push(item.key);
    } else {

      this.prgdays.forEach((x, index) => {
        if (x === item.key) this.prgdays.splice(index, 1);
      });
    }
  }
  addclinicalinformation() {
    this.clinicalInfo.push({
      clinicalInformationId: 0,
      authorizationsDetailsId: 0,
      information: "",
      isActive: true
    });
  }
  removeclinicalinformation(data, index) {
    if (data.clinicalInformationId != 0) {
      this.removeexistclicalinfo(data);
    }
    this.clinicalInfo.splice(index, 1);
  }
  removeexistclicalinfo(data) {
    this.leadservice.removeClinicalInfo(data.clinicalInformationId).subscribe((res: any) => {
      this.spinnerservice.hide();
    },
      err => {
        this.spinnerservice.hide();
      }
    );
  }
  onBack(){

    if(this.pageFrom1 == 2){
      this.router.navigate(['/patientslists'])
    }

    if(this.pageFrom == 1)
    {
      this.router.navigateByUrl('/dashboard');
    }

    if(this.generatePdf){
      this.router.navigate(['/cumulative-insurance'], { queryParams: { id: this.commonService.FrontEndEncryption(this.patientinfoid),
        patientAccId : this.commonService.FrontEndEncryption(this.patientaccIdcrba) } });
    }

    
    else{
      this.router.navigateByUrl('/leadlist');
    }


    
  }
  savecrbainfo(form: NgForm) {
    if (!form || this.prgdays.length == 0) {
      return
    }
    var startDate = moment(this.crbainfo.authorizationsDetails.startDate).format("MM/DD/YYYY");
    var endDate = moment(this.crbainfo.authorizationsDetails.endDate).format("MM/DD/YYYY");

    //this.isValidDate = this.validateDates(startDate, endDate);
    // this.isValidDate = moment('2010-10-20').isSameOrAfter('2010-10-19');
    // if (this.isValidDate) {
      this.spinnerservice.show();
    let isError = false;
    for(let i=0;i<this.clinicalInfo.length;i++)
      {
        if (this.clinicalInfo[i].information.length >149)
          {
            this.spinnerservice.hide();
            this.snackbar.error("Clinical information text length should be 150 characters only");
            isError=true;
          }
      }
      if(isError){
        this.spinnerservice.hide();
        return;
      }
      else
      {
      this.crbainfo.patientInfoId = Number(this.patientinfoid);
      this.crbainfo.authorizationsRequiredInfo.ext = String(this.crbainfo.authorizationsRequiredInfo.ext);
      this.crbainfo.programDetails.programDays = this.prgdays.map((item) => { return item }).join(',')
      this.crbainfo.authorizationsDetails.clinicalInformations = this.clinicalInfo;
      this.crbainfo.benefitDetails.coInsurance = Number(this.crbainfo.benefitDetails.coInsurance);
      this.crbainfo.benefitDetails.coPay = Number(this.crbainfo.benefitDetails.coPay);
      this.crbainfo.benefitDetails.deductibleforindividual = Number(this.crbainfo.benefitDetails.deductibleforindividual);
      this.crbainfo.benefitDetails.deductibleforfamily = Number(this.crbainfo.benefitDetails.deductibleforfamily);
      this.crbainfo.benefitDetails.individualDeductible = Number(this.crbainfo.benefitDetails.individualDeductible);
      this.crbainfo.benefitDetails.familyDeductible = Number(this.crbainfo.benefitDetails.familyDeductible);
      this.crbainfo.benefitDetails.maxOutOfPocket = Number(this.crbainfo.benefitDetails.maxOutOfPocket);
      this.crbainfo.benefitDetails.maxOutOfPocketFamily = Number(this.crbainfo.benefitDetails.maxOutOfPocketFamily);
      this.crbainfo.benefitDetails.deductibleUsed = Number(this.crbainfo.benefitDetails.deductibleUsed);
      this.crbainfo.benefitDetails.maxNumberofVisitsPa = Number(this.crbainfo.benefitDetails.maxNumberofVisitsPa);
      this.crbainfo.authorizationsDetails.type = Number(this.crbainfo.authorizationsDetails.type);
      this.crbainfo.authorizationsDetails.daysPerWeek = Number(this.crbainfo.authorizationsDetails.daysPerWeek);
      this.crbainfo.authorizationsDetails.totalVisits = Number(this.crbainfo.authorizationsDetails.totalVisits);
      this.crbainfo.benefitDetails.address = String(this.crbainfo.benefitDetails.address);
      this.crbainfo.benefitDetails.stateId = Number(this.crbainfo.benefitDetails.stateId);
      this.crbainfo.benefitDetails.cityId = Number(this.crbainfo.benefitDetails.cityId);
      this.crbainfo.benefitDetails.zipcode = Number(this.crbainfo.benefitDetails.zipcode);
      this.crbainfo.authorizationsRequiredInfo.comments = this.crbainfo.authorizationsRequiredInfo.comments;
      this.crbainfo.programDetails.insuranceId=this.insuranceDetailsId;


      this.spinnerservice.show();
      const dataToEncrypt = this.commonService.DataToEncrypt(this.crbainfo, false);
      this.leadservice.postProgramDetails(this.crbainfo).subscribe((res: any) => {
      //  this.spinnerservice.hide();
        if (res) {
          this.crbainfo.crbainformationId = Number(res);
          this.SaveBenefitDetails();
        }
        else
        {
          this.spinnerservice.hide();
         // this.snackbar.error("Please check the benefits  details");
        }
      },
        err => {
          this.spinnerservice.hide();
         this.snackbar.error("Please check the benefits  details");
        }
      );
    // } else {
    //   this.afterDate = true;
    //   this.snackbar.error(constantVariables.ErrorMessage.endate)
    //   return
    // }
      }
  }
  validateDates(sDate: string, eDate: string) {
    this.isValidDate = true;
    if ((sDate == null || eDate == null)) {

      this.isValidDate = false;
    }

    if ((sDate != null && eDate != null) && (eDate) < (sDate)) {
      this.afterDate = true;
      this.isValidDate = false;
    }
    return this.isValidDate;
  }
  SaveBenefitDetails() {

    const dataToEncrypt = this.commonService.DataToEncrypt(this.crbainfo, false);
    this.leadservice.postBenefitDetails(this.crbainfo).subscribe((res: any) => {
     // this.spinnerservice.hide();
      if (res) {
        this.SaveAuthorization();
      }
      else
      {
        this.spinnerservice.hide();
        this.snackbar.error("Please check the benefits  details");
      }
    },
      err => {
        this.spinnerservice.hide();
        this.snackbar.error("Please check the benefits  details");
      }
    );
  }
  SaveAuthorization() {
    const dataToEncrypt = this.commonService.DataToEncrypt(this.crbainfo, false);
    this.leadservice.postAuthorization(this.crbainfo).subscribe((res: any) => {
     // this.spinnerservice.hide();
      if (res) {
        this.spinnerservice.hide();
        this.snackbar.success(res);
        if(this.pageFrom1 == 2){
          this.router.navigate(['/patientslists'])
        }
    
        else
        {
        this.router.navigateByUrl('/leadlist');
        }
      }
      else
      {
        this.spinnerservice.hide();
        this.snackbar.error("Please check the benefits  details");
      }
    },
      err => {
        this.spinnerservice.hide();
        this.snackbar.error("Please check the benefits  details");
      }
    );
  }
  getInsuranceMaster() {

    this.leadservice.getInsuranceMaster().subscribe(
      res => {
        this.spinnerservice.hide();
        if (res != null) {
          this.ddlInsuranceType = this.commonService.decrypdata(res.insuranceType);
          this.ddlInsuranceRelationShip = this.commonService.decrypdata(res.insuranceRelationship);

        }
      },
      err => {
        this.spinnerservice.hide();
      },
    );
  }
  GetBenefitsAndAuthoizationPdf() {
    this.spinnerservice.show();

    this.patientservice.GetBenefitsAndAuthorizationInsurancePdf(this.patientaccIdcrba,this.patientinfoid,this.insuranceDetailsId,this.HistoryId).subscribe(
      (res: any) => {
        this.spinnerservice.hide();
          const linkSource = 'data:application/pdf;base64,' + res.base64File + '';
           this.downloadLink.nativeElement.href = res.base64File;
          this.downloadLink.nativeElement.download = res.fileName;
          this.downloadLink.nativeElement.click();
         
      },
      err => {
        this.spinnerservice.hide();
      },
    );
  }
  // getCrbaviewInsurance() {
  //   this.spinnerservice.show();

  //   this.leadservice.getCrbaviewInsurance(this.insuranceDetailsId).subscribe(
  //     (res: any) => {
  //       this.spinnerservice.hide();
  //       if (res) {
  //           this.crbainfo = cloneDeep(res);
  //         this.clinicalInfo = this.crbainfo.authorizationsDetails.clinicalInformations;
  //         if (this.clinicalInfo && this.clinicalInfo.length == 0) {
  //           this.clinicalInfo.push({
  //             clinicalInformationId: 0,
  //             authorizationsDetailsId: 0,
  //             information: "",
  //             isActive: true
  //           });
  //         }
  //           // this.prgdays = JSON.parse('[' + this.crbainfo.programDetails.programDays.replace(/'/g, '') + ']');
            
  //         if (this.crbainfo.programDetails.programDays) {
  //           this.prgdays = this.crbainfo.programDetails.programDays.split(',');
  //         }
  //       }
  //     },
  //     err => {
  //       this.spinnerservice.hide();
  //     },
      
  //   );
  // }
  // async getstate(){
  //   this.spinnerservice.show();
  //   var res = await this.leadservice.getstateMasterForAllState();
  //   this.ddlStates = this.commonService.decrypdata(res.states);
  //   this.ddlCitiesLoad = this.commonService.decrypdata(res.cities);


  //   if (this.crbainfo.insuranceDetails.StatesId) {
  //     if (this.ddlCitiesLoad.length > 0) {
  //       this.spinnerservice.hide();
  //       this.ddlCities = this.ddlCitiesLoad.filter(x => x.StatesId == this.crbainfo.insuranceDetails.StatesId)
  //     } else {
  //       this.leadservice.getcities(this.crbainfo.insuranceDetails.StatesId).then(
  //         res => {
  //           this.spinnerservice.hide();
  //           this.ddlCities = this.commonService.decrypdata(res.cities);
  //         },
  //         error =>
  //           this.spinnerservice.hide()
  //       );
  //     }
  //   }

  //   // this.leadservice.getcities(this.crbainfo.insuranceDetails.StatesId).then(
  //   //   res => {
  //   //     this.spinnerservice.hide();
  //   //     this.ddlCities = this.commonService.decrypdata(res.cities);
  //   //   })
  // }
  // getCity(){
  //   this.leadservice.getcities(this.crbainfo.insuranceDetails.StatesId).then(
  //     res => {
  //       this.spinnerservice.hide();
  //       this.ddlCities = this.commonService.decrypdata(res.cities);
  //     })
  // }
  getstate() {
    this.spinnerservice.show();
if(this.global.gblStates.length > 0 && this.global.gblCities.length > 0 && this.global.gblGrade.length > 0)
{
  this.ddlStates= this.global.gblStates;
  this.ddlCitiesLoad = this.global.gblCities; 
  this.spinnerservice.hide();
   this.getCrbaview();

}else{
  this.leadservice.getstateMaster().subscribe(
    res => {
      this.spinnerservice.hide();
      if (res != null) {
        this.ddlStates= this.commonService.decrypdata(res.states);
        this.ddlCitiesLoad = this.commonService.decrypdata(res.cities);  
         this.getCrbaview();

       }
       
    },
    err => {
      this.spinnerservice.hide();
    },
  );
}

  
  }
  onStateChnage(state) {
    // this.isSubmit = false;
    let stateItem = this.ddlStates.find(x => x.StatesId == state);
    this.ddlCitiesProviderinfo = this.ddlCitiesLoad.filter((item) => item.StatesId == stateItem.StatesId); 
    this.crbainfo.benefitDetails.cityId = 0;
  }
  onChangeProviderinfoCity(city)
  {
   let stId=this.ddlCitiesProviderinfo.find(x=>x.CitiesId==city.value).StatesId;
   this.ddlStates.filter((item) => item.StatesId == stId);
   
  this.crbainfo.benefitDetails.stateId=this.ddlStates.find(x=>x.StatesId==stId).StatesId;
  this.crbainfo.benefitDetails.cityId = city.value;
  }
  onloadcityforProvider(id) {
      
    this.leadservice.getcities(id).then(
      res => {
        this.ddlCitiesLoad = this.commonService.decrypdata(res.cities);
        this.CityName=this.ddlCitiesProviderinfo.find(x=>x.CitiesId==this.crbainfo.benefitDetails.cityId).CityName;   
        this.crbainfo.benefitDetails.cityId = this.crbainfo.benefitDetails.cityId;     
           },
      error =>
        this.spinnerservice.hide()
    );
  }
}
