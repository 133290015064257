 <div class="comment-popup re-schedule-popup anyclick-popup">
    <div class="modal" tabindex="-1" role="dialog" id="scheduleAppointment" [style.display]="isSchedule ? 'block' : 'none'">
      <div class="modal-dialog mat-dialog-container " role="document">
        <div class="modal-content border-0">
          <div class="modal-header">
            <h6 class="modal-title"> Schedule Appointment</h6>
            <button type="button" class="close"  data-dismiss="modal" aria-label="Close" (click)="OnCancelReschedule()">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
  <div >
    <form (ngSubmit)="this.isFormSubmitted=true;scheduleModality.form.valid && getConflictsModality(); " id="scheduleModality"
    autocomplete="off" #scheduleModality="ngForm" novalidate>
    <div class="col-md-12">
        <div class="row mb-2">
          <div class="col-md-6">
            <div class="row">
              <div class="col-md-5">
                <label class="control-label-new font-weight-bold">Location</label>
        
              </div>
        
               <div class="col-md-7 form-group">
                <select class="form-control  mb-2" [(ngModel)]="dtoScheduleModality.location"
                #location="ngModel" name="location" required (change)="getMeetingLocationById($event.target.value)">
                <option value=0>--Select Location--</option>
                <option *ngFor="let a of ddlSfccLocationforclinician" value="{{a.sfccMasterId}}">
                   {{a.locationName}}
                </option>
             </select>
             <mat-error *ngIf="scheduleModality.submitted && (location.invalid||dtoScheduleModality.location==0)">
                <mat-error class="mat-form-field-subscript-wrapper mt-3"
                   *ngIf="dtoScheduleModality.location==0">Location
                   is
                   required</mat-error>
             </mat-error>
              </div>
              </div>
          </div>

          <div class="col-md-6">
            <div class="row">
              <div class="col-md-5">
                <label class="control-label-new font-weight-bold">Client Name</label>
               </div>
                 <!-- <div class="col-md-7 form-group"> -->
                  <!-- <select class="form-control mb-2"  id="searchDropdown" name="searchDropdown"
                  [(ngModel)]="dtoScheduleModality.patientAccountId" #patientAccountId="ngModel" 
                  (change)="getModalityForSchedule(dtoScheduleModality.patientAccountId)" required>
                  <option value="undefined">--Select Client--</option>
                  <option *ngFor="let a of ddlpatientList" value="{{a.patientAccountId}}">
                     {{a.patientName}}
                  </option>
               </select> -->

               <div class="col-md-7 client-mat-autocomplete">
                <mat-form-field>
                  <input matInput name="patientAccountId" [(ngModel)]="searchpatientName" #patientAccountId="ngModel"
                   placeholder="Select Client" [matAutocomplete]="auto" required 
                   
                   (input)="filterClients($event.target.value)">
                    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="getPatientId($event)" class="client-mat-autocomplete-list">
                        <mat-option *ngFor="let a of filteredClientName" [value]="a.patientName">
                            {{a.patientName}}
                        </mat-option>
                    </mat-autocomplete> 
                </mat-form-field>
                <mat-error *ngIf="scheduleModality.submitted && patientAccountId.invalid">
                  <mat-error class="mat-form-field-subscript-wrapper mt-3"
                     *ngIf="patientAccountId.errors.required">Client Name is required</mat-error>
               </mat-error>
               </div>

               
                <!-- <p-autoComplete name="patientAccountId"  [(ngModel)]="dtoScheduleModality.patientAccountId" #patientAccountId="ngModel"
                [suggestions]="ddlpatientList" 
                 field="patientName">
              </p-autoComplete> -->
              <!-- <div>
                <p-autoComplete name="patientAccountId" 
                [(ngModel)]="dtoScheduleModality.patientAccountId.patientAccountId" #patientAccountId="ngModel"
                [suggestions]="filteredClientName" 
                (onSelect)="getModalityForSchedule(dtoScheduleModality.patientAccountId)"
                (completeMethod)="filterClients($event)" field="patientName"></p-autoComplete>
            </div> -->
            
            
               
                <!-- </div> -->
            </div>
          </div>
        </div>
           
      <div class="row mb-2">
        <div class="col-md-6">
          <div class="row">
            <div class="col-md-5">
              <label class="control-label-new font-weight-bold">Appointment Type</label>
             </div>
               <div class="col-md-7 form-group">
                <select class="form-control mb-2" name="therapyId"
                [(ngModel)]="dtoScheduleModality.therapyId" #therapyId="ngModel" name="therapyId"
                (change)="ModalityChange(dtoScheduleModality.therapyId)"  required>
                <option value="undefined">--Select Appointment Type--</option>
                <option *ngFor="let a of _dtoModality" value="{{a.therapyId}}">
                   {{a.therapyName}}
                </option>
             </select>
             <mat-error *ngIf="scheduleModality.submitted && therapyId.invalid">
               <mat-error class="mat-form-field-subscript-wrapper mt-3"
                  *ngIf="therapyId.errors.required">Appointment Type is required</mat-error>
            </mat-error>
              </div>
            </div>
            </div>


        <div class="col-md-6">
          <div class="row">
            <div class="col-md-5">
              <label class="control-label-new font-weight-bold">Appointment  Date</label>
      
            </div>
      
             <div class="col-md-7  ">
              <mat-form-field class="example-full-width w-100 " >
                <mat-label>Choose a date</mat-label>
                <input required matInput [matDatepicker]="picker2" readonly
                   [min]="minDate"  (dateChange)="handleStartDatechange($event)"
                   [(ngModel)]="dtoScheduleModality.startDate"
                   #startDate="ngModel" name="startDate" />
                <mat-datepicker-toggle matSuffix [for]="picker2">
                </mat-datepicker-toggle>
                <mat-datepicker #picker2></mat-datepicker>
                <mat-error *ngIf="scheduleModality.submitted && startDate.invalid">
                   <mat-error *ngIf="startDate.errors.required">Appointment  Date is required
                   </mat-error>
                </mat-error>
             </mat-form-field>
            </div>
          </div>
        </div>
      
      </div>
      
      
       <div class="row mb-2">
          <div class="col-md-6">
            <div class="row">
              <div class="col-md-5">
                <label class="control-label-new font-weight-bold">Appointment  Time</label>
              </div>
              <div class="col-md-7 ">
                <mat-form-field class="w-100 mt--14">
                  <mat-label>Select appointment time </mat-label>
                <mat-select   #startTime="ngModel" (selectionChange)="OnTimeChange()" [(ngModel)]="dtoScheduleModality.startTime" name="startTime" [min]="currenttime"
                max="12:00" required
                required>
                <mat-option *ngFor="let item of meetingTimeslots" [value]="item.slotTime">
                    {{item.slotTime | date: 'hh:mm a'}}
                </mat-option>
            </mat-select>
            
            <mat-error *ngIf="scheduleModality.submitted && startTime.invalid">
              <mat-error *ngIf="startTime.errors.required ">Appointment Time is required
              </mat-error>
          </mat-error>
          </mat-form-field>
          <mat-error class="text-xs" *ngIf="IspastTime">
            Appointment Time is invalid
            </mat-error>
              </div>
              </div>
          </div>
          <div class="col-md-6">
            <div class="row">
              <div class="col-md-5">
                <label class="control-label-new font-weight-bold">Duration</label>
        
              </div>
        
                <div class="col-md-4 form-group">
                <select class="form-control mb-1" name="duration1"
                [(ngModel)]="durationhr"  required (change)="hoursChange(durationhr)">
                <option value="undefined">hr</option>         
                <option value=0>0 hr</option>
                <option value=60>1 hr</option>
                <option value=120>2 hr</option>
                <option value=180>3 hr</option>
                <option value=240>4 hr</option>
                <option value=300>5 hr</option>
                <option value=360>6 hr</option>
                <option value=420>7 hr</option>
                <option value=480>8 hr</option>
              </select>
              <mat-error *ngIf="scheduleModality.submitted && (durationmin==undefined || durationhr==undefined)">
                <mat-error class="mat-form-field-subscript-wrapper mt-3"
                    *ngIf="( durationhr==undefined)">Hours required</mat-error>
              </mat-error>
              <mat-error class="mat-form-field-subscript-wrapper mt-3" *ngIf="IsDurationInvalid">
              Duration is invalid
                </mat-error>
              </div>
              <div class="col-md-3 form-group">
                <select class="form-control " name="duration2"
                [(ngModel)]="durationmin"  required (change)="minChange(durationmin)">
                <option value="undefined">mins</option>
                <option value=00>00 mins</option>
                <option value=15>15 mins</option>
                <option value=30>30 mins</option>
                <option value=45>45 mins</option>             
              </select>
              <mat-error *ngIf="scheduleModality.submitted && (durationmin==undefined || durationhr==undefined)">
              <mat-error class="mat-form-field-subscript-wrapper mt-3"
                  *ngIf="(durationmin==undefined )">Mins required</mat-error>
            </mat-error>
              </div>
              
              </div>
          </div>
       </div> 
    
      <div class="row mb-2">
        <div class="col-md-6">
          <div class="row">
            <div class="col-md-5">
              <label class="control-label-new font-weight-bold">Provider</label>
             </div>
               <div class="col-md-7 form-group">
                <select class="form-control mb-2" 
                [(ngModel)]="dtoScheduleModality.provider" #provider="ngModel" name="provider" required (change)="modalityChange(dtoScheduleModality.provider)">
                <option value="undefined">--Select Provider--</option>
                <option *ngFor="let a of ddlStafflist" value="{{a.staffId}}">
                   {{a.firstName}} {{a.lastName}}
                </option>
             </select>
             <mat-error *ngIf="scheduleModality.submitted && provider.invalid">
              <mat-error class="mat-form-field-subscript-wrapper mt-3"
                  *ngIf="provider.errors.required">Provider is required</mat-error>
            </mat-error>
              </div>
          </div>
        </div>

        <div class="col-md-6">
          <div class="row">
            <div class="col-md-5">
              <label class="control-label-new font-weight-bold">Meeting Room</label>
      
            </div>
      
              <div class="col-md-7 form-group">
              <select class="form-control mb-2" [(ngModel)]="dtoScheduleModality.meetingRoom"
                #meetingRoom="ngModel" name="meetingRoom" required (change)="meetingRoomChange(dtoScheduleModality.meetingRoom)">
                <option value="undefined">--Select Location--</option>
                <option *ngFor="let a of ddlMeetinglocation" value="{{a.meetingLocationsId}}">
                    {{a.meetingName}}
                </option>
                </select>
                <mat-error *ngIf="scheduleModality.submitted && meetingRoom.invalid">
                  <mat-error class="mat-form-field-subscript-wrapper mt-3"
                      *ngIf="meetingRoom.errors.required">Meeting Room is required</mat-error>
                </mat-error>
            </div>
            </div>
        </div>
       
      </div>
     
      <div class="row mb-2">
        <div class="col-md-6">
          <div class="row">
            <div class="col-md-5">
              <label class="control-label-new font-weight-bold">Meeting Type</label>
              </div>
              <div class="col-md-7 form-group">
                 <select class="form-control mb-2"  [(ngModel)]="dtoScheduleModality.meetingType" #meetingType="ngModel" name="meetingType" required (change)="meetingTypeChange(dtoScheduleModality.meetingType)">
                     <!-- <option [value]=0>Select Meeting Type</option> -->
                     <option value="undefined">--Select Meeting Type--</option>
                     <option *ngFor="let item of ddlMeetingType " [value]="item.msMeetingTypeId" >{{item.meetingType}}</option>
                 </select>
                 <mat-error *ngIf="scheduleModality.submitted && meetingType.invalid">
                  <mat-error class="mat-form-field-subscript-wrapper mt-3"
                       *ngIf="meetingType.errors.required">Meeting Type is required</mat-error>
                 </mat-error>
           </div>
            </div>
            </div>
            <div class="col-md-6">
              <div class="row">
                <div class="col-md-5">
                  <label class="control-label-new font-weight-bold">Admin Notes</label>
          
                </div>
          
                 <div class="col-md-7 form-group">
                  <textarea cols="2" cols="2" class="form-control font-italic" maxlength="400" #adminNotes="ngModel" name="adminNotes"  [(ngModel)]="dtoScheduleModality.adminNotes"   placeholder="Enter admin  notes" ></textarea>
                 
                </div>
                </div>
                </div>
        
    </div>
     

    </div>
    <div class="text-right col-md-12 mt-2">
      <button mat-raised-button class="btn primary-btn-filled primary-btn-m h-40 mr-2"  >Schedule</button>
      <button type="button" class="btn btn-cancel" data-dismiss="modal"  (click)="OnCancelReschedule()" >Cancel</button>
    </div>
  
  
  </form>
  </div>
  
  
  </div>
  </div>
  </div>
  </div>
  </div> 

 <div class="comment-popup re-schedule-popup blocktime-popup">
  <div class="modal" tabindex="-1" role="dialog" id="blockTime" [style.display]="isBlockTime ? 'block' : 'none'">
    <div class="modal-dialog mat-dialog-container" role="document">
      <div class="modal-content border-0">
        <div class="modal-header px-5">
          <h6 class="modal-title">BLOCK TIME FRAME</h6>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            (click)="OnCancelReschedule()"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div>
            <form class="px-5"
              (ngSubmit)="blockTime.form.valid && getConflictsModality()"
              id="blockTime"
              autocomplete="off"
              #blockTime="ngForm"
              novalidate
            >
              <div class="row mb-2">
                <div class="col-md-4 pr-0 pl-0">
                  <label class="control-label-new font-weight-bold"
                    >Purpose of Blocking</label
                  >
                </div>
                <div class="col-md-5 pl-0">
                  <div class="form-group">
                    <select
                      class="form-control mb-2"
                      name="purposeMasterId"
                      [(ngModel)]="dtoScheduleModality.blockTimeId"
                      #purposeMasterId="ngModel"
                      
                      required
                    >
                      <option value="undefined">--Select Purpose--</option>
                      <option
                        *ngFor="let a of purposeOfBlockTime"
                        value="{{ a.blockTimeId }}"
                      >
                        {{ a.dtoPurposeMaster.purposeName }}
                      </option>
                    </select>
                    <mat-error
                      *ngIf="
                      blockTime.submitted && purposeMasterId.invalid"
                    >
                      <mat-error
                        class="mat-form-field-subscript-wrapper mt-3"
                        *ngIf="purposeMasterId.errors.required"
                        >Purpose of Blocking is required</mat-error
                      >
                    </mat-error>
                  </div>
                </div>
              </div>
  
                <div class="row">
                  <div class="col-md-4 pr-0 pl-0">
                    <label class="control-label-new font-weight-bold">Start time</label>
            
                  </div>
                  <div class="col-md-7 ">
                    <mat-form-field class="w-100 mt--14">
                      <mat-label>Select start time </mat-label>
                    <mat-select   #startTime="ngModel" (selectionChange)="OnTimeChange()" [(ngModel)]="dtoScheduleModality.startTime" name="startTime" [min]="currenttime"
                    max="12:00" required
                    required>
                    <mat-option *ngFor="let item of meetingTimeslots" [value]="item.slotTime">
                        {{item.slotTime | date: 'hh:mm a'}}
                    </mat-option>
                </mat-select>
               
              </mat-form-field>
               <mat-error class="text-xs" *ngIf="IspastTime">
                Start  Time is invalid
                </mat-error>
                  </div>
                   <!-- <div class="col-md-4 form-group pl-0">
                    
                    <select class="form-control mb-1" name="duration1"
                    [(ngModel)]="durationhr"  required>
                    <option value="undefined">hr</option>         
                    <option value=0>0 hr</option>
                    <option value=60>1 hr</option>
                    <option value=120>2 hr</option>
                    <option value=180>3 hr</option>
                    <option value=240>4 hr</option>
                    <option value=300>5 hr</option>
                    <option value=360>6 hr</option>
                    <option value=420>7 hr</option>
                    <option value=480>8 hr</option>
                 </select>
                 <mat-error *ngIf="scheduleModality.submitted && (durationmin==undefined || durationhr==undefined)">
                    <mat-error class="mat-form-field-subscript-wrapper mt-3"
                       *ngIf="( durationhr==undefined)">*required</mat-error>
                 </mat-error>
                  </div> -->
                  <!-- <div class="col-md-3 form-group">
                    <select class="form-control " name="duration2"
                    [(ngModel)]="durationmin"  required>
                    <option value="undefined">mins</option>
                    <option value=00>00 mins</option>
                    <option value=15>15 mins</option>
                    <option value=30>30 mins</option>
                    <option value=45>45 mins</option>             
                 </select>
                 <mat-error *ngIf="scheduleModality.submitted && (durationmin==undefined || durationhr==undefined)">
                  <mat-error class="mat-form-field-subscript-wrapper mt-3"
                     *ngIf="(durationmin==undefined )">*required</mat-error>
               </mat-error>
                  </div> -->
                  <!-- <mat-error class="text-xs" *ngIf="IsDurationInvalid">
                   Duration is invalid
                    </mat-error> -->
                  </div> 
                  <div class="row">
                    <div class="col-md-4 pr-0 pl-0">
                      <label class="control-label-new font-weight-bold">Duration</label>
              
                    </div>
              
                     <div class="col-md-4 form-group pl-0">
                      <select class="form-control mb-1" name="duration1"
                      [(ngModel)]="durationhr"  required>
                      <option value="undefined">hr</option>         
                      <option value=0>0 hr</option>
                      <option value=60>1 hr</option>
                      <option value=120>2 hr</option>
                      <option value=180>3 hr</option>
                      <option value=240>4 hr</option>
                      <option value=300>5 hr</option>
                      <option value=360>6 hr</option>
                      <option value=420>7 hr</option>
                      <option value=480>8 hr</option>
                   </select>
                   <mat-error *ngIf="blockTime.submitted && (durationmin==undefined || durationhr==undefined)">
                      <mat-error class="mat-form-field-subscript-wrapper mt-3"
                         *ngIf="( durationhr==undefined)">Hours required</mat-error>
                   </mat-error>
                   <mat-error class="mat-form-field-subscript-wrapper mt-3" *ngIf="IsDurationInvalid">
                    Duration is invalid
                     </mat-error>
                    </div>
                   
                    <div class="col-md-3 form-group">
                      <select class="form-control " name="duration2"
                      [(ngModel)]="durationmin"  required>
                      <option value="undefined">mins</option>
                      <option value=00>00 mins</option>
                      <option value=15>15 mins</option>
                      <option value=30>30 mins</option>
                      <option value=45>45 mins</option>             
                   </select>
                   <mat-error *ngIf="blockTime.submitted && (durationmin==undefined || durationhr==undefined)">
                    <mat-error class="mat-form-field-subscript-wrapper mt-3"
                       *ngIf="(durationmin==undefined )"> Mins required</mat-error>
                 </mat-error>
                    </div>
                   
                    </div>
       

              <div class="row mb-2">
                <div class="col-md-4 pr-0 pl-0">
                  <label class="control-label-new font-weight-bold"
                    >Notes</label
                  >
                </div>
                <div class="col-md-7 pl-0">
                  <textarea
                    cols="3"
                    class="form-control font-italic"
                    placeholder=""
                    [(ngModel)]="dtoScheduleModality.notes"
                    #notes="ngModel"
                    name="notes"
                    maxlength="400"
                    resize="none"
                  ></textarea>
                  <!-- <mat-error
                  *ngIf="
                  blockTime.submitted && notes.invalid"
                > -->
                  <!-- <mat-error
                    class="mat-form-field-subscript-wrapper mt-3"
                    *ngIf="notes.errors.required"
                    >Notes is required</mat-error
                  >
                </mat-error> -->
                </div>
              </div>

              <div class="text-right col-md-12 mt-3">
                <button
                  mat-raised-button
                  class="btn primary-btn-filled primary-btn-m h-40 mr-2"
                >
                  Save
                </button>
                <button
                  type="button"
                  class="btn btn-cancel"
                  data-dismiss="modal"
                  (click)="OnCancelReschedule()"
                >
                  Clear
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>  

 <div class="comment-popup re-schedule-popup selecttime-popup">
  <div class="modal" tabindex="-1" role="dialog" id="selectTime" [style.display]="isScheduleOrBlock ? 'block' : 'none'">
    <div class="modal-dialog mat-dialog-container" role="document">
      <div class="modal-content border-0">
         <div class="modal-header border-0">
       
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            (click)="OnCancelReschedule()"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div>
            <form
              (ngSubmit)="scheduleModality.form.valid && getConflictsModality()"
              id="scheduleModality"
              autocomplete="off"
              #scheduleModality="ngForm"
              novalidate
            >
              <div class="row mb-2 pb-4">
                <div class="col-md-12 mt-2 mb-4 text-center">
                  <p>Kindly choose the required service</p>
                </div>
                <div class="d-flex m-auto">
                  <button
                    mat-raised-button
                    class="btn primary-btn-filled primary-btn-m h-40 mr-2"
                  (click)="ScheduleAppointment()">
                    Schedule Appointment
                  </button>
                  <button
                    type="button"
                    class="btn primary-btn-filled primary-btn-m h-40 mr-2"
                    data-dismiss="modal"
                    (click)="BlockTime()"
                  >
                    Block Time
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>  
